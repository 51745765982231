import { useMutation } from 'react-query'

import useUpload from './useUpload'
import useRequest from './useRequest'
import * as gql from '../gql/queries'
import * as endpoints from '../config/endpoints'

export default function useEditForm ({ editMutation, entityId, entity, uploadFields = [] }) {
  const { request } = useRequest()
  const [editData, { error, isLoading }] = useMutation((variables) => request({ query: gql[editMutation.query], variables, endpoint: endpoints[editMutation.endpoint] }))

  const uploadFn = useUpload({ entity, uploadFields })

  async function submit (passedValues) {
    const values = await uploadFn({ ...passedValues }, entityId)

    const editedData = await editData({ ...values, _id: entityId })
    if (!editData) return undefined

    return editedData
  }

  return [submit, { isLoading, error }]
}
