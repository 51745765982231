const parse = (json) =>
  JSON.parse(json, (key, value) => {
    if (typeof value === 'string' && value.startsWith('$$FUNCTION')) {
      try {
        // eslint-disable-next-line no-new-func
        const f = new Function(value.trim().replace('$$FUNCTION', 'return'))
        return f()
      } catch (e) {
        if (process.env.NODE_ENV === 'development') {
          // eslint-disable-next-line no-debugger
          debugger
          console.log('Failed to parse function: ', { e, value })
        }
      }
    }
    return value
  })

export default parse
