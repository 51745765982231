import { Controller, FormProvider, useFieldArray, useForm, useWatch } from 'react-hook-form'
import React, { useEffect, useMemo, useReducer, useRef } from 'react'
import { find, isNil, map, path, pathEq, pathOr, pluck, prop, propEq, propOr, reject } from 'ramda'
import Grid from '@material-ui/core/Grid'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import Button from '../../core/Button/Button'
import useRequest from '../../../helpers/useRequest'
import { useMutation, useQuery } from 'react-query'
import { EDIT_ADMISSION, GET_EVENTS } from '../../../gql/queries'
import SelectSync from '../../core/SelectSync/SelectSync'
import getErrorMessages from '../../../helpers/getErrorMessages'
import AutoComplete from '../../core/AutoComplete/AutoComplete'
import Typography from '@material-ui/core/Typography'
import RemoveIcon from '@material-ui/icons/Remove'
import AddIcon from '@material-ui/icons/Add'
import Checkbox from '../../core/Checkbox/Checkbox'
import { useSnackbar } from 'notistack'
import { queryCache } from '../../../index'
import { useDialog } from 'muibox'
import { useTheme } from '@material-ui/core/styles'
import EditIcon from '@material-ui/icons/Edit'
import * as endpoints from '../../../config/endpoints'

export const useGetExams = (round) => {
  const { request } = useRequest()

  const { isLoading: loading, data } = useQuery(
    ['events', 'round', round],
    () => request({ query: GET_EVENTS, variables: { round }, endpoint: endpoints[process.env.REACT_APP_ENDPOINT] }),
    { enabled: !!round },
  )

  const allExams = useMemo(() => pathOr([], ['getEvents', 'items'], data), [data])

  return { loading, allExams }
}

function ProctorExamFieldItem ({ control, setValue, index, allExams, allExamOptions, field: defaultValue, fields: events }) {
  const field = useWatch({ name: `events.${index}`, control, defaultValue })
  const examId = field?.event ?? ''
  const alreadyExists = !!find(propEq('event', examId), events)?._id
  const exam = useMemo(() => find(propEq('_id', examId), allExams), [examId, allExams])
  const isEST1 = pathEq(['name', 'en'], 'EST I', exam)
  const tickets = field.tickets ?? (isEST1 ? '' : [])

  const subjects = useMemo(() => {
    return map((v) => ({ label: v.name, key: v.key }), pathOr([], ['tickets'], exam))
  }, [exam])

  const examOptions = useMemo(() => {
    const examIds = new Set(pluck('event', events ?? []))
    return reject(({ key }) => examIds.has(key) && key !== examId, allExamOptions)
  }, [allExamOptions, events, examId])

  return (
    <>
      <Grid item xs={12} sm={6}>
        <Controller
          name={`events.${index}.event`}
          render={({ value, onChange }) =>
            (
              <SelectSync
                disabled={alreadyExists}
                label='Exam'
                options={examOptions}
                margin='normal'
                value={value}
                onChange={(e) => {
                  onChange(e.target.value)
                  if (tickets && setValue) setValue(`events.${index}.tickets`, isEST1 ? '' : [])
                }}
              />
            )}
          defaultValue={examId}
          rules={{ required: 'Exam is required' }}
          control={control}
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <Controller
          name={`events.${index}.tickets`}
          render={({ value, onChange }) =>
            (
              <SelectSync
                disabled={!examId}
                multiple={!isEST1}
                label='Subjects'
                options={subjects}
                margin='normal'
                value={value}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          rules={{
            validate: (data) => reject(isNil, data).length > 0 ? true : 'Subject is required',
          }}
          defaultValue={tickets}
          control={control}
        />
      </Grid>
    </>
  )
}

export const AdmissionEventFieldArray = ({ control, setValue }) => {
  const [renderCount, forceUpdate] = useReducer((c) => c + 1, 0)
  const { fields, append, remove } = useFieldArray({ control, name: 'events' })
  const round = useWatch({ name: 'round', control })

  const { allExams } = useGetExams(round)
  const handleAdd = () => append({})
  const handleRemove = (index) => {
    remove(index)
    forceUpdate()
  }

  const allExamOptions = useMemo(
    () => map((v) => ({ label: path(['name', 'en'], v), key: v._id }), allExams),
    [allExams],
  )

  const noMoreExams = fields.length === allExamOptions.length

  return (
    <Grid container item={12} spacing={2}>
      {fields.map((exam, index) => (
        <React.Fragment key={exam.id}>
          <Grid container item xs={11} spacing={2}>
            <ProctorExamFieldItem
              index={index}
              allExamOptions={allExamOptions}
              control={control}
              setValue={setValue}
              allExams={allExams}
              field={exam}
              fields={fields}
              key={renderCount}
            />
          </Grid>

          <Grid item xs={1}>
            <IconButton onClick={() => handleRemove(index, exam)}>
              <RemoveIcon />
            </IconButton>
          </Grid>
        </React.Fragment>
      ))}
      {!noMoreExams && (

        <Grid item xs={12}>
          <Button
            fullWidth
            variant='contained'
            color='secondary'
            onClick={handleAdd}
          >
            <AddIcon />
            Add {fields.length > 0 && 'another'} exam
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

function AdmissionForm ({ admission, originalAdmission, onClose }) {
  const dialog = useDialog()

  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const form = useForm({
    defaultValues: admission,
    reValidateMode: 'onChange',
    mode: 'all',
  })

  const selectedCity = form.watch('city')
  const previousSelectedCity = usePrevious(selectedCity)

  useEffect(() => {
    if (previousSelectedCity && selectedCity !== previousSelectedCity) {
      form.setValue('district', '')
    }
  }, [selectedCity])

  const [edit, { isLoading, isSuccess, isError, error }] = useMutation(
    (variables) => request({
      query: EDIT_ADMISSION,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
      variables,
    }),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(['admission'], { refetchActive: true })
      },
    })

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Updated successfully', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const { control, errors, handleSubmit } = form

  const errorMessages = getErrorMessages(errors)

  const onSubmit = async (values) => {
    try {
      await dialog.confirm({
        title: 'Are You Sure?',
        message: <Typography style={{ color: theme.palette.primary.main }}>This action is permanent!</Typography>,
        ok: { variant: 'contained', color: 'primary', text: 'Edit', startIcon: <EditIcon /> },
      })

      // mapping from event._id to AdmissionEvent
      const examEventMap = Object.fromEntries(originalAdmission.events.map((e) => [e.event._id, e]))

      const newEvents = propOr([], 'events', values)
        .map((e) => {
          const oldEvent = examEventMap[e.event]

          const tickets = typeof e?.tickets === 'string'
            ? [e.tickets]
            : propOr([], 'tickets', e)

          if (!oldEvent) {
            return {
              event: e.event,
              tickets: tickets.map((t) => ({ ticket: t })),
            }
          }

          const oldTicketsMap = Object.fromEntries(examEventMap[e.event].tickets.map((t) => [t.ticket._id, t]))

          return {
            _id: oldEvent._id,
            event: e.event,
            seat: oldEvent.seat,
            room: oldEvent.room,
            venue: path(['venue', '_id'], oldEvent),
            tickets: tickets.map((ticketId) => {
              const oldTicket = oldTicketsMap[ticketId]

              if (oldTicket) {
                return { _id: oldTicket._id, ticket: ticketId }
              }
              return { ticket: ticketId }
            }),
          }
        })

      edit({ _id: originalAdmission._id, ...values, events: newEvents })
        .then(onClose)
        .catch(() => {
        })
    } catch (e) {
      console.error('catch', e)
    }
  }

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Controller
              name='district'
              control={control}
              rules={{ required: 'District is required' }}
              render={({ onChange, value }) => (
                <AutoComplete
                  label='district'
                  value={value}
                  onChange={onChange}
                  valueFormatter={(_, item) => item?.key}
                  displayFormatter={(_, item) => item?.name.en}
                  searchQuery={{ query: 'GET_TEST_DISTRICTS', endpoint: process.env.REACT_APP_ENDPOINT }}
                  singleQuery={{ query: 'GET_DISTRICT', endpoint: process.env.REACT_APP_ENDPOINT }}
                  dependencyValue={{ city: selectedCity ? [selectedCity] : [] }}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name='city'
              control={control}
              rules={{ required: 'City is required' }}
              render={({ onChange, value }) => (
                <AutoComplete
                  label='city'
                  value={value}
                  onChange={onChange}
                  valueFormatter={(_, item) => item?.key}
                  displayFormatter={(_, item) => item?.name.en}
                  searchQuery={{ query: 'GET_TEST_CITIES', endpoint: process.env.REACT_APP_ENDPOINT }}
                  singleQuery={{ query: 'GET_CITY', endpoint: process.env.REACT_APP_ENDPOINT }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='universities'
              control={control}
              render={({ onChange, value }) => (
                <AutoComplete
                  label='Universities'
                  multiple
                  value={value}
                  onChange={onChange}
                  valueFormatter={(_, item) => item?.key}
                  displayFormatter={(_, item) => item?.name.en}
                  searchQuery={{ query: 'GET_UNIVERSITIES', endpoint: process.env.REACT_APP_ENDPOINT }}
                  singleQuery={{ query: 'GET_UNIVERSITY', endpoint: process.env.REACT_APP_ENDPOINT }}
                />
              )}
            />
          </Grid>

          <AdmissionEventFieldArray control={control} />

          <Grid item xs={6}>
            <Controller
              name='hasSpecialNeeds'
              control={control}
              render={({ onChange, value }) => (
                <Checkbox
                  label='Has Special Needs?'
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Controller
              name='allowSharing'
              control={control}
              render={({ onChange, value }) => (
                <Checkbox
                  label='Allowing Sharing?'
                  value={value}
                  onChange={onChange}
                />
              )}
            />
          </Grid>

          {errorMessages.length > 0 && (
            <ul>
              {errorMessages.map((message, index) => (
                <Typography
                  style={{ color: 'red' }}
                  key={index}
                  component='li'
                  gutterBottom
                  align='left'
                >
                  {message}
                </Typography>
              ))}
            </ul>
          )}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button type='submit' variant='contained' disabled={isLoading}>Save</Button>
            <Button onClick={onClose}>Close</Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export function AdmissionModal ({ onClose, admission }) {
  const formattedAdmission = useMemo(() => ({
    ...admission,
    round: path(['round', '_id'], admission),
    city: path(['city', '_id'], admission),
    district: path(['district', '_id'], admission),
    universities: pluck('_id', propOr([], 'universities', admission)),
    events: admission?.events?.map(({ _id, event, tickets }) => ({
      _id,
      event: prop('_id', event),
      tickets: map(path(['ticket', '_id']), tickets),
    })),
  }), [admission])

  return (
    <Dialog open onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle>
        Edit Admission
      </DialogTitle>
      <DialogContent>
        <AdmissionForm admission={formattedAdmission} originalAdmission={admission} onClose={onClose} />
      </DialogContent>
    </Dialog>
  )
}
function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}
