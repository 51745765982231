import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { equals } from 'ramda'
import Button from '@material-ui/core/Button'
import { makeStyles } from '@material-ui/core/styles'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'

function FileListItem ({ file, onRemove }) {
  const isURL = typeof file === 'string'

  const filenameComponent = !isURL
    ? file?.name
    : (
      <a target='_blank' rel='noopener noreferrer' href={file}>
        {file.split('/')
          .slice(-1)}
      </a>
      )

  return (
    <li>
      {filenameComponent}
      <IconButton onClick={() => onRemove(file)}>
        <CloseIcon />
      </IconButton>
    </li>
  )
}

function FileList ({ classes, multiple, state, setState, onChange }) {
  const files = multiple ? state : (state ? [state] : [])

  const onRemove = (item) => {
    if (multiple) {
      setState((state) => {
        const newState = state.filter((f) => f !== item)
        onChange(newState)
        return newState
      })
    } else {
      onChange(undefined)
      setState(undefined)
    }
  }

  return (
    <ul className={classes.list}>
      {files.map((file) => <FileListItem file={file} key={file?.name ?? file} onRemove={onRemove} />)}
    </ul>
  )
}

const MyUpload = forwardRef(({
  value,
  multiple = false,
  name,
  onMounted = () => {},
  onChange = () => {},
  disabled = false,
  label = '',
}, ref) => {
  const [state, setState] = useState(multiple ? [] : undefined)
  const [randomId, seRandomId] = useState()
  const classes = useStyles()

  useEffect(() => {
    if (value && typeof value === 'string' && !equals(value, state)) applyChange(value)
  }, [value]) // eslint-disable-line

  useEffect(() => {
    seRandomId(Math.random())
    onMounted(name)
  }, []) // eslint-disable-line

  const applyChange = useCallback((val) => {
    let newValue
    if (Array.isArray(val)) {
      newValue = !multiple ? val.pop() : val
    } else {
      newValue = !multiple ? val : [val]
    }
    setState(newValue)
    onChange(val, name)
  }, [name, multiple, onChange])

  const onFileAdded = useCallback((e) => {
    console.log(e)
    const fileList = e.target.files
    if (multiple) {
      const files = []
      fileList.forEach((file) => files.push(file))
      applyChange(files)
    } else {
      applyChange(fileList[0])
    }
  }, [applyChange, multiple])

  return (
    <div className={classes.container}>
      <label className={classes.label}>
        {label}
      </label>
      <input
        className={classes.input}
        id={randomId}
        multiple={multiple}
        type='file'
        onChange={onFileAdded}
      />
      <label htmlFor={randomId}>
        <Button variant='contained' color='primary' component='span' disabled={disabled}>
          Upload
        </Button>
      </label>
      <FileList
        classes={classes}
        multiple={multiple}
        state={state}
        setState={setState}
        onChange={(val) => onChange(val, name)}
      />
    </div>
  )
})

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  list: {
    textDecoration: 'none',
    listStyleType: 'none',
    marginLeft: -40,
    marginTop: 5,
    '& li': {
      color: theme.palette.info.main,
      '& a': {
        color: theme.palette.info.main,
      },
    },
  },
  label: {
    display: 'block',
    color: theme.palette.info.main,
  },
  container: {
    padding: '.4rem 0',
  },
}))

export default MyUpload
