import React from 'react'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import DatePicker from '../../core/DatePicker'

export default function Totals ({ onMinValueChange = () => {}, onMaxValueChange = () => {}, minValue, maxValue }) {
  return (
    <>
      <Box display='flex' justifyContent='space-between' flexWrap='wrap' style={{ margin: '10px 0' }}>
        <Box display='flex' alignItems='center'>
          <Typography style={{ margin: '0 3px' }} variant='h5' component='p'>
            DATE RANGE
          </Typography>
          <Box marginX={2}>
            <DatePicker value={minValue} onChange={(val) => onMinValueChange(val?.valueOf())} onClear={() => onMinValueChange(undefined)} placeholder='Start Date' />
          </Box>
          <Box marginX={2}>
            <DatePicker value={maxValue} onChange={(val) => onMaxValueChange(val?.valueOf())} onClear={() => onMaxValueChange(undefined)} placeholder='End Date' />
          </Box>
        </Box>
      </Box>
    </>
  )
}
