import React, { useMemo, useState } from 'react'
import SendBird from 'sendbird'
import { useQueryCache } from 'react-query'

import { Button, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useStateMachine } from 'little-state-machine'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles({
  icon: {
    marginTop: 8,
  },
  alert: {
    fontSize: 18,
  },
})

const sendBirdAppId = process.env.REACT_APP_SEND_BIRD_API_KEY
const sb = new SendBird({ appId: sendBirdAppId })

export const SendBirdMessagesChecker = ({ children }) => {
  const cache = useQueryCache()
  const styles = useStyles()
  const history = useHistory()
  const _id = cache.getQueryData('GET_CURRENT_VENDOR')?.getCurrentVendor?._id
  const [count, setCount] = useState(0)
  const [ignoreUpdates, setIgnore] = useState(false)
  const { state } = useStateMachine()

  if (_id) {
    sb.connect(_id, function () {
      sb.getTotalUnreadMessageCount(function (count) {
        console.log({ count })
        setCount(count)
      })
    })
  }

  const hasUpdate = useMemo(() => {
    const newCount = count !== state.lastUpdate
    return !ignoreUpdates && newCount
  }, [state.lastUpdate, count, ignoreUpdates])

  return (
    <>
      <Snackbar open={hasUpdate && count !== 0} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          onClose={() => setIgnore(true)}
          className={styles.alert}
          classes={{ icon: styles.icon }}
          severity='info'
        >
          You have a new messages!
          <Button onClick={() => history.push('/chat')} variant='text' color='primary'>Check Them</Button>
        </Alert>
      </Snackbar>
      {children}
    </>
  )
}
