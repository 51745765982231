import SelectOrderStatusAction from './SelectOrderStatusAction'
import StructuredCategories from './StructuredCategories'
import StructuredCategoriesForVendor from './StructuredCategoriesForVendor'
import AcceptProductRequest from './AcceptProductRequest'
import RejectProductRequest from './RejectProductRequest'
import AcceptOrderRequest from './AcceptOrderRequest'
import RejectOrderRequest from './RejectOrderRequest'
import SimpleSingleOrderModule from './SimpleSingleOrder'
import SingleOrder from './SingleOrder'
import RegistrationCompleted from './RegistrationCompleted'
import SingleDriverModule from './SingleDriver'
import CustomCheckBox from './CustomCheckbox'
import VerifyDriver from './VerifyDriver'
import VerifyCustomer from './VerifyCustomer'
import SingleTrip from './SingleTrip'
import SingleRequest from './SingleRequest'
import AddRole from './AddRole'
import EditRole from './EditRole'
import ApprovalModal from './ApprovalModal'
import CheckoutPaymentStatus from './CheckoutPaymentStatus'
import AdmissionModule from './Admission'
import ProctorModule, { ProctorsModule } from './Proctor'
import FulfillmentModule from './Fulfillment'
import { FulfillmentOrdersListing, FulfillmentRequestsListing } from './FulfillmentListing'
import ResetPasswordAsAdmin from './ResetPasswordAsAdmin'
import SendESTScoreEmail from './SendESTScoreEmail'
import { DynamicField } from './DynamicFieldsEST'
import CleverSellSingleOrder from './CleverSellSingleOrder'
import CleverSellSelectOrderStatusAction from './CleverSellSelectOrderStatusAction'
import DownloadFileButton from './ServiceDownloadBtn'
import ServiceRequestEST from './ServiceRequestEST'
import ESTExport from './ESTExport'
import SelectServiceProviderService from './SelectServiceProviderService'
import SupReports from './SupReports'
import ApproveServiceProvider from './ApproveServiceProvider'
import CMSCategories from './cmsCategories'
import RecurringOrderDetails from './RecurringOrderDetails'
import { ESTRoundActions } from './ESTRoundActions'
import { ESTFacilityActions } from './ESTFacilityActions'
import AddGemmaProduct from './GemmaProducts/addProduct'
import EditGemmaProduct from './GemmaProducts/editProduct'
import AddGemmaLoyaltyProduct from './GemmaProducts/addLoyaltyProduct'
import EditGemmaLoyaltyProduct from './GemmaProducts/editLoyaltyProduct'
import SendBirdChat from './SendBirdChat'
import ApproveLocationRequest from './ApproveLocationRequest'
import ApproveTaxonomyRequest from './ApproveTaxonomyRequest'
import EditGemmaOrderStatus from './EditGemmaOrderStatus'
import GemmaSingleOrder from './GemmaSingleOrder'
import { addESTAdmission } from './Admission/addESTAdmission'
import ExamCommentField from './ExamComment'
import CityLocationField from './CityLocationField'
import CountryLocationField from './CountryLocationField'
import DistrictLocationField from './DistrictLocationField'
import CustomAutoComplete from './customAutoComplete'
import AddGarnellProduct from './GarnellProducts/addProduct'
import EditGarnellProduct from './GarnellProducts/editProduct'
import GarnellSingleOrder from './GarnellSingleOrder'
import AccountStatus from './AccountStatus'
import ExportGarnellCustomers from './GarnellExport/ExportGarnellCustomers'
import ExportGarnellOrders from './GarnellExport/ExportGarnellOrders'
import HasExtraFeesForRound from './HasExtraFeesForRound'
import GuideBookRequestEST from './GuideBookRequestEST'
import AveragesModule from './Averages'

const CustomComponents = {
  SelectOrderStatusAction,
  StructuredCategories,
  StructuredCategoriesForVendor,
  AcceptProductRequest,
  RejectProductRequest,
  AcceptOrderRequest,
  RejectOrderRequest,
  SimpleSingleOrderModule,
  RegistrationCompleted,
  SingleDriverModule,
  VerifyDriver,
  VerifyCustomer,
  CustomCheckBox,
  SingleTrip,
  SingleRequest,
  SingleOrder,
  AddRole,
  EditRole,
  ApprovalModal,
  CheckoutPaymentStatus,
  AdmissionModule,
  ProctorModule,
  ProctorsModule,
  FulfillmentModule,
  FulfillmentOrdersListing,
  FulfillmentRequestsListing,
  ResetPasswordAsAdmin,
  SendESTScoreEmail,
  DynamicField,
  CleverSellSingleOrder,
  CleverSellSelectOrderStatusAction,
  DownloadFileButton,
  ServiceRequestEST,
  ESTExport,
  ESTRoundActions,
  SelectServiceProviderService,
  SupReports,
  ApproveServiceProvider,
  CMSCategories,
  RecurringOrderDetails,
  AddGemmaProduct,
  EditGemmaProduct,
  AddGemmaLoyaltyProduct,
  EditGemmaLoyaltyProduct,
  SendBirdChat,
  ApproveLocationRequest,
  ApproveTaxonomyRequest,
  EditGemmaOrderStatus,
  GemmaSingleOrder,
  addESTAdmission,
  ExamCommentField,
  CityLocationField,
  CountryLocationField,
  DistrictLocationField,
  CustomAutoComplete,
  AddGarnellProduct,
  EditGarnellProduct,
  GarnellSingleOrder,
  AccountStatus,
  ExportGarnellCustomers,
  ExportGarnellOrders,
  HasExtraFeesForRound,
  GuideBookRequestEST,
  ESTFacilityActions,
  AveragesModule,
}

export default CustomComponents
