import useRequest from '../../../helpers/useRequest'
import queryString from 'query-string'
import React, { useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import { DELETE_PROCTOR_ASSIGNMENT, GET_PROCTOR_ASSIGNMENTS } from '../../../gql/queries'
import * as R from 'ramda'
import { isEmpty, isNil, pathOr, reject } from 'ramda'
import Grid from '@material-ui/core/Grid'
import Table from '../../core/Table'
import Pagination from '../../core/Pagination'
import definitions from '../../../config/definitions'
import Typography from '@material-ui/core/Typography'
import { useDialog } from 'muibox'
import { useSnackbar } from 'notistack'
import { useTheme } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { getAssignmentsTable } from './fields'
import { ProctorAssignmentDetailsModal, ProctorAssignmentModal } from './ProctorAssignmentModal'
import { useHistory, useLocation } from 'react-router-dom'
import { ProctorTableHeader } from './ProctorTableHeader'
import Actions from '../../core/Actions'
import * as endpoints from '../../../config/endpoints'

const defaultFilters = {
  role: undefined,
  cities: [],
  rounds: [],
  districts: [],
  centers: [],
}

const sortOptions = [
  { label: 'Default', key: '' },
  { label: 'Newest', key: 'Newest' },
  { label: 'Oldest', key: 'Oldest' },
]

export function ProctorAssignmentTable ({ proctor }) {
  const { request } = useRequest()

  const location = useLocation()
  const query = queryString.parse(location.search)
  const page = parseInt(query.page) || 1
  const history = useHistory()

  const [showAddModal, setShowAddModal] = useState(false)
  const [showDetailsModal, setShowDetailsModal] = useState(false)
  const [assignmentId, setAssignmentId] = useState(undefined)

  const [filters, setFilters] = useState(defaultFilters)

  const dialog = useDialog()
  const theme = useTheme()
  const { enqueueSnackbar } = useSnackbar()

  const { isLoading, data, refetch } = useQuery(['proctorAssignments', filters, query],
    () => request({
      query: GET_PROCTOR_ASSIGNMENTS,
      variables: reject((v) => isNil(v) || isEmpty(v), {
        proctor,
        ...filters,
        searchTerm: query.searchTerm,
        page,
        sort: query.sort,
        pageSize: 30,
      }),
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    }),
  )

  const onEdit = (id) => {
    setShowAddModal(true)
    setAssignmentId(id)
  }

  const onClose = () => {
    setShowAddModal(false)
    setShowDetailsModal(false)
    setAssignmentId(undefined)
    setFilters(defaultFilters)
  }

  const onView = ({ _id, player }) => {
    if (proctor) {
      setShowDetailsModal(true)
      setAssignmentId(_id)
    } else {
      history.push(`/proctor/${player}/view`)
    }
  }

  const fields = useMemo(() => getAssignmentsTable({ onEdit, onView, proctor }), [])

  const onDelete = (key) => {
    dialog.confirm({
      title: 'Are You Sure?',
      message: <Typography style={{ color: theme.palette.primary.main }}>This action is permanent!</Typography>,
      ok: { variant: 'contained', color: 'primary', text: 'DELETE', startIcon: <DeleteIcon /> },
    })
      .then(() => request({
        endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
        query: DELETE_PROCTOR_ASSIGNMENT,
        variables: { _id: key },
      }))
      .then(() => {
        refetch()
        enqueueSnackbar('Deleted Item Successfully', { variant: 'success' })
      })
      .catch((error) => {
        if (error) enqueueSnackbar(R.pathOr('failed to delete', ['response', 'errors', 0, 'message'], error), { variant: 'error' })
      })
  }

  const records = pathOr([], ['getProctorAssignments', 'items'], data)
  const totalRecords = pathOr(0, ['getProctorAssignments', 'totalCount'], data)

  return (
    <Grid container direction='column' spacing={1}>
      <ProctorTableHeader filters={filters} setFilters={setFilters} onAdd={() => setShowAddModal(true)} />

      <Grid item>
        <Actions sortOptions={sortOptions} />
      </Grid>

      <Grid item>
        <Table fields={fields} records={records} isLoading={isLoading} onDelete={onDelete} />
      </Grid>

      <Grid item>
        <Pagination totalCount={totalRecords} pageSize={definitions.defaultPageSize} />
      </Grid>

      {showAddModal && (
        <ProctorAssignmentModal
          isGlobal={!proctor}
          assignmentId={assignmentId}
          onClose={onClose}
          player={proctor}
        />
      )}

      {showDetailsModal && (
        <ProctorAssignmentDetailsModal assignmentId={assignmentId} onClose={onClose} />
      )}
    </Grid>
  )
}
