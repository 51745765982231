import React from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import TextInput from '../../core/Text'
import Checkbox from '../../core/Checkbox/Checkbox'
import { propEq, reject } from 'ramda'
import RemoveIcon from '@material-ui/icons/Remove'
import Button from '../../core/Button/Button'
import { nanoid } from 'nanoid'
import AddIcon from '@material-ui/icons/Add'

const minZero = (v) => Math.max(parseInt(v, 10), 0)

export function SelectFields ({ state, setValue, setState }) {
  const onValueChange = (name, id, formatter) => (e) => setState((s) => ({
    ...s,
    options: s.options.map((o) => o.id === id ? ({ ...o, [name]: formatter?.(e.target.value) ?? e.target.value }) : o),
  }))

  const removeItem = (id) => () => setState((s) => ({
    ...s,
    options: reject(propEq('id', id), s.options ?? []),
  }))

  const addOption = () => setState((s) => ({
    ...s,
    options: [...(s.options ?? []), { id: nanoid(), name: '', price: 0 }],
  }))

  return (
    <>
      <Grid item xs={4}>
        <Checkbox
          value={!!state.multiple}
          label='Multiple Select'
          helperText='Can students select multiple items'
          onChange={setValue('multiple')}
        />
      </Grid>
      {!!state.multiple && (
        <>
          <Grid item xs={4}>
            <TextInput
              inputType='number'
              label='min'
              onChange={setValue('min', minZero)}
              value={state.min ?? 0}
              helperText='min number of items a user can select'
            />
          </Grid>
          <Grid item xs={4}>
            <TextInput
              inputType='number'
              label='max'
              onChange={setValue('max', minZero)}
              value={state.max ?? 0}
              helperText='max number of items a user can select (0 = no limit)'
            />
          </Grid>
        </>
      )}

      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography color='primary' variant='h5'>Options: </Typography>
        </Grid>

        {(state.options ?? []).map((value) => (
          <React.Fragment key={value.id}>
            <Grid item xs={5}>
              <TextInput
                label='Option name'
                onChange={onValueChange('name', value.id)}
                value={value.name ?? ''}
              />
            </Grid>
            <Grid item xs={5}>
              <TextInput
                inputType='number'
                label='Option Price'
                onChange={onValueChange('price', value.id, minZero)}
                value={value.price ?? 0}
              />
            </Grid>
            <Grid item xs={2}>
              <IconButton onClick={removeItem(value.id)}>
                <RemoveIcon />
              </IconButton>
            </Grid>
          </React.Fragment>
        ))}
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant='contained'
          color='secondary'
          onClick={addOption}
        >
          <AddIcon />
          Add {state.options?.length > 0 && 'another'} option
        </Button>
      </Grid>
    </>
  )
}
