import React, { useState, Fragment, useEffect, useRef, useCallback, forwardRef, useMemo } from 'react'
import * as R from 'ramda'
import { useMutation } from 'react-query'
// import ChipInput from 'material-ui-chip-input'
import { makeStyles, useTheme } from '@material-ui/core/styles'
// import InputAdornment from '@material-ui/core/InputAdornment'
// import List from '@material-ui/core/List'
import Chip from '@material-ui/core/Chip'
// import ListItem from '@material-ui/core/ListItem'
// import ListItemText from '@material-ui/core/ListItemText'
// import CircularProgress from '@material-ui/core/CircularProgress'
import FormHelperText from '@material-ui/core/FormHelperText'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'

import useRequest from '../../../helpers/useRequest'
import * as Icons from '../../../Icons'
import * as endpoints from '../../../config/endpoints'
import * as gql from '../../../gql/queries'
import Dialog from '../Dialog'
import Tabs from '../Tabs'
import { reject } from 'ramda'

const AddIcon = Icons.Add
// const LaunchIcon = Icons.Launch
// const SearchIcon = Icons['Search']
// import AddNew from './AddNew'

/*
  props:
    multiple -> whether the component accepts multiple items, boolean
    singleQuery -> query to fetch selected item info, must have an _id parameter
    searchQuery -> query for... search, must have a searchTerm parameter
    onChange -> function to listen to value change
    label -> used for label and modal label, string
    name -> to be used in the form, string
    searchHelperText -> text to be displayed under the search input, string
    helperText -> text to be displayed under the input, string
    disabled -> whether the component is read only or not (modal cannot be opened), boolean
    displayFormatter -> a formatter for the item text that appears on the search result and chip, function(args[1])
    valueFormatter -> a formatter to extract the value from the item object, function(args[1])
    module -> module name of the document, if provided, 'add new' tab will be available
    rules -> NA
  */

function useIsMountedRef () {
  const isMountedRef = useRef(null)
  useEffect(() => {
    isMountedRef.current = true
    return () => { isMountedRef.current = false }
  })
  return isMountedRef
}

const AutoCompleteInput = forwardRef(({
  multiple,
  singleQuery,
  searchQuery,
  onChange,
  label,
  name,
  searchHelperText,
  helperText,
  disabled,
  displayFormatter,
  valueFormatter,
  module: moduleName,
  dependencyValue,
  value,
  onMounted,
  error = true,
  // onModuleSubmit,
  blacklist = [],
}, ref) => {
  const isMountedRef = useIsMountedRef()
  const [values, setValues] = useState([])
  const [display, setDisplay] = useState([])
  const [options, setOptions] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [currentActiveTab, setCurrentActiveTab] = useState(1)
  // const iconClasses = useIconStyles()
  // const listItemClasses = useListItemStyles()
  // const chipInputClasses = useChipInputStyles({ disabled })
  const autoCompleteClasses = useAutoCompleteStyles()
  const labelClasses = useLabelStyles()
  const theme = useTheme()
  const { request } = useRequest()
  const [fetchData] = useMutation((variables) => request({ query: gql[singleQuery.query], variables, endpoint: endpoints[singleQuery.endpoint] }))
  const [searchData, { data: searchResult, isLoading: searching }] = useMutation((variables) => request({ query: gql[searchQuery.query], variables, endpoint: endpoints[searchQuery.endpoint] }))

  const blacklistSet = useMemo(() => new Set(blacklist), [blacklist])

  const defaultDisplayFormatter = useCallback((option) => option?.name, [])
  const defaultValueFormatter = useCallback((option) => option?.value, [])

  const getDisplayNames = useCallback(async (newValues) => {
    const newDisplays = []
    const newCompleteValues = []
    for (const value of newValues) {
      if (!isMountedRef.current) return
      const data = await fetchData({ _id: value, name: value })
      if (data) {
        newDisplays.push(displayFormatter ? displayFormatter(R, data[Object.keys(data)[0]]) : defaultDisplayFormatter(data[Object.keys(data)[0]]))
        newCompleteValues.push(data[Object.keys(data)[0]])
      }
    }
    if (isMountedRef.current) setDisplay(newDisplays)
  }, [displayFormatter, fetchData, isMountedRef, defaultDisplayFormatter])

  useEffect(() => {
    const incomingValue = R.reject(R.isNil, R.flatten([value]))
    if (!R.equals(incomingValue, values)) {
      applyChange(incomingValue)
      getDisplayNames(incomingValue)
    }
  }, [value]) // eslint-disable-line

  useEffect(() => {
    if (searchResult) {
      setOptions([...searchResult[Object.keys(searchResult)[0]].items])
    }
  }, [searchResult]) // eslint-disable-line

  useEffect(() => {
    if (onMounted) onMounted(name)
  }, []) // eslint-disable-line

  const applyChange = useCallback((val) => {
    setValues(val)
    if (onChange) onChange(multiple ? val : val[0], name)
  }, [onChange, multiple, name])

  const onInputEnter = useCallback((obj) => {
    const newValue = valueFormatter ? valueFormatter(R, obj) : defaultValueFormatter(obj)
    const enteredDisplay = displayFormatter ? displayFormatter(R, obj) : defaultDisplayFormatter(obj)
    if (values.find((v) => v === newValue)) return
    if (multiple) {
      setDisplay([...display, enteredDisplay])
      applyChange([...values, newValue])
    } else {
      setDisplay([enteredDisplay])
      applyChange([newValue])
      setDialogOpen(false)
    }
  }, [defaultDisplayFormatter, defaultValueFormatter, displayFormatter, valueFormatter, display, values, applyChange, multiple])

  // const onBeforeAdd = useCallback((val) => {
  //   if (searchResult && searchResult[Object.keys(searchResult)[0]].items.length) {
  //     onInputEnter(searchResult[Object.keys(searchResult)[0]].items[0])
  //   }
  // }, [onInputEnter, searchResult])

  const onDelete = useCallback((val, index) => {
    const newValues = values.filter((item, i) => i !== index)
    const newDisplay = display.filter((item, i) => i !== index)
    setDisplay(newDisplay)
    applyChange(newValues)
  }, [applyChange, display, values])

  const onInput = useCallback((e) => {
    if (typeof e.target.value !== 'string') return
    const dependencyValueObject = dependencyValue || {}
    searchData({ searchTerm: e.target.value, ...dependencyValueObject })
  }, [dependencyValue, searchData])

  // const onAddNew = useCallback((values) => {
  //   onInputEnter(values)
  //   setCurrentActiveTab(1)
  // }, [onInputEnter, setCurrentActiveTab])

  return (
    <>
      <Autocomplete
        ref={ref}
        multiple
        size='small'
        classes={autoCompleteClasses}
        autoComplete
        autoHighlight
        options={options}
        placeholder='Search'
        onFocus={onInput}
        disabled={disabled}
        popupIcon={null}
        closeIcon={null}
        loading={searching}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip key={index} style={multiple ? { } : { color: theme.palette.text.primary, borderRadius: 0, background: 'transparent' }} label={option} {...getTagProps({ index })} disabled={disabled} />
          ))}
        getOptionLabel={(option) => displayFormatter ? displayFormatter(R, option) : defaultDisplayFormatter(option)}
        filterOptions={(options, state) => {
          return reject((v) => blacklistSet.has(v.key), options)
        }}
        onInputChange={onInput}
        onChange={(e, val) => {
          if (val.length < values.length) {
            const valueToBeDeleted = R.difference(display, val)
            onDelete(valueToBeDeleted[0], display.indexOf(valueToBeDeleted[0]))
          } else {
            onInputEnter(val[val.length - 1])
          }
        }}
        value={display}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            InputLabelProps={{
              shrink: true,
              className: labelClasses.label,
            }}
            InputProps={{
              ...params.InputProps,
              // endAdornment: true
              //   ? null
              //   : (
              //     <InputAdornment position='end' style={{ marginRight: -56 }} onClick={() => setDialogOpen(true)}>
              //       <LaunchIcon classes={iconClasses} />
              //     </InputAdornment>
              //     ),
            }}
          />
        )}

      />
      <FormHelperText style={{ ...error && { color: 'red' } }}>{helperText}</FormHelperText>

      <Dialog onClose={() => setDialogOpen(false)} open={dialogOpen} contentHeight='70vh' label={label}>
        <Tabs currentActive={currentActiveTab} setCurrentActive={setCurrentActiveTab} tabContentStyle={{ paddingTop: '.8rem' }}>
          <div>
            {/* <Fragment> <SearchIcon /> {'Search'}</Fragment> */}
            {moduleName && <> <AddIcon /> Add New</>}
          </div>
          <div>
            {/* <Fragment>
              <ChipInput fullWidth fullWidthInput
                onBeforeAdd={onBeforeAdd}
                onDelete={onDelete}
                value={display}
                helperText={searchHelperText}
                clearInputValueOnChange={true}
                onUpdateInput={onInput}
                classes={chipInputClasses}
                FormHelperTextProps={{
                  style: {
                    marginTop: -3
                  }
                }}
                InputProps={{
                  autoFocus: true,
                  startAdornment: (
                    <InputAdornment position='start' >
                      <SearchIcon />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position='end' >
                      { searching && <CircularProgress color='primary' size={20} /> }
                    </InputAdornment>
                  ),
                }}
              />
              <List style={{ overflow: 'auto', height: '55vh' }} >
                {
                  options.map((result) => (
                    <ListItem button classes={listItemClasses} key={result.key} onClick={() => onInputEnter(result)}>
                      <ListItemText primary={displayFormatter ? displayFormatter(R, result) : defaultDisplayFormatter(result)} />
                    </ListItem>
                  ))
                }
              </List>
            </Fragment> */}
            <>
              {/* { moduleName && <AddNew module={moduleName} onModuleSubmit={onModuleSubmit} onAdd={onAddNew} />} */}
            </>
          </div>
        </Tabs>

      </Dialog>
    </>
  )
})

// const useIconStyles = makeStyles((theme) => ({
//   root: {
//     color: theme.palette.primary.main,
//     cursor: 'pointer',
//   },
// }))

// const useListItemStyles = makeStyles( theme => ({
//   root: {
//     display: 'flex',
//     justifyContent: 'center',
//     textAlign: 'center',
//     borderRadius: 8,
//   },
// }))

// const useChipInputStyles = makeStyles( theme => ({
//   chip: {
//     height: '1.1rem',
//     fontSize: '.7rem',
//     fontWeight: 300,
//     marginTop: 8,
//     backgroundColor: theme.palette.primary.main,
//     color: theme.palette.text.secondary,
//     '&:hover': {
//       backgroundColor: theme.palette.primary.light,
//     },
//     '&:focus': {
//       backgroundColor: theme.palette.primary.dark,
//     },
//     '& svg': {
//       display: props => props.disabled ? 'none' : 'inline-block',
//     }
//   }
// }))

const useAutoCompleteStyles = makeStyles((theme) => ({
  listbox: {
    background: theme.palette.secondary.main,
    '& > li': {
      margin: 0,
      padding: 0,
      height: '3em',
      fontSize: theme.typography.body1.fontSize,
      textTransform: 'uppercase',
      fontFamily: 'Barlow Condensed',
      textAlign: 'center',
      display: 'box',
      boxPack: 'center',
      boxAlign: 'center',
      width: '100%',
      borderBottom: `1px solid ${theme.palette.divider}`,
      color: 'white',
      '&:hover': {
        background: theme.palette.primary.main,
        color: 'white',
      },
    },
  },
  inputRoot: {
    padding: 0,
  },
  noOptions: {
    textAlign: 'center',
    background: theme.palette.secondary.main,
  },
  loading: {
    textAlign: 'center',
    background: theme.palette.secondary.main,
  },
  tag: {
    background: theme.palette.primary.main,
    color: theme.palette.text.secondary,
  },
}))

const useLabelStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.h5.fontSize,
  },
}))

export default AutoCompleteInput
