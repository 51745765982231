import { Link, useParams } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import useRequest from '../../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import { EDIT_ADMISSION_EVENT_VENUE, GET_VENUES, GET_ADMISSION_NOTES } from '../../../../gql/queries'
import { map, path, pathOr, prepend, prop } from 'ramda'
import { queryCache } from '../../../../index'
import SelectSync from '../../../core/SelectSync/SelectSync'
import { Checkbox, Link as MUILink, TextField, Tooltip, Dialog,
  DialogActions,
  DialogContent,
  DialogTitle, DialogContentText, Typography } from '@material-ui/core'
import Button from '../../../core/Button/Button'
import { Person, Warning } from '@material-ui/icons'
import * as endpoints from '../../../../config/endpoints'

export const ChangeCenter = ({ _id: eventId, venue: oldVenue }) => {
  const { key: _id } = useParams()
  const [venue, setVenue] = useState(oldVenue?._id)

  useEffect(() => {
    setVenue(oldVenue?._id)
  }, [oldVenue?._id])

  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const { data } = useQuery(
    ['venues'],
    () => request({ query: GET_VENUES, endpoint: endpoints[process.env.REACT_APP_ENDPOINT] }),
  )

  const formatItem = ({ key, name }) => ({ key, label: name?.en ?? name?.ar })
  const items = pathOr([], ['getVenues', 'items'], data)
  const options = prepend({ key: '', label: 'None' }, map(formatItem, items))

  const handleSuccess = () => queryCache.invalidateQueries(['admission', _id], { refetchActive: true })

  const [changeVenue, { isLoading: isChangeLoading }] = useMutation(
    (variables) => request({
      query: EDIT_ADMISSION_EVENT_VENUE,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
      variables,
    }), { onSuccess: handleSuccess })

  const handleChange = (e) => {
    const venue = e.target.value

    return changeVenue({ ...venue && { venue }, eventId, admissionId: _id, update: 'VENUE' })
      .then(() => {
        setVenue(venue)
        enqueueSnackbar('Success', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('Error failed to updated', { variant: 'error' })
        setVenue(oldVenue?._id)
      })
  }

  return (
    <SelectSync
      name='venues'
      variant='contained'
      value={venue}
      options={options}
      disabled={isChangeLoading}
      onChange={handleChange}
    />
  )
}
export const ChangeRoom = ({ _id: eventId, room }) => {
  const { key: _id } = useParams()
  const { request } = useRequest()
  const [text, setText] = useState(room)
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    setText(room)
  }, [room])

  const handleSuccess = () => queryCache.invalidateQueries(['admission', _id], { refetchActive: true })

  const [changeRoom, { isLoading: isChangeLoading }] = useMutation(
    (variables) => request({
      query: EDIT_ADMISSION_EVENT_VENUE,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
      variables,
    }), { onSuccess: handleSuccess })

  const handleSubmit = () =>
    changeRoom({ eventId, admissionId: _id, room: text.toLowerCase(), update: 'ROOM' })
      .then(() => {
        enqueueSnackbar('Success', { variant: 'success' })
      })
      .catch(() => {
        enqueueSnackbar('Error failed to updated', { variant: 'error' })
      })

  return (
    <TextField
      value={text}
      disabled={isChangeLoading}
      onChange={(e) => setText(e.target.value)}
      style={{ maxWidth: '10rem' }}
      InputProps={{ endAdornment: <Button onClick={handleSubmit}>Save</Button> }}
    />
  )
}
export const ExamInfoFields = [
  { key: 'Exam', path: ['event', 'name', 'en'], width: 4 },
  {
    key: 'Exam Date',
    path: ['event', 'startDate'],
    width: 4,
    formatter: (num) => {
      let date = new Date(num)
      if ((Date.now() / num) > 100) { // 100 is enough of an epsilon to determine if it's seconds or milliseconds
        date = new Date(num * 1000)
      }

      return num
        ? date.toISOString()
          .split('T')[0]
        : '-'
    },
  },
  {
    key: 'Room',
    path: [],
    width: 4,
    formatter: (data) => <ChangeRoom {...data} />,
  },
  {
    key: 'Center',
    path: [],
    fullWidth: true,
    formatter: (data) => <ChangeCenter {...data} />,
  },
]
const CheckInField = ({ cell, isCheckIn }) => {
  if (!cell) return '-'

  const room = prop('room', cell)
  const originalRoom = prop('originalRoom', cell)
  const proctorName = path(['by', 'nameObject', 'en'], cell)
  const proctorId = path(['by', '_id'], cell)
  const forced = path(['forced'], cell)
  const forceReason = path(['forceReason'], cell)

  const checkedTime = new Date(path(['at'], cell)).toLocaleTimeString('en', {
    minute: '2-digit',
    hour: 'numeric',
    hour12: false,
  })

  const checkInType = isCheckIn ? 'check in' : 'check out'

  const tooltip = forced
    ? (
      <span style={{ fontSize: 20 }}>
        {checkInType} was forced because {forceReason}
      </span>
      )
    : ''

  return (
    <div>
      <Tooltip title={tooltip}>
        <div>
          {forced && <Warning style={{ marginBottom: -4, marginRight: 4 }} />}

          {checkedTime} {(room && room !== originalRoom && isCheckIn) && <span>in room #{room}</span>}
        </div>
      </Tooltip>
      <div>
        By
        <Link to={`/proctor/${proctorId}/view`}>
          <Person style={{ marginBottom: -4 }} />
          <MUILink>{proctorName || 'name'}</MUILink>
        </Link>
      </div>
    </div>
  )
}

const AdmissionNoteField = ({ cell }) => {
  const [show, setShow] = useState(false)
  const { request } = useRequest()
  const subjectId = path(['ticket', '_id'], cell)
  const { key: _id } = useParams()

  const { data } = useQuery(
    ['admissionNotes', cell.admissionId, subjectId],
    () => request({ query: GET_ADMISSION_NOTES, endpoint: endpoints[process.env.REACT_APP_ENDPOINT], variables: { admission: _id, subjects: [subjectId] } }))

  const handleClick = () => {
    setShow(!show)
  }
  const noNotesStyle = {
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
  }
  return (
    <div>
      <Button onClick={handleClick}>Click Here</Button>
      {show &&
        <Dialog open={open} onClose={handleClick}>
          <DialogTitle>
            <Typography variant='h5'>Admission Notes</Typography>
          </DialogTitle>
          <DialogContent>
            {data?.getAdmissionNotes?.items.map(({ _id, note, proctorAssignment, createdAt }) => (
              <DialogContentText key={_id} color='black'>
                {note}-{'  ' + proctorAssignment?.player?.name ?? ' '}-{'  ' + new Date(createdAt).toLocaleTimeString()}
                <hr />
              </DialogContentText>
            ))}
          </DialogContent>
          {data?.getAdmissionNotes?.items.length === 0 && <DialogContentText style={noNotesStyle}>No notes</DialogContentText>}
          <DialogActions>
            <Button onClick={handleClick}>Close</Button>
          </DialogActions>
        </Dialog>}

    </div>
  )
}
export const subjectsTable = [
  {
    label: 'Subject',
    key: 'name',
  },
  {
    label: 'time',
    key: 'ticket.ticket',
    formatField: (R, ticket) => `${ticket?.time} ${ticket?.endTime ? '- ' + ticket?.endTime : ''}`,
  },
  {
    label: 'Paid',
    key: 'isPaid',
    formatField: (R, cell) => <Checkbox disabled checked={cell} />,
  },
  {
    label: 'Admission Notes',
    key: 'ticket',
    formatField: (R, cell) => <AdmissionNoteField cell={cell} />,
  },
  {
    label: 'Checked In Room',
    key: 'ticket',
    formatField: (R, ticket) => `${ticket?.checkedIn?.room ?? ''}`,
  },
  {
    label: 'Checked In',
    key: 'checkedIn',
    formatField: (R, cell) => <CheckInField cell={cell} isCheckIn />,
  },
  {
    label: 'Checked Out',
    key: 'checkedOut',
    formatField: (R, cell) => <CheckInField cell={cell} isCheckIn={false} />,
  },
  {
    label: 'Price',
    key: 'price',
    formatField: (R, cell) =>
      cell
        ? Number(cell)
          .toFixed(2)
          .toString()
        : '',
  },
]
