import { useCardStyles, useTextStyles } from '../Admission/InfoCardTab'
import Typography from '@material-ui/core/Typography'
import React, { Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import { path } from 'ramda'

const formatNum = (num) => Number(num ?? 0)
  .toFixed(2)

export const TotalsCard = ({ rows, totals }) => {
  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()

  return (
    <div className={cardClasses.card} style={{ height: '24rem' }}>
      <Typography className={textClasses.header}>Payment</Typography>
      <div>
        {rows.map(({ name, value, formatter }, i) => (
          <Fragment key={name}>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item xs={6}>
                <Typography className={textClasses.totalsKey}>{name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Box display='flex' justifyContent='flex-end'>
                  <Typography className={textClasses.totalsKey}>
                    {!path(value, totals) ? '-' : (formatter ?? formatNum)(path(value, totals))}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider className={cardClasses.divider} hidden={i === rows.length - 1} />
          </Fragment>
        ))}
      </div>
    </div>
  )
}
