import React, { useCallback, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import * as queries from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import * as R from 'ramda'
import { path, pathOr, prop } from 'ramda'
import * as Icons from '../../../Icons'
import useRequest from '../../../helpers/useRequest'
import { Typography } from '@material-ui/core'
import { MapModal } from './MapModal'

const { MapIcon } = Icons

const useStyles = makeStyles((theme) => ({
  icon: {
    fontSize: 25,
    color: theme.palette.primary.main,
  },
  iconContainer: {
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  label: {
    marginLeft: 5,
    paddingTop: 3,
    color: theme.palette.text.primary,
  },
}))

const MapComponent = ({
  field,
  record,
  value,
  onChange,
  label,
  customProps,
  refetch = () => {
  },
}) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)

  const openMap = useCallback(() => setOpen(true), [setOpen])
  const closeMap = useCallback(() => setOpen(false), [setOpen])

  const [loading, setLoading] = useState(false)
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const location = record?.[field?.key?.split('.')] ?? value

  const props = field?.customProps ?? customProps
  const onSave = async (marker) => {
    if (loading) return
    try {
      if (field) { // in view
        await request({
          query: prop(props?.query, queries),
          endpoint: endpoints[field.endpoint],
          variables: pathOr(() => {
          }, ['customProps', 'variables'], field)(R, marker, field, record),
        })
        refetch()
        enqueueSnackbar('Success', { variant: 'success' })
      } else {
        onChange(marker ?? location)
      }
      closeMap()
    } catch (err) {
      console.log(err)
      if (path(['response', 'errors', 0, 'message'], err)) {
        enqueueSnackbar(path(['response', 'errors', 0, 'message'], err), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    } finally {
      setLoading(false)
    }
  }

  const center = props?.center ?? location
  const searchOptions = props?.searchOptions ?? {}

  return (
    <>
      <div
        onClick={openMap}
        className={classes.iconContainer}
      >
        <MapIcon className={classes.icon} />
        {!field && <Typography className={classes.label}>{label ?? 'location'}</Typography>}
      </div>
      <MapModal
        open={open}
        onClose={closeMap}
        center={center}
        location={location}
        onSubmit={customProps.readOnly ? () => {} : onSave}
        readOnly={customProps.readOnly}
        searchOptions={searchOptions}
      />
    </>
  )
}

export default MapComponent
