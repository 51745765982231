import React, { useEffect, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'

const TextInput = forwardRef(({
  name,
  label = '',
  placeholder = '',
  error = false,
  helperText = ' ',
  inputType,
  fullWidth = true,
  textAreaSize = undefined,
  onChange = () => {},
  onBlur = () => {},
  onMounted = () => {},
  value = '',
  disabled = false,
  hidden = false,
  className,
  classes,
  margin = 'normal',
}, ref) => {
  useEffect(() => onMounted(name), []) // eslint-disable-line
  const defaultClasses = useStyles()

  return (
    <>
      <TextField
        label={label}
        placeholder={placeholder}
        error={error}
        helperText={helperText}
        fullWidth={fullWidth}
        multiline={!!textAreaSize}
        inputProps={{ autoComplete: 'new-password' }}
        type={inputType}
        rows={textAreaSize}
        margin={margin}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        InputLabelProps={{
          shrink: true,
          className: defaultClasses.label,
        }}
        disabled={disabled}
        className={`${className} ${hidden ? defaultClasses.hidden : ''}`}
        classes={classes}
      />
    </>
  )
})

export default TextInput

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: theme.typography.h5.fontSize,
  },
  hidden: {
    display: 'none',
  },
}))
