import React, { useState, useEffect } from 'react'
import { Link, useRouteMatch, matchPath, useLocation } from 'react-router-dom'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'

import * as Icons from '../../../Icons'
const ExpandMoreIcon = Icons.ExpandMore
const ExpandLessIcon = Icons.ExpandLess

const ListItemComponent = ({
  sidebarOpen = true,
  label = '',
  icon = '',
  route = '',
  groupItems = [],
}) => {
  const [groupOpen, setGroupOpen] = useState(false)
  const theme = useTheme()
  const match = useRouteMatch(route)
  const location = useLocation()
  const classes = useStyles()

  useEffect(() => {
    setGroupOpen(groupItems.some((item) => matchPath(location.pathname, { path: item.route })))
  }, [groupItems, location.pathname])

  const handleClick = () => {
    if (!groupItems.length) return
    setGroupOpen((wasOpen) => !wasOpen)
  }

  const GroupIcon = Icons[icon]

  return (
    <>
      <ListItem classes={classes} component={groupItems.length ? undefined : Link} to={route} button disableRipple onClick={handleClick} style={{ ...!sidebarOpen && { padding: 'auto 0' } }} selected={groupItems.length ? false : Boolean(match)}>
        <ListItemIcon style={{ ...!sidebarOpen && { margin: 'auto' }, minWidth: theme.typography.h3.fontSize }}>
          <GroupIcon style={{ ...!sidebarOpen && { margin: 'auto' }, fontSize: theme.typography.h6.fontSize, color: theme.palette.text.secondary }} />
        </ListItemIcon>
        {sidebarOpen && <ListItemText primary={label} primaryTypographyProps={{ component: 'h6', variant: 'h6', style: { color: theme.palette.text.secondary, textTransform: 'uppercase' } }} style={{ fontSize: theme.typography.h6.fontSize }} />}
        {groupItems.length ? sidebarOpen && (groupOpen ? <ExpandLessIcon style={{ color: theme.palette.text.secondary }} /> : <ExpandMoreIcon style={{ color: theme.palette.text.secondary }} />) : null}
      </ListItem>
      <Collapse in={groupOpen} timeout='auto' unmountOnExit>
        <List component='div' disablePadding style={{ backgroundColor: theme.palette.secondary.dark, boxShadow: 'inset 0 0 5px #000000' }}>
          {
            groupItems.map((groupItem) => {
              const Icon = Icons[groupItem.icon]
              return (
                <ListItem key={groupItem.key} classes={classes} component={Link} to={groupItem.route} button disableRipple style={{ ...!sidebarOpen && { padding: 'auto 0' } }} selected={Boolean(matchPath(location.pathname, { path: groupItem.route }))}>
                  <ListItemIcon style={{ ...!sidebarOpen && { margin: 'auto' }, minWidth: theme.typography.h3.fontSize }}>
                    <Icon style={{ ...!sidebarOpen && { margin: 'auto' }, fontSize: theme.typography.h6.fontSize, color: theme.palette.text.secondary }} />
                  </ListItemIcon>
                  {sidebarOpen && <ListItemText primary={groupItem.label} primaryTypographyProps={{ component: 'h6', variant: 'h6', style: { color: theme.palette.text.secondary, textTransform: 'uppercase' } }} />}
                </ListItem>
              )
            })
          }
        </List>
      </Collapse>
    </>
  )
}

export default ListItemComponent

const useStyles = makeStyles((theme) => ({
  selected: {
    '& *': {
      color: [theme.palette.primary.main, '!important'],
    },
  },
}))
