import React from 'react'
import * as R from 'ramda'
import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import IconButton from '@material-ui/core/IconButton'

import CustomComponents from '../components/custom'
import * as Icons from '../Icons'

const EditIcon = Icons.Edit
const DeleteIcon = Icons.Delete
const ExitToAppIcon = Icons.ExitToApp
const VerifyIcon = Icons.Check

const getTableField = ({ field, cell, record, onDelete, onVerify, refetch = {}, theme }) => {
  const value = field.formatField ? field.formatField(R, cell, record) : cell

  switch (field.type) {
    case 'text':
      return <p>{value}</p>

    case 'image':
      return <span><img style={{ height: '8vh', display: 'inline' }} src={`https://res.cloudinary.com/qpixio/image/fetch/c_fill,g_north,h_1121,w_726/${value}`} alt={field.label} /></span>

    case 'edit':
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Link type='link' style={{ display: 'flex' }} to={field.route.replace(':key', record.key)}>
            <EditIcon style={{ fontSize: theme.typography.h5.fontSize }} />
          </Link>
        </Box>
      )

    case 'delete':
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <IconButton size='small' onClick={() => onDelete(record.key)}>
            <DeleteIcon style={{ fontSize: theme.typography.h5.fontSize }} />
          </IconButton>
        </Box>
      )

    case 'verify':
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <IconButton size='small' onClick={() => onVerify(record.key)}>
            <VerifyIcon style={{ fontSize: theme.typography.h5.fontSize }} />
          </IconButton>
        </Box>
      )

    case 'goTo': {
      const path = field.route.replace(/:key/g, record.key)

      if (path.startsWith('http')) { // external link
        return (
          <Box display='flex' alignItems='center' justifyContent='center'>
            <a type='link' style={{ display: 'flex' }} href={path}>
              <ExitToAppIcon style={{ fontSize: theme.typography.h5.fontSize }} />
            </a>
          </Box>
        )
      }

      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Link type='link' style={{ display: 'flex' }} to={path}>
            <ExitToAppIcon style={{ fontSize: theme.typography.h5.fontSize }} />
          </Link>
        </Box>
      )
    }

    case 'custom': {
      const Comp = CustomComponents[field.name]
      return (
        <Box display='flex' alignItems='center' justifyContent='center'>
          <Comp refetch={refetch} field={field} record={record} cell={cell} />
        </Box>
      )
    }

    default:
      return <p>{value}</p>
  }
}

export default getTableField
