import React, { useCallback, useMemo, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import * as Icons from '../../../Icons'
import { makeStyles } from '@material-ui/core/styles'
import { LocationSearch } from './LocationSearch'

const { RoomIcon } = Icons

const useStyles = makeStyles({
  root: { height: 400, width: 600, position: 'relative' },
})

const useMarkerStyles = makeStyles((theme) => ({
  marker: {
    color: theme.palette.primary.main,
    fontSize: 30,
    transform: 'translate3d(-50%, -100%, 0)',
  },
}))

const Marker = () => {
  const classes = useMarkerStyles()
  return <RoomIcon className={classes.marker} />
}

export const GoogleMap = ({
  zoom = 10,
  center,
  marker,
  setMarker,
  searchOptions,
  readOnly,
}) => {
  const classes = useStyles()
  const [api, setApi] = useState()

  const onChange = useCallback(({ lat, lng }) => {
    if (readOnly) return
    setMarker({ longitude: lng, latitude: lat })
  }, [setMarker])

  const onSelect = (location) => {
    if (readOnly) return
    onChange(location)
    api?.map?.setCenter?.(location)
  }
  const defaultCenter = useMemo(() => marker || center, [])
  return (
    <div className={classes.root}>
      {api && <LocationSearch onSelect={onSelect} searchOptions={searchOptions} />}
      <GoogleMapReact
        bootstrapURLKeys={{
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
          libraries: 'places',
        }}
        defaultCenter={{
          lng: defaultCenter.longitude,
          lat: defaultCenter.latitude,
        }}
        options={{
          clickableIcons: false,
          fullscreenControl: false,
          disableDefaultUI: false,
          mapTypeControl: false,
          styles: [{ featureType: 'poi', elementType: 'labels', stylers: [{ visibility: 'on' }] }],
        }}
        defaultZoom={zoom}
        onClick={onChange}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={({ map, maps }) => setApi({ map, maps })}
      >
        <Marker
          lat={marker.latitude}
          lng={marker.longitude}
        />
      </GoogleMapReact>
    </div>
  )
}
