import useRequest from './helpers/useRequest'
import { useQuery } from 'react-query'
import { CLEVERSHOP_CONTROL_ENDPOINT } from './config/endpoints'
import { Button, Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import React, { useMemo, useState } from 'react'
import { useStateMachine } from 'little-state-machine'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles({
  icon: {
    marginTop: 8,
  },
  alert: {
    fontSize: 18,
  },
})

export const UpdateChecker = ({ children }) => {
  const styles = useStyles()
  const { controlRequest } = useRequest()

  const [ignoreUpdates, setIgnore] = useState(false)
  const { state } = useStateMachine()

  const { data } = useQuery(
    ['version'],
    () => controlRequest({
      endpoint: CLEVERSHOP_CONTROL_ENDPOINT,
      query: '{ getLatestDashboardVersion { createdAt } }',
    }),
    { enabled: !ignoreUpdates },
  )

  const hasUpdate = useMemo(() => {
    const newVersion = +data?.getLatestDashboardVersion?.createdAt > state.lastUpdate
    return !ignoreUpdates && newVersion
  }, [state.lastUpdate, data, ignoreUpdates])

  return (
    <>
      <Snackbar open={hasUpdate} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
        <Alert
          onClose={() => setIgnore(true)}
          className={styles.alert}
          classes={{ icon: styles.icon }}
          severity='info'
        >
          a new update is available
          <Button onClick={() => window.location.reload()} variant='text' color='primary'>Refresh</Button>
        </Alert>
      </Snackbar>
      {children}
    </>
  )
}
