import React, { useEffect } from 'react'
import { useHistory, Switch, Route } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'

import { setTitle } from './store/actions'
import parse from './helpers/parseStructure'
import Layout from './components/layout'
import AddForm from './components/main/AddForm'
import EditForm from './components/main/EditForm'
import View from './components/main/View'
import Login from './components/main/Login'
import CustomComponents from './components/custom'

const MainComponentMapper = {
  add: AddForm,
  edit: EditForm,
  view: View,
  custom: ({ moduleStructure }) => {
    const Comp = CustomComponents[moduleStructure.name]

    return <Comp moduleStructure={moduleStructure} />
  },
}

export const PrivateRoute = (options) => {
  const history = useHistory()
  const { action, state } = useStateMachine(setTitle)

  useEffect(() => {
    action({ title: options.title })
  }, []) // eslint-disable-line

  if (!state.token) history.push('/login')

  return <Layout> <Route {...options} /> </Layout>
}

const LoginRoute = (options) => {
  const history = useHistory()
  const { state } = useStateMachine()

  if (state.token) history.replace('/services')

  return <Route {...options} />
}

const RedirectedRoute = (options) => {
  const history = useHistory()
  const { state } = useStateMachine()

  if (state.token) history.replace(options.route)
  else history.replace('/login')

  return <Route {...options} />
}

export default function Router () {
  const { state: littleState } = useStateMachine()
  const structure = parse(littleState.structure)
  const defaultRoute = structure.sidebarItems[0].route

  return (
    <>
      <Switch>
        <LoginRoute exact path='/login' render={() => <Login />} />

        {
          structure.modules.map((m) => {
            const Comp = MainComponentMapper[m.type]
            return m.isPublic
              ? <Route key={m.route} exact path={m.route} title={m.title} render={() => <Comp moduleStructure={m} />} />
              : <PrivateRoute key={m.route} exact path={m.route} title={m.title} render={() => <Comp moduleStructure={m} />} />
          })
        }

        <RedirectedRoute exact path='/*' route={defaultRoute} />
      </Switch>
    </>
  )
}
