
import React, { useMemo } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import { useStateMachine } from 'little-state-machine'

const LogoComponent = () => {
  const classes = useStyles()
  const { state } = useStateMachine()
  const parsedStructure = useMemo(() => state?.structure ? JSON.parse(state?.structure) : {}, [state])
  const clevershopLogo = process.env.PUBLIC_URL + '/img/sidebar-logo.svg'

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='center' style={{ height: '100%' }}>
        <img
          className={classes.img}
          src={parsedStructure?.branding?.logoUrl ?? clevershopLogo}
          alt='logo'
          title='clevershop'
        />
      </Box>
    </>
  )
}

export default LogoComponent

const useStyles = makeStyles((theme) => ({
  img: {
    width: 67,
    margin: '46px auto',
    display: 'block',
  },
}))
