import { Controller, useForm } from 'react-hook-form'
import React, { useEffect, useMemo } from 'react'
import { clamp, find, isNil, path, pathEq, propOr, reject, sum } from 'ramda'
import Grid from '@material-ui/core/Grid'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Button from '../../core/Button/Button'
import Text from '../../core/Text/Text'
import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { queryCache } from '../../../index'
import { EDIT_ADMISSION_SCORE } from '../../../gql/queries'
import { useMutation } from 'react-query'
import getErrorMessages from '../../../helpers/getErrorMessages'
import Typography from '@material-ui/core/Typography'
import * as endpoints from '../../../config/endpoints'

const LEGACY_NAMES = { Math: 'Mathematics', English: 'Literacy' }
const LEGACY_ROUND_IDS = new Set(['202105', '202106', '202108'])

const getTicketName = (name) => name
  ?.replace('EST I -', '')
  ?.replace('EST II -', '')
  ?.trim()

function ScoresForm ({ event, originalAdmission }) {
  /** @type {boolean} */
  const isEST1 = pathEq(['event', 'name', 'en'], 'EST I', event)

  const ticketMap = Object.fromEntries(propOr([], 'tickets', event)
    .map((t) => [getTicketName(t.ticket.name), t]))

  const est1Ticket = event?.tickets?.[0]

  /** @type {boolean} */
  const hasEssay = event.tickets.some((t) => t?.ticket?.name?.toLowerCase()
    .includes('with essay'))

  /** @type {[{name: string, ticket: {}}]} */
  const tickets = isEST1
    ? [
        { name: 'Literacy', ticket: est1Ticket },
        { name: 'Mathematics', ticket: est1Ticket },
        ...(hasEssay ? [{ name: 'Essay', ticket: est1Ticket }] : []),
      ]
    : event.tickets.map((t) => ({
      name: getTicketName(t.ticket.name),
      ticket: t,
    }))

  const defaultValues = useMemo(() => {
    /** @type {{subjects?: [], total?: number, scoreReportPdfPath?: string}} */
    const score = propOr({}, 'score', event)

    const roundId = originalAdmission.admissionId.split('-')[0]
    const subjects = Object.fromEntries(propOr([], 'subjects', score)
      .map((s) => {
        if (isEST1 && LEGACY_ROUND_IDS.has(roundId)) {
          // legacy rounds used to have legacy names like Math instead of Mathematics
          return [LEGACY_NAMES[s.name] ?? getTicketName(s.name), s.score]
        }

        return [getTicketName(s.name), s.score]
      }))

    return {
      scoreReportPdfPath: score.scoreReportPdfPath,
      ESTReportReference: isEST1 ? originalAdmission.ESTOneReportReference : originalAdmission.ESTTwoReportReference,
      ...subjects,
    }
  }, [event])

  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()
  const form = useForm({
    defaultValues,
    reValidateMode: 'onChange',
    mode: 'all',
  })

  useEffect(() => {
    form.reset(defaultValues)
  }, [defaultValues])

  const onSuccess = () => queryCache.invalidateQueries(['admission'], { refetchActive: true })
  const [edit, { isLoading, isSuccess, isError, error }] = useMutation(
    (variables) => request({ query: EDIT_ADMISSION_SCORE, endpoint: endpoints[process.env.REACT_APP_ENDPOINT], variables }),
    { onSuccess })

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Updated successfully', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const { control, errors, handleSubmit } = form

  const errorMessages = getErrorMessages(errors)

  const onSubmit = (values) => {
    const { scoreReportPdfPath, ESTReportReference, ...rest } = values
    const restEntries = Object.entries(rest)

    const subjects = restEntries
      .map(([name, score]) => {
        const ticket = ticketMap?.[name]
        const isEssay = name === 'Essay'

        return ({
          name,
          score,
          type: 'VALUE',
          ticket: ticket?._id,
          range: { min: isEssay ? 2 : 200, max: isEssay ? 8 : 800 },
        })
      })

    const isNA = find(pathEq([1, 'type'], 'NA'), restEntries)

    const total = isNA
      ? 'N/A'
      : sum(reject(([name, _score]) => name === 'Essay', restEntries)
        .map(([_name, score]) => score))

    const score = reject(isNil, {
      subjects,
      scoreReportPdfPath,
      total: isEST1 && total ? total?.toString() : undefined,
    })

    edit({ admission: originalAdmission._id, event: event._id, score, ...(isEST1 ? { ESTOneReportReference: ESTReportReference } : { ESTTwoReportReference: ESTReportReference }) })
      .catch(() => {
      })
  }
  console.log({ event, originalAdmission, isEST1, hasEssay })

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={1}>
        {event?.event?.name?.en}
        <hr />
        <Controller
          name='scoreReportPdfPath'
          rules={{ required: 'Score Report PDF is required' }}
          control={control}
          render={({ value, onChange }) => (
            <Grid item xs={12}>
              <Text
                value={value}
                onChange={onChange}
                inputType='url'
                label='Score PDF URL'
                placeholder='https://scores.est.moe.gov.eg/...'
              />
            </Grid>
          )}
        />
        {/* eslint-disable-next-line */}
        {isEST1 ? 
        // eslint-disable-next-line
        <Controller
          name='ESTReportReference'
          rules={{ required: false }}
          control={control}
          render={({ value, onChange }) => (
            <Grid item xs={12}>
              <Text
                value={value}
                onChange={onChange}
                label='EST I report reference'
                placeholder=''
              />
            </Grid>
          )}
           // eslint-disable-next-line
        /> 
        // eslint-disable-next-line
        : 
        <Controller
          name='ESTReportReference'
          rules={{ required: false }}
          control={control}
          render={({ value, onChange }) => (
            <Grid item xs={12}>
              <Text
                value={value}
                onChange={onChange}
                label='EST II report reference'
                placeholder=''
              />
            </Grid>
          )}
        />}

        {tickets?.map(({ name }) => (
          <Grid item xs={6} key={name}>
            <Controller
              rules={{ required: name + ' is required' }}
              control={control}
              name={name}
              render={({ value, onChange }) => (
                <Grid item xs={12}>
                  <Text
                    value={value}
                    label={name}
                    inputType='number'
                    onChange={(e) => {
                      const value = parseInt(e.target.value)
                      const isEssay = name === 'Essay'

                      return onChange(clamp(0, isEssay ? 8 : 800, value))
                    }}
                  />
                </Grid>
              )}
            />
          </Grid>
        ))}

        {errorMessages.length > 0 && (
          <ul>
            {errorMessages.map((message, index) => (
              <Typography
                style={{ color: 'red' }}
                key={index}
                component='li'
                gutterBottom
                align='left'
              >
                {message}
              </Typography>
            ))}
          </ul>
        )}

        <Grid container item xs={12} direction='row-reverse'>
          <Grid>
            <Button loading={isLoading} type='submit' variant='contained'>Save</Button>
          </Grid>
        </Grid>
      </Grid>
    </form>
  )
}

export function ScoreModal ({ onClose, admission }) {
  return (
    <Dialog open onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle>
        Scores
      </DialogTitle>
      <DialogContent>
        {admission.events?.map((event) => (
          <ScoresForm event={event} originalAdmission={admission} key={event._id} />
        ))}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
