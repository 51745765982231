import { Grid } from '@material-ui/core'
import React from 'react'
import Checkbox from '../../core/Checkbox/Checkbox'

export const SubjectsFields = ({ state, setValue }) => (
  <>
    <Grid item xs={12}>
      <Checkbox
        label='multiple'
        onChange={setValue('multiple')}
        value={state.multiple ?? false}
        helperText='can a student select multiple subjects per exam?'
      />
    </Grid>
  </>
)
