import { GraphQLClient } from 'graphql-request'
import { useStateMachine } from 'little-state-machine'

const useRequest = () => {
  const { state } = useStateMachine()

  const token = state.token
  const tenet = process.env.REACT_APP_TENET_TOKEN

  const request = async ({ endpoint, query, variables, token: passedToken }) => {
    const graphQLClient = new GraphQLClient(endpoint, { headers: { authorization: `Bearer ${passedToken || token}`, tenet: `Bearer ${tenet}` } })

    const response = await graphQLClient.request(query, variables)

    return response
  }

  const controlRequest = async ({ endpoint, query, variables }) => {
    const graphQLClient = new GraphQLClient(endpoint, { headers: { tenet: `Bearer ${tenet}` } })

    const response = await graphQLClient.request(query, variables)

    return response
  }

  return { request, controlRequest }
}

export default useRequest
