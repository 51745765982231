import React, { useEffect, useState } from 'react'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { GoogleMap } from './GoogleMaps'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    textTransform: 'uppercase',
    fontFamily: 'Barlow Condensed',
    fontSize: '2rem',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    letterSpacing: '0',
    backgroundColor: theme.palette.secondary.main,
  },
  marker: {
    color: theme.palette.primary.main,
    fontSize: 30,
    transform: 'translate3d(-50%, -100%, 0)',
  },
}))

export const MapModal = ({ onClose, open, location, onSubmit, center, searchOptions, readOnly }) => {
  const styles = useStyles()

  const [marker, setMarker] = useState(location)

  useEffect(() => {
    // location is initially undefined when editing
    setMarker(location)
  }, [location])

  return (
    <Dialog
      maxWidth='md'
      open={open}
      onClose={onClose}
    >
      <DialogTitle className={styles.title}>
        <Typography>Location</Typography>
      </DialogTitle>
      <DialogContent>
        <GoogleMap
          center={center}
          marker={marker}
          setMarker={setMarker}
          searchOptions={searchOptions}
          readOnly={readOnly}
        />
      </DialogContent>
      <DialogActions>
        {!readOnly &&
          <Button color='primary' variant='contained' onClick={() => onSubmit(marker)}>
            SAVE
          </Button>}
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
