export const setStructure = (state, payload) => {
  return {
    ...state,
    structure: payload.structure,
    lastUpdate: Date.now(),
  }
}

export const setTheme = (state, payload) => {
  return {
    ...state,
    theme: payload.theme,
  }
}

export const switchTheme = (state) => {
  return {
    ...state,
    theme: state.theme === 'light' ? 'dark' : 'light',
  }
}

export const login = (state, payload) => {
  return {
    ...state,
    user: payload.user,
    token: payload.token,
    accountType: payload.accountType,
  }
}

export const logout = (state) => {
  return {
    ...state,
    user: null,
    token: null,
  }
}

export const setTitle = (state, payload) => {
  return {
    ...state,
    title: payload.title,
  }
}

export const setPermissions = (state, payload) => {
  return {
    ...state,
    permissions: payload.permissions,
  }
}
