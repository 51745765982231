import React, { useEffect } from 'react'
import { pathOr } from 'ramda'
import { useStateMachine } from 'little-state-machine'
import Typography from '@material-ui/core/Typography'
import { useMutation } from 'react-query'
import useRequest from '../../../helpers/useRequest'
import { GET_CURRENT_STAFF } from '../../../modules/Auth/store/gql'
import { GET_CURRENT_VENDOR } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import parseStructure from '../../../helpers/parseStructure'

const useCurrentUser = ({ query, queryName, endpoint, token }) => {
  const { request } = useRequest()
  const [fetchData, { data: currentUser }] =
    useMutation((variables) => request(
      {
        query,
        variables,
        endpoint,
        token,
      }))

  const defaultName = pathOr('', [queryName, 'name'], currentUser)
  const userName = pathOr(defaultName, [queryName, 'name', 'en'], currentUser)
  return {
    fetchData, userName,
  }
}

const UserName = () => {
  const { state } = useStateMachine()
  const structure = parseStructure(state.structure)

  const { fetchData, userName } = useCurrentUser({
    query: state.accountType === 'staff' ? GET_CURRENT_STAFF : GET_CURRENT_VENDOR,
    queryName: state.accountType === 'staff' ? 'getCurrentStaff' : 'getCurrentVendor',
    token: state.token,
    endpoint: endpoints[pathOr(process.env.REACT_APP_ENDPOINT, ['login', 'endpoint'], structure)],
  })

  useEffect(() => {
    fetchData()
  }, [fetchData])

  return (
    <Typography component='h4' variant='h4'>{userName || 'ADMIN'}</Typography>
  )
}

export default UserName
