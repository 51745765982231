import React, { forwardRef, useEffect, useMemo, useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import './quil-register'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { uploadFiles } from '@qpixio/upload'

import { mergeRefs, noop } from './utils'

const RichTextEditor = forwardRef(({
  value = '',
  name = '',
  label = '',
  disabled = false,
  onChange = noop,
  onMounted = noop,
}, ref) => {
  const localRef = useRef()

  const theme = useTheme()
  useStyles()

  useEffect(() => {
    if (onMounted) onMounted(name)
  }, []) // eslint-disable-line

  const modules = useMemo(() => {
    const fileUploadHandler = (type) => () => {
      const input = document.createElement('input')

      input.setAttribute('type', 'file')
      input.setAttribute('accept', type === 'image' ? 'image/*' : 'video/*')
      input.click()

      input.onchange = async () => {
        const editor = localRef.current?.editor
        if (!editor) return

        // Save current cursor state
        const range = editor.getSelection(true)

        // Insert temporary loading placeholder image
        editor.insertEmbed(range.index, 'image', 'https://hbs-static.s3.eu-west-1.amazonaws.com/hbs-static/files/file/inV5xgCucOXORFyf6LqOg')
        const [url] = await uploadFiles(input.files, 'file')

        // Move cursor to right side of image and
        editor.setSelection(range.index + 1)
        // remove placeholder
        editor.deleteText(range.index, 1)

        // Insert uploaded image
        editor.insertEmbed(range.index, type, url)

        // big yuck but it works, this is required because editor operations don't actually update the react state / value
        onChange(editor.container.querySelector('.ql-editor').innerHTML)
      }
    }

    return ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ list: 'ordered' }, { list: 'bullet' }],
          ['link', 'image', 'video'],
          [{ indent: '-1' }, { indent: '+1' }],
          [{ color: [] }],
          [{ align: [] }],
          ['clean'],
        ],
        handlers: {
          image: fileUploadHandler('image'),
          video: fileUploadHandler('video'),
        },
      },
      imageResize: {
        parchment: Quill.import('parchment'),
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      VideoResize: {
        modules: ['Resize', 'DisplaySize', 'Toolbar'],
      },
      clipboard: {
        matchVisual: false,
      },
    })
  }, [])

  return (
    <>
      <Typography
        style={{
          color: theme.palette.info.main,
          fontSize: theme.typography.h5.fontSize,
        }}
      >
        {label}
      </Typography>

      <ReactQuill
        value={value}
        onChange={onChange}
        modules={modules}
        placeholder={label}
        ref={mergeRefs(localRef, ref)}
        disabled={disabled}
      />
    </>
  )
})

export default RichTextEditor

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.ql-toolbar': {
      border: '0 !important',
      color: [theme.palette.text.primary, '!important'],
    },
    '.ql-picker-label': {
      color: [theme.palette.text.primary, '!important'],
    },
    '.ql-stroke': {
      stroke: [theme.palette.text.primary, '!important'],
    },
    '.ql-fill': {
      fill: [theme.palette.text.primary, '!important'],
    },
    '.ql-container': {
      borderWidth: '0 0 1px 0 !important',
      borderColor: 'gray !important',
    },
    '.ql-editor::before': {
      color: [theme.palette.text.primary, '!important'],
    },
    '.ql-picker-options': {
      background: [theme.palette.secondary.main, '!important'],
      borderRadius: '8px !important',
      borderWidth: '0 !important',
      color: [theme.palette.text.secondary, '!important'],
    },
    '.ql-picker-item:hover': {
      color: [theme.palette.primary.main, '!important'],
    },
    '.ql-selected': {
      color: [theme.palette.primary.main, '!important'],
    },
    '.ql-editor iframe': {
      pointerEvents: 'none',
    },
  },
}))
