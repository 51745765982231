import React, { useEffect, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

import * as Icons from '../../../Icons'
const StopRoundedIcon = Icons.StopRounded
const CropSquareRoundedIcon = Icons.CropSquareRounded

const CheckboxInput = forwardRef(({
  value,
  onMounted,
  onChange,
  name,
  disabled,
  label,
  helperText,
  style,
  error,
}, ref) => {
  const formControlLabelClasses = useFormControlLabelStyles()
  const checkBoxClasses = useCheckBoxStyles()
  const iconClasses = useIconStyles()

  useEffect(() => {
    if (onMounted) onMounted(name)
  }, [onMounted, name])

  return (
    <>
      <FormControlLabel
        style={style}
        classes={formControlLabelClasses}
        control={
          <Checkbox
            classes={checkBoxClasses}
            checked={Boolean(value)}
            onChange={(e) => onChange({ ...e, target: { ...e.target, value: Boolean(e.target.checked) } })}
            ref={ref}
            disabled={disabled}
            icon={<CropSquareRoundedIcon className={iconClasses.root} />}
            checkedIcon={<StopRoundedIcon className={iconClasses.root} />}
            color='primary'
          />
        }
        label={label}
      />
      <FormHelperText style={{ ...error && { color: 'red' } }}>{helperText}</FormHelperText>
    </>
  )
})

const useFormControlLabelStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 0,
    paddingTop: 10,
  },
  label: {
    marginLeft: 5,
    paddingTop: 3,
    color: theme.palette.text.primary,
  },
}))

const useCheckBoxStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[3],
    borderRadius: 0,
    padding: 0,
  },
}))

const useIconStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    fontSize: theme.typography.body1.fontSize,
  },
}))

export default CheckboxInput
