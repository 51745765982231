import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import Button from '../../core/Button/Button'
import { Box, CircularProgress, FormControl, IconButton, InputLabel, MenuItem, Modal, Select } from '@material-ui/core'
import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import CloseIcon from '@material-ui/icons/Close'
import { saveAs } from 'file-saver'
import { last } from 'ramda'
import {
  GET_ROUNDS,
} from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'

export const Exportbuttons = ({ record, title, query, icon }) => {
  const [selectedRoundId, setSelectedRoundId] = useState('')
  const [open, setOpen] = useState(false)
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const [mutate, { isLoading, isSuccess, isError, error }] = useMutation(
    () =>
      request({
        query,
        endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
        variables: { roundId: selectedRoundId, schoolId: record._id },
      }),
    {
      enabled: false,
    },
  )

  const { data: roundDataFormatted } = useQuery(['round'], () =>
    request({
      query: GET_ROUNDS,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    }),
  )
  const rounds = roundDataFormatted?.getRounds?.items

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Successfully Downloaded', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar('Error occurred while downloading', { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const handleSubmit = () => {
    mutate()
      .then((resp) => {
        const url = Object.values(resp)[0]
        return saveAs(url, last(url.split('/')))
      })
      .catch(() => enqueueSnackbar('Failed to download', { variant: 'error' }))
  }

  const handleSelectChange = (event) => {
    const selectedRound = rounds.find(
      (round) => round.name.en === event.target.value,
    )
    setSelectedRoundId(selectedRound._id)
  }

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <div>
      <Button
        icon={icon}
        style={{
          padding: '1rem',
        }}
        onClick={handleToggle}
      >
        {title}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '80%', sm: '60%', md: '40%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton
            aria-label='close'
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <FormControl fullWidth style={{ padding: '10px 0px 10px' }}>
            <InputLabel id='select-label'>Select Round</InputLabel>
            <Select
              labelId='select-label'
              value={
                rounds?.find((round) => round._id === selectedRoundId)?.name
                  .en || ''
              }
              onChange={handleSelectChange}
              label='Select Round'
              disabled={isLoading}
            >
              {rounds &&
                rounds.map((round, index) => (
                  <MenuItem key={index} value={round.name.en}>
                    {round.name.en}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button
            variant='contained'
            onClick={handleSubmit}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
