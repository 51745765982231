import React from 'react'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const RegistrationCompleted = () => {
  return (
    <>
      <Box display='flex' justifyContent='center' alignItems='center' width='100vw' height='100vh'>
        <Box style={{ textAlign: 'center' }}>
          <Typography variant='h1' color='secondary'>Registration Completed</Typography>
          <Typography variant='h3'>Your account is now pending approval</Typography>
        </Box>
      </Box>
    </>
  )
}

export default RegistrationCompleted
