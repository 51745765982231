import React, { useEffect } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { GET_PLAYER } from '../../../gql/queries'
import { ProctorTabCells } from './fields'
import { CardTab } from './InfoCardTab'
import { ProctorAssignmentTable } from './ProctorAssignmentTable'

export default function ProctorPage ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()

  const { isLoading: loading, data: fetchedData } = useQuery(['proctor', key], () => request({
    query: GET_PLAYER,
    variables: { _id: key },
    endpoint: endpoints[moduleStructure.endpoint],
  }))

  useEffect(() => {
    action({ title: 'Proctor' })
  }, [])

  if (loading) {
    return (
      <div style={{ width: '95%', position: 'relative', margin: 'auto' }}>
        <LinearProgress />
      </div>
    )
  }

  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ minHeight: '20rem' }}>
          <CardTab fetchedData={fetchedData} cells={ProctorTabCells} />
        </Grid>
        <Grid item xs={12}>
          <ProctorAssignmentTable proctor={key} />
        </Grid>
      </Grid>
    </div>
  )
}
