import React, { useState, useEffect, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'

const SelectInputBase = forwardRef(({
  label = '',
  error = false,
  helperText = ' ',
  fullWidth = true,
  onChange = () => {},
  onBlur = () => {},
  onMounted = () => {},
  value = '',
  disabled = false,
  multiple = false,
  options = [],
  classes = undefined,
  className,
  name,
}, ref) => {
  useEffect(() => onMounted(name), []) // eslint-disable-line
  const [open, setOpen] = useState(false)
  const defaultClasses = useSelectStyles()

  return (
    <div>
      <FormControl fullWidth>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple={multiple}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={value || (multiple ? [] : '')}
          onChange={onChange}
          onBlur={onBlur}
          classes={classes}
          className={`${defaultClasses.root} ${className || ''}`}
          fullWidth
          disabled={disabled}
        >
          {options.map((option) => typeof option === 'string' ? <MenuItem key={option} value={option}>{option.toUpperCase()}</MenuItem> : <MenuItem key={option.key} value={option.key}>{option.label.toUpperCase()}</MenuItem>)}
        </Select>
        <FormHelperText style={{ ...error && { color: 'red' } }}>{helperText}</FormHelperText>
      </FormControl>
    </div>
  )
})

export default SelectInputBase

const useSelectStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minWidth: 100,
  },
}))
