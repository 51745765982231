import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'
import * as R from 'ramda'
import * as dateFns from 'date-fns'
import { useSnackbar } from 'notistack'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'

import useRequest from '../../helpers/useRequest'
import getFields from '../../helpers/getFormFields'
import getErrorMessages from '../../helpers/getErrorMessages'
import useEditForm from '../../helpers/useEditForm'
import * as endpoints from '../../config/endpoints'
import Button from '../core/Button'
import * as gql from '../../gql/queries'

function EditForm ({ moduleStructure }) {
  const { key } = useParams()
  const formMethods = useForm({ mode: 'all' })
  const [loading, setLoading] = useState(false)
  const { handleSubmit, reset, watch, errors } = formMethods
  const { request } = useRequest()
  const { data: initialValues, isLoading: isFetchingInitialValues, error: initialValuesError } = useQuery([moduleStructure.key, key], () => request({ endpoint: endpoints[moduleStructure.editQuery.endpoint], query: gql[moduleStructure.editQuery.query], variables: { _id: key } }), { cacheTime: 0, refetchOnWindowFocus: false })
  const [submit, { error }] = useEditForm({ editMutation: moduleStructure.editMutation, entity: moduleStructure.entity, entityId: key, uploadFields: moduleStructure.uploadFields })
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useTypographyStyles()
  const theme = useTheme()
  if (moduleStructure.dependencies) watch(moduleStructure.dependencies)

  const errorMessages = getErrorMessages(errors)

  useEffect(() => {
    if (initialValues) reset(moduleStructure.formatInitialValues ? moduleStructure.formatInitialValues(R, initialValues) : initialValues)
  }, [initialValues, reset])

  const edit = async (values) => {
    const data = await submit(values)
    if (data && data[Object.keys(data)[0]]) {
      enqueueSnackbar('Edited Item Successfully', { variant: 'success' })
      setLoading(false)
      history.push(moduleStructure.redirectRoute.replace(':key', data[Object.keys(data)[0]].key))
    }
  }

  useEffect(() => {
    if (error) {
      if (R.path(['response', 'errors', 0, 'message'], error)) {
        enqueueSnackbar(R.path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
      setLoading(false)
      console.log(error)
    }
  }, [enqueueSnackbar, error])

  const onSubmit = (values) => {
    if (loading) return
    setLoading(true)
    if (moduleStructure.beforeSubmit) {
      const { values: newValues, error } = moduleStructure.beforeSubmit(
        R,
        values,
        initialValues,
        { dateFns },
      )
      if (!error) edit({ ...newValues, _id: key })
      else {
        enqueueSnackbar(error, { variant: 'error' })
        setLoading(false)
      }
    } else edit({ ...values, _id: key })
  }

  if (initialValuesError) return <div> failed to fetch item values </div>
  if (isFetchingInitialValues) return <LinearProgress />

  return (
    <div style={{ paddingTop: '2.5rem' }}>
      <Typography component='h2' variant='h2' classes={classes} gutterBottom align='center'>
        {moduleStructure.label}
      </Typography>
      <Box display='flex' justifyContent='center'>
        <Box style={{ width: 1100 }}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                getFields({ fields: moduleStructure.fields, formMethods, moduleStructure })
              }

              <Box display='flex' justifyContent='flex-end' padding='1rem'>
                {
                  errorMessages.length
                    ? <ul> {errorMessages.map((message, index) => (<Typography style={{ color: 'red' }} key={index} component='li' gutterBottom align='left'> {message} </Typography>))} </ul>
                    : null
                }
                <Button
                  color='primary'
                  variant='contained'
                  disableElevation
                  type='submit'
                  size='large'
                  style={{
                    margin: '0 1rem',
                    height: '3.2rem',
                    width: '7rem',
                    fontSize: theme.typography.h3.fontSize,
                  }}
                  loading={loading}
                >
                  EDIT
                </Button>
              </Box>
              <p style={{ color: 'red', textAlign: 'center' }}>{R.path(['graphQLErrors', '0', 'message'], error) || ''}</p>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </div>
  )
}

export default EditForm

const useTypographyStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.info.main,
    textTransform: 'uppercase',
  },
}))
