import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { GET_TRIP } from '../../../gql/queries'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as endpoints from '../../../config/endpoints'
import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container } from '@material-ui/core'
import { path, pathOr } from 'ramda'

export default function SingleTrip ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()
  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()

  const { isLoading: loading, data: fetchedTrip, error } = useQuery(['trip', key], () => request({ query: GET_TRIP, variables: { _id: key }, endpoint: endpoints[moduleStructure.endpoint] }))

  useEffect(() => {
    action({ title: 'drivers' })
  }, []) // eslint-disable-line

  if (loading && !fetchedTrip) return <div style={{ width: '95%', position: 'relative', margin: 'auto' }}><LinearProgress /></div>
  if (error) return <div>failed to fetch request</div>

  return (
    <Container maxWidth='lg' style={{ padding: 0 }}>
      <Grid container spacing={4}>
        <Grid item xs={8}>
          <div className={cardClasses.card}>
            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>Status</div>
                <div
                  className={textClasses.value}
                >{pathOr('-', ['getTrip', 'status'], fetchedTrip)}
                </div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>Accepted Time</div>
                <div className={textClasses.value}>
                  {
                  path(['getTrip', 'acceptedTime'], fetchedTrip)
                    ? new Date(Number(path(['getTrip', 'acceptedTime'], fetchedTrip))).toLocaleString('en-us', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    })
                    : '-'
                }
                </div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>Fulfillment time</div>
                <div className={textClasses.value}>
                  {
                  path(['getTrip', 'fulfillmentTime'], fetchedTrip)
                    ? new Date(Number(path(['getTrip', 'fulfillmentTime'], fetchedTrip))).toLocaleString('en-us', {
                      day: 'numeric',
                      month: 'short',
                      year: 'numeric',
                      hour: 'numeric',
                      minute: 'numeric',
                      hour12: false,
                    })
                    : '-'
                }
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>Driver Phone</div>
                <div
                  className={textClasses.value}
                >{pathOr('-', ['getTrip', 'driver', 'phone'], fetchedTrip)}
                </div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>Driver Name</div>
                <div
                  className={textClasses.value}
                >{pathOr('-', ['getTrip', 'driver', 'name'], fetchedTrip)}
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>User Phone</div>
                <div
                  className={textClasses.value}
                >{pathOr('-', ['getTrip', 'user', 'phone'], fetchedTrip)}
                </div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>User Name</div>
                <div
                  className={textClasses.value}
                >{pathOr('-', ['getTrip', 'user', 'name'], fetchedTrip)}
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
        <Grid item xs={4}>
          <div className={cardClasses.card}>
            <div className={textClasses.key} style={{ marginBottom: 20 }}>Payment</div>
            <Grid container>
              <Grid item xs={9}>
                <div className={textClasses.value}>SUBTOTAL</div>
              </Grid>
              <Grid item xs={3}>
                <div className={textClasses.valueRight}>{Number(pathOr(0, ['getTrip', 'totals', 'subtotal'], fetchedTrip)).toFixed(2)}</div>
              </Grid>
            </Grid>
            <div className={cardClasses.divider} />
            <Grid container>
              <Grid item xs={9}>
                <div className={textClasses.value}>SHIPPING</div>
              </Grid>
              <Grid item xs={3}>
                <div className={textClasses.valueRight}>{Number(pathOr(0, ['getTrip', 'totals', 'shipping'], fetchedTrip)).toFixed(2)}</div>
              </Grid>
            </Grid>
            <div className={cardClasses.divider} />
            <Grid container>
              <Grid item xs={9}>
                <div className={textClasses.value}>DRIVER FEES</div>
              </Grid>
              <Grid item xs={3}>
                <div className={textClasses.valueRight}>{Number(pathOr(0, ['getTrip', 'totals', 'driverFees'], fetchedTrip)).toFixed(2)}</div>
              </Grid>
            </Grid>
            <div className={cardClasses.divider} />
            <Grid container>
              <Grid item xs={9}>
                <div className={textClasses.value}>DISCOUNT</div>
              </Grid>
              <Grid item xs={3}>
                <div className={textClasses.valueRight}>
                  {Number(pathOr(0, ['getTrip', 'totals', 'discount', 'amount'], fetchedTrip)).toFixed(2)}
                  {path(['getTrip', 'totals', 'discount', 'reference'], fetchedTrip)
                    ? ` (${path(['getTrip', 'totals', 'discount', 'reference', 'code'], fetchedTrip)} - ${path(['getTrip', 'totals', 'discount', 'reference', 'valueType'], fetchedTrip) === 'Percentage'
                      ? `${path(['getTrip', 'totals', 'discount', 'reference', 'value'], fetchedTrip)}%)`
                      : `${path(['getTrip', 'totals', 'discount', 'reference', 'value'], fetchedTrip)})`}`
                    : ''}
                </div>
              </Grid>
            </Grid>
            <div className={cardClasses.divider} />
            <Grid container>
              <Grid item xs={9}>
                <div className={textClasses.value}>TOTAL</div>
              </Grid>
              <Grid item xs={3}>
                <div className={textClasses.valueRight}>{Number(pathOr(0, ['getTrip', 'totals', 'total'], fetchedTrip)).toFixed(2)}</div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div className={textClasses.mainHeader}>REQUEST STEPS</div>
      <div className={cardClasses.card} style={{ minHeight: '13rem' }}>
        {Array.isArray(path(['getTrip', 'request', 'steps'], fetchedTrip)) && path(['getTrip', 'request', 'steps'], fetchedTrip).map((step) => (
          <div key={step.name}>
            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>Name</div>
                <div className={textClasses.value}>{pathOr('-', ['name'], step)}</div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>type</div>
                <div className={textClasses.value}>{pathOr('-', ['type'], step)}</div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>status</div>
                <div className={textClasses.value}>{pathOr('-', ['status'], step)}</div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>expected amount range</div>
                <div className={textClasses.value}>{pathOr(0, ['expectedAmountRange', 'min'], step).toFixed(2)} - {pathOr(0, ['expectedAmountRange', 'max'], step).toFixed(2)}</div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>order</div>
                <div className={textClasses.value}>{pathOr('-', ['order'], step)}</div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>comment</div>
                <div className={textClasses.value}>{pathOr('-', ['comment'], step)}</div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={12}>
                <div className={textClasses.key}>content</div>
                <div className={textClasses.value}>{pathOr('-', ['content'], step)}</div>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </Container>
  )
}

const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.6rem',
  },
  subKey: {
    color: theme.palette.info.main,
    fontSize: '1.5rem',
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
    fontWeight: 300,
  },
  valueRight: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
    fontWeight: 300,
    textAlign: 'end',
  },
  mainHeader: {
    color: theme.palette.info.main,
    textAlign: 'center',
    fontSize: '2rem',
    fontWeight: 400,
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  header: {
    color: theme.palette.info.main,
    fontSize: '1.7rem',
    marginTop: '.56rem',
    marginBottom: '3.6rem',
  },
  totalsKey: {
    fontSize: '1.6rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

const useCardStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    padding: '2rem',
    marginTop: '1.5rem',
    minHeight: '20rem',
  },
  divider: {
    background: '#82b3d626',
    color: '#82b3d626',
    height: 1,
    margin: 'auto',
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '65%',
  },
}))
