/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import Button from '../../core/Button/Button'
import useRequest from '../../../helpers/useRequest'
import {
  EXPORT_ADMISSION_RECEIPTS,
  EXPORT_ADMISSIONS,
  EXPORT_ADMISSIONS_NOTES,
  EXPORT_CENTERS,
  EXPORT_PROCTOR_ASSIGNMENTS,
  EXPORT_PROCTORS,
  EXPORT_SCHOOLS,
  EXPORT_STUDENTS,
  EXPORT_ALL_ROUNDS_SCORES,
  EXPORT_EST_RESOURCES,
} from '../../../gql/queries'
import { useSnackbar } from 'notistack'
import { last } from 'ramda'
import { saveAs } from 'file-saver'
import * as endpoints from '../../../config/endpoints'
import { Grid } from '@material-ui/core'
import TextField from '@material-ui/core/TextField'

const getExportButton = ({ query, label }) => () => {
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()
  const [mutate, { isSuccess, isError, error }] = useMutation(() => request({
    query,
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    variables: label === 'Download Scores' ? { email: email } : {},
  }))

  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState('')
  const [emailTextField, setEmailTextField] = useState(false)

  useEffect(() => {
    if (isSuccess && label !== 'Download Scores') {
      enqueueSnackbar('Downloading', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(error.message, { variant: 'error' })
    } else if (isSuccess && label === 'Download Scores') {
      enqueueSnackbar('sent', { variant: 'success' })
    }
  }, [isError, isSuccess, error])

  const handleClick = () => {
    if (label !== 'Download Scores') {
      setLoading(true)
      enqueueSnackbar('Generating file', { variant: 'info' })
      mutate()
        .then((resp) => {
          const url = Object.values(resp)[0]
          return saveAs(url, last(url.split('/')))
        })
        .catch(() => enqueueSnackbar('Failed to download file', { variant: 'error' }))
        .finally(() => setLoading(false))
    } else {
      setEmailTextField((preState) => !preState)
    }
  }

  const handleScoreExport = () => {
    setLoading(true)
    mutate()
      .then()
      .catch(() => enqueueSnackbar('Failed to send', { variant: 'error' }))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Button
        onClick={handleClick}
        disabled={loading}
        variant='contained'
        loading={loading}
        style={{
          alignSelf: 'center',
          margin: '0 0 0 auto',
          padding: '1rem',
        }}
      >
        {label}
      </Button>
      {emailTextField && <Grid>
        <Grid item xs={12}>
          <TextField
            id='outlined-multiline-flexible'
            label='Email'
            multiline
            maxRows={4}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>
        <Button
          onClick={handleScoreExport}
          variant='contained'
          loading={loading}
          style={{
            alignSelf: 'center',
            margin: '0 0 0 auto',
            padding: '1rem',
          }}
        >
          submit
        </Button>
      </Grid>}
    </>
  )
}

const exporters = [
  { name: 'Receipts', query: EXPORT_ADMISSION_RECEIPTS },
  { name: 'Admission', query: EXPORT_ADMISSIONS },
  { name: 'Students', query: EXPORT_STUDENTS },
  { name: 'Proctors', query: EXPORT_PROCTORS },
  { name: 'Proctor Assignments', query: EXPORT_PROCTOR_ASSIGNMENTS },
  { name: 'Centers', query: EXPORT_CENTERS },
  { name: 'Schools', query: EXPORT_SCHOOLS },
  { name: 'Admissions Notes', query: EXPORT_ADMISSIONS_NOTES },
  { name: 'Scores', query: EXPORT_ALL_ROUNDS_SCORES },
  { name: 'EST Resources', query: EXPORT_EST_RESOURCES },
]

export const exportAdmissionsCell = exporters.map(({ name, query }) => ({
  key: `Export ${name}`,
  path: [],
  // this needs to be a form not a button
  formatter: getExportButton({ query, label: `Download ${name}` }),
}))
