import { useState } from 'react'

const useDebounce = (fn, time) => {
  const [timer, setTimer] = useState()

  return (args) => {
    if (timer) setTimer(clearTimeout(timer))
    setTimer(setTimeout(() => fn(args), time))
  }
}

export default useDebounce
