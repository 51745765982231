import React, { useState } from 'react'
import { isNil, path, prop } from 'ramda'
import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { APPROVE_TAXONOMY_REQUEST } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import * as Icons from '../../../Icons'

import IconButton from '@material-ui/core/IconButton'
import SelectSync from '../../core/SelectSync'
import Text from '../../core/Text'
import Button from '../../core/Button'
import Dialog from '../../core/Dialog'
import { Box } from '@material-ui/core'
import { useHistory } from 'react-router-dom'

const VerifiedUserIcon = Icons.VerifiedUserIcon

const ApproveTaxonomyRequest = ({
  field,
  record,
  refetch = () => {
  },
}) => {
  const [loading, setLoading] = useState(false)
  const [taxonomyCode, setTaxonomyCode] = useState(null)
  const [parentCode, setParentCode] = useState(null)
  const [status, setStatus] = useState('approved')
  const [open, setOpen] = useState(false)
  const { request } = useRequest()
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  const handleApprove = async () => {
    try {
      await request({
        query: APPROVE_TAXONOMY_REQUEST,
        endpoint: endpoints[field.endpoint],
        variables: {
          _id: record._id,
          ...(taxonomyCode && { newTaxonomy: { code: taxonomyCode, relatedTo: 'Service' } }),
          ...(parentCode && { newParent: { code: parentCode, relatedTo: 'Service' } }),
        },
      })

      refetch()
      enqueueSnackbar('Success', { variant: 'success' })
    } catch (err) {
      console.log(err)
      if (path(['response', 'errors', 0, 'message'], err)) {
        enqueueSnackbar(path(['response', 'errors', 0, 'message'], err), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    } finally {
      setLoading(false)
    }
  }
  const onClick = async () => {
    if (loading) return
    if (status === 'approved') await handleApprove()
    setOpen(false)
  }

  const btnDisabled = (!isNil(record.newTaxonomy) && !taxonomyCode) || (!isNil(record.newParent) && !parentCode)

  if (!prop('_id', record)) {
    return null
  }
  return (
    <IconButton
      color='primary'
    >
      <VerifiedUserIcon onClick={() => setOpen(true)} />
      <Dialog
        label='Approve Taxonomy Request' open={open}
        onClose={() => setOpen(false)}
      >
        <SelectSync
          label='Status'
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          options={['approved', 'rejected']}
        />
        {!isNil(record.newTaxonomy) && <Text
          label='Taxonomy Code'
          value={taxonomyCode}
          onChange={(e) => setTaxonomyCode(e.target.value)}
          error={isNil(taxonomyCode)}
                                       />}
        {!isNil(record.newParent) && <Text
          label='Parent Code'
          value={parentCode}
          onChange={(e) => setParentCode(e.target.value)}
          error={isNil(parentCode)}
                                     />}

        <Box display='flex' justifyContent='space-between' alignItems='center' width='100%'>
          <Button
            onClick={onClick}
            variant='contained'
            style={{ margin: '2rem 2rem 0 2rem' }}
            loading={loading}
            fullWidth
            disabled={btnDisabled}
          >
            Update Status
          </Button>
          <Button
            onClick={() => history.push(`/taxonomyRequests/${record._id}/edit`)}
            variant='contained'
            style={{ margin: '2rem 2rem 0 2rem' }}
            fullWidth
            // width='20rem'
          >Edit Request
          </Button>
        </Box>

      </Dialog>
    </IconButton>
  )
}

export default ApproveTaxonomyRequest
