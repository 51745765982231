import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { pathOr } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Paper from '@material-ui/core/Paper'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TablePagination from '@material-ui/core/TablePagination'
import TableRow from '@material-ui/core/TableRow'
import Chip from '@material-ui/core/Chip'
import Box from '@material-ui/core/Box'

import TrendingUpIcon from '@material-ui/icons/TrendingUp'
import TrendingDownIcon from '@material-ui/icons/TrendingDown'
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat'

import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { SUP_GET_TOP_PRODUCTS_REPORT } from '../../../gql/queries'

const pageSize = 10

const columns = [
  {
    id: 'name',
    label: 'Name',
    minWidth: 100,
    format: (value, cell) => <ProductName cell={cell} />,
  },
  {
    id: 'date',
    label: 'Date',
    minWidth: 100,
    format: (value) => <ProductDate value={value} />,
  },
  {
    id: 'quantityDisplay',
    label: 'Quantity',
    minWidth: 170,
  },
  {
    id: 'priceGreaterThanMarketplacePrice',
    label: 'indicator',
    minWidth: 200,
    format: (value) => <ProductPriceComparison value={value} />,
  },
  {
    id: 'price',
    label: 'Price',
    minWidth: 100,
    format: (value) => <ProductPrice value={value} />,
  },
  {
    id: 'marketplacePrice',
    label: 'marketplacePrice',
    minWidth: 100,
    format: (value) => <ProductPrice value={value} backgroundColor='#696969' color='white' />,
  },
]

export default function Totals ({ moduleStructure, minDate, maxDate }) {
  const { request } = useRequest()
  const [page, setPage] = useState(0)
  const variables = { page: page + 1, pageSize, ...(minDate && maxDate) && { dateRange: { min: `${new Date(minDate).setHours(0, 0, 0, 0).valueOf()}`, max: `${new Date(maxDate).setHours(23, 59, 59, 0).valueOf()}` } } }
  const { data } = useQuery(['sup_reports_top_products', variables], () => request({ query: SUP_GET_TOP_PRODUCTS_REPORT, variables, endpoint: endpoints[moduleStructure.endpoint] }))
  const classes = useStyles()

  return (
    <>
      <Box display='flex' justifyContent='space-between' alignItems='center' flexWrap='wrap'>
        <Box>
          <Typography variant='h5' component='h4'>
            FREQUENTLY BOUGHT WATCH LIST
          </Typography>
        </Box>
        <Box>
          <TablePagination
            style={{ minHeight: '40px' }}
            rowsPerPageOptions={[]}
            component='div'
            count={pathOr(0, ['getSupTopProducts', 'totalCount'], data)}
            rowsPerPage={pageSize}
            page={page}
            onChangePage={(e, newPage) => setPage(newPage)}
          />
        </Box>
      </Box>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label='sticky table'>
            <TableBody>
              {pathOr([], ['getSupTopProducts', 'items'], data).map((row) => {
                return (
                  <TableRow hover role='checkbox' tabIndex={-1} key={row.code}>
                    {columns.map((column) => {
                      const value = row[column.id]
                      return (
                        <TableCell key={column.id} align={column.align}>
                          {column.format ? column.format(value, row) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </>
  )
}

function ProductName ({ cell }) {
  return (
    <Typography style={{ fontSize: '1.1em' }} variant='p' component='p'>
      {cell.product.name} - {cell.countryOfOrigin.name}
    </Typography>
  )
}

function ProductDate ({ value }) {
  if (!value) return null
  return (
    <Typography style={{ fontSize: '1.1em' }} variant='p' component='p'>
      {new Date(Number(value)).toDateString()}
    </Typography>
  )
}

function ProductPriceComparison ({ value }) {
  if (value === 1) return <TrendingUpIcon style={{ color: 'green' }} />
  if (value === -1) return <TrendingDownIcon style={{ color: 'red' }} />
  return <TrendingFlatIcon />
}

function ProductPrice ({ value, backgroundColor, color }) {
  return (
    <Chip
      label={`${value?.toFixed?.(2)} AED`}
      style={{
        backgroundColor,
        color,
        width: '100px',
        borderRadius: 0,
        letterSpacing: '1px',
        fontSize: '1.1em',
      }}
    />
  )
}

const useStyles = makeStyles({
  root: {
    width: '100%',
    marginTop: '5px',
  },
  container: {
    // maxHeight: 245,
  },
})
