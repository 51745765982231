import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import {
  ADD_PROCTOR_ASSIGNMENT,
  EDIT_PROCTOR_ASSIGNMENT,
  GET_PROCTOR_ASSIGNMENT,
  GET_PROCTOR_ASSIGNMENT_FULL,
} from '../../../gql/queries'
import { queryCache } from '../../../index'
import { useEffect, useMemo } from 'react'
import { map, path, prop, propOr } from 'ramda'
import * as endpoints from '../../../config/endpoints'

export const useAddAssignment = () => {
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const [add, { isLoading, isSuccess, isError, error }] = useMutation(
    (variables) => request({
      query: ADD_PROCTOR_ASSIGNMENT,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
      variables,
    }),
    {
      onSuccess: () => queryCache.invalidateQueries(['proctorAssignments'], { refetchActive: true }),
    })

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Created successfully', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const handleAdd = (data) => {
    return add(data)
      .then(({ error }) => {
        if (error) {
          throw error
        }
      })
  }

  return { add: handleAdd, loading: isLoading }
}

export function useGetAssigment (assignmentId, full = false) {
  const { request } = useRequest()

  const { isLoading, data } = useQuery([full ? 'proctorAssignmentFull' : 'proctorAssignment', assignmentId],
    () => request({
      query: full ? GET_PROCTOR_ASSIGNMENT_FULL : GET_PROCTOR_ASSIGNMENT,
      variables: { _id: assignmentId },
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    }),
    { enabled: assignmentId },
  )

  const assignment = useMemo(() => propOr({}, 'getProctorAssignment', data), [data])

  return { isLoading, assignment }
}

export const useEditAssignment = (assignmentId) => {
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const { isLoading, assignment } = useGetAssigment(assignmentId)

  const [edit, { isLoading: editLoading, isSuccess, isError, error }] = useMutation(
    (variables) => request({
      query: EDIT_PROCTOR_ASSIGNMENT,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
      variables,
    }),
    {
      onSuccess: async () => {
        await queryCache.invalidateQueries(['proctorAssignments'], { refetchActive: true })
        await queryCache.invalidateQueries(['proctorAssignment'], { refetchActive: true })
      },
    })

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Updated successfully', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const handleEdit = (data) => {
    return edit(data)
      .then(({ error }) => {
        if (error) {
          throw error
        }
      })
  }

  const formatted = useMemo(() => ({
    ...assignment,
    round: path(['round', '_id'], assignment),
    centers: map(prop('_id'), propOr([], 'centers', assignment)),
    exam: path(['exam', '_id'], assignment),
  }), [assignment])

  console.log({ formatted })
  return { edit: handleEdit, assignment: formatted, loading: isLoading || editLoading }
}
