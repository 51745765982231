import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Button from '../../core/Button/Button'
import useRequest from '../../../helpers/useRequest'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { queryCache } from '../../../index'
import * as endpoints from '../../../config/endpoints'
import { EDIT_GUIDE_BOOK_REQUEST } from '../../../gql/queries'
import SelectSync from '../../core/SelectSync/SelectSync'
import TextInput from '../../core/Text'
import { governorateCities, governorates } from './locations-data'

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 284px)',
  },
  bodyCell: {
    fontSize: theme.typography.h5.fontSize,
    height: 90,
    padding: 0,
    '& p': {
      margin: 0,
    },
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h5.fontSize,
    },
  },
  headerCell: {
    fontSize: theme.typography.h5.fontSize,
    padding: 0,
    height: 90,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.info.main,
  },
}))

function AttributeCell ({ felidName, handleChange, shippingAddress }) {
  const [shippingAdd, setShippingAdd] = useState({ ...shippingAddress })

  useEffect(() => {
    handleChange(shippingAdd)
  }, [shippingAdd])

  if (felidName === 'governorate') {
    return (
      <SelectSync
        value={shippingAdd[felidName]}
        options={governorates}
        onChange={(e) => setShippingAdd({ ...shippingAddress, [felidName]: e.target.value })}
      />
    )
  }

  if (felidName === 'city') {
    const government = shippingAddress.governorate
    return (
      <SelectSync
        value={shippingAdd[felidName]}
        options={governorateCities[government].map((city) => ({ key: city._id, label: city.label }))}
        onChange={(e) => setShippingAdd({ ...shippingAddress, [felidName]: e.target.value })}
      />
    )
  }

  return (
    <TextInput
      value={shippingAdd[felidName]}
      onChange={(e) => setShippingAdd({ ...shippingAddress, [felidName]: e.target.value })}
    />
  )
}

function RequestAttributesBody ({ shippingAddress, editing, handleChange }) {
  const styles = useStyles()

  return Object.entries(shippingAddress).map(([key, value]) => (
    <TableRow key={key}>
      <TableCell align='center' className={styles.bodyCell}>shipping {key}</TableCell>
      <TableCell align='center' className={styles.bodyCell}>
        {editing ? <AttributeCell handleChange={handleChange} felidName={key} shippingAddress={shippingAddress} /> : value}
      </TableCell>
      <TableCell align='center' className={styles.bodyCell} />
    </TableRow>
  ))
}

export function RequestFieldTable ({ shippingAddress }) {
  const { request } = useRequest()
  const { key } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const [edit, { isLoading: loading }] = useMutation(
    (variables) => request({
      query: EDIT_GUIDE_BOOK_REQUEST,
      variables,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    }), {
      onSuccess: () => queryCache.invalidateQueries(['bookRequest', key]),
    })

  const [editing, setEditing] = useState(false)
  const [editingData, setEditingData] = useState({})
  const styles = useStyles()

  const handleChange = (newShipping) => {
    setEditingData({ ...newShipping })
  }

  const handleEdit = () => {
    if (!editing) {
      setEditing(true)
      return
    }

    edit({ _id: key, shippingAddress: { ...editingData } })
      .then(() => {
        enqueueSnackbar('Saved successfully', { variant: 'success' })
        setEditing(false)
      })
  }
  return (
    <TableContainer className={styles.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' className={styles.headerCell}>Name</TableCell>
            <TableCell align='center' className={styles.headerCell}>Value</TableCell>
            <TableCell align='center' className={styles.headerCell}>
              <Button
                variant='contained'
                style={{ marginLeft: 20, marginBottom: 10 }}
                onClick={handleEdit}
              >
                {editing ? (loading ? 'saving' : 'Save') : 'Edit'}
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RequestAttributesBody shippingAddress={shippingAddress} editing={editing} handleChange={handleChange} />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
