import React from 'react'
import { path } from 'ramda'
import definitions from '../../../config/definitions'

export const simpleVariations = [
  {
    label: 'Image',
    key: 'product.images',
    formatField: (R, cell, record) => cell && cell.length > 0 ? <span><img style={{ height: '8vh', display: 'inline' }} src={`${definitions.cloudinaryUrl}${cell[0]}`} alt={path(['variation', 'product', 'name', 'en'], record)} /></span> : '-',
  },
  {
    label: 'Name',
    key: 'product.name.en',
  },
  {
    label: 'Number of Pieces',
    key: 'variation.size.code',
  },
  {
    label: 'Quantity',
    key: 'quantity',
    formatField: (R, cell) => cell ? cell.toString() : '',
  },
  {
    label: 'Unit Price',
    key: 'itemPrice',
    formatField: (R, cell) => cell ? Number(cell).toFixed(2).toString() : '',
  },
  {
    label: 'Total Price',
    key: 'totalPrice',
    formatField: (R, cell) => cell ? Number(cell).toFixed(2).toString() : '',
  },
]

export const collections = [
  {
    label: 'Image',
    key: 'collection.images',
    formatField: (R, cell, record) => cell && cell.length > 0 ? <span><img style={{ height: '8vh', display: 'inline' }} src={`${definitions.cloudinaryUrl}${cell[0]}`} /></span> : '-',
  },
  {
    label: 'Combo Name',
    key: 'collection.name.en',
  },
  {
    label: 'Number of Pieces',
    key: 'collection.sizes.0.code',
  },
  {
    label: 'Quantity',
    key: 'quantity',
    formatField: (R, cell) => cell ? cell.toString() : '',
  },
  {
    label: 'Unit Price',
    key: 'itemPrice',
    formatField: (R, cell) => cell ? Number(cell).toFixed(2).toString() : '',
  },
  {
    label: 'Total Price',
    key: 'totalPrice',
    formatField: (R, cell) => cell ? Number(cell).toFixed(2).toString() : '',
  },
]

export const nestedCollections = [
  {
    label: 'Name',
    key: 'product.name.en',
  },
  {
    label: 'Number of Pieces',
    key: 'variation.size.code',
  },
]
