import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { useForm, Controller } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles'
import { Grid } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import { useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import * as R from 'ramda'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useHistory, useParams } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import * as Icons from '../../../Icons'
import Button from '../../core/Button'
import Box from '@material-ui/core/Box'
import { GET_PERMISSIONS } from '../../../gql/queries'
import * as gql from '../../../gql/queries'
import useEditForm from '../../../helpers/useEditForm'

const StopRoundedIcon = Icons.StopRounded
const CropSquareRoundedIcon = Icons.CropSquareRounded

export default function EditRole ({ moduleStructure }) {
  console.log(moduleStructure)
  const { key } = useParams()

  const textClasses = useTextStyles()
  const formControlLabelClasses = useFormControlLabelStyles()
  const checkBoxClasses = useCheckBoxStyles()
  const iconClasses = useIconStyles()
  const history = useHistory()

  const { enqueueSnackbar } = useSnackbar()
  const { request } = useRequest()
  const [loading, setLoading] = useState(false)
  const [checkedPermissions, setCheckedPermissions] = useState([])
  const { editQuery, editMutation } = moduleStructure.customProps

  const { data: initialValues, isLoading: isFetchingInitialValues, error: initialValuesError } = useQuery([moduleStructure.key, key], () => request({ endpoint: endpoints[editQuery.endpoint], query: gql[editQuery.query], variables: { _id: key } }), { cacheTime: 0 })
  const { handleSubmit, setValue, getValues, errors, reset, control } = useForm({
    defaultValues: {
      name: '',
      permissions: [],
    },
  })

  const { data: { getPermissions: fetchedPermissions = [] } = {} } = useQuery(['permissions', 'addRole'], () => request({ query: GET_PERMISSIONS, endpoint: endpoints[moduleStructure.endpoint] }))
  const [submit, { error }] = useEditForm({ editMutation: editMutation, entity: 'role', entityId: key })

  useEffect(() => {
    if (initialValues?.getRole) {
      reset({ ...initialValues?.getRole, permissions: initialValues?.getRole.permissions.map((p) => p.key) })
      setCheckedPermissions(initialValues?.getRole.permissions.map((p) => p.key))
    }
  }, [initialValues, reset])

  useEffect(() => {
    if (error) {
      if (R.path(['response', 'errors', 0, 'message'], error)) {
        enqueueSnackbar(R.path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
      setLoading(false)
      console.log(error)
    }
  }, [enqueueSnackbar, error])

  const onSubmit = async (data) => {
    if (loading) return
    setLoading(true)
    const result = await submit(data, 0)
    if (result && result[Object.keys(result)[0]]) {
      enqueueSnackbar('Edited Item Successfully', { variant: 'success' })
      history.push('/roles')
    }
  }

  const handleChange = (permissionId) => {
    const currentChosenPermissions = getValues('permissions')
    const alreadyChosen = currentChosenPermissions.find((p) => p === permissionId)
    if (alreadyChosen) {
      setValue('permissions', currentChosenPermissions.filter((p) => p !== permissionId))
      setCheckedPermissions(currentChosenPermissions.filter((p) => p !== permissionId))
    } else {
      setValue('permissions', [...currentChosenPermissions, permissionId])
      setCheckedPermissions([...currentChosenPermissions, permissionId])
    }
  }

  if (initialValuesError) return <div> failed to fetch item values </div>
  if (isFetchingInitialValues) return <LinearProgress />

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      style={{ minHeight: '100vh' }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '80%' }}>
        <Grid item style={{ paddingTop: '2.5rem' }}>
          <Typography component='h2' variant='h2' gutterBottom align='center' className={textClasses.mainHeader}>
            Edit Role
          </Typography>
        </Grid>
        <Grid item>
          <Controller
            as={<TextField
              error={!!errors.name}
              label='Name'
              name='name'
              fullWidth
              type='text'
              margin='normal'
              helperText={errors.name ? errors.name.message : null}
              onChange={(e) => setValue('name', e.target.value, { shouldValidate: true })}
                />} name='name' control={control}
          />
        </Grid>
        <Typography component='h3' variant='h3' gutterBottom align='left' className={textClasses.mainHeader}>
          Permissions
        </Typography>
        {!fetchedPermissions?.items?.length
          ? <LinearProgress />
          : (
            <Grid container spacing={4}>
              {fetchedPermissions.items.map((permission) =>
                <Grid key={permission.key} item xs={3}>
                  <Controller
                    as={<FormControlLabel
                      classes={formControlLabelClasses}
                      name={permission.key}
                      control={
                        <Checkbox
                          classes={checkBoxClasses}
                          onChange={() => handleChange(permission.key)}
                          icon={<CropSquareRoundedIcon className={iconClasses.root} />}
                          checkedIcon={<StopRoundedIcon className={iconClasses.root} />}
                          color='primary'
                          checked={Boolean(checkedPermissions.find((p) => p === permission.key))}
                        />
                      }
                      label={permission.name}
                        />} name='permissions' control={control}
                  />
                </Grid>)}
            </Grid>)}
        <Box display='flex' justifyContent='flex-end' style={{ marginTop: 40 }}>
          <Button
            color='primary'
            variant='contained'
            disableElevation
            type='submit'
            size='large'
            style={{
              margin: '0 1rem',
              height: '4.2rem',
              width: '9rem',
              fontSize: '2.5rem',
            }}
            loading={loading}
          >
            EDIT
          </Button>
        </Box>
      </form>
    </Grid>
  )
}

const useTextStyles = makeStyles((theme) => ({
  mainHeader: {
    color: theme.palette.info.main,
    fontSize: '2rem',
    fontWeight: 400,
    marginTop: '3rem',
    marginBottom: '3rem',
  },
}))

const useFormControlLabelStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 0,
    paddingTop: 10,
  },
  label: {
    marginLeft: 5,
    paddingTop: 3,
    fontFamily: 'inherit',
    color: theme.palette.text.primary,
  },
}))

const useCheckBoxStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    fontFamily: 'Barlow Condensed',
    fontWeight: 300,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[3],
    borderRadius: 0,
    padding: 0,
  },
}))

const useIconStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
  },
}))
