import React from 'react'
import Box from '@material-ui/core/Box'
import EditIcon from '@material-ui/icons/Edit'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { map, path } from 'ramda'
import Grid from '@material-ui/core/Grid'
import { Divider } from '@material-ui/core'

export const ProctorTabCells = [
  { key: 'English Name', path: ['getPlayer', 'nameObject', 'en'] },
  { key: 'Arabic Name', path: ['getPlayer', 'nameObject', 'ar'] },
  { key: 'Phone', path: ['getPlayer', 'phone'] },
  { key: 'Email', path: ['getPlayer', 'email'] },
  { key: 'City', path: ['getPlayer', 'city', 'name', 'en'] },
  { key: 'District', path: ['getPlayer', 'district', 'name', 'en'] },
  { key: 'Qualification', path: ['getPlayer', 'education', 'qualification'] },
  { key: 'Organization', path: ['getPlayer', 'business', 'name'] },
  { key: 'Organization Type', path: ['getPlayer', 'business', 'type'] },
  { key: 'Department', path: ['getPlayer', 'business', 'department'] },
]

export const ProctorAssignmentCells = [
  { key: 'Proctor', path: ['player', 'nameObject', 'en'] },
  { key: 'Role', path: ['role'] },
  {
    showFn: (data, { role }) => role !== 'lead',
    key: 'City',
    path: ['centers', 0, 'city', 'name', 'en'],
  },
  {
    showFn: (data, { role }) => role !== 'lead',
    key: 'District',
    path: ['centers', 0, 'district', 'name', 'en'],
  },
  {
    showFn: (data) => data?.length > 0,
    fullWidth: true,
    key: 'Junior Proctors',
    path: ['proctors'],
    formatter:
      (proctors) => map(path(['nameObject', 'en']), proctors ?? [])
        .join(', ') || '-',
  },
  {
    showFn: (data) => data?.length > 0,
    fullWidth: true,
    key: 'Senior Proctors',
    path: ['seniorProctors'],
    formatter:
      (proctors) => map(path(['nameObject', 'en']), proctors ?? [])
        .join(', ') || '-',
  },
  {
    showFn: (data) => data?.length > 1,
    fullWidth: true,
    key: 'Centers',
    path: ['centers'],
    formatter: (data) => {
      const centers = map(path(['name', 'en']), data ?? [])
      return (
        <ul style={{ margin: 0 }}>
          {/* eslint-disable-next-line react/jsx-key */}
          {centers.map((center) => <li>{center}</li>)}
        </ul>
      )
    },
  },
  {
    showFn: (data) => data?.length === 1,
    fullWidth: true,
    key: 'Center',
    path: ['centers'],
    formatter: (data) => {
      const centers = map(path(['name', 'en']), data ?? [])
      return centers[0]
    },
  },
  {
    showFn: (data, { role }) => role !== 'lead',
    fullWidth: true,
    key: 'Exam',
    path: ['exam'],
    formatter: (exam, { room } = {}) => {
      return (
        <ul style={{ margin: 0 }}>
          <li> {exam?.name?.en} {room ? ` in room #${room}` : ''} </li>
        </ul>
      )
    },
  },
  {
    showFn: (data, { role } = {}) => role === 'lead',
    fullWidth: true,
    key: 'Center Exams',
    path: ['centerExamAssignments'],
    formatter: (centerExams) => {
      console.log({ exams: centerExams })
      return (
        <Grid container>
          {centerExams?.map(({ center, exam, proctors, seniorProctors }) => {
            const juniorProctors = map(path(['nameObject', 'en']), proctors ?? [])

            const seniors = map(path(['nameObject', 'en']), seniorProctors ?? [])

            return (
              <React.Fragment key={center._id}>
                <Grid item xs={6}>
                  Center: {center?.name?.en}
                </Grid>

                <Grid item xs={6}>
                  Exam: {exam?.name?.en}
                </Grid>

                <Grid item xs={12}>
                  <h5>Junior Proctors:</h5>

                  <ul style={{ margin: 0 }}>
                    {juniorProctors.map((p) => <li key={p}>{p}</li>)}
                  </ul>
                </Grid>

                <Grid item xs={12}>
                  <h5>Senior Proctors:</h5>

                  <ul style={{ margin: 0 }}>
                    {seniors.map((p) => <li key={p}>{p}</li>)}
                  </ul>
                </Grid>
                <Divider />
              </React.Fragment>
            )
          })}
        </Grid>
      )
    },
  },
]

export const getAssignmentsTable = ({ onEdit, onView, proctor }) => [
  ...(!proctor
    ? [{
        label: 'Proctor',
        key: 'player.nameObject.en',
      }]
    : []),
  {
    label: 'Role',
    key: 'role',
  },
  {
    label: 'Round',
    key: 'round.name.en',
  },
  {
    label: 'Center',
    key: 'centers',
    formatField: (R, centers) => {
      if (centers.length > 1) return centers[0]?.name?.en + ', ' + centers[1]?.name?.en?.substring(0, 3) + '...'
      return R.map(R.path(['name', 'en']), centers ?? [])
        .join(', ')
    },
  },
  {
    label: 'Exam',
    key: 'exam',
    formatField: (R, exam) => {
      return R.pathOr('-', ['name', 'en'], exam)
    },
  },
  {
    label: 'Room',
    key: 'room',
  },
  {
    label: 'View',
    key: 'view',
    formatField: (R, _, { _id, player: { _id: player } }) => (
      <Box
        style={{ cursor: 'pointer' }}
        display='flex'
        alignItems='center'
        justifyContent='center'
        onClick={() => onView({ _id, player })}
      >
        <ExitToAppIcon />
      </Box>
    ),
  },
  {
    label: 'Edit',
    key: 'edit',
    formatField: (R, _, { _id }) => (
      <Box
        style={{ cursor: 'pointer' }}
        display='flex'
        alignItems='center'
        justifyContent='center'
        onClick={() => onEdit(_id)}
      >
        <EditIcon />
      </Box>
    ),
  },
  {
    label: 'Delete',
    type: 'delete',
    key: 'delete',
  },
]
