import * as R from 'ramda'

export const getFullDate = (d) => {
  try {

    const date = new Date(d)
    const isNextDay = date.getUTCHours() >= 12

    date.setUTCHours(0)
    date.setUTCDate(date.getDate() + (isNextDay ? 1 : 0))
    return date.toISOString()
      .split('T')[0]
  } catch (e) {
    return ''
  }
}

function ordinalSuffixOf (i) {
  const j = i % 10
  const k = i % 100

  if (j === 1 && k !== 11) {
    return `${i}st`
  }

  if (j === 2 && k !== 12) {
    return `${i}nd`
  }

  if (j === 3 && k !== 13) {
    return `${i}rd`
  }
  return `${i}th`
}

export const formatAttributes = (
  fields,
  attributes,
  arrayPrefix = '',
  arrayId,
) => {
  const groupedAttrs = R.groupBy(R.prop('name'), attributes)
  console.log({ attributes })
  const formattedAttrsArray = Object.entries(fields)
    .map(([fieldName, field]) => {
      const attrs = groupedAttrs[fieldName] ?? []
      const label = field.label.replace(/:/g, '')
        ?.trim()

      if (field.type === 'university') {
        const formattedAttrs = attrs.map((attr, index) => ({
          [`${arrayPrefix}${ordinalSuffixOf(index + 1)} ${label}`]: {
            value: R.propOr('', 'valueText', attr),
            field,
            attribute: attr,
            arrayId,
          },
        }))

        // typescript is kind of stupid
        return R.mergeAll(formattedAttrs)
      }

      if (field.type === 'array') {
        const arrayFields = Object.fromEntries(field.fields.map((f) => [f.name, f]))

        const formattedAttrs = attrs.map((attr, index) =>
          formatAttributes(
            arrayFields,
            R.pathOr([], ['value', 'attributes'], attr),
            `${fieldName}[${index + 1}] `,
            attr.attrId,
          ))

        return R.mergeAll(formattedAttrs)
      }

      if (field.type === 'address') {
        const formattedAttrs = attrs.map((attr) => {
          const value = R.prop('value', attr)

          const entries = Object.entries(value ?? {})
            .map(([k, v]) => [`${arrayPrefix} ${attr.name} ${k}`, {
              value: v,
              field,
              attribute: attr,
              arrayId,
              key:k,
            }])

          const fullAddr = R.propOr('', 'valueText', attr)

          return ({
            [`${arrayPrefix} ${attr.name} full-address`]: {
              value: fullAddr,
              field,
              attribute: attr,
              arrayId,
            },
            ...Object.fromEntries(entries),
          })
        })

        return R.mergeAll(formattedAttrs)
      }

      if (field.type === 'select') {
        const formattedAttrs = attrs.map((attr, index) => {
          const price = field.options.find((o) => o.name === attr?.valueText)?.price
          const ordinalText = attrs.length > 1 ? ordinalSuffixOf(index + 1) : ''

          const name = `${arrayPrefix}${ordinalText} ${label}`
          return ({
            [name]: {
              value: R.propOr('', 'valueText', attr),
              price: (price ? ` ${price} EGP` : ''),
              field,
              attribute: attr,
              arrayId,
            },
          })
        })

        return R.mergeAll(formattedAttrs)
      }

      if (field.type === 'date') {
        const formattedAttrs = attrs.map((attr) => ({
          [`${arrayPrefix} ${label}`]: {
            value: getFullDate(R.propOr('', 'valueText', attr)),
            field,
            attribute: attr,
            arrayId,
          },
        }))

        return R.mergeAll(formattedAttrs)
      }

      if (field.type === 'exam') {
        const examAttrs=attrs?.map((attr,index)=> {
            const fieldName= `${arrayPrefix}${label} (${index + 1})`
            const exam = {
            value: R.propOr('', 'valueText', attr ?? {}),
            field,
            attribute: attr,
            arrayId,
            }
            return ({
                [fieldName]: exam,
                ...field.hasFees && { [`${fieldName} certification`]: exam }
            }) ?? []
        })

        return R.mergeAll(examAttrs)
      }

      const otherAttrs = attrs?.map((attr) => ({
        [`${arrayPrefix}${label}`]: {
          value: R.propOr('', 'valueText', attr ?? {}),
          field,
          attribute: attr,
          arrayId,
        },
      })) ?? []

      return R.mergeAll(otherAttrs)
    })

  return R.mergeAll(formattedAttrsArray)
}
