import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Checkbox from '@material-ui/core/Checkbox'

import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import * as queries from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import * as R from 'ramda'
import { path, pathOr, prop } from 'ramda'
import * as Icons from '../../../Icons'

const StopRoundedIcon = Icons.StopRounded
const CropSquareRoundedIcon = Icons.CropSquareRounded

const CustomCheckBox = ({
  field,
  record,
  refetch = () => {},
}) => {
  const checkBoxClasses = useCheckBoxStyles()
  const iconClasses = useIconStyles()

  const [loading, setLoading] = useState(false)
  const { request } = useRequest()

  const { enqueueSnackbar } = useSnackbar()
  const value = path(field.key.split('.'), record)
  const onChange = async (e) => {
    if (loading) return
    try {
      await request({
        query: prop(path(['customProps', 'query'], field), queries),
        endpoint: endpoints[field.endpoint],
        variables: pathOr(() => {}, ['customProps', 'variables'], field)(R, value, field, record, e),
      })

      refetch()
      enqueueSnackbar('Success', { variant: 'success' })
    } catch (err) {
      console.log(err)
      if (path(['response', 'errors', 0, 'message'], err)) {
        enqueueSnackbar(path(['response', 'errors', 0, 'message'], err), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    } finally {
      setLoading(false)
    }
  }

  return (
    <Checkbox
      classes={checkBoxClasses}
      checked={Boolean(value)}
      onChange={onChange}
      icon={<CropSquareRoundedIcon className={iconClasses.root} />}
      checkedIcon={<StopRoundedIcon className={iconClasses.root} />}
      color='primary'
    />
  )
}

const useCheckBoxStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    fontFamily: 'Barlow Condensed',
    fontWeight: 300,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[3],
    borderRadius: 0,
    padding: 0,
  },
}))

const useIconStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
  },
}))

export default CustomCheckBox
