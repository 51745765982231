import React from 'react'
import { prop, path } from 'ramda'

import definitions from '../../../config/definitions'

export const getSimpleVariations = (endpoint) => {
  return [
    {
      label: 'Image',
      key: 'variationCopy.images',
      formatField: (R, cell, record) => cell && cell.length > 0 ? <span><img style={{ height: '8vh', display: 'inline' }} src={`${definitions.cloudinaryUrl}${cell[0]}`} alt={path(['variation', 'product', 'name', 'en'], record)} /></span> : '-',
    },
    {
      label: 'Name',
      key: 'variationCopy.name.en',
    },
    ...endpoint === 'SUP'
      ? [{
          label: 'Country Of Origin',
          key: 'variationCopy.mainAttributesNames',
          formatField: (R, cell, record) => cell?.[0] ?? '-',
        }]
      : [{
          label: 'SKU',
          key: 'variationCopy.serialNumber',
        }],
    {
      label: 'Quantity',
      key: 'quantity',
      formatField: (R, cell) => cell ? cell.toString() : '',
    },
    {
      label: 'Unit Price',
      key: 'price',
      formatField: (R, cell) => cell ? Number(cell).toFixed(2).toString() : '',
    },
    {
      label: 'Margin Price',
      key: 'marginPrice',
      formatField: (R, cell) => cell ? Number(cell).toFixed(2).toString() : '0.00',
    },
    {
      label: 'Total Price',
      key: 'total',
      formatField: (R, cell, record) =>
        record.total
          ? record.total.toFixed(2)
          : prop('quantity', record) && prop('price', record)
            ? (Number(record.price) * Number(record.quantity)).toFixed(2).toString()
            : '',
    },
  ]
}
