export default function useFields () {
  /*
    replaces the value at the path from updatedValue to value
    ex:
      value: { a: 1, b: [{ x: 3, y: 3 }, { x: undefined, y: 5 }] }
      updatedValue: { a: 1, b: [{ x: undefined, y: 3 }, { x: 4, y: 5 }] }
      fields: ['b', 'x']
      returns: { a: 1, b: [{ x: undefined, y: 3 }, { x: 4, y: 5 }] }
  */
  const replaceFields = (fields, value, updatedValue) => {
    if (!fields.length) return updatedValue

    const newFieldValue = updatedValue[fields[0]]
    if (!newFieldValue) return value
    const oldFieldValue = value[fields[0]]

    if (Array.isArray(newFieldValue) && fields.length > 1) return { ...value, [fields[0]]: newFieldValue.map((field, index) => replaceFields([index, ...fields.slice(1)], oldFieldValue, newFieldValue)) }

    if (Array.isArray(value)) return replaceFields(fields.slice(1), oldFieldValue, newFieldValue)

    return { ...value, [fields[0]]: replaceFields(fields.slice(1), oldFieldValue, newFieldValue) }
  }

  /*
    empties the given field path in the given value
    ex:
      value: { a: 1, b: [{ x: 2, y: 3 }, { x: 4, y: 5 }] }
      fields: ['b', 'x']
      returns: { a: 1, b: [{ x: undefined, y: 3 }, { x: undefined, y: 5 }] }
  */
  const emptyFields = (fields, value) => {
    if (!fields.length) return undefined

    const oldFieldValue = value[fields[0]]

    if (!oldFieldValue) return value

    if (Array.isArray(oldFieldValue) && fields.length > 1) return { ...value, [fields[0]]: oldFieldValue.map((field, index) => emptyFields([index, ...fields.slice(1)], oldFieldValue)) }

    if (Array.isArray(value)) return emptyFields(fields.slice(1), oldFieldValue)

    return { ...value, [fields[0]]: emptyFields(fields.slice(1), oldFieldValue) }
  }

  return { replaceFields, emptyFields }
}
