import React, { useEffect } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useQuery } from 'react-query'
import { pathOr, path } from 'ramda'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'

import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { GET_ORDER_CLEVERSELL } from '../../../gql/queries'
import Table from '../../core/Table'
import { simpleVariations } from './fields'
import CleverSellSelectOrderStatusAction from '../CleverSellSelectOrderStatusAction'

export default function CleverSellSingleOrder ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()
  const { isLoading: loading, data: fetchedData } = useQuery(['cleversell-order', key], () => request({ query: GET_ORDER_CLEVERSELL, variables: { _id: key }, endpoint: endpoints[moduleStructure.endpoint] }))
  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()

  useEffect(() => {
    action({ title: 'orders' })
  }, []) // eslint-disable-line

  if (loading) return <div style={{ width: '95%', position: 'relative', margin: 'auto' }}> <LinearProgress /> </div>
  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ minHeight: '28rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <div className={cardClasses.card}>
                <Grid container spacing={4}>
                  <Grid item xs={12} md={4}>
                    <Typography className={textClasses.key}>ORDER ID </Typography>
                    <Typography className={textClasses.value}>
                      {path(['getOrder', 'orderId'], fetchedData)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={textClasses.key}>Status</Typography>
                    <CleverSellSelectOrderStatusAction field={moduleStructure} cell={path(['getOrder', 'status', 'name'], fetchedData)} record={path(['getOrder'], fetchedData)} />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={textClasses.key}>Payment Method</Typography>
                    <Typography className={textClasses.value}>{pathOr('-', ['getOrder', 'paymentMethod'], fetchedData)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={textClasses.key}>Date</Typography>
                    <Typography className={textClasses.value}>
                      {
                          path(['getOrder', 'createdAt'], fetchedData)
                            ? new Date(path(['getOrder', 'createdAt'], fetchedData)).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                            : '-'
                        }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={textClasses.key}>Customer</Typography>
                    <Typography className={textClasses.value}>{pathOr('-', ['getOrder', 'player', 'name'], fetchedData)}</Typography>
                    <Typography className={textClasses.value}>{pathOr('-', ['getOrder', 'player', 'phone'], fetchedData)}</Typography>
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <Typography className={textClasses.key}>Is Original</Typography>
                    <Typography className={textClasses.value}>
                      {
                          path(['getOrder', 'original', 'isOriginal'], fetchedData)
                            ? 'Yes'
                            : 'No'
                        }
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Typography className={textClasses.key}>Original OrderId</Typography>
                    <Typography className={textClasses.value}>{path(['getOrder', 'original', 'original'], fetchedData)}</Typography>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={cardClasses.card}>
                <Typography className={textClasses.header}>Payment</Typography>
                <div>
                  <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={4}>
                      <Typography className={textClasses.totalsKey}>Subtotal</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display='flex' justifyContent='flex-end'><Typography className={textClasses.totalsKey}>{Number(pathOr(0, ['getOrder', 'totals', 'subtotal'], fetchedData)).toFixed(2)}</Typography></Box>
                    </Grid>
                  </Grid>
                  <Divider className={cardClasses.divider} />
                  <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={4}>
                      <Typography className={textClasses.totalsKey}>Discount</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display='flex' justifyContent='flex-end'>
                        <Typography className={textClasses.totalsKey}>
                          {Number(pathOr(0, ['getOrder', 'totals', 'discount', 'amount'], fetchedData)).toFixed(2)}
                          {path(['getOrder', 'totals', 'discount', 'reference'], fetchedData)
                            ? ` (${path(['getOrder', 'totals', 'discount', 'reference', 'code'], fetchedData)} - ${path(['getOrder', 'totals', 'discount', 'reference', 'valueType'], fetchedData) === 'Percentage'
                            ? `${path(['getOrder', 'totals', 'discount', 'reference', 'value'], fetchedData)}%)`
                            : `${path(['getOrder', 'totals', 'discount', 'reference', 'value'], fetchedData)})`}`
                            : ''}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider className={cardClasses.divider} />
                  <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={4}>
                      <Typography className={textClasses.totalsKey}>Total</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display='flex' justifyContent='flex-end'><Typography className={textClasses.totalsKey}>{Number(pathOr(0, ['getOrder', 'totals', 'total'], fetchedData)).toFixed(2)}</Typography></Box>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid>
            <Table fields={simpleVariations} records={path(['getOrder', 'products'], fetchedData)} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.6rem',
  },
  subKey: {
    color: theme.palette.info.main,
    fontSize: '1.5rem',
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  header: {
    color: theme.palette.info.main,
    fontSize: '1.7rem',
    marginTop: '.56rem',
    marginBottom: '3.6rem',
  },
  totalsKey: {
    fontSize: '1.6rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

const useCardStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    padding: '1rem',
    minHeight: '20rem',
  },
  divider: {
    background: 'gray',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}))
