import React, { forwardRef, useEffect, Fragment } from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DateTimePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'

const DateTimePickerComponent = forwardRef(({
  value,
  onMounted = () => {},
  onChange = () => {},
  name = '',
  disabled = false,
  label = '',
  helperText = '',
}, ref) => {
  const dateTimePickerClasses = useDateTimePickerStyles()

  useEffect(() => {
    onMounted(name)
  }, []) // eslint-disable-line

  return (
    <>
      <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
        <DateTimePicker
          classes={dateTimePickerClasses}
          margin='dense'
          format='DD MMM YYYY H:m'
          ref={ref}
          disabled={disabled}
          value={value}
          onChange={onChange}
          label={label}
          helperText={helperText}
        />
      </MuiPickersUtilsProvider>
    </>
  )
})

const useDateTimePickerStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
}))

export default DateTimePickerComponent
