import React, { useState, useReducer } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useForm, Controller } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { path, pluck } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Link from '@material-ui/core/Link'
import Typography from '@material-ui/core/Typography'
import InputBase from '@material-ui/core/InputBase'

import { login as loginAction, setPermissions as setPermissionsAction } from '../../store/actions'
import * as gql from '../../gql/queries'
import useRequest from '../../helpers/useRequest'
import parseStructure from '../../helpers/parseStructure'
import * as endpoints from '../../config/endpoints'
import Select from '../core/SelectSync'
import Button from '../core/Button'
import ForgetPassword from './reset-password/ForgetPassword'
import ResetPassword from './reset-password/ResetPassword'
import { GET_DASHBOARD } from '../../App'
import { CLEVERSHOP_CONTROL_ENDPOINT } from '../../config/endpoints'
import useGetCurrentModerator from './../../helpers/useGetCurrentModerator'

const LoginBox = () => {
  const { actions, state } = useStateMachine({ loginAction, setPermissionsAction }, { shouldReRenderApp: false })
  const [loading, setLoading] = useState(false)
  const [forgetPswState, setForgetPswState] = useReducer(
    (prevState, newState) => ({
      ...prevState,
      ...newState,
    }),
    {
      username: '',
      accountType: '',
      forgetPasswordVisible: false,
      resetPasswordVisible: false,
    },
  )

  const { handleSubmit, errors, control, watch } = useForm()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const { request, controlRequest } = useRequest()
  const getCurrentModerator = useGetCurrentModerator({ disabled: false })

  const structure = parseStructure(state.structure)
  const branding = structure?.branding ?? {}

  const onSubmit = async ({ username, password, accountType }) => {
    setLoading(true)
    try {
      const data = await request({ query: gql.LOGIN, variables: { username, password, accountType }, endpoint: endpoints[path(['login', 'endpoint'], structure)] })
      const token = path(['login'], data)
      if (!token) {
        return setLoading(false)
      }

      actions.loginAction({ token, accountType })
      if (accountType === 'vendor') {
        const data = await request({
          endpoint: endpoints[path(['login', 'endpoint'], structure)],
          query: gql.GET_CURRENT_VENDOR,
          token,
        })
        const permissions = pluck('name', path(['getCurrentVendor', 'role', 'permissions'], data) || [])
        actions.setPermissionsAction({ permissions })
      }
      if (accountType === 'branch') {
        const data = await request({
          endpoint: endpoints[path(['login', 'endpoint'], structure)],
          query: gql.GET_CURRENT_BRANCH,
          token,
        })
        const permissions = pluck('name', path(['getCurrentBranch', 'role', 'permissions'], data) || [])
        actions.setPermissionsAction({ permissions })
      }
      if (accountType === 'moderator') {
        const permissions = await getCurrentModerator(structure, token)
        if (permissions.length === 0) {
          enqueueSnackbar('Moderator has no permissions.', { variant: 'error' })
          throw Error('Moderator has no permissions.')
        }

        const dashboardData = await controlRequest({
          endpoint: CLEVERSHOP_CONTROL_ENDPOINT,
          query: GET_DASHBOARD('moderator', permissions),
        })
        const schema = path(['getCurrentTenet', 'dashboardSchema'], dashboardData)
        const dashboard = JSON.parse(schema)

        if (!dashboard.modules.length || !dashboard.sidebarItems.length) {
          enqueueSnackbar('Moderator has no permissions.', { variant: 'error' })
          throw Error('Moderator has no permissions.')
        }
        actions.setPermissionsAction({ permissions })
      }
      setTimeout(() => { window.location = '/' }, 2000) // stupid workaround
    } catch (error) {
      localStorage.clear()
      setLoading(false)
      if (error.response) {
        enqueueSnackbar(path(['response', 'errors', '0', 'message'], error), { variant: 'error' })
      } else {
        enqueueSnackbar('Failed to login.', { variant: 'error' })
      }
    }
  }

  const clevershopLogo = process.env.PUBLIC_URL + '/img/clevershop.png'
  const defaultGreetingText = 'WELCOME BACK TO ' + (branding?.dashboardName?.toUpperCase?.() ?? 'CLEVERSHOP')

  return (
    <>
      <ForgetPassword
        structure={structure}
        state={forgetPswState}
        setState={setForgetPswState}
      />
      <ResetPassword
        structure={structure}
        state={forgetPswState}
        setState={setForgetPswState}
      />
      <Grid container style={{ height: '100vh' }}>
        <Grid item xs={12} md={6}>
          <Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%'>
            <Box className={classes.logoBox} display='flex' justifyContent='center'>
              <Box>
                <Grid container direction='column'>
                  <Grid item>
                    <Box width='100%' justifyContent='center' display='flex'>
                      <img className={classes.img} src={branding?.logoUrl ?? clevershopLogo} alt='logo' />
                    </Box>
                  </Grid>
                  <Grid item>
                    <Typography className={classes.title} component='h1' as='h1'>
                      {branding?.dashboardName ?? 'CLEVERSHOP'}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box display='flex' justifyContent='center' alignItems='center' height='100%' width='100%'>
            <Box style={{ textAlign: 'center' }}>
              <Typography as='h2' className={classes.h2}>
                {branding?.greetingText ?? defaultGreetingText}
              </Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
                      <Typography className={classes.formLabel}>Account Type:</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      as={<Select
                        label='Account Type'
                        error={errors.accountType}
                        margin='normal'
                        control={control}
                        key='accountType'
                        options={path(['login', 'accountTypes'], structure)}
                        disabled={loading}
                        className={classes.select}
                          />}
                      name='accountType'
                      control={control}
                      defaultValue='staff'
                      rules={{
                        required: 'Account Type is required',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
                      <Typography className={classes.formLabel}>{watch('accountType') === 'vendor' ? 'Email:' : 'Username:'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      as={<InputBase
                        control={control}
                        key='username'
                        disabled={loading}
                        className={classes.input}
                          />}
                      name='username'
                      control={control}
                      defaultValue=''
                      rules={{
                        required: 'Username is required',
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
                      <Typography className={classes.formLabel}>Password:</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Controller
                      as={<InputBase
                        control={control}
                        key='password'
                        type='password'
                        className={classes.input}
                        disabled={loading}
                          />}
                      name='password'
                      control={control}
                      defaultValue=''
                      rules={{
                        required: 'Password is required',
                      }}
                    />
                  </Grid>
                </Grid>
                <Box display='flex' justifyContent='center'>
                  <Button
                    color='primary'
                    variant='contained'
                    disableElevation
                    type='submit'
                    htmlType='submit'
                    size='large'
                    style={{
                      margin: '1rem',
                      height: '3.3rem',
                      width: '6rem',
                      fontSize: '2rem',
                    }}
                    disabled={loading}
                    loading={loading}
                  >
                    ENTER
                  </Button>
                  <Link href='#' color='primary' className={classes.link} onClick={() => setForgetPswState({ forgetPasswordVisible: true })}>
                    Forgot Password
                  </Link>
                </Box>
              </form>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  )
}

export default LoginBox

const useStyles = makeStyles((theme) => ({
  logoBox: {
    borderRight: '1px solid #979797',
    width: '100%',
  },
  title: {
    fontSize: theme.typography.h1.fontSize,
    fontWeight: 300,
  },
  img: {
    width: 205,
    margin: '0 auto',
  },
  formLabel: {
    fontWeight: 300,
    fontSize: theme.typography.h4.fontSize,
    textTransform: 'uppercase',
  },
  select: {
    height: '2.5rem',
    width: 232,
  },
  input: {
    height: '3.5rem',
    width: 232,
    borderRadius: 4,
    background: '#a9aaaf',
    clear: 'both',
    border: 0,
    fontWeight: 300,
    padding: '0 10px',
  },
  h2: {
    fontWeight: 400,
    fontSize: '2.7rem',
    textTransform: 'uppercase',
    marginBottom: '3rem',
  },
  link: {
    fontWeight: 400,
    fontSize: '1.5rem',
    alignSelf: 'center',
    marginLeft: '3rem',
  },
}))
