import { flatten, path, reject, isNil } from 'ramda'

const getErrorMessages = (errors) => {
  if (!errors) return []

  if (Array.isArray(errors)) {
    return reject(isNil, flatten(errors.map((error) => getErrorMessages(error))))
  }

  if (typeof errors === 'object') {
    if (path(['message'], errors) && typeof errors.message === 'string') return errors.message

    const keys = Object.keys(errors)
    return reject(isNil, flatten(keys.map((key) => getErrorMessages(errors[key]))))
  }

  return []
}

export default getErrorMessages
