import { React, useEffect, useRef, useState } from 'react'
import { useFormContext } from 'react-hook-form'

import AutoComplete from '../../core/AutoComplete'

const CustomAutoComplete = ({ onChange, value, customProps, label }) => {
  const { watch } = useFormContext()
  const watchValue = watch(customProps.watchVariable)
  const [val, setVal] = useState(value)
  const displayFormatter = (_, arg) => {
    return arg?.name?.en
  }
  const valueFormatter = (_, arg) => {
    return arg?.key
  }
  const previousWatchValue = usePrevious(watchValue)
  useEffect(() => {
    if (previousWatchValue && previousWatchValue !== watchValue) {
      onChange('', customProps.key)
      setVal('')
    }
  }, [watchValue])

  useEffect(() => {
    if (val !== value) {
      setVal(value)
    }
  }, [value])

  return (
    <div>
      <AutoComplete label={label} name={customProps.key} displayFormatter={displayFormatter} valueFormatter={valueFormatter} searchQuery={{ query: customProps.searchQuery, endpoint: process.env.REACT_APP_ENDPOINT }} singleQuery={{ query: customProps.singleQuery, endpoint: process.env.REACT_APP_ENDPOINT }} value={val} onChange={onChange} dependencyValue={{ [customProps.watchVariable]: [watchValue] }} />
    </div>
  )
}

function usePrevious (value) {
  const ref = useRef()
  useEffect(() => {
    ref.current = value
  })
  return ref.current
}

export default CustomAutoComplete
