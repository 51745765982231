import React, { useEffect, useState } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useQuery } from 'react-query'
import { path, pathOr } from 'ramda'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { useTheme } from '@material-ui/core/styles'

import Tabs from '../../core/Tabs'
import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { GET_FULFILLMENT } from '../../../gql/queries'
import {
  addressCells,
  Customer,
  fulfilmentCells,
  supWarehouseAddressCells,
} from './fields'
import { CardTab } from '../Admission/InfoCardTab'
import { TotalsCard } from './TotalsCard'
import { RenderOrderTable, RenderRequestsTable } from '../FulfillmentListing'
import { Typography } from '@material-ui/core'

const totalRows = [
  { name: 'Subtotal', value: ['totals', 'subtotal'] },
  { name: 'Shipping', value: ['totals', 'shipping'] },
  { name: 'Margin', value: ['totals', 'margin'] },
  {
    name: 'Discount',
    value: ['totals', 'discount'],
    formatter: (discount) => {
      if (!path(['reference'], discount)) return ''

      const code = path(['reference', 'code'], discount)
      const amount = path(['reference', 'value'], discount)

      if (path(['reference', 'valueType'], discount) === 'Percentage') {
        return ` (${code} - ${amount}%)`
      }

      return ` (${code} - ${amount})`
    },
  },
  { name: 'Total', value: ['totals', 'total'] },
]

export default function FulfillmentPage ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()
  const theme = useTheme()

  const [currentActiveTab, setCurrentActiveTAb] = useState(1)
  const { isLoading: loading, data: fetchedData } = useQuery(['fulfillment', key],
    () => request({
      query: GET_FULFILLMENT,
      variables: { _id: key },
      endpoint: endpoints[moduleStructure.endpoint],
    }))

  useEffect(() => {
    action({ title: 'Fulfillment' })
  }, []) // eslint-disable-line

  const fulfilment = path(['getFulfillment'], fetchedData)
  if (loading) return <div style={{ width: '95%', position: 'relative', margin: 'auto' }}><LinearProgress /></div>

  const isBusinessCustomer = pathOr(false, ['customer', 'isBusiness'], fulfilment) === true ? 'B2B' : 'B2C'
  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ minHeight: '28rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <Tabs currentActive={currentActiveTab} setCurrentActive={setCurrentActiveTAb}>
                <div>
                  <span>Fulfillment summary</span>
                  <span>Customer</span>
                  <span>Address</span>
                </div>
                <div>
                  <CardTab cells={fulfilmentCells} fetchedData={fulfilment} />
                  <CardTab
                    cells={[...Customer, { key: 'businessType', value: isBusinessCustomer }]}
                    fetchedData={fulfilment}
                  />
                  <CardTab
                    cells={isBusinessCustomer ? supWarehouseAddressCells : addressCells}
                    fetchedData={fulfilment}
                  />
                </div>
              </Tabs>
            </Grid>
            <Grid item xs={12} md={3}>
              <TotalsCard rows={totalRows} totals={fulfilment} />
            </Grid>
          </Grid>
        </Grid>

        {!!fulfilment?.orders.length &&
          <Grid container>
            <Grid item xs={12}>
              <Typography align='center' variant='h2' style={{ color: theme.palette.info.main, paddingTop: '2rem', textTransform: 'uppercase' }}>Orders</Typography>
            </Grid>
            <Grid item xs={12}>
              <RenderOrderTable record={fulfilment} />
            </Grid>
          </Grid>}

        {!!fulfilment?.orderRequests?.length && (
          <Grid container>
            <Grid item xs={12}>
              <Typography align='center' variant='h2' style={{ color: theme.palette.info.main, paddingTop: '2rem', textTransform: 'uppercase' }}>Order Requests</Typography>
            </Grid>
            <Grid item xs={12}>
              <RenderRequestsTable record={fulfilment} />
            </Grid>
          </Grid>
        )}

      </Grid>
    </div>
  )
}
