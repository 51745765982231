import { React, useEffect, useState } from 'react'
import { useMutation } from 'react-query'
import { EDIT_COUNTRY } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import useRequest from '../../../helpers/useRequest'
import * as Icons from '../../../Icons'
import { makeStyles } from '@material-ui/core/styles'
import { useSnackbar } from 'notistack'
import { path } from 'ramda'

import Checkbox from '../../core/Checkbox'

const CountryLocationField = ({ record }) => {
  const { request } = useRequest()
  const [flag, setFlag] = useState(false)
  const iconClasses = useIconStyles()
  const { enqueueSnackbar } = useSnackbar()

  const StopRoundedIcon = Icons.StopRounded
  const CropSquareRoundedIcon = Icons.CropSquareRounded

  const [editFlag, { isLoading, isSuccess, isError, error }] = useMutation(
    (variables) => request({
      query: EDIT_COUNTRY,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
      variables,
    }))

  const onChange = (e) => {
    setFlag(e.target.value)
    editFlag({ testCountry: e.target.value, _id: record._id })
  }
  useEffect(() => {
    if (record) {
      setFlag(record.testCountry ?? false)
    }
  }, [record])
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Edit successfully', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
    }
  }, [isError, isSuccess, error])
  return (
    <div style={{ justifyContent: 'center' }}>
      {isLoading && <span> isLoading</span>}
      {!isLoading && <Checkbox
        value={flag}
        onChange={onChange}
        icon={<CropSquareRoundedIcon className={iconClasses.root} />}
        checkedIcon={<StopRoundedIcon className={iconClasses.root} />}
                     />}
    </div>
  )
}

const useIconStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
  },
}))

export default CountryLocationField
