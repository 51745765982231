import { path, pathOr, pluck } from 'ramda'

import * as endpoints from '../config/endpoints'
import useRequest from './useRequest'
import * as gql from '../gql/queries'

const useGetCurrentModerator = () => {
  const { request } = useRequest()
  const getCurrentModerator = async (structure, token) => {
    const data = await request({
      endpoint: endpoints[path(['login', 'endpoint'], structure)],
      query: gql.GET_CURRENT_MODERATOR,
      token,
    })
    const permissions = pluck('permission', pathOr([], ['getCurrentPlayer', 'permissions'], data))
    return permissions
  }
  return getCurrentModerator
}

export default useGetCurrentModerator
