import React, { useEffect, forwardRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Input from '@material-ui/core/Input'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import FormHelperText from '@material-ui/core/FormHelperText'

const NumberInput = forwardRef(({
  label = '',
  error = false,
  helperText = ' ',
  max = Number.MAX_SAFE_INTEGER,
  min = Number.MIN_SAFE_INTEGER,
  integer = false,
  onChange = () => {},
  onBlur = () => {},
  onMounted = () => {},
  value = 0,
  disabled = false,
  className,
  classes,
}, ref) => {
  useEffect(() => onMounted(), []) // eslint-disable-line
  const defaultClasses = useStyles()

  const formatValue = (val) => {
    let number = !Number.isNaN(parseFloat(val)) ? integer ? parseInt(val) : parseFloat(val) : 0
    if (number > max) number = max
    if (number < min) number = min
    return number
  }

  return (
    <>
      <FormControl fullWidth margin='dense'>
        <InputLabel className={defaultClasses.label}>{label}</InputLabel>
        <Input
          disabled={disabled}
          error={error}
          value={formatValue(value)}
          onChange={(e) => onChange({ ...e, target: { ...e.target, value: formatValue(e.target.value) } })}
          onBlur={onBlur}
          fullWidth
          type='number'
          className={`${defaultClasses.root} ${className || ''}`}
          classes={classes}
        />
        <FormHelperText style={{ ...error && { color: 'red' } }}>{helperText}</FormHelperText>
      </FormControl>
    </>
  )
})

export default NumberInput

const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: theme.typography.body1.fontSize,
  },
  label: {
    fontSize: theme.typography.h5.fontSize,
  },
}))
