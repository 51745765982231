import React, { useEffect, useState } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as R from 'ramda'
import * as dateFns from 'date-fns'
import { useSnackbar } from 'notistack'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

import useAddForm from '../../helpers/useAddForm'
import getFields from '../../helpers/getFormFields'
import getErrorMessages from '../../helpers/getErrorMessages'
import Button from '../core/Button'

function AddForm ({ moduleStructure }) {
  const formMethods = useForm({ mode: 'all' })
  const [loading, setLoading] = useState(false)
  const { handleSubmit, watch, errors } = formMethods
  const [submit, { error }] = useAddForm({ addMutations: moduleStructure.addMutations, editMutation: moduleStructure.editMutation, entity: moduleStructure.entity, uploadFields: moduleStructure.uploadFields, entityIdPath: moduleStructure.entityIdPath })
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useTypographyStyles()
  const theme = useTheme()
  if (moduleStructure.dependencies) watch(moduleStructure.dependencies)

  const errorMessages = getErrorMessages(errors)

  useEffect(() => {
    if (error) {
      if (R.path(['response', 'errors', 0, 'message'], error)) {
        enqueueSnackbar(R.path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
      setLoading(false)
      console.log(error)
    }
  }, [enqueueSnackbar, error])

  const add = async (values, mutationIndex = 0) => {
    const data = await submit(values, mutationIndex)
    if (data && data[Object.keys(data)[0]]) {
      const successMessage = R.propOr('Added Item Successfully', 'successMessage', moduleStructure)
      enqueueSnackbar(successMessage, { variant: 'success' })
      history.push(moduleStructure.redirectRoute.replace(':key', data[Object.keys(data)[0]].key))
    }
  }

  const onSubmit = (values) => {
    if (loading) return
    setLoading(true)
    if (moduleStructure.beforeSubmit) {
      const { values: newValues, error, mutationIndex } = moduleStructure.beforeSubmit(
        R,
        values,
        { dateFns },
      )
      if (!error) add({ ...newValues }, mutationIndex)
      else {
        enqueueSnackbar(error, { variant: 'error' })
        setLoading(false)
      }
    } else add({ ...values })
  }

  return (
    <div style={{ paddingTop: '2.5rem' }}>
      <Typography component='h2' variant='h2' classes={classes} gutterBottom align='center'>
        {moduleStructure.label}
      </Typography>
      <Box display='flex' justifyContent='center'>
        <Box style={{ width: 1100 }}>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              {
                getFields({ fields: moduleStructure.fields, formMethods, moduleStructure })
              }

              <Box display='flex' justifyContent='flex-end'>
                {
                  errorMessages.length
                    ? <ul> {errorMessages.map((message, index) => (<Typography style={{ color: 'red' }} key={index} component='li' gutterBottom align='left'> {message} </Typography>))} </ul>
                    : null
                }
                <Button
                  color='primary'
                  variant='contained'
                  disableElevation
                  type='submit'
                  size='large'
                  style={{
                    margin: '0 1rem',
                    height: '3.2rem',
                    width: '7rem',
                    fontSize: theme.typography.h3.fontSize,
                  }}
                  loading={loading}
                >
                  ADD
                </Button>
              </Box>
              <p style={{ color: 'red', textAlign: 'center' }}>{R.path(['graphQLErrors', '0', 'message'], error) || ''}</p>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </div>
  )
}

export default AddForm

const useTypographyStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.info.main,
    textTransform: 'uppercase',
  },
}))
