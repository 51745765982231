import React, { useEffect } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useMutation, useQuery } from 'react-query'
import { isEmpty, path, pathOr } from 'ramda'
import { useHistory, useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'

import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import {
  CANCEL_RECURRING_ORDER,
  GET_RECURRING_ORDER,
} from '../../../gql/queries'
import Table from '../../core/Table'
import { RecurringOrderFields, RecurringOrderVariationsFields } from './fields'
import Button from '../../core/Button'

export default function RecurringOrderDetails ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()
  const { isLoading: loading, data: fetchedData } = useQuery(['recurringOrder', key], () => request({ query: GET_RECURRING_ORDER, variables: { _id: key }, endpoint: endpoints[moduleStructure.endpoint] }))

  const [cancelRecurring] = useMutation(() => request({
    query: CANCEL_RECURRING_ORDER,
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    variables: {
      _id: key,
    },
  }))

  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()
  const history = useHistory()

  useEffect(() => {
    action({ title: 'recurringOrders' })
  }, []) // eslint-disable-line

  if (loading) return <div style={{ width: '95%', position: 'relative', margin: 'auto' }}> <LinearProgress /> </div>
  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ minHeight: '28rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={12}>
              <div className={cardClasses.card}>
                <Grid container spacing={4}>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <Typography className={textClasses.key}>Recurring ID </Typography>
                      <Typography className={textClasses.value}>
                        {path(['getRecurringOrder', 'recurringOrder', '_id'], fetchedData)}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={textClasses.key}>Pattern</Typography>
                      <Typography className={textClasses.value}>{path(['getRecurringOrder', 'recurringOrder', 'recurrencePattern'], fetchedData)}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={textClasses.key}>Payment Method</Typography>
                      <Typography className={textClasses.value}>{pathOr('-', ['getRecurringOrder', 'recurringOrder', 'paymentMethod'], fetchedData)}</Typography>
                    </Grid>
                  </Grid>

                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <Typography className={textClasses.key}>Start Date</Typography>
                      <Typography className={textClasses.value}>
                        {
                          path(['getRecurringOrder', 'recurringOrder', 'date'], fetchedData)
                            ? new Date(+path(['getRecurringOrder', 'recurringOrder', 'date'], fetchedData)).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                            : '-'
                        }
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography className={textClasses.key}>Hour</Typography>
                      <Typography className={textClasses.value}>{pathOr('-', ['getRecurringOrder', 'recurringOrder', 'hour'], fetchedData)}</Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Typography className={textClasses.key}>Customer</Typography>
                      <Typography className={textClasses.value}>{pathOr('-', ['getRecurringOrder', 'recurringOrder', 'customer', 'name'], fetchedData)}</Typography>
                      <Typography className={textClasses.value}>{pathOr('-', ['getRecurringOrder', 'recurringOrder', 'customer', 'phone'], fetchedData)}</Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={4}>
                      <Typography className={textClasses.key}>Last Run At</Typography>
                      <Typography className={textClasses.value}>
                        {
                        path(['getRecurringOrder', 'recurringOrder', 'lastRunAt'], fetchedData)
                          ? new Date(+path(['getRecurringOrder', 'recurringOrder', 'lastRunAt'], fetchedData)).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                          : '-'
                      }
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography className={textClasses.key}>Next Run At</Typography>
                      <Typography className={textClasses.value}>                      {
                      path(['getRecurringOrder', 'recurringOrder', 'nextRunAt'], fetchedData)
                        ? new Date(+path(['getRecurringOrder', 'recurringOrder', 'nextRunAt'], fetchedData)).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                        : '-'
                    }
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid xs={4} />
                    <Grid xs={4} />
                    <Grid item xs={4}>
                      <Button
                        color='danger' size='large' onClick={() => {
                          cancelRecurring().then(() => history.push('/recurringOrders'))
                        }} style={{ width: '3rem', marginLeft: '20rem' }}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Typography className={textClasses.key} style={{ marginLeft: '1rem', textAlign: 'left' }}>Variations</Typography>
        <Table fields={RecurringOrderVariationsFields} records={path(['getRecurringOrder', 'recurringOrder', 'variations'], fetchedData)} />
        {isEmpty(path(['getRecurringOrder', 'orders'], fetchedData))
          ? <Typography className={textClasses.key} style={{ textAlign: 'center' }}>No Orders Created Yet</Typography>
          : (
            <Grid>
              <Typography
                className={textClasses.key}
                style={{ marginLeft: '1rem' }}
              >Previous
                Orders
              </Typography>
              <Table
                fields={RecurringOrderFields}
                records={path(['getRecurringOrder', 'orders'], fetchedData)}
              />
            </Grid>
            )}
      </Grid>
    </div>
  )
}

const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.6rem',
  },
  subKey: {
    color: theme.palette.info.main,
    fontSize: '1.5rem',
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  header: {
    color: theme.palette.info.main,
    fontSize: '1.7rem',
    marginTop: '.56rem',
    marginBottom: '3.6rem',
  },
  totalsKey: {
    fontSize: '1.6rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

const useCardStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    padding: '1rem',
    minHeight: '20rem',
  },
  divider: {
    background: 'gray',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}))
