import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import queryString from 'query-string'
import { makeStyles } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Pagination from '@material-ui/lab/Pagination'

const PaginationView = ({ totalCount, pageSize }) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const parsedPageNumber = parseInt(query.page) || 1

  const handlePageChange = (e, newPageNumber) => {
    query.page = newPageNumber
    history.push({ search: queryString.stringify(query) })
  }

  return (
    <Box display='flex' alignItems='center' justifyContent='center'>
      <Pagination classes={classes} count={Math.ceil(totalCount / pageSize)} page={Number(parsedPageNumber)} onChange={handlePageChange} color='primary' />
    </Box>
  )
}

export default PaginationView

const useStyles = makeStyles((theme) => ({
  root: {
    '& *': {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: 300,
    },
    '& .Mui-selected': {
      color: theme.palette.primary.main,
      background: 'none',
      '&:hover': {
        background: 'none',
      },
    },
  },
  ul: {
    fontSize: theme.typography.h4.fontSize,
  },
}))
