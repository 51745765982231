import React, { forwardRef, useEffect, useState } from 'react'
import MomentUtils from '@date-io/moment'
import { MuiPickersUtilsProvider, DatePicker } from '@material-ui/pickers'
import { makeStyles } from '@material-ui/core/styles'
import moment from 'moment'
import CloseIcon from '@material-ui/icons/Close'
import { IconButton } from '@material-ui/core'

const DatePickerComponent = forwardRef(({
  value,
  onMounted = () => {},
  onChange = () => {},
  onClear = () => {},
  name = '',
  disabled = false,
  label = '',
  helperText = '',
  placeholder,
}, ref) => {
  const dateTimePickerClasses = useDateTimePickerStyles()

  const [dateVal, setDateVal] = useState(null)

  useEffect(() => {
    onMounted(name)
  }, []) // eslint-disable-line

  useEffect(() => {
    if (value) {
      if (!(dateVal instanceof moment)) {
        setDateVal(moment.unix(+value))
      } else {
        setDateVal(dateVal)
      }
    }
  }, [value])

  const handleChange = (val) => {
    setDateVal(val)
    onChange(val)
  }
  return (
    <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
      <div style={{ position: 'relative' }}>
        <DatePicker
          classes={dateTimePickerClasses}
          margin='normal'
          format='DD MMM YYYY'
          ref={ref}
          disabled={disabled}
          value={dateVal}
          onChange={handleChange}
          label={label}
          helperText={helperText}
          placeholder={placeholder}
        />
        <IconButton
          onClick={() => {
            onClear()
            handleChange(null)
          }}
          className={dateTimePickerClasses.clearBtn}
        >
          <CloseIcon />
        </IconButton>
      </div>
    </MuiPickersUtilsProvider>
  )
})

const useDateTimePickerStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  clearBtn: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: 0,
  },
}))

export default DatePickerComponent
