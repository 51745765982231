import { Grid } from '@material-ui/core'
import Checkbox from '../../core/Checkbox/Checkbox'
import React from 'react'
import TextInput from '../../core/Text'

const minZero = (v) => Math.max(v, 0)

export function ExamFields ({ state, setValue }) {
  return (
    <>
      <Grid item xs={6}>
        <Checkbox
          value={!!state.multiple}
          label='Multiple Select'
          helperText='Can students select multiple exams?'
          onChange={setValue('multiple')}
        />
      </Grid>

      <Grid item xs={6}>
        <TextInput
          inputType='number'
          label='price'
          onChange={setValue('price', minZero)}
          value={state.price ?? 0}
          helperText='the price of each selection (total = num selections * price)'
        />
      </Grid>

      <Grid item xs={12}>
        <Checkbox
          value={state.hasFees ?? false}
          label='Has certification fees?'
          onChange={setValue('hasFees')}
        />
      </Grid>

      {state.hasFees && (
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={3}>
            <TextInput
              inputType='number'
              label='Certification Price'
              onChange={setValue('certification.certificationFee', minZero)}
              value={state?.certification?.certificationFee ?? 0}
              helperText='the price of certifying each sheet'
            />
          </Grid>

          <Grid item xs={3}>
            <TextInput
              inputType='number'
              label='Exam Price'
              onChange={setValue('certification.pricePerExam', minZero)}
              value={state?.certification?.pricePerExam ?? 0}
              helperText='the price of certifying each exam'
            />
          </Grid>

          <Grid item xs={3}>
            <TextInput
              inputType='number'
              label='Sheet Price'
              onChange={setValue('certification.pricePerSheet', minZero)}
              value={state?.certification?.pricePerSheet ?? 0}
              helperText='the price of each certified sheet'
            />
          </Grid>

          <Grid item xs={3}>
            <TextInput
              inputType='number'
              label='Exams Per Sheet'
              onChange={setValue('certification.examsPerSheet', minZero)}
              value={state?.certification?.examsPerSheet ?? 0}
              helperText='The max number of exams that can be certified on a single sheet, if this is 0, then the sheet price is ignored'
            />
          </Grid>
        </Grid>
      )}
    </>
  )
}
