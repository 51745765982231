import React from 'react'
import { useQuery } from 'react-query'
import useRequest from '../../../helpers/useRequest'
import { GET_PAYMENTS } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import { path } from 'ramda'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'

const CheckPaymentStatus = ({
  field,
  record,
}) => {
  const { request } = useRequest()
  const { data, isFetching, error } = useQuery([record.key, GET_PAYMENTS], () => request({ endpoint: endpoints[field.endpoint], query: GET_PAYMENTS, variables: { context: record.checkoutId } }),
    { refetchOnWindowFocus: false, enabled: record.checkoutId && record.paymentMethod === 'visa' },
  )

  console.log(record.paymentMethod, record.checkoutId)
  if (error || !record.checkoutId) return <Typography> failed to fetch payment status </Typography>
  if (record.paymentMethod === 'cash' || !record.paymentMethod) return <Typography> Cash </Typography>
  return (
    <Typography>
      {record.paymentMethod} - {' '}
      {isFetching ? <CircularProgress /> : (path(['getPayments', 'totalCount'], data) > 1 ? 'PAID' : 'NOT PAID')}
    </Typography>
  )
}

export default CheckPaymentStatus
