import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import React, { useRef, useState } from 'react'
import Button from '@material-ui/core/Button'
import { CloudUpload } from '@material-ui/icons'
import { Dialog, DialogActions, DialogContentText, DialogTitle } from '@material-ui/core'
import { useUploadFiles } from '@qpixio/upload'
import Typography from '@material-ui/core/Typography'
import * as endpoints from '../../../config/endpoints'

export const ImportModal = ({ mutation, record, round, text, onClose, file }) => {
  const { request } = useRequest()
  const { upload, cancel, loading: isUploading } = useUploadFiles('file')
  const [message, setMessage] = useState('')
  const { enqueueSnackbar } = useSnackbar()
  const [mutate, { isLoading }] = useMutation((variables) =>
    request({ query: mutation, endpoint: endpoints[process.env.REACT_APP_ENDPOINT], variables }))

  const handleSubmit = async (dryRun) => {
    enqueueSnackbar('Uploading file', { variant: 'info' })
    const urls = await upload(file)
    const url = urls[0]
    enqueueSnackbar('Checking file', { variant: 'info' })
    const resp = await mutate({ ...round && { round }, dryRun, url })
    console.log({ resp })
    const message = Object.values(resp ?? {})[0]
    if (message?.toLowerCase()
      ?.includes('success')) {
      enqueueSnackbar('Imported successfully', { variant: 'success' })
      onClose()
    } else {
      setMessage(message)
    }
  }

  const onClick = () => {
    cancel()
    onClose()
  }
  return (
    <Dialog open onClose={onClose}>
      <DialogTitle>
        <Typography variant='h5' color='primary'>{text}</Typography>
      </DialogTitle>
      <DialogContentText style={{ marginLeft: '1.5rem', paddingRight: '1rem' }}>
        <Typography paragraph color='textPrimary'>
          Are you sure you want to import "{file?.name}"?
        </Typography>
        {message && (
          <Typography paragraph color='error'>{message}</Typography>
        )}
      </DialogContentText>
      <DialogActions>
        <Button onClick={onClick}>
          close
        </Button>
        <Button
          color='primary'
          onClick={() => handleSubmit(false)}
          disabled={isLoading || isUploading || !!message.trim()}
        >
          Upload
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export const ImportForRound = ({ mutation, text, record }) => {
  const inputRef = useRef(null)
  const [show, setShow] = useState(false)
  const [file, setFile] = useState(null)

  const handleFileSelection = (e) => {
    console.log(e)
    const file = e.target.files[0]
    setFile(file)
    setShow(true)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <>
      {show && (
        <ImportModal
          record={record}
          round={record._id}
          mutation={mutation}
          text={text}
          file={file}
          onClose={() => {
            setFile(null)
            setShow(false)
          }}
        />
      )}

      <input
        accept='text/csv'
        type='file'
        id={text}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelection}
      />
      <label htmlFor={text}>
        <Button
          color='primary'
          component='span'
          style={{
            alignSelf: 'center',
            margin: '0 0 0 auto',
            padding: '1rem',
          }}
        >
          {text} <CloudUpload style={{ marginLeft: 4 }} />
        </Button>
      </label>
    </>
  )
}

export const ImportWithoutRound = ({ mutation, text, record }) => {
  const inputRef = useRef(null)
  const [show, setShow] = useState(false)
  const [file, setFile] = useState(null)

  const handleFileSelection = (e) => {
    console.log(e)
    const file = e.target.files[0]
    setFile(file)
    setShow(true)

    if (inputRef.current) {
      inputRef.current.value = ''
    }
  }

  return (
    <>
      {show && (
        <ImportModal
          record={record}
          round={null}
          mutation={mutation}
          text={text}
          file={file}
          onClose={() => {
            setFile(null)
            setShow(false)
          }}
        />
      )}

      <input
        accept='text/csv'
        type='file'
        id={text}
        ref={inputRef}
        style={{ display: 'none' }}
        onChange={handleFileSelection}
      />
      <label htmlFor={text}>
        <Button
          color='primary'
          component='span'
          style={{
            alignSelf: 'center',
            margin: '0 0 0 auto',
            padding: '1rem',
          }}
        >
          {text} <CloudUpload style={{ marginLeft: 4 }} />
        </Button>
      </label>
    </>
  )
}
