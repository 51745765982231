import React from 'react'
import { useQuery } from 'react-query'
import { pathOr } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Skeleton from '@material-ui/lab/Skeleton'
import { BarChart, Bar, Tooltip, Legend, PieChart, Pie, Cell, ResponsiveContainer } from 'recharts'

import StopIcon from '@material-ui/icons/Stop'

import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { SUP_GET_TOP_TAXONOMIES_REPORT } from '../../../gql/queries'

export default function Totals ({ moduleStructure, minDate, maxDate }) {
  const { request } = useRequest()
  const variables = { ...(minDate && maxDate) && { dateRange: { min: `${new Date(minDate).setHours(0, 0, 0, 0).valueOf()}`, max: `${new Date(maxDate).setHours(23, 59, 59, 0).valueOf()}` } } }
  const { isLoading, data } = useQuery(['sup_reports_taxonomies', variables], () => request({ query: SUP_GET_TOP_TAXONOMIES_REPORT, variables, endpoint: endpoints[moduleStructure.endpoint] }))
  const classes = useStyles()

  const BAR_COLORS = ['#C62828', '#311B92', '#006064', '#F57F17']
  const PIE_COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042']

  const formatTaxonomiesForBarChart = (res = []) => {
    return res.map((obj, index) => ({
      name: obj?.taxonomy?.name,
      [obj?.taxonomy?.name ?? index]: obj?.purchases ?? 0,
    }))
  }

  const getTaxonomiesKeysForBarChart = (res = []) => {
    return res.map((obj, index) => ({ name: obj?.taxonomy?.name, color: BAR_COLORS[index % BAR_COLORS.length] }))
  }

  const formatTaxonomiesForPieChart = (res = []) => {
    return res.map((obj, index) => ({
      name: obj?.taxonomy?.name,
      expanses: obj?.expanses ?? 0,
    }))
  }

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant='h5' component='h4'>
                TOP LINE PURCHASING
              </Typography>
              <Divider width='30%' style={{ marginTop: '5px' }} />
              <Box display='flex' justifyContent='center'>
                <ResponsiveContainer width={500} height={280}>
                  {isLoading
                    ? <Skeleton />
                    : <BarChart data={formatTaxonomiesForBarChart(pathOr([], ['getSupTaxonomiesReport', 'topPurchases'], data))}>
                      <Tooltip content={PurchasesCustomToolTip} />
                      {
                        getTaxonomiesKeysForBarChart(pathOr([], ['getSupTaxonomiesReport', 'topPurchases'], data)).map((obj) => (
                          <Bar key={obj.name} dataKey={obj.name} stackId='same_stack' fill={obj.color} />
                        ))
                      }
                      <Legend content={CustomLegend} />
                      {/* eslint-disable-next-line */}
                    </BarChart>}
                </ResponsiveContainer>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={5}>
          <Card className={classes.root}>
            <CardContent>
              <Typography variant='h5' component='h4'>
                CATEGORY EXPANSES
              </Typography>
              <Divider width='30%' style={{ marginTop: '5px' }} />
              <Box display='flex' justifyContent='center'>
                {isLoading
                  ? <Skeleton width={500} height={280} />
                  : <PieChart width={500} height={280}>
                    <Tooltip content={ExpansesCustomToolTip} />
                    <Legend content={CustomLegend} />
                    <Pie
                      data={formatTaxonomiesForPieChart(pathOr([], ['getSupTaxonomiesReport', 'topExpanses'], data))}
                      innerRadius={30}
                      outerRadius={120}
                      fill='#8884d8'
                      paddingAngle={5}
                      dataKey='expanses'
                    >
                      {pathOr([], ['getSupTaxonomiesReport', 'topExpanses'], data).map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
                      ))}
                    </Pie>
                    {/* eslint-disable-next-line */}
                  </PieChart>}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </>
  )
}

function PurchasesCustomToolTip ({ active, payload }) {
  if (active && payload && payload.length) {
    return (
      <Paper style={{ padding: '5px' }}>
        <Typography>
          {`${payload[0].name}: ${payload[0].value}`}
        </Typography>
      </Paper>
    )
  }

  return null
}

function ExpansesCustomToolTip ({ active, payload }) {
  if (active && payload && payload.length) {
    return (
      <Paper style={{ padding: '5px' }}>
        <Typography>
          {`${payload[0].name}: ${(payload[0].value || 0).toFixed(2)} AED`}
        </Typography>
      </Paper>
    )
  }

  return null
}

function CustomLegend ({ payload }) {
  return (
    <Box display='flex' justifyContent='center' marginTop='10px'>
      {
        payload.map((p) => (
          <React.Fragment key={p.value}>
            <StopIcon style={{ color: p.color }} />
            <Typography>
              {p.value}
            </Typography>
          </React.Fragment>
        ))
      }
    </Box>
  )
}

const useStyles = makeStyles({
  root: {
    marginTop: '1em',
    marginBottom: '1em',
  },
  pos: {
    color: 'gray',
    letterSpacing: '1px',
  },
})
