import React, { useState, useEffect } from 'react'
import { pathOr, pluck } from 'ramda'
import { useQuery } from 'react-query'

import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import * as gql from '../../../gql/queries'

const SelectService = ({ value, onChange, optionsData, disabled = false, label = 'Select Service' }) => {
  const [open, setOpen] = useState(false)
  return (
    <FormControl fullWidth>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        open={open}
        onClose={() => setOpen(false)}
        onOpen={() => setOpen(true)}
        value={value || ''}
        onChange={onChange}
        disabled={disabled}
        fullWidth
      >
        {optionsData.map((option) => {
          return (
            <MenuItem
              key={option._id}
              value={option._id}
            >{option.name.en.toUpperCase()}
            </MenuItem>
          )
        })}
      </Select>
    </FormControl>
  )
}

const SelectServiceProviderService = (props) => {
  const { request } = useRequest()
  const [mainService, setMainService] = useState([])
  const [childService, setChildService] = useState([])
  const { data: optionsData } = useQuery([name || 'selectAsync'], async () => await request({ query: gql.GET_TAXONOMIES_CLEVERSELL, endpoint: endpoints[props.endpoint] }))
  const textClasses = useTextStyles()

  useEffect(() => {
    if (props.value?.length > 0) {
      const mainService = props.value.filter((s) => !s.parent)
      const childService = props.value.filter((s) => s.parent)
      setMainService(pluck('_id', mainService))
      setChildService(pluck('_id', childService))
      props.onChange({ parent: pluck('_id', mainService), child: pluck('_id', childService) })
    }
  }, [props.value])

  return (
    <div>
      <Typography className={textClasses.key}>Parent {props.endpoint !== 'SARVECNY' ? 'Category' : 'Service'}</Typography>
      <SelectService
        value={mainService}
        onChange={(event) => {
          setMainService(event.target.value)
          props.onChange({ parent: event.target.value })
        }}
        optionsData={pathOr([], ['getTaxonomies', 'items'], optionsData).filter((o) => !o.parent)}
      />
      <Typography className={textClasses.key}>Child {props.endpoint !== 'SARVECNY' ? 'Category' : 'Service'}</Typography>
      <SelectService
        value={childService}
        onChange={(event) => {
          setChildService(event.target.value)
          props.onChange({ parent: mainService, child: event.target.value })
        }}
        optionsData={pathOr([], ['getTaxonomies', 'items'], optionsData).filter((o) => mainService.includes(pathOr('', ['parent', '_id'], o)))}
        disabled={!mainService}
        label='please make sure to select any option if available'
      />
    </div>
  )
}

export default SelectServiceProviderService

const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.2rem',
  },
}))
