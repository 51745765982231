import React, { useState } from 'react'
import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { VERIFY_DRIVER } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import * as Icons from '../../../Icons'
import { path, prop } from 'ramda'
import IconButton from '@material-ui/core/IconButton'

const VerifiedUserIcon = Icons.VerifiedUserIcon

const VerifyDriver = ({
  field,
  record,
  refetch = () => {
  },
}) => {
  const [loading, setLoading] = useState(false)
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const onClick = async () => {
    if (loading) return
    try {
      console.log('sending request')
      await request({
        query: VERIFY_DRIVER,
        endpoint: endpoints[field.endpoint],
        variables: { username: record.username },
      })

      refetch()
      enqueueSnackbar('Success', { variant: 'success' })
    } catch (err) {
      console.log(err)
      if (path(['response', 'errors', 0, 'message'], err)) {
        enqueueSnackbar(path(['response', 'errors', 0, 'message'], err), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    } finally {
      setLoading(false)
    }
  }

  if (!prop('username', record)) {
    return null
  }

  return (
    <IconButton onClick={onClick} color='primary'>
      <VerifiedUserIcon />
    </IconButton>
  )
}

export default VerifyDriver
