import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { path, pluck, prop } from 'ramda'
import { useMutation, useQuery } from 'react-query'

import {
  EDIT_ORDER_CLEVERSELL,
  GET_ORDER_CONFIGURATION,
} from '../../../gql/queries'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { GEMMA } from '../../../config/endpoints'
import SelectSync from '../../core/SelectSync'

const EditGemmaOrderStatus = ({ field, record }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { request } = useRequest()
  const [value, setValue] = useState(record?.status?.name || '')
  const [editOrder, {
    data,
    error: editError,
  }] = useMutation((variables) => request(
    {
      query: EDIT_ORDER_CLEVERSELL,
      variables,
      endpoint: endpoints[field.endpoint],
    }))

  const { data: availableStatuses } = useQuery(
    ['orderStatuses'],
    () => request({
      query: GET_ORDER_CONFIGURATION,
      endpoint: GEMMA,
    }))
  console.log({ record })
  const onChange = async (e) => {
    const val = e.target.value
    console.log({ val })
    const previousValue = value
    setValue(val)
    if (val && !(val === value)) {
      await editOrder({ status: val, _id: path(['_id'], record) })
      if (editError) {
        setValue(previousValue)
      } else if (prop('editOrder', data)) {
        enqueueSnackbar('Status updated successfully', { variant: 'success' })
      }
    }
  }

  return (
    <SelectSync
      options={pluck('name', availableStatuses?.getOrderConfiguration?.orderStatusList || [])}
      value={value}
      onChange={onChange}
    />
  )
}

export default EditGemmaOrderStatus
