import { useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Search from '../../core/Search'
import Button from '../../core/Button/Button'
import { FilterList } from '@material-ui/icons'
import AddIcon from '@material-ui/icons/Add'
import React, { useCallback, useEffect, useState } from 'react'
import AutoComplete from '../../core/AutoComplete/AutoComplete'
import SelectSync from '../../core/SelectSync/SelectSync'
import { PROCTOR_ROLE_OPTIONS } from './constants'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

const ROLE_FILTER_OPTIONS = [{ key: undefined, label: 'None' }, ...PROCTOR_ROLE_OPTIONS]

const HeaderToolbar = ({ toggleFilters, onAdd }) => {
  const theme = useTheme()
  return (
    <Grid container item xs={12} spacing={1}>
      <Grid item xs={12} md={8}>
        <Search />
      </Grid>

      <Grid item xs={6} md={2}>
        <Button
          variant='contained'
          style={{
            height: 64,
            width: '100%',
            boxShadow: theme.shadows[26],
            fontWeight: 300,
            fontSize: theme.typography.h3.fontSize,
          }}
          onClick={toggleFilters}
        >
          filters
          <FilterList style={{ fontSize: theme.typography.h3.fontSize }} />
        </Button>
      </Grid>

      <Grid item xs={6} md={2}>
        <Button
          variant='contained'
          style={{
            height: 64,
            width: '100%',
            boxShadow: theme.shadows[26],
            fontWeight: 300,
            fontSize: theme.typography.h3.fontSize,
          }}
          onClick={onAdd}
        >
          <AddIcon style={{ fontSize: theme.typography.h3.fontSize }} />
          add
        </Button>
      </Grid>
    </Grid>
  )
}

const ProctorTableFilters = ({ filters, setFilters, showFilters, onClose }) => {
  const [tempFilters, setTempFilters] = useState(filters)

  useEffect(() => {
    setTempFilters(filters)
  }, [filters])

  const onChange = useCallback((name) => (e) => {
    // fuck this shit
    const value = Array.isArray(e) ? e : e.target.value

    return setTempFilters((f) => ({ ...f, [name]: value }))
  }, [])

  const handleClose = () => {
    setFilters(tempFilters)
    onClose()
  }

  return (
    <Dialog open={showFilters} onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>
        Filters
      </DialogTitle>
      <DialogContent>
        <Grid container item xs={12} direction='column' spacing={2}>
          <Grid item>
            <AutoComplete
              label='Round'
              multiple
              value={tempFilters.rounds}
              onChange={onChange('rounds')}
              valueFormatter={(_, round) => round?.key}
              displayFormatter={(_, round) => round?.name.en}
              searchQuery={{ query: 'GET_ROUNDS', endpoint: process.env.REACT_APP_ENDPOINT }}
              singleQuery={{ query: 'GET_ROUND', endpoint: process.env.REACT_APP_ENDPOINT }}
            />
          </Grid>

          <Grid item>
            <AutoComplete
              label='District'
              multiple
              value={tempFilters.districts}
              onChange={onChange('districts')}
              valueFormatter={(_, city) => city?.key}
              displayFormatter={(_, city) => city?.name.en}
              searchQuery={{ query: 'GET_DISTRICTS', endpoint: process.env.REACT_APP_ENDPOINT }}
              singleQuery={{ query: 'GET_DISTRICT', endpoint: process.env.REACT_APP_ENDPOINT }}
            />
          </Grid>

          <Grid item>
            <AutoComplete
              label='Center'
              multiple
              value={tempFilters.centers}
              onChange={onChange('centers')}
              valueFormatter={(_, city) => city?.key}
              displayFormatter={(_, city) => city?.name.en}
              searchQuery={{ query: 'GET_VENUES', endpoint: process.env.REACT_APP_ENDPOINT }}
              singleQuery={{ query: 'GET_VENUE', endpoint: process.env.REACT_APP_ENDPOINT }}
            />
          </Grid>
          <Grid item>
            <AutoComplete
              label='City'
              multiple
              value={tempFilters.cities}
              onChange={onChange('cities')}
              valueFormatter={(_, city) => city?.key}
              displayFormatter={(_, city) => city?.name.en}
              searchQuery={{ query: 'GET_CITIES', endpoint: process.env.REACT_APP_ENDPOINT }}
              singleQuery={{ query: 'GET_CITY', endpoint: process.env.REACT_APP_ENDPOINT }}
            />
          </Grid>

          <Grid item>
            <SelectSync
              label='Role'
              value={tempFilters.role}
              onChange={onChange('role')}
              options={ROLE_FILTER_OPTIONS}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Apply</Button>
      </DialogActions>
    </Dialog>
  )
}

export const ProctorTableHeader = ({ setFilters, filters, onAdd }) => {
  const [showFilters, setShowFilters] = useState(false)

  const onClose = () => setShowFilters(false)

  const toggleFilters = () => setShowFilters((e) => !e)

  return (
    <Grid container item spacing={1} style={{ marginTop: 4 }}>
      <HeaderToolbar toggleFilters={toggleFilters} showFilters={showFilters} onAdd={onAdd} />

      <ProctorTableFilters
        filters={filters}
        setFilters={setFilters}
        showFilters={showFilters}
        onClose={onClose}
      />
    </Grid>
  )
}
