import React from 'react'
import { prop, path } from 'ramda'
import SingleAddOn from '../SingleAddOns'
import definitions from '../../../config/definitions'

export const simpleVariations = [
  {
    label: 'Image',
    key: 'variationCopy.images',
    formatField: (R, cell, record) => cell && cell.length > 0 ? <span><img style={{ height: '8vh', display: 'inline' }} src={`${definitions.cloudinaryUrl}${cell[0]}`} alt={path(['variation', 'product', 'name', 'en'], record)} /></span> : '-',
  },
  {
    label: 'Name',
    key: 'variationCopy.name.en',
  },
  {
    label: 'SKU',
    key: 'variationCopy.serialNumber',
  },
  {
    label: 'Quantity',
    key: 'quantity',
    formatField: (R, cell) => cell ? cell.toString() : '',
  },
  {
    label: 'Unit Price',
    key: 'price',
    formatField: (R, cell) => cell ? Number(cell).toFixed(2).toString() : '',
  },
  {
    label: 'Total Price',
    key: 'total',
    formatField: (R, cell, record) =>
      record.total
        ? record.total.toFixed(2)
        : prop('quantity', record) && prop('price', record)
          ? (Number(record.price) * Number(record.quantity)).toFixed(2).toString()
          : '',
  },
  {
    label: 'AddOn',
    key: 'variation',
    formatField: (R, cell, record) => {
      if (record?.addOns && record.addOns.length > 0) {
        return (
          <SingleAddOn addOns={record.addOns} />
        )
      } else {
        return '-'
      }
    },
  },
]

export const simpleAddOns = [
  {
    label: 'Image',
    key: 'addOn.images',
    formatField: (R, cell) => {
      console.log({ length: cell.length })
      if (cell && cell.length > 0) {
        return cell.map((cell) => (
          <span key={cell} style={{ marginRight: '10px' }}>
            <img
              style={{ height: '8vh', display: 'inline' }} src={cell}
              alt='img'
            />
          </span>))
      }
    },
  },
  {
    label: 'Name',
    key: 'addOn.name.en',
  },
  {
    label: 'Price',
    key: 'addOn.price',
    formatField: (R, cell, record) => {
      return cell.discountedPrice ? cell.discountedPrice.toString() : cell.mainPrice?.toString() || 0
    },
  },
  {
    label: 'Quantity',
    key: 'quantity',
  },
]
