import React from 'react'
import { pathOr, reject, isNil } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'

export default function Tabs ({
  defaultActive = 1,
  currentActive = 1,
  setCurrentActive = () => {},
  tabContentStyle,
  children,
  tabHeight,
  tabFontSize,
}) {
  const typographyClasses = useTypographyStyles({ tabHeight, tabFontSize })
  const tabClasses = useTabsStyles({ tabHeight, tabFontSize })

  const Tabs = reject(isNil, pathOr([], ['0', 'props', 'children'], children))
  const TabsContent = pathOr([], ['1', 'props', 'children'], children)

  if (!Tabs.length || !TabsContent.length) return null
  return (
    <>
      <Grid container spacing={1}>
        {
          Tabs.map((Tab, index) => (
            <Grid key={index} item xs={Tabs.length > 6 ? 12 : 12 / Tabs.length} lg={Tabs.length > 6 ? 3 : 12 / Tabs.length}>
              <Box
                alignItems='center' display='flex' justifyContent='center'
                className={`${currentActive === index + 1 && tabClasses.active} ${tabClasses.tab}`} onClick={() => setCurrentActive(index + 1)}
              >
                <Typography className={`${currentActive === index + 1 && typographyClasses.active} ${typographyClasses.header}`}>{Tab}</Typography>
              </Box>
            </Grid>
          ))
        }
        <Grid item xs={12} style={tabContentStyle}>
          {
            TabsContent.map((TabContent, index) => (
              currentActive === index + 1 && <div key={index}>{TabContent}</div>
            ))
          }
        </Grid>
      </Grid>
    </>
  )
}

const useTabsStyles = makeStyles((theme) => ({
  tab: {
    height: (props) => props.tabHeight || '5.8rem',
    background: theme.palette.secondary.main,
    cursor: 'pointer',
    borderRadius: '.5rem',
    border: 0,
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    '&:hover *': {
      color: theme.palette.primary.main,
    },
    userSelect: 'none',
  },
  active: {
    color: [theme.palette.primary.main, '!important'],
    boxShadow: 'inset 2px 2px 3px 0 #000000',
  },
}))

const useTypographyStyles = makeStyles((theme) => ({
  header: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    verticalAlign: 'middle',
    display: 'flex',
    alignItems: 'center',
    fontSize: (props) => props.tabFontSize || '1.7rem',
  },
  active: {
    color: [theme.palette.primary.main, '!important'],
  },
}))
