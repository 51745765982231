import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { path, propOr } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import useRequest from '../../../helpers/useRequest'
import Button from '../../core/Button'
import Dialog from '../../core/Dialog'
import Box from '@material-ui/core/Box'
import InputBase from '@material-ui/core/InputBase'
import * as gql from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'

const ResetPassword = ({ state, setState, structure }) => {
  const [pin, setPin] = useState()
  const [password, setPassword] = useState()
  const [loading, setLoading] = useState(false)
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()
  const classes = useStyles()
  const handleSubmit = async () => {
    setLoading(true)
    try {
      const data = await request({ query: gql.VERIFY_PIN,
        variables: { username: state.username, accountType: state.accountType, verificationStrategy: 'email', pin },
        endpoint: endpoints[path(['forgetPassword', 'mutation', 'endpoint'], structure)] })
      const token = propOr(null, 'verifyPin', data)
      if (token) {
        await request({ query: gql.RESET_PASSWORD_SERVICES,
          variables: { accountType: state.accountType, token, password },
          endpoint: endpoints[path(['forgetPassword', 'mutation', 'endpoint'], structure)] })
        enqueueSnackbar('password changes successfully')
      }
      setLoading(false)
      setState({ resetPasswordVisible: false, username: '', accountType: '' })
    } catch (error) {
      console.log({ state })
      setLoading(false)
      if (error.response) {
        enqueueSnackbar(path(['response', 'errors', '0', 'message'], error), { variant: 'error' })
      } else {
        enqueueSnackbar('Failed to login.', { variant: 'error' })
      }
    }
  }

  return (
    <>
      <Dialog label='Reset Password' open={state.resetPasswordVisible} onClose={() => setState({ resetPasswordVisible: false, username: '', accountType: '' })}>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box display='flex' alignItems='center' height='100%'>
              <Typography className={classes.formLabel}>Code:</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <InputBase
              key='pin'
              type='text'
              disabled={loading}
              className={classes.input}
              value={pin}
              onChange={(e) => setPin(e.target.value)}
            />
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Box display='flex' alignItems='center' height='100%'>
              <Typography className={classes.formLabel}>Password:</Typography>
            </Box>
          </Grid>
          <Grid item xs={9}>
            <InputBase
              key='username'
              type='password'
              disabled={loading}
              className={classes.input}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Grid>
        </Grid>
        <Button
          onClick={handleSubmit}
          variant='contained'
          style={{ margin: '2rem 0 0 0' }}
          fullWidth
          loading={loading}
        >
          Submit
        </Button>
      </Dialog>
    </>
  )
}

export default ResetPassword

const useStyles = makeStyles((theme) => ({
  formLabel: {
    fontWeight: 300,
    fontSize: theme.typography.h4.fontSize,
    textTransform: 'uppercase',
  },
  input: {
    height: '3.5rem',
    width: 400,
    borderRadius: 4,
    background: '#a9aaaf',
    clear: 'both',
    border: 0,
    fontWeight: 300,
    padding: '0 10px',
  },
}))
