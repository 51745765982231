import React, { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button'
import DownloadIcon from '@material-ui/icons/CloudDownloadOutlined'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { last } from 'ramda'
import { saveAs } from 'file-saver'
import Dialog from '../../core/Dialog'
import DateRange from './DateRange'
import useRequest from '../../../helpers/useRequest'
import {
  EXPORT_ORDERS,
} from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'

export default function ExportGarnellCustomers () {
  const [isOpen, setOpen] = useState(false)
  const [minDate, setMinDate] = useState(undefined)
  const [maxDate, setMaxDate] = useState(undefined)

  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const [mutate, { isSuccess, isError, error }] = useMutation(() => request({ query: EXPORT_ORDERS,
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT || 'GARNELL'],
    variables: { ...minDate && { startDate: new Date(new Date(minDate).setHours(0, 0, 0, 0)).toISOString() }, ...maxDate && { endDate: new Date(new Date(maxDate).setHours(23, 59, 59, 0)).toISOString() } } }))

  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Downloading', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const handleClick = () => {
    setLoading(true)
    enqueueSnackbar('Generating file', { variant: 'info' })
    mutate()
      .then((resp) => {
        const url = Object.values(resp)[0]
        return saveAs(url, last(url.split('/')))
      })
      .catch(() => enqueueSnackbar('Failed to download file', { variant: 'error' }))
      .finally(() => setLoading(false))
  }

  return (
    <>
      <Button variant='contained' color='primary' startIcon={<DownloadIcon />} onClick={() => setOpen(true)}>
        Export
      </Button>
      <Dialog open={isOpen} onClose={() => setOpen(false)} label='Export Orders'>
        <DateRange onMaxValueChange={(val) => setMaxDate(val)} onMinValueChange={(val) => setMinDate(val)} maxValue={maxDate} minValue={minDate} label='DATE RANGE' />
        <Button
          onClick={handleClick}
          disabled={loading}
          variant='contained'
          loading={loading}
          startIcon={<DownloadIcon />}
          style={{
            alignSelf: 'center',
            margin: '0 0 0 auto',
            marginTop: '2rem',
            padding: '1rem',
          }}
          fullWidth
          color='primary'
        >
          Export
        </Button>
      </Dialog>
    </>
  )
}
