import React, { useState } from 'react'
import Button from '../../core/Button/Button'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import {
  bulkMarkAsPaidV1,
  EXPORT_ROUND_ADMISSIONS_FOR_SCHOOL,
  EXPORT_ROUND_SCORES_FOR_SCHOOL,
  IMPORT_STUDENTS_MUTATION,
} from '../../../gql/queries'
import { Exportbuttons } from './exportButtons'
import { BulkMarkAsPaidButton } from './bulkMarkAsPaidbutton'
import { GetApp, PaymentOutlined } from '@material-ui/icons'
import { ImportStudents } from './importStudentsButton'

export const ESTFacilityActions = ({ record }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <Button onClick={() => setShow(true)}>
        <PlayCircleOutlineIcon /> actions
      </Button>
      <Dialog open={show} onClose={() => setShow(false)} maxWidth='sm' fullWidth='true'>
        <DialogTitle>Actions</DialogTitle>
        <DialogContent>
          <Grid container>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <Exportbuttons record={record} title='DOWNLOAD SCORES' query={EXPORT_ROUND_SCORES_FOR_SCHOOL} icon={GetApp} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <Exportbuttons record={record} title='DOWNLOAD ADMISSIONS' query={EXPORT_ROUND_ADMISSIONS_FOR_SCHOOL} icon={GetApp} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <BulkMarkAsPaidButton record={record} title='BULK MARK AS PAID' query={bulkMarkAsPaidV1} icon={PaymentOutlined} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 10 }}>
              <ImportStudents record={record} text='IMPORT STUDENTS' mutation={IMPORT_STUDENTS_MUTATION} />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: '1rem' }}>
          <Button style={{ padding: '1rem' }} onClick={() => setShow(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
