import React, { useEffect, useState } from 'react'
import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Grid, IconButton, InputLabel, MenuItem, Modal, Select } from '@material-ui/core'
import Button from '../../core/Button/Button'
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline'
import SendESTAdmissionEmail from '../SendESTScoreEmail'
import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { useMutation, useQuery } from 'react-query'
import {
  EXPORT_ADMISSIONS_FOR_ROUND,
  EXPORT_ROUND_SCORES,
  IMPORT_CENTERS_FOR_ROUND,
  IMPORT_SCORES_FOR_ROUND,
  EXPORT_ADMISSIONS_NOTES_FOR_ROUND,
  EXPORT_CHECK_IN_FOR_ROUND,
  EXPORT_PROCTOR_ASSIGNMENTS,
  EXPORT_PROCTORS,
  IMPORT_PROCTOR_ASSIGNMENTS_FOR_ROUND,
  IMPORT_PROCTORS,
  EXPORT_COMMENTS_FOR_ROUND,
  ADD_STATS,
  GET_SCHOOLS,
  bulkMarkAsPaidV1,
} from '../../../gql/queries'
import { last } from 'ramda'
import { saveAs } from 'file-saver'
import { GetApp, BarChart, PaymentOutlined } from '@material-ui/icons'
import { ImportForRound, ImportWithoutRound } from './importButton'
import CloseIcon from '@material-ui/icons/Close'
import * as endpoints from '../../../config/endpoints'

const RoundExportAndStats = ({ record, title, query, isStats }) => {
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const [mutate, { isLoading, isSuccess, isError, error }] = useMutation(() =>
    request({
      query,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
      variables: { roundId: record._id },
    }))

  useEffect(() => {
    if (isSuccess && !isStats) {
      enqueueSnackbar('Downloading', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const handleClick = () => {
    mutate()
      .then((resp) => {
        if (isStats) enqueueSnackbar('Stats Added', { variant: 'success' })
        else {
          const url = Object.values(resp)[0]
          return saveAs(url, last(url.split('/')))
        }
      })
      .catch((e) => enqueueSnackbar(`${isStats ? 'Failed to add stats' : 'Failed to download file'}`, { variant: 'error' }))
  }

  return (
    <Button
      onClick={handleClick}
      disabled={isLoading}
      loading={isLoading}
      style={{
        alignSelf: 'center',
        margin: '0 0 0 auto',
        padding: '1rem',
      }}
    >
      {title} {isStats ? <BarChart /> : <GetApp />}
    </Button>
  )
}

const BulkMarkAsPaid = ({ record, title, query, isStats }) => {
  const { request } = useRequest()
  const [open, setOpen] = useState(false)
  const [selectedSchoolId, setSelectedSchoolId] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const { enqueueSnackbar } = useSnackbar()

  const { data: schoolsDataFormatted } = useQuery(
    ['schools'],
    () => request({
      query: GET_SCHOOLS,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    }),
  )
  const schools = schoolsDataFormatted?.getSchools?.items

  const [mutate, { isSuccess, isError, error }] = useMutation(() => request({
    query,
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    variables: { roundId: record._id, educationId: selectedSchoolId },
  }),
  {
    enabled: false,
  },
  )

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Successfully Marked As Paid', { variant: 'success' })
      setOpen(false)
    } else if (isError && error.message) {
      enqueueSnackbar('Please try pressing again', { variant: 'error' })
      setOpen(false)
    }
  }, [isError, isSuccess, error])

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleSelectChange = (event) => {
    const selectedSchool = schools.find((school) => school.name.en === event.target.value)
    setSelectedSchoolId(selectedSchool._id)
  }

  const handleSubmit = () => {
    setIsLoading(true)
    mutate()
      .then()
      .catch(() => enqueueSnackbar('Failed to mark as paid', { variant: 'error' }))
      .finally(() => setIsLoading(false))
  }

  return (
    <div>
      <Button
        style={{
          padding: '1rem',
        }}
        onClick={handleToggle}
      >
        {title} {isStats ? <PaymentOutlined /> : <PaymentOutlined />}
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '80%', sm: '60%', md: '40%' },
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <IconButton
            aria-label='close'
            onClick={handleClose}
            style={{
              position: 'absolute',
              right: 8,
              top: 8,
            }}
          >
            <CloseIcon />
          </IconButton>
          <FormControl fullWidth style={{ padding: '10px 0px 10px' }}>
            <InputLabel id='select-label'>Select School</InputLabel>
            <Select
              labelId='select-label'
              value={
                schools?.find((school) => school._id === selectedSchoolId)
                  ?.name.en || ''
              }
              onChange={handleSelectChange}
              label='Select School'
              disabled={isLoading}
            >
              {schools &&
                schools.map((school, index) => (
                  <MenuItem key={index} value={school.name.en}>
                    {school.name.en}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <Button
            variant='contained'
            onClick={handleSubmit}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? <CircularProgress size={24} /> : 'Mark As Paid'}
          </Button>
        </Box>
      </Modal>
    </div>
  )
}
export default BulkMarkAsPaid

export const ESTRoundActions = ({ record }) => {
  const [show, setShow] = useState(false)

  return (
    <>
      <Button onClick={() => setShow(true)}>
        <PlayCircleOutlineIcon /> actions
      </Button>
      <Dialog open={show} onClose={() => setShow(false)} maxWidth='sm' fullWidth='true'>
        <DialogTitle>Actions</DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item xs={6}>
              <SendESTAdmissionEmail
                record={record}
                field={{
                  label: 'Send Score Email',
                  showAlert: true,
                  alertBody: 'This action will send an email for all the students registered in this round, this is not reversible. Are you sure you want to continue?',
                  query: 'SEND_SCORE_EMAIL_FOR_ROUND',
                  endpoint: 'EST',
                }}
              />
            </Grid>

            <Grid item xs={6}>
              <SendESTAdmissionEmail
                record={record}
                field={{
                  label: 'Send Center Assignment Email',
                  showAlert: true,
                  alertBody: 'This action will send an email for all the students registered in this round, this is not reversible. Are you sure you want to continue?',
                  name: 'SendESTAdmissionEmail',
                  query: 'SEND_CENTER_ASSIGNMENT_EMAIL_FOR_ROUND',
                  endpoint: 'EST',
                }}
              />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 20 }}>
              <ImportForRound mutation={IMPORT_CENTERS_FOR_ROUND} text='Import Center Assignments' record={record} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <ImportForRound mutation={IMPORT_SCORES_FOR_ROUND} text='Import Scores' record={record} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <ImportForRound mutation={IMPORT_PROCTOR_ASSIGNMENTS_FOR_ROUND} text='Import Proctor Assignments' record={record} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <ImportWithoutRound mutation={IMPORT_PROCTORS} text='Import Proctors' record={record} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <RoundExportAndStats record={record} title='DOWNLOAD SCORES' query={EXPORT_ROUND_SCORES} isStats={false} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <RoundExportAndStats record={record} title='DOWNLOAD COMMENTS' query={EXPORT_COMMENTS_FOR_ROUND} isStats={false} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <RoundExportAndStats record={record} title='DOWNLOAD ADMISSIONS' query={EXPORT_ADMISSIONS_FOR_ROUND} isStats={false} />
            </Grid>
            <Grid item xs={6} style={{ marginTop: 20 }}>
              <RoundExportAndStats record={record} title='DOWNLOAD ADMISSIONS NOTES' query={EXPORT_ADMISSIONS_NOTES_FOR_ROUND} isStats={false} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <RoundExportAndStats record={record} title='Check in/out Export' query={EXPORT_CHECK_IN_FOR_ROUND} isStats={false} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <RoundExportAndStats record={record} title='Export Proctors' query={EXPORT_PROCTORS} isStats={false} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <RoundExportAndStats record={record} title='Export Proctors Assignments' query={EXPORT_PROCTOR_ASSIGNMENTS} isStats={false} />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <RoundExportAndStats record={record} title='Add Stats' query={ADD_STATS} isStats />
            </Grid>

            <Grid item xs={6} style={{ marginTop: 20 }}>
              <BulkMarkAsPaid query={bulkMarkAsPaidV1} record={record} title='Bulk mark as paid' isStats={false} />
            </Grid>

          </Grid>
        </DialogContent>
        <DialogActions style={{ marginBottom: '1rem' }}>
          <Button style={{ padding: '1rem' }} onClick={() => setShow(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
