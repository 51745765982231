// we could do a more compact format with something like Record<key, string[]>
// and get rid of the governorates but they're probably gonna get compressed well enough
// that we don't need to do that
const ALL_GOVERNORATES = ['Alexandria', 'Assiut', 'Aswan', 'Beheira', 'Beni Suef', 'Cairo', 'Dakahlia', 'Damietta', 'Fayoum', 'Gharbiya', 'Giza', 'Ismailia', 'Kafr Al sheikh', 'Luxor', 'Matrouh', 'Menofia', 'Minya', 'New Valley', 'North Sinai', 'Port Said', 'Qaliubiya', 'Qena', 'Red Sea', 'Sharkia', 'Sohag', 'South Sinai', 'Suez']
/**
 * @type {{label: string, _id: string}[]}
 */
export const governorates = ALL_GOVERNORATES.map((g) => ({
  key: g,
  label: g,
}))

/**
 * @type {Record<string, { _id: string, label: string }[]>}
 */
export const governorateCities = {
  Alexandria: [
    {
      _id: 'Abu Qir',
      label: 'Abu Qir',
    },
    {
      _id: 'Agamy',
      label: 'Agamy',
    },
    {
      _id: 'Al Ibrahimeyah',
      label: 'Al Ibrahimeyah',
    },
    {
      _id: 'Al Mafrouza',
      label: 'Al Mafrouza',
    },
    {
      _id: 'Al Mamurah',
      label: 'Al Mamurah',
    },
    {
      _id: 'Al Mandara',
      label: 'Al Mandara',
    },
    {
      _id: 'Al Max',
      label: 'Al Max',
    },
    {
      _id: 'Alattarin',
      label: 'Alattarin',
    },
    {
      _id: 'Alhadra',
      label: 'Alhadra',
    },
    {
      _id: 'Ambrozo',
      label: 'Ambrozo',
    },
    {
      _id: 'Ameria',
      label: 'Ameria',
    },
    {
      _id: 'Anfoushi',
      label: 'Anfoushi',
    },
    {
      _id: 'Asafra',
      label: 'Asafra',
    },
    {
      _id: 'Azarita',
      label: 'Azarita',
    },
    {
      _id: 'Bab Sharq',
      label: 'Bab Sharq',
    },
    {
      _id: 'Bakos',
      label: 'Bakos',
    },
    {
      _id: 'Boulkly',
      label: 'Boulkly',
    },
    {
      _id: 'Bourj Alarab',
      label: 'Bourj Alarab',
    },
    {
      _id: 'Camp Shizar',
      label: 'Camp Shizar',
    },
    {
      _id: 'Cleopatra',
      label: 'Cleopatra',
    },
    {
      _id: 'Dekheila',
      label: 'Dekheila',
    },
    {
      _id: 'El Labban',
      label: 'El Labban',
    },
    {
      _id: 'El Montaza',
      label: 'El Montaza',
    },
    {
      _id: 'El Soyof',
      label: 'El Soyof',
    },
    {
      _id: 'Elgomrok',
      label: 'Elgomrok',
    },
    {
      _id: 'Elshatby',
      label: 'Elshatby',
    },
    {
      _id: 'Fleming',
      label: 'Fleming',
    },
    {
      _id: 'Gheet Alenab',
      label: 'Gheet Alenab',
    },
    {
      _id: 'Glim',
      label: 'Glim',
    },
    {
      _id: 'Karmooz',
      label: 'Karmooz',
    },
    {
      _id: 'Mahta Alraml',
      label: 'Mahta Alraml',
    },
    {
      _id: 'Mansheya',
      label: 'Mansheya',
    },
    {
      _id: 'Marina',
      label: 'Marina',
    },
    {
      _id: 'Mina El-Basal',
      label: 'Mina El-Basal',
    },
    {
      _id: 'Moharam Bek',
      label: 'Moharam Bek',
    },
    {
      _id: 'Naseria',
      label: 'Naseria',
    },
    {
      _id: 'North Coast/sahel',
      label: 'North Coast/sahel',
    },
    {
      _id: 'Shads',
      label: 'Shads',
    },
    {
      _id: 'Sidi Bishr',
      label: 'Sidi Bishr',
    },
    {
      _id: 'Sidi Gaber',
      label: 'Sidi Gaber',
    },
    {
      _id: 'Sidi Kerir',
      label: 'Sidi Kerir',
    },
    {
      _id: 'Smouha',
      label: 'Smouha',
    },
    {
      _id: 'Stanley',
      label: 'Stanley',
    },
    {
      _id: 'Victoria',
      label: 'Victoria',
    },
  ],
  Assiut: [
    {
      _id: 'Abnoub',
      label: 'Abnoub',
    },
    {
      _id: 'Abu Tig',
      label: 'Abu Tig',
    },
    {
      _id: 'Assiut',
      label: 'Assiut',
    },
    {
      _id: 'Assiut El Gedida',
      label: 'Assiut El Gedida',
    },
    {
      _id: 'Dayrout',
      label: 'Dayrout',
    },
    {
      _id: 'El Badari',
      label: 'El Badari',
    },
    {
      _id: 'El Ghanaim',
      label: 'El Ghanaim',
    },
    {
      _id: 'Manfalut',
      label: 'Manfalut',
    },
    {
      _id: 'Qusiya',
      label: 'Qusiya',
    },
    {
      _id: 'Sahel Selim',
      label: 'Sahel Selim',
    },
    {
      _id: 'Sidfa',
      label: 'Sidfa',
    },
  ],
  Aswan: [
    {
      _id: 'Abo Simbl Al Siyahia',
      label: 'Abo Simbl Al Siyahia',
    },
    {
      _id: 'Al Basilia',
      label: 'Al Basilia',
    },
    {
      _id: 'Al Sibaeia',
      label: 'Al Sibaeia',
    },
    {
      _id: 'Al-Radisiyah',
      label: 'Al-Radisiyah',
    },
    {
      _id: 'Aswan',
      label: 'Aswan',
    },
    {
      _id: 'Aswan El Gedida',
      label: 'Aswan El Gedida',
    },
    {
      _id: 'Drau',
      label: 'Drau',
    },
    {
      _id: 'Edfu',
      label: 'Edfu',
    },
    {
      _id: 'Kalabsha',
      label: 'Kalabsha',
    },
    {
      _id: 'Kom Ombo',
      label: 'Kom Ombo',
    },
    {
      _id: 'Marsa Alam',
      label: 'Marsa Alam',
    },
    {
      _id: 'Nasr Al Nuba',
      label: 'Nasr Al Nuba',
    },
  ],
  Beheira: [
    {
      _id: 'Abu Homs',
      label: 'Abu Homs',
    },
    {
      _id: 'Abu al-Matamir',
      label: 'Abu al-Matamir',
    },
    {
      _id: 'Alnoubareya',
      label: 'Alnoubareya',
    },
    {
      _id: 'Badr',
      label: 'Badr',
    },
    {
      _id: 'Damanhour',
      label: 'Damanhour',
    },
    {
      _id: 'Delengat',
      label: 'Delengat',
    },
    {
      _id: 'Edco',
      label: 'Edco',
    },
    {
      _id: 'Housh Eissa',
      label: 'Housh Eissa',
    },
    {
      _id: 'Itai Baroud',
      label: 'Itai Baroud',
    },
    {
      _id: 'Kafr El Dawar',
      label: 'Kafr El Dawar',
    },
    {
      _id: 'Kom Hamada',
      label: 'Kom Hamada',
    },
    {
      _id: 'Mahmoudiyah',
      label: 'Mahmoudiyah',
    },
    {
      _id: 'New Nubaria',
      label: 'New Nubaria',
    },
    {
      _id: 'Rahmaniyah',
      label: 'Rahmaniyah',
    },
    {
      _id: 'Rashid',
      label: 'Rashid',
    },
    {
      _id: 'Shubrakhit',
      label: 'Shubrakhit',
    },
    {
      _id: 'Wadi Natrun',
      label: 'Wadi Natrun',
    },
  ],
  'Beni Suef': [
    {
      _id: 'Al Wasta',
      label: 'Al Wasta',
    },
    {
      _id: 'Alabbaseri',
      label: 'Alabbaseri',
    },
    {
      _id: 'Bani Sweif',
      label: 'Bani Sweif',
    },
    {
      _id: 'Beni Suef El Gedida',
      label: 'Beni Suef El Gedida',
    },
    {
      _id: 'Ehnasia',
      label: 'Ehnasia',
    },
    {
      _id: 'Fashn',
      label: 'Fashn',
    },
    {
      _id: 'Mokbel',
      label: 'Mokbel',
    },
    {
      _id: 'Naser',
      label: 'Naser',
    },
    {
      _id: 'Somasta',
      label: 'Somasta',
    },
    {
      _id: 'beba',
      label: 'beba',
    },
  ],
  Cairo: [
    {
      _id: '10th of Ramadan City',
      label: '10th of Ramadan City',
    },
    {
      _id: '15 May',
      label: '15 May',
    },
    {
      _id: 'Abaseya',
      label: 'Abaseya',
    },
    {
      _id: 'Abdeen',
      label: 'Abdeen',
    },
    {
      _id: 'Ain Shams',
      label: 'Ain Shams',
    },
    {
      _id: 'Al Azbakeyah',
      label: 'Al Azbakeyah',
    },
    {
      _id: 'Al Basatin',
      label: 'Al Basatin',
    },
    {
      _id: 'Aldarb Alahmar',
      label: 'Aldarb Alahmar',
    },
    {
      _id: 'Ataba',
      label: 'Ataba',
    },
    {
      _id: 'Bab al-Shereia',
      label: 'Bab al-Shereia',
    },
    {
      _id: 'Badr City',
      label: 'Badr City',
    },
    {
      _id: 'Bolaq',
      label: 'Bolaq',
    },
    {
      _id: 'Cairo Downtown',
      label: 'Cairo Downtown',
    },
    {
      _id: 'Capital New',
      label: 'Capital New',
    },
    {
      _id: 'Dar Al Salam',
      label: 'Dar Al Salam',
    },
    {
      _id: 'El Daher',
      label: 'El Daher',
    },
    {
      _id: 'El Marg',
      label: 'El Marg',
    },
    {
      _id: 'El Salam',
      label: 'El Salam',
    },
    {
      _id: 'El Sharabeya',
      label: 'El Sharabeya',
    },
    {
      _id: 'El darrasa',
      label: 'El darrasa',
    },
    {
      _id: 'El-Gamaleya',
      label: 'El-Gamaleya',
    },
    {
      _id: 'El-Khalifa',
      label: 'El-Khalifa',
    },
    {
      _id: 'El-Zaytoun',
      label: 'El-Zaytoun',
    },
    {
      _id: 'Ezbet el Nakhl',
      label: 'Ezbet el Nakhl',
    },
    {
      _id: 'Garden City',
      label: 'Garden City',
    },
    {
      _id: 'Hadayek El-Kobba',
      label: 'Hadayek El-Kobba',
    },
    {
      _id: 'Helmeyat Alzaytoun',
      label: 'Helmeyat Alzaytoun',
    },
    {
      _id: 'Helwan',
      label: 'Helwan',
    },
    {
      _id: 'Kasr El Nile',
      label: 'Kasr El Nile',
    },
    {
      _id: 'Katameya',
      label: 'Katameya',
    },
    {
      _id: 'Maadi',
      label: 'Maadi',
    },
    {
      _id: 'Maasara',
      label: 'Maasara',
    },
    {
      _id: 'Madinty',
      label: 'Madinty',
    },
    {
      _id: 'Mansheya Nasir',
      label: 'Mansheya Nasir',
    },
    {
      _id: 'Manyal',
      label: 'Manyal',
    },
    {
      _id: 'Masr Al Qadima',
      label: 'Masr Al Qadima',
    },
    {
      _id: 'Matareya',
      label: 'Matareya',
    },
    {
      _id: 'Mokattam',
      label: 'Mokattam',
    },
    {
      _id: 'Mosky',
      label: 'Mosky',
    },
    {
      _id: 'Nasr City',
      label: 'Nasr City',
    },
    {
      _id: 'New Cairo',
      label: 'New Cairo',
    },
    {
      _id: 'New Heliopolis',
      label: 'New Heliopolis',
    },
    {
      _id: 'New Nozha',
      label: 'New Nozha',
    },
    {
      _id: 'Nozha',
      label: 'Nozha',
    },
    {
      _id: 'Obour City',
      label: 'Obour City',
    },
    {
      _id: 'Rehab',
      label: 'Rehab',
    },
    {
      _id: 'Rod Alfarag',
      label: 'Rod Alfarag',
    },
    {
      _id: 'Sahel',
      label: 'Sahel',
    },
    {
      _id: 'Sayeda Zeinab',
      label: 'Sayeda Zeinab',
    },
    {
      _id: 'Sheraton',
      label: 'Sheraton',
    },
    {
      _id: 'Shorouk',
      label: 'Shorouk',
    },
    {
      _id: 'Shubra',
      label: 'Shubra',
    },
    {
      _id: 'Tebin',
      label: 'Tebin',
    },
    {
      _id: 'Tura',
      label: 'Tura',
    },
    {
      _id: 'Waily',
      label: 'Waily',
    },
    {
      _id: 'Zamalek',
      label: 'Zamalek',
    },
    {
      _id: 'Zawya al-Hamra',
      label: 'Zawya al-Hamra',
    },
  ],
  Dakahlia: [
    {
      _id: 'Aga',
      label: 'Aga',
    },
    {
      _id: 'Al Manzala',
      label: 'Al Manzala',
    },
    {
      _id: 'Bani Ubaid',
      label: 'Bani Ubaid',
    },
    {
      _id: 'Belqas',
      label: 'Belqas',
    },
    {
      _id: 'Dekernes',
      label: 'Dekernes',
    },
    {
      _id: 'El Kurdi',
      label: 'El Kurdi',
    },
    {
      _id: 'Gamasa',
      label: 'Gamasa',
    },
    {
      _id: 'Mahalat Damana',
      label: 'Mahalat Damana',
    },
    {
      _id: 'Mansoura',
      label: 'Mansoura',
    },
    {
      _id: 'Mataria',
      label: 'Mataria',
    },
    {
      _id: 'Meet Salsil',
      label: 'Meet Salsil',
    },
    {
      _id: 'Menia El Nasr',
      label: 'Menia El Nasr',
    },
    {
      _id: 'Mitt Ghamr',
      label: 'Mitt Ghamr',
    },
    {
      _id: 'Nabroh',
      label: 'Nabroh',
    },
    {
      _id: 'Sherbin',
      label: 'Sherbin',
    },
    {
      _id: 'Sinbillawin',
      label: 'Sinbillawin',
    },
    {
      _id: 'Talkha',
      label: 'Talkha',
    },
    {
      _id: 'aljamalia',
      label: 'aljamalia',
    },
    {
      _id: 'tami al\'amdid',
      label: 'tami al\'amdid',
    },
  ],
  Damietta: [
    {
      _id: 'Azbet Al Burg',
      label: 'Azbet Al Burg',
    },
    {
      _id: 'Damietta',
      label: 'Damietta',
    },
    {
      _id: 'Faraskour',
      label: 'Faraskour',
    },
    {
      _id: 'Kafr El-Batikh',
      label: 'Kafr El-Batikh',
    },
    {
      _id: 'Kafr Saad',
      label: 'Kafr Saad',
    },
    {
      _id: 'Meet Abou Ghalib',
      label: 'Meet Abou Ghalib',
    },
    {
      _id: 'New Damietta',
      label: 'New Damietta',
    },
    {
      _id: 'Ras El Bar',
      label: 'Ras El Bar',
    },
    {
      _id: 'Zarqa',
      label: 'Zarqa',
    },
    {
      _id: 'alruwda',
      label: 'alruwda',
    },
    {
      _id: 'alsaru',
      label: 'alsaru',
    },
  ],
  Fayoum: [
    {
      _id: 'Algamaa',
      label: 'Algamaa',
    },
    {
      _id: 'Atsa',
      label: 'Atsa',
    },
    {
      _id: 'Epschway',
      label: 'Epschway',
    },
    {
      _id: 'Etsa',
      label: 'Etsa',
    },
    {
      _id: 'Fayoum',
      label: 'Fayoum',
    },
    {
      _id: 'Fayoum El Gedida',
      label: 'Fayoum El Gedida',
    },
    {
      _id: 'Hadqa',
      label: 'Hadqa',
    },
    {
      _id: 'Sayala',
      label: 'Sayala',
    },
    {
      _id: 'Snores',
      label: 'Snores',
    },
    {
      _id: 'Tamiya',
      label: 'Tamiya',
    },
    {
      _id: 'Yusuf El Sediaq',
      label: 'Yusuf El Sediaq',
    },
  ],
  Gharbiya: [
    {
      _id: 'Al Mahalla Al Kobra',
      label: 'Al Mahalla Al Kobra',
    },
    {
      _id: 'Basion',
      label: 'Basion',
    },
    {
      _id: 'El Santa',
      label: 'El Santa',
    },
    {
      _id: 'Kafr El Zayat',
      label: 'Kafr El Zayat',
    },
    {
      _id: 'Qutour',
      label: 'Qutour',
    },
    {
      _id: 'Samannoud',
      label: 'Samannoud',
    },
    {
      _id: 'Tanta',
      label: 'Tanta',
    },
    {
      _id: 'Zefta',
      label: 'Zefta',
    },
  ],
  Giza: [
    {
      _id: 'Abu Nomros',
      label: 'Abu Nomros',
    },
    {
      _id: 'Abu Rawash',
      label: 'Abu Rawash',
    },
    {
      _id: 'Agouza',
      label: 'Agouza',
    },
    {
      _id: 'Al Ayat',
      label: 'Al Ayat',
    },
    {
      _id: 'Al Badrasheen',
      label: 'Al Badrasheen',
    },
    {
      _id: 'Al Wahat Al Baharia',
      label: 'Al Wahat Al Baharia',
    },
    {
      _id: 'Al-Bawaiti',
      label: 'Al-Bawaiti',
    },
    {
      _id: 'Ard Ellwaa',
      label: 'Ard Ellwaa',
    },
    {
      _id: 'Atfih',
      label: 'Atfih',
    },
    {
      _id: 'Bin Alsarayat',
      label: 'Bin Alsarayat',
    },
    {
      _id: 'Boulaq Dakrour',
      label: 'Boulaq Dakrour',
    },
    {
      _id: 'Cheikh Zayed',
      label: 'Cheikh Zayed',
    },
    {
      _id: 'Dokki',
      label: 'Dokki',
    },
    {
      _id: 'Faisal',
      label: 'Faisal',
    },
    {
      _id: 'Giza',
      label: 'Giza',
    },
    {
      _id: 'Hadayek Alahram',
      label: 'Hadayek Alahram',
    },
    {
      _id: 'Hadayek October',
      label: 'Hadayek October',
    },
    {
      _id: 'Haram',
      label: 'Haram',
    },
    {
      _id: 'Haraneya',
      label: 'Haraneya',
    },
    {
      _id: 'Hawamdiyah',
      label: 'Hawamdiyah',
    },
    {
      _id: 'Imbaba',
      label: 'Imbaba',
    },
    {
      _id: 'Kafr Ghati',
      label: 'Kafr Ghati',
    },
    {
      _id: 'Kerdasa',
      label: 'Kerdasa',
    },
    {
      _id: 'Kit Kat',
      label: 'Kit Kat',
    },
    {
      _id: 'Manshiyet Al Bakari',
      label: 'Manshiyet Al Bakari',
    },
    {
      _id: 'ManshiyetAl Qanater',
      label: 'ManshiyetAl Qanater',
    },
    {
      _id: 'Mohandessin',
      label: 'Mohandessin',
    },
    {
      _id: 'Moneeb',
      label: 'Moneeb',
    },
    {
      _id: 'Oaseem',
      label: 'Oaseem',
    },
    {
      _id: 'Omraneya',
      label: 'Omraneya',
    },
    {
      _id: 'Saf',
      label: 'Saf',
    },
    {
      _id: 'Saft Allaban',
      label: 'Saft Allaban',
    },
    {
      _id: 'Sixth of October',
      label: 'Sixth of October',
    },
    {
      _id: 'Smart Village',
      label: 'Smart Village',
    },
    {
      _id: 'Warraq',
      label: 'Warraq',
    },
  ],
  Ismailia: [
    {
      _id: 'Abu Sawir',
      label: 'Abu Sawir',
    },
    {
      _id: 'El Tal El Kabier',
      label: 'El Tal El Kabier',
    },
    {
      _id: 'Fayed',
      label: 'Fayed',
    },
    {
      _id: 'Ismailia',
      label: 'Ismailia',
    },
    {
      _id: 'Kasasien El Gedida',
      label: 'Kasasien El Gedida',
    },
    {
      _id: 'Nefesha',
      label: 'Nefesha',
    },
    {
      _id: 'Qantara Gharb',
      label: 'Qantara Gharb',
    },
    {
      _id: 'Qantara Sharq',
      label: 'Qantara Sharq',
    },
    {
      _id: 'Sheikh Zayed',
      label: 'Sheikh Zayed',
    },
  ],
  'Kafr Al sheikh': [
    {
      _id: 'Baltim',
      label: 'Baltim',
    },
    {
      _id: 'Bella',
      label: 'Bella',
    },
    {
      _id: 'Burg Al Burullus',
      label: 'Burg Al Burullus',
    },
    {
      _id: 'Desouq',
      label: 'Desouq',
    },
    {
      _id: 'Fooh',
      label: 'Fooh',
    },
    {
      _id: 'Hamol',
      label: 'Hamol',
    },
    {
      _id: 'Kafr El Sheikh',
      label: 'Kafr El Sheikh',
    },
    {
      _id: 'Kafr El Sheikh Downtown',
      label: 'Kafr El Sheikh Downtown',
    },
    {
      _id: 'Masief Baltim',
      label: 'Masief Baltim',
    },
    {
      _id: 'Metobas',
      label: 'Metobas',
    },
    {
      _id: 'Qellen',
      label: 'Qellen',
    },
    {
      _id: 'Riyadh',
      label: 'Riyadh',
    },
    {
      _id: 'Sidi Ghazi',
      label: 'Sidi Ghazi',
    },
    {
      _id: 'Sidi Salm',
      label: 'Sidi Salm',
    },
  ],
  Luxor: [
    {
      _id: 'Al Bayadieh',
      label: 'Al Bayadieh',
    },
    {
      _id: 'Al Qarna',
      label: 'Al Qarna',
    },
    {
      _id: 'Al Tud',
      label: 'Al Tud',
    },
    {
      _id: 'Al ziynia',
      label: 'Al ziynia',
    },
    {
      _id: 'Armant',
      label: 'Armant',
    },
    {
      _id: 'Esna',
      label: 'Esna',
    },
    {
      _id: 'Luxor',
      label: 'Luxor',
    },
    {
      _id: 'New Luxor',
      label: 'New Luxor',
    },
    {
      _id: 'New Tiba',
      label: 'New Tiba',
    },
  ],
  Matrouh: [
    {
      _id: 'Al-Nagila',
      label: 'Al-Nagila',
    },
    {
      _id: 'Alamein',
      label: 'Alamein',
    },
    {
      _id: 'Dabaa',
      label: 'Dabaa',
    },
    {
      _id: 'El Hamam',
      label: 'El Hamam',
    },
    {
      _id: 'Marina',
      label: 'Marina',
    },
    {
      _id: 'Marsa Matrouh',
      label: 'Marsa Matrouh',
    },
    {
      _id: 'North Coast',
      label: 'North Coast',
    },
    {
      _id: 'Salloum',
      label: 'Salloum',
    },
    {
      _id: 'Sidi Brani',
      label: 'Sidi Brani',
    },
    {
      _id: 'Siwa',
      label: 'Siwa',
    },
  ],
  Menofia: [
    {
      _id: 'Al Bagor',
      label: 'Al Bagor',
    },
    {
      _id: 'Al Shohada',
      label: 'Al Shohada',
    },
    {
      _id: 'Ashmon',
      label: 'Ashmon',
    },
    {
      _id: 'Berkat El Saba',
      label: 'Berkat El Saba',
    },
    {
      _id: 'Menouf',
      label: 'Menouf',
    },
    {
      _id: 'Quesna',
      label: 'Quesna',
    },
    {
      _id: 'Sadat City',
      label: 'Sadat City',
    },
    {
      _id: 'Sars El-Layan',
      label: 'Sars El-Layan',
    },
    {
      _id: 'Shbeen El Koom',
      label: 'Shbeen El Koom',
    },
    {
      _id: 'Tala',
      label: 'Tala',
    },
  ],
  Minya: [
    {
      _id: 'Abu Qurqas',
      label: 'Abu Qurqas',
    },
    {
      _id: 'Ard Sultan',
      label: 'Ard Sultan',
    },
    {
      _id: 'Bani Mazar',
      label: 'Bani Mazar',
    },
    {
      _id: 'Deir Mawas',
      label: 'Deir Mawas',
    },
    {
      _id: 'El Adwa',
      label: 'El Adwa',
    },
    {
      _id: 'Madinat El Fekria',
      label: 'Madinat El Fekria',
    },
    {
      _id: 'Magagha',
      label: 'Magagha',
    },
    {
      _id: 'Mattay',
      label: 'Mattay',
    },
    {
      _id: 'Meloy',
      label: 'Meloy',
    },
    {
      _id: 'Minya',
      label: 'Minya',
    },
    {
      _id: 'Minya El Gedida',
      label: 'Minya El Gedida',
    },
    {
      _id: 'Samalut',
      label: 'Samalut',
    },
  ],
  'New Valley': [
    {
      _id: 'Balat',
      label: 'Balat',
    },
    {
      _id: 'Dakhla',
      label: 'Dakhla',
    },
    {
      _id: 'El Kharga',
      label: 'El Kharga',
    },
    {
      _id: 'Farafra',
      label: 'Farafra',
    },
    {
      _id: 'Mout',
      label: 'Mout',
    },
    {
      _id: 'Paris',
      label: 'Paris',
    },
  ],
  'North Sinai': [
    {
      _id: 'Al Hasana',
      label: 'Al Hasana',
    },
    {
      _id: 'Arish',
      label: 'Arish',
    },
    {
      _id: 'Bir al-Abed',
      label: 'Bir al-Abed',
    },
    {
      _id: 'Nakhl',
      label: 'Nakhl',
    },
    {
      _id: 'Rafah',
      label: 'Rafah',
    },
    {
      _id: 'Sheikh Zowaid',
      label: 'Sheikh Zowaid',
    },
  ],
  'Port Said': [
    {
      _id: 'Alarab',
      label: 'Alarab',
    },
    {
      _id: 'Aldawahi',
      label: 'Aldawahi',
    },
    {
      _id: 'Almanakh',
      label: 'Almanakh',
    },
    {
      _id: 'Alsharq',
      label: 'Alsharq',
    },
    {
      _id: 'Mubarak',
      label: 'Mubarak',
    },
    {
      _id: 'PorSaid',
      label: 'PorSaid',
    },
    {
      _id: 'Port Fouad',
      label: 'Port Fouad',
    },
    {
      _id: 'Zohour',
      label: 'Zohour',
    },
  ],
  Qaliubiya: [
    {
      _id: 'Al Qanater Charity',
      label: 'Al Qanater Charity',
    },
    {
      _id: 'Banha',
      label: 'Banha',
    },
    {
      _id: 'Kafr Shukr',
      label: 'Kafr Shukr',
    },
    {
      _id: 'Khanka',
      label: 'Khanka',
    },
    {
      _id: 'Khosous',
      label: 'Khosous',
    },
    {
      _id: 'Mostorod',
      label: 'Mostorod',
    },
    {
      _id: 'Obour',
      label: 'Obour',
    },
    {
      _id: 'Qaha',
      label: 'Qaha',
    },
    {
      _id: 'Qalyub',
      label: 'Qalyub',
    },
    {
      _id: 'Shibin Al Qanater',
      label: 'Shibin Al Qanater',
    },
    {
      _id: 'Shubra Al Khaimah',
      label: 'Shubra Al Khaimah',
    },
    {
      _id: 'Tukh',
      label: 'Tukh',
    },
  ],
  Qena: [
    {
      _id: 'Abu Tesht',
      label: 'Abu Tesht',
    },
    {
      _id: 'Alwaqf',
      label: 'Alwaqf',
    },
    {
      _id: 'Deshna',
      label: 'Deshna',
    },
    {
      _id: 'Farshout',
      label: 'Farshout',
    },
    {
      _id: 'Nag Hammadi',
      label: 'Nag Hammadi',
    },
    {
      _id: 'Naqada',
      label: 'Naqada',
    },
    {
      _id: 'New Qena',
      label: 'New Qena',
    },
    {
      _id: 'Qaft',
      label: 'Qaft',
    },
    {
      _id: 'Qena',
      label: 'Qena',
    },
    {
      _id: 'Quos',
      label: 'Quos',
    },
  ],
  'Red Sea': [
    {
      _id: 'Aldahar',
      label: 'Aldahar',
    },
    {
      _id: 'El Qusiar',
      label: 'El Qusiar',
    },
    {
      _id: 'Halaib',
      label: 'Halaib',
    },
    {
      _id: 'Hurghada',
      label: 'Hurghada',
    },
    {
      _id: 'Marsa Alam',
      label: 'Marsa Alam',
    },
    {
      _id: 'Ras Ghareb',
      label: 'Ras Ghareb',
    },
    {
      _id: 'Safaga',
      label: 'Safaga',
    },
    {
      _id: 'Shalatin',
      label: 'Shalatin',
    },
  ],
  Sharkia: [
    {
      _id: 'Abu Hammad',
      label: 'Abu Hammad',
    },
    {
      _id: 'Abu Kabir',
      label: 'Abu Kabir',
    },
    {
      _id: 'Al Ashr Men Ramadan',
      label: 'Al Ashr Men Ramadan',
    },
    {
      _id: 'Al Ibrahimiyah',
      label: 'Al Ibrahimiyah',
    },
    {
      _id: 'Awlad Saqr',
      label: 'Awlad Saqr',
    },
    {
      _id: 'Belbeis',
      label: 'Belbeis',
    },
    {
      _id: 'Deirb Negm',
      label: 'Deirb Negm',
    },
    {
      _id: 'El Qurain',
      label: 'El Qurain',
    },
    {
      _id: 'El Salihia El Gedida',
      label: 'El Salihia El Gedida',
    },
    {
      _id: 'Faccus',
      label: 'Faccus',
    },
    {
      _id: 'Hehia',
      label: 'Hehia',
    },
    {
      _id: 'Husseiniya',
      label: 'Husseiniya',
    },
    {
      _id: 'Kafr Saqr',
      label: 'Kafr Saqr',
    },
    {
      _id: 'Manshayat Abu Omar',
      label: 'Manshayat Abu Omar',
    },
    {
      _id: 'Mashtoul El Souq',
      label: 'Mashtoul El Souq',
    },
    {
      _id: 'Minya Al Qamh',
      label: 'Minya Al Qamh',
    },
    {
      _id: 'Qenaiat',
      label: 'Qenaiat',
    },
    {
      _id: 'Zagazig',
      label: 'Zagazig',
    },
    {
      _id: 'san alhajar alqablia',
      label: 'san alhajar alqablia',
    },
  ],
  Sohag: [
    {
      _id: 'Akhmeem',
      label: 'Akhmeem',
    },
    {
      _id: 'Akhmim El Gedida',
      label: 'Akhmim El Gedida',
    },
    {
      _id: 'Albalina',
      label: 'Albalina',
    },
    {
      _id: 'Alkawthar',
      label: 'Alkawthar',
    },
    {
      _id: 'Dar AISalaam',
      label: 'Dar AISalaam',
    },
    {
      _id: 'El Maragha',
      label: 'El Maragha',
    },
    {
      _id: 'Gerga',
      label: 'Gerga',
    },
    {
      _id: 'Jahina Al Gharbia',
      label: 'Jahina Al Gharbia',
    },
    {
      _id: 'Saqilatuh',
      label: 'Saqilatuh',
    },
    {
      _id: 'Sohag',
      label: 'Sohag',
    },
    {
      _id: 'Sohag El Gedida',
      label: 'Sohag El Gedida',
    },
    {
      _id: 'Tahta',
      label: 'Tahta',
    },
    {
      _id: 'Tama',
      label: 'Tama',
    },
    {
      _id: 'almunsha\'a',
      label: 'almunsha\'a',
    },
  ],
  'South Sinai': [
    {
      _id: 'Abu Redis',
      label: 'Abu Redis',
    },
    {
      _id: 'Abu Zenaima',
      label: 'Abu Zenaima',
    },
    {
      _id: 'Al Toor',
      label: 'Al Toor',
    },
    {
      _id: 'Dahab',
      label: 'Dahab',
    },
    {
      _id: 'Nuweiba',
      label: 'Nuweiba',
    },
    {
      _id: 'Ras Sidr',
      label: 'Ras Sidr',
    },
    {
      _id: 'Saint Catherine',
      label: 'Saint Catherine',
    },
    {
      _id: 'Sharm El-Shaikh',
      label: 'Sharm El-Shaikh',
    },
    {
      _id: 'Taba',
      label: 'Taba',
    },
  ],
  Suez: [
    {
      _id: 'Ain Sokhna',
      label: 'Ain Sokhna',
    },
    {
      _id: 'Alganayen',
      label: 'Alganayen',
    },
    {
      _id: 'Ataqah',
      label: 'Ataqah',
    },
    {
      _id: 'Faysal',
      label: 'Faysal',
    },
    {
      _id: 'Suez',
      label: 'Suez',
    },
  ],
}
