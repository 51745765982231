import React, { useEffect, useMemo, useState } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useQuery } from 'react-query'
import { filter, path, pathOr, prop } from 'ramda'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { GET_ADMISSION } from '../../../gql/queries'
import Tabs from '../../core/Tabs'
import { getStudentTabCells } from './fields'
import { CardTab } from './InfoCardTab'
import { AdmissionTotals } from './AdmissionTotals'
import { ScoreCard } from './ScoreCard'
import { AdmissionModal } from './AdmissionModal'
import { ScoreModal } from './ScoreModal'
import { ExamsInfo } from './ExamsInfo'

export default function AdmissionPage ({ moduleStructure }) {
  const [show, setShow] = useState(false)
  const [showScoreModal, setShowScoreModal] = useState(false)

  const [currentActiveTab, setCurrentActiveTab] = useState(1)
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()

  const { isLoading: loading, data: fetchedData } = useQuery(['admission', key], () => request({
    query: GET_ADMISSION,
    variables: { _id: key },
    endpoint: endpoints[moduleStructure.endpoint],
  }))

  const admissionTotals = path(['getAdmission', 'totals'], fetchedData)
  const admissionPayments = pathOr([], ['getAdmission', 'payments', 'items'], fetchedData)

  useEffect(() => {
    action({ title: 'admission' })
  }, []) // eslint-disable-line

  const scoresData = useMemo(() => {
    const examsWithScores = filter(prop('score'), pathOr([], ['getAdmission', 'events'], fetchedData))
    return examsWithScores.map((e) => ({
      name: pathOr('-', ['event', 'name', 'en'], e),
      score: e.score,
    }))
  }, [fetchedData])

  const fields = useMemo(() => getStudentTabCells({
    onOpen: () => setShow(true),
    onAddScore: () => setShowScoreModal(true),
  }), [])

  if (loading) {
    return (
      <div style={{ width: '95%', position: 'relative', margin: 'auto' }}>
        <LinearProgress />
      </div>
    )
  }

  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={9} style={{ minHeight: '20rem' }}>
          {scoresData.length > 0
            ? (
              <Tabs currentActive={currentActiveTab} setCurrentActive={setCurrentActiveTab}>
                <div>
                  <span>Info</span>
                  <span>Score</span>
                </div>
                <div>
                  <CardTab fetchedData={fetchedData} cells={fields} />
                  <ScoreCard events={scoresData} admission={fetchedData?.getAdmission} />
                </div>
              </Tabs>
              )
            : (
              <CardTab fetchedData={fetchedData} cells={fields} />
              )}
        </Grid>
        <Grid item xs={12} md={3}>
          <AdmissionTotals
            totals={admissionTotals}
            payments={admissionPayments}
            currency={pathOr('EGP', ['getAdmission', 'round', 'currency'], fetchedData)}
            status={fetchedData?.getAdmission?.status}
          />
        </Grid>
        <Grid item xs={12}>
          <ExamsInfo fetchedData={fetchedData} />
        </Grid>
      </Grid>
      {show && <AdmissionModal admission={fetchedData?.getAdmission} onClose={() => setShow(false)} />}
      {showScoreModal &&
        <ScoreModal admission={fetchedData?.getAdmission} onClose={() => setShowScoreModal(false)} />}
    </div>
  )
}
