import React, { useEffect, useState } from 'react'
import { useSnackbar } from 'notistack'
import { path, prop } from 'ramda'
import { useMutation } from 'react-query'

import { EDIT_FULFILLMENT_WITH_ID } from '../../../gql/queries'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import SelectAsync from '../../core/SelectAsync'

const SelectStatus = ({ field, cell, record }) => {
  const { enqueueSnackbar } = useSnackbar()
  const { request } = useRequest()
  const [value, setValue] = useState()
  const [editFulfillment, { data, error: editError }] = useMutation((variables) => request({ query: EDIT_FULFILLMENT_WITH_ID, variables, endpoint: endpoints[field.endpoint] }))

  useEffect(() => {
    setValue(cell)
  }, [cell]) // eslint-disable-line

  const onChange = async (e) => {
    const val = e.target.value
    const previousValue = value
    setValue(val)
    if (val && !(val === value)) {
      console.log(path(['key'], record), 'key')
      await editFulfillment({ status: val, _id: path(['key'], record) })
      if (editError) {
        setValue(previousValue)
      } else if (prop('editFulfillment', data)) {
        enqueueSnackbar('Status updated successfully', { variant: 'success' })
      }
    }
  }

  console.log(record)

  return (
    <SelectAsync
      query={{ query: 'GET_FULFILLMENT_STATUS_LIST', endpoint: process.env.REACT_APP_ENDPOINT }}
      value={value}
      onChange={onChange}
    />
  )
}

export default SelectStatus
