import TextInput from './Text'
import NumberInput from './Number'
import SelectSyncInput from './SelectSync'
import ArrayInput from './Array'
import AutoCompleteInput from './AutoComplete'
import Button from './Button'
import CheckboxInput from './Checkbox'
import CustomAttributeInput from './CustomAttribute'
import DatePickerInput from './DatePicker'
import DateTimePickerInput from './DateTimePicker'
import ImagesUploadInput from './Images/UploadImages'
import RichTextInput from './RichText'
import SelectAsyncInput from './SelectAsync'
import UploadFilesInput from './UploadFiles'
import MapsComponent from './Map'

const CoreComponents = {
  text: TextInput,
  number: NumberInput,
  selectSync: SelectSyncInput,
  array: ArrayInput,
  autoComplete: AutoCompleteInput,
  button: Button,
  checkbox: CheckboxInput,
  customAttribute: CustomAttributeInput,
  DatePicker: DatePickerInput,
  DateTimePicker: DateTimePickerInput,
  uploadImages: ImagesUploadInput,
  richText: RichTextInput,
  selectAsync: SelectAsyncInput,
  uploadFiles: UploadFilesInput,
  Maps: MapsComponent,
}

export default CoreComponents
