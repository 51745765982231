import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Grid, IconButton, Typography } from '@material-ui/core'
import { nanoid } from 'nanoid'
import RemoveIcon from '@material-ui/icons/Remove'
import { propEq, reject } from 'ramda'
import Button from '../../core/Button/Button'
import AddIcon from '@material-ui/icons/Add'
import Text from '../../core/Text/Text'
import DragHandleIcon from '@material-ui/icons/DragHandle'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'

import arrayMove from 'array-move'

const DragHandle = SortableHandle(() => <DragHandleIcon style={{ cursor: 'pointer' }} />)

const CategoryItem = SortableElement(({ item, setItems }) => {
  const onRemove = () => setItems((items) => reject(propEq('id', item.id), items))

  const onChange = (e) => {
    const name = e.target.value
    setItems((items) => items.map((i) => i.id === item.id ? ({ ...i, name }) : i))
  }

  return (
    <Grid container item xs={12}>
      <Grid item xs={1} style={{ display: 'grid', placeItems: 'center' }}>
        <DragHandle />
      </Grid>
      <Grid item xs={10}>
        <Text disabled={item.disabled} value={item.name} onChange={onChange} />
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={onRemove}>
          <RemoveIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
})

const Container = SortableContainer(({ items, label, onAdd, setItems }) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant='h5'>
        {label}
      </Typography>
    </Grid>

    {items.map((item, index) => (
      <CategoryItem
        index={index}
        key={item.id}
        item={item}
        setItems={setItems}
      />
    ))}

    <Grid item xs={12}>
      <Button
        fullWidth
        variant='contained'
        color='secondary'
        onClick={onAdd}
      >
        <AddIcon />
        Add Category
      </Button>
    </Grid>
  </Grid>
))

const CMSCategories = React.forwardRef(({
  value,
  onChange,
  disabled = false,
  label = '',
  endpoint,
  onMounted,
}, ref) => {
  const initializedRef = useRef(false)
  const [items, setItems] = useState([])

  const onAdd = useCallback(() => {
    setItems((items) => [...items, { name: '', id: nanoid() }])
  }, [])

  const onSortEnd = useCallback(({ oldIndex, newIndex }) => {
    setItems((items) => arrayMove(items, oldIndex, newIndex))
  }, [])

  useEffect(() => {
    if (value && !initializedRef.current) {
      setItems(value.map((item) => ({ ...item, id: nanoid(), disabled: true })))
      initializedRef.current = true
    }
  }, [value])

  useEffect(() => {
    if (onMounted) onMounted(name)
  }, [onMounted, name])

  useEffect(() => {
    if (initializedRef.current) onChange?.(items, name)
  }, [items])

  return (
    <Container
      useDragHandle
      onSortEnd={onSortEnd}
      label={label}
      items={items}
      setItems={setItems}
      onAdd={onAdd}
    />
  )
})

export default CMSCategories
