import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { path } from 'ramda'

import Dialog from '../../core/Dialog'
import Button from '../../core/Button'
import AutoComplete from '../../core/AutoComplete'
import useRequest from '../../../helpers/useRequest'
import { ACCEPT_PRODUCT_REQUEST } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'

const AcceptProductRequest = ({ field, record, refetch = () => {} }) => {
  const [open, setOpen] = useState(false)
  const [product, setProduct] = useState()
  const [loading, setLoading] = useState(false)
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const onChange = (value) => {
    setProduct(value)
  }

  const accept = async () => {
    if (loading) return
    try {
      await request({ query: ACCEPT_PRODUCT_REQUEST, endpoint: endpoints[field.endpoint], variables: { _id: path(['key'], record), product } })
      setLoading(false)
      setOpen(false)
      refetch()
      enqueueSnackbar('Product Request Accepted Successfully', { variant: 'success' })
    } catch (err) {
      console.log(err)
      setLoading(false)
      if (path(['response', 'errors', 0, 'message'], err)) {
        enqueueSnackbar(path(['response', 'errors', 0, 'message'], err), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    }
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
      >
        Accept
      </Button>

      <Dialog label='Accept Product Request' open={open} onClose={() => setOpen(false)}>
        <AutoComplete
          name='product'
          label='Product'
          valueFormatter={(_, product) => product?.product?.key}
          displayFormatter={(_, product) => product?.product?.name?.en}
          onChange={onChange}
          searchQuery={{ query: 'GET_PRODUCTS_SLIM', endpoint: field.endpoint }}
          singleQuery={{ query: 'GET_PRODUCT_WITH_VARIATION_SLIM', endpoint: field.endpoint }}
        />

        <Button
          onClick={accept}
          variant='contained'
          style={{ margin: '2rem 0 0 0' }}
          fullWidth
          loading={loading}
        >
          Accept
        </Button>
      </Dialog>
    </>
  )
}

export default AcceptProductRequest
