import { Controller, FormProvider, useForm, useFormContext, useWatch } from 'react-hook-form'
import React, { useEffect, useMemo } from 'react'
import { isNil, last, map, omit, path, pathOr, propOr, reject } from 'ramda'
import Grid from '@material-ui/core/Grid'
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField } from '@material-ui/core'
import Button from '../../core/Button/Button'
import FormHelperText from '@material-ui/core/FormHelperText'
import SelectSync from '../../core/SelectSync/SelectSync'
import { useAddAssignment, useEditAssignment, useGetAssigment } from './data'
import getErrorMessages from '../../../helpers/getErrorMessages'
import { PROCTOR_ROLE_OPTIONS } from './constants'
import AutoComplete from '../../core/AutoComplete/AutoComplete'
import Typography from '@material-ui/core/Typography'
import LinearProgress from '@material-ui/core/LinearProgress'
import { ProctorAssignmentCells } from './fields'
import { makeStyles } from '@material-ui/core/styles'
import { useTextStyles } from '../Admission/InfoCardTab'
import { useGetExams } from '../Admission/AdmissionModal'
import Checkbox from '../../core/Checkbox'

const ProctorExam = () => {
  const { control } = useFormContext()
  const round = useWatch({ name: 'round', control })
  const { loading, allExams } = useGetExams(round)

  const role = useWatch({ name: 'role', control })
  const allExamOptions = useMemo(
    () => map((v) => ({ label: path(['name', 'en'], v), key: v._id }), allExams),
    [allExams],
  )
  return (
    <Grid container item={12} spacing={2}>
      <Grid item xs={6}>
        <Controller
          name='exam'
          render={({ value, onChange }) => (
            <SelectSync
              disabled={loading || !round}
              label='Exam'
              options={allExamOptions}
              margin='normal'
              value={value}
              onChange={(e) => onChange(e.target.value)}
            />
          )}
          rules={{ required: 'Exam is required' }}
          control={control}
        />
      </Grid>

      {role === 'regular' && (
        <Grid item xs={6}>
          <Controller
            name='room'
            control={control}
            render={({ value, onChange }) => (
              <TextField
                label='Room'
                fullWidth
                margin='none'
                value={value}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(e) => onChange(e.target.value)}
              />
            )}
          />
        </Grid>
      )}

      <Grid item xs={12}>
        {!round && (
          <FormHelperText style={{ color: 'red' }}>
            Please select a round before adding an exam
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  )
}

function ProctorAssignmentForm ({ player, assignmentId, onClose }) {
  const form = useForm({
    defaultValues: { role: 'regular' },
    reValidateMode: 'onChange',
    mode: 'all',
  })

  const { control, reset, errors, handleSubmit, setValue } = form

  const { add, loading: addLoading } = useAddAssignment()

  const { assignment, edit, loading: editLoading } = useEditAssignment(assignmentId)
  const selectAllCenters = useWatch({ name: 'selectAllCenters', control })

  useEffect(() => {
    reset(assignment)
    setTimeout(() => {
      // big yuck but whaever
      setValue('exam', assignment.exam)
    }, 0)
  }, [assignment])

  const errorMessages = getErrorMessages(errors)

  const onSubmit = (values) => {
    console.log('onSubmit', values)
    if (assignmentId) {
      edit({ ...values, _id: assignmentId, exams: map(omit(['id']), propOr([], 'exams', values)) })
        .then(onClose)
        .catch(() => {
        })
    } else {
      add({ ...values, player: values?.player ?? player, exams: map(omit(['id']), propOr([], 'exams', values)) })
        .then(onClose)
        .catch(() => {
        })
    }
  }
  const role = useWatch({ name: 'role', control })
  const isLead = role === 'lead'

  const loading = addLoading || editLoading

  return (
    <FormProvider {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={1}>
          {(!player && !assignmentId) && (
            <Grid item xs={6}>
              <Controller
                name='player'
                rules={{ required: 'Proctor is required' }}
                control={control}
                render={({ onChange, value }) => (
                  <AutoComplete
                    label='Proctor'
                    value={value}
                    onChange={onChange}
                    valueFormatter={(_, player) => player?.key}
                    displayFormatter={(_, player) => player?.nameObject.en}
                    searchQuery={{ query: 'GET_PROCTORS', endpoint: process.env.REACT_APP_ENDPOINT }}
                    singleQuery={{ query: 'GET_PLAYER', endpoint: process.env.REACT_APP_ENDPOINT }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={6}>
            <Controller
              name='role'
              control={control}
              defaultValue='regular'
              rules={{ required: 'Role is Required' }}
              render={({ value, onChange }) => (
                <SelectSync
                  label='Role'
                  value={value}
                  onChange={(e) => onChange(e.target.value)}
                  options={PROCTOR_ROLE_OPTIONS}
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <>
              {!selectAllCenters && (
                <Controller
                  name='centers'
                  rules={{ validate: (data) => reject(isNil, data).length > 0 ? true : 'Center is required' }}
                  control={control}
                  defaultValue={[]}
                  render={({ onChange, value }) => (
                    <AutoComplete
                      multiple
                      label={isLead ? 'Centers' : 'Center'}
                      value={isLead ? value : (value[0] ? [value[0]] : [])}
                      onChange={(value) => onChange(isLead ? value : [last(value)])}
                      valueFormatter={(_, center) => center?.key}
                      displayFormatter={(_, center) => center?.name.en}
                      searchQuery={{ query: 'GET_VENUES', endpoint: process.env.REACT_APP_ENDPOINT }}
                      singleQuery={{ query: 'GET_VENUE', endpoint: process.env.REACT_APP_ENDPOINT }}
                    />
                  )}
                />)}
              {isLead && <Controller
                name='selectAllCenters'
                control={control}
                defaultValue={false}
                render={({ onChange, value }) => (
                  <Checkbox
                    label='Select all centers'
                    value={value}
                    onChange={onChange}
                  />
                )}
                         />}
            </>
          </Grid>

          <Grid item xs={12}>
            <Controller
              name='round'
              rules={{ required: 'Round is required' }}
              control={control}
              render={({ onChange, value }) => (
                <AutoComplete
                  label='Round'
                  value={value}
                  onChange={(e) => {
                    setValue('exam', undefined)
                    onChange(e)
                  }}
                  valueFormatter={(_, round) => round?.key}
                  displayFormatter={(_, round) => round?.name.en}
                  searchQuery={{ query: 'GET_ROUNDS', endpoint: process.env.REACT_APP_ENDPOINT }}
                  singleQuery={{ query: 'GET_ROUND', endpoint: process.env.REACT_APP_ENDPOINT }}
                />
              )}
            />
          </Grid>

          <ProctorExam />

          {errorMessages.length > 0 && (
            <ul>
              {errorMessages.map((message, index) => (
                <Typography
                  style={{ color: 'red' }}
                  key={index}
                  component='li'
                  gutterBottom
                  align='left'
                >
                  {message}
                </Typography>
              ))}
            </ul>
          )}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Button loading={loading} type='submit' variant='contained'>Save</Button>
            <Button onClick={onClose}>Close</Button>
          </Grid>
        </Grid>
      </form>
    </FormProvider>
  )
}

export function ProctorAssignmentModal ({ onClose, assignmentId, player }) {
  return (
    <Dialog open onClose={onClose} maxWidth='lg' fullWidth>
      <DialogTitle>
        {assignmentId ? 'Edit Proctor Assignment' : 'Add Proctor Assignment'}
      </DialogTitle>
      <DialogContent>
        <ProctorAssignmentForm player={player} assignmentId={assignmentId} onClose={onClose} />
      </DialogContent>
    </Dialog>
  )
}

function AssignmentDetails ({ assignment }) {
  const textClasses = useTextStyles()

  return (
    <Grid container>
      {ProctorAssignmentCells.map(({ key, path: dataPath, formatter, value, fullWidth = false, showFn }) => {
        const cellValue = value || (formatter?.(path(dataPath, assignment), assignment) ?? pathOr('-', dataPath, assignment))
        const show = showFn?.(path(dataPath, assignment), assignment)
        if (typeof show === 'boolean' && !show) return null

        return (
          <Grid item xs={12} md={fullWidth ? 12 : 6} key={key}>
            <Typography className={textClasses.key}>{key}</Typography>
            <Typography className={textClasses.valuePrimary}>
              {cellValue}
            </Typography>
          </Grid>
        )
      })}
    </Grid>
  )
}

export function ProctorAssignmentDetailsModal ({ onClose, assignmentId }) {
  const { assignment, isLoading } = useGetAssigment(assignmentId, true)
  const dialogClasses = useDialogStyles()
  console.log({ assignmentData: assignment })

  return (
    <Dialog open onClose={onClose} maxWidth='sm' fullWidth>
      <DialogTitle>

        <DialogTitle>
          <Typography className={dialogClasses.title}>
            Proctor Assignment Details
          </Typography>
        </DialogTitle>
      </DialogTitle>
      <DialogContent>
        {isLoading && (
          <div style={{ width: '95%', position: 'relative', margin: 'auto' }}>
            <LinearProgress />
          </div>
        )}

        {assignment && <AssignmentDetails assignment={assignment} />}

      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}

const useDialogStyles = makeStyles((theme) => ({
  title: {
    color: theme.palette.text.primary,
    fontSize: '1.8rem',
  },
}))
