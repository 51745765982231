import React, { useEffect, useState, forwardRef } from 'react'
import { equals, flatten, reject, isNil } from 'ramda'
import Composite from './Composite'

const ObjectInput = forwardRef(({
  value,
  onChange = () => {},
  disabled = false,
  label = '',
  endpoint,
}, ref) => {
  const [state, setState] = useState(value || [])

  useEffect(() => {
    if (value && !equals(value, state)) handleChange(value)
  }, [value]) // eslint-disable-line

  const handleChange = (val, name) => {
    setState(reject(isNil, flatten(val)))
    onChange(reject(isNil, flatten(val)), name)
  }

  return (
    <Composite
      readOnly={disabled}
      title={label}
      value={state}
      ref={ref}
      onChange={handleChange}
      endpoint={endpoint}
    />
  )
})

export default ObjectInput
