import { Grid, IconButton, Typography } from '@material-ui/core'
import TextInput from '../../core/Text'
import React, { useCallback } from 'react'
import RemoveIcon from '@material-ui/icons/Remove'
import Button from '../../core/Button/Button'
import { nanoid } from 'nanoid'
import AddIcon from '@material-ui/icons/Add'
import { DynamicField } from './DynamicField'

const minZero = (v) => Math.max(parseInt(v, 10), 0)

const ArrayFieldItem = React.memo(({ setState, value }) => {
  const onChange = useCallback((newField) =>
    setState((state) => ({
      ...state,
      fields: state.fields.map((field) => field.id === newField.id ? newField : field),
    })), [])

  const remove = useCallback((id) =>
    setState((state) => ({
      ...state,
      fields: state.fields.filter((field) => field.id !== id),
    })), [])

  return (
    <>
      <Grid item xs={11}>
        <DynamicField hasArray={false} value={value} onChange={onChange} />
      </Grid>
      <Grid item xs={1}>
        <IconButton> <RemoveIcon onClick={() => remove(value.id)} /> </IconButton>
      </Grid>
    </>
  )
})

export const ArrayFields = ({ state, setValue, setState }) => {
  const addField = () =>
    setState((state) => ({
      ...state,
      fields: [...(state.fields ?? []), { name: '', label: '', type: 'text', id: nanoid() }],
    }))
  console.log({ fields: state.fields })
  return (
    <Grid container item={12} spacing={2}>
      <Grid item xs={6}>
        <TextInput
          inputType='number'
          label='min'
          onChange={setValue('min', minZero)}
          value={state.min ?? 0}
          helperText='min number of items a user can add'
        />
      </Grid>
      <Grid item xs={6}>
        <TextInput
          inputType='number'
          label='max'
          onChange={setValue('max', minZero)}
          value={state.max ?? 0}
          helperText='max number of items a user can add'
        />
      </Grid>

      <Grid item xs={12}>
        <Typography color='primary' variant='h5'>Fields: </Typography>
      </Grid>

      {state.fields?.map((field) => (
        <ArrayFieldItem key={field.id} value={field} setState={setState} />
      ))}

      <Grid item xs={12}>
        <Button
          fullWidth
          variant='contained'
          color='secondary'
          onClick={addField}
        >
          <AddIcon />
          Add {state.fields?.length > 0 && 'another'} field
        </Button>
      </Grid>
    </Grid>
  )
}
