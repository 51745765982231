
import React from 'react'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'

import * as Icons from '../../../Icons'

const ChevronLeft = Icons.ChevronLeft
const ChevronRight = Icons.ChevronRight

const SidebarToggleComponent = ({
  sidebarOpen = true,
  handleSidebarToggle = () => {},
}) => {
  const theme = useTheme()

  return (
    <>
      <Box display='flex' alignItems='center' justifyContent='center' style={{ height: '100%' }}>
        <Button
          onClick={handleSidebarToggle}
          fullWidth
          disableFocusRipple
          disableRipple
          disableTouchRipple
          style={{ height: '100%', color: theme.palette.text.secondary }}
        >
          {sidebarOpen ? <ChevronLeft /> : <ChevronRight />}
        </Button>
      </Box>
    </>
  )
}

export default SidebarToggleComponent
