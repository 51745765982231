import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { path, pathOr } from 'ramda'

import Button from '../../core/Button'
import useRequest from '../../../helpers/useRequest'
import { ACCEPT_ORDER_REQUEST } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import { ShippingSlotsModal } from '../../common/ShippingSlotsModal'

const AcceptOrderRequest = ({ field, record, refetch = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const [showSlotsModal, setShowSlotsModal] = useState(false)
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const shippingSlots = path(['shippingSlots'], record)

  const handleAccept = () => {
    if (shippingSlots?.length > 0) {
      setShowSlotsModal(true)
    } else {
      accept()
    }
  }

  const accept = async (selectedSlot) => {
    if (loading) return
    try {
      await request({
        query: ACCEPT_ORDER_REQUEST,
        endpoint: endpoints[field.endpoint],
        variables: { _id: path(['key'], record), selectedSlot },
      })
      refetch()
      setLoading(false)
      enqueueSnackbar('Order Request Accepted', { variant: 'success' })
    } catch (err) {
      setLoading(false)
      enqueueSnackbar(pathOr('Unexpected Error', ['response', 'errors', 0, 'message'], err), { variant: 'error' })
    }
  }

  return (
    <>
      <Button onClick={handleAccept} loading={loading}>
        Accept
      </Button>
      <ShippingSlotsModal
        open={showSlotsModal}
        onClose={() => setShowSlotsModal(false)}
        slots={shippingSlots}
        submit={accept}
      />
    </>
  )
}

export default AcceptOrderRequest
