export async function downloadFromUrl (url, fileName) {
  try {
    const response = await fetch(url)
    const blob = await response.blob()
    const blobUrl = URL.createObjectURL(blob)
    const link = document.createElement('a')

    link.download = fileName
    link.href = blobUrl

    link.click()

    URL.revokeObjectURL(blobUrl)
  } catch (error) {
    console.error(error)
  }
}
