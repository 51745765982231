import React from 'react'
import { path } from 'ramda'
import CheckoutPaymentStatus from '../CheckoutPaymentStatus'
import SelectFulfillmentStatusAction from '../SelectFulfillmentStatusAction'

const dateFormatter = (value) =>
  new Date(value).toLocaleString('en-us', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })

export const fulfilmentCells = [
  { key: 'Fulfillment ID', path: ['checkoutId'] },
  {
    key: 'totals',
    path: ['totals', 'total'],
    formatter: (value) =>
      value
        ? Number(value)
          .toFixed(2)
          .toString()
        : '-',
  },
  {
    key: 'status',
    path: [],
    formatter: (record) => (
      <SelectFulfillmentStatusAction
        field={{ endpoint: process.env.REACT_APP_ENDPOINT }}
        record={record}
        cell={path(['status'], record)}
      />
    ),
  },
  {
    key: 'Paid',
    formatter: (record) => (
      <CheckoutPaymentStatus
        field={{
          key: '_id',
          type: 'custom',
          label: 'Paid',
          name: 'CheckoutPaymentStatus',
          endpoint: 'SUP_MS',
          permissions: ['view.module.order'],
          sortOrder: 5,
        }}
        record={record}
      />
    ),
    path: [],
  },
  {
    key: 'Date',
    path: ['createdAt'],
    formatter: (num) => num ? dateFormatter(+num) : '-',
  },
  {
    key: 'Shipping Date',
    path: ['shippingDate'],
    formatter: (num) => num ? dateFormatter(+num) : '-',
  },
]

export const Customer = [
  { key: 'name', path: ['customer', 'name'] },
  { key: 'phone', path: ['customer', 'phone'] },
]

export const addressCells = [
  { key: 'Phone', path: ['shipping', 'phone'] },
  { key: 'Email', path: ['shipping', 'email'] },
  { key: 'Country', path: ['shipping', 'country'] },
  { key: 'State', path: ['shipping', 'state'] },
  { key: 'City', path: ['shipping', 'city'] },
  { key: 'Postal Code', path: ['shipping', 'postalCode'] },
  { key: 'Street', path: ['shipping', 'street'] },
  { key: 'Building', path: ['shipping', 'building'] },
  { key: 'Floor', path: ['shipping', 'floor'] },
  { key: 'Apartment', path: ['shipping', 'apartment'] },
]

export const supWarehouseAddressCells = [
  { key: 'Country', value: 'Emirates' },
  { key: 'City', value: 'Dubai' },
  { key: 'Area', value: 'Al QuozAl Quoz Industrial Area 2' },
  { key: 'Address', value: 'Warehouse 1 24B Street' },
]
