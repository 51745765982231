import React, { Fragment, useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import Dialog from './Dialog'
import * as Icons from '../../../Icons'
const DeleteIcon = Icons.Delete
const VisibilityIcon = Icons.Visibility
const ArrowBackIosIcon = Icons.ArrowBackIos
const ArrowForwardIosIcon = Icons.ArrowForwardIos

export default function ImageList ({ list = [], onChange = (list, index) => {}, disabled = false }) {
  const [innerList, setInnerList] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [selectedImage, setSelectedImage] = useState(false)

  useEffect(() => {
    if (Array.isArray(list)) setInnerList(list)
  }, [list])

  const onRemove = (index) => {
    setInnerList([...innerList.filter((item, i) => index !== i)])
    onChange([...innerList.filter((item, i) => index !== i)], index)
  }

  const onLeft = (index) => {
    if (index === 0) return
    const tempList = [...innerList]
    const tempValue = tempList[index]
    tempList[index] = tempList[index - 1]
    tempList[index - 1] = tempValue

    setInnerList([...tempList])
    onChange([...tempList])
  }

  const onRight = (index) => {
    if (index === innerList.length - 1) return
    const tempList = [...innerList]
    const tempValue = tempList[index]
    tempList[index] = tempList[index + 1]
    tempList[index + 1] = tempValue

    setInnerList([...tempList])
    onChange([...tempList])
  }

  return (
    <>
      <Dialog label='Image' open={dialogOpen} onClose={() => setDialogOpen(false)}><img src={selectedImage} alt='selected' /></Dialog>
      <Grid container spacing={1}>
        {
          list.map((listItem, index) =>
            <Grid item key={listItem + Math.random()}>
              <ImageCard
                src={listItem}
                onRemove={() => onRemove(index)}
                setDialogOpen={setDialogOpen}
                setSelectedImage={setSelectedImage}
                readOnly={disabled}
                onLeft={() => onLeft(index)}
                onRight={() => onRight(index)}
              />
            </Grid>)
        }
      </Grid>
    </>
  )
}

const ImageCard = (props) => {
  const outerClasses = useOuterStyles(props)
  const containerClasses = useContainerStyles(props)
  const contentClasses = useContentStyles(props)
  const iconsClasses = useIconsStyles()

  const viewImage = () => {
    props.setSelectedImage(props.src)
    props.setDialogOpen(true)
  }

  return (
    <div className={outerClasses.root}>
      <div className={containerClasses.root}>
        <div className={contentClasses.root}>
          <IconButton onClick={() => props.onLeft ? props.onLeft() : null} style={{ height: '100%' }} size='small'>
            <ArrowBackIosIcon className={iconsClasses.root} />
          </IconButton>
          <div>
            <IconButton onClick={viewImage} size='small'>
              <VisibilityIcon className={iconsClasses.root} />
            </IconButton>
            {!props.readOnly &&
              <IconButton onClick={() => props.onRemove ? props.onRemove() : null} size='small'>
                <DeleteIcon className={iconsClasses.root} />
              </IconButton>}
          </div>
          <IconButton onClick={() => props.onRight ? props.onRight() : null} style={{ height: '100%' }} size='small'>
            <ArrowForwardIosIcon className={iconsClasses.root} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

const useOuterStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    background: 'transparent',
    fontFamily: 'inherit',
    border: `1px solid ${theme.palette.grey[100]}`,
    width: 100,
    height: 100,
    position: 'relative',
    padding: 2,
  },
}))

const useContainerStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
    backgroundImage: (props) => `url(${props.src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      '& > div': {
        display: 'grid',
        opacity: 0.7,
      },
    },
  },
}))

const useContentStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    display: 'none',
    gridTemplateColumns: '1fr 3fr 1fr',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    transition: 'opacity 1s ease-out',
    opacity: 0,
    background: theme.palette.common.black,
  },
}))

const useIconsStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}))
