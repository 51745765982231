import React, { forwardRef } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import IconButton from '@material-ui/core/IconButton'

import getFields from '../../../helpers/getFormFields'
import * as Icons from '../../../Icons'

const RemoveIcon = Icons.Remove
const AddIcon = Icons.Add

const ArrayComponent = forwardRef(({
  control,
  name,
  dataIndex,
  label,
  helperText,
  moduleStructure,
}, ref) => {
  const { fields, append, remove } = useFieldArray({ control, name: dataIndex || name })
  const formMethods = useFormContext()
  const arrayFields = moduleStructure.fields.filter((field) => field.arrayKey === name).map((field) => ({ ...field, arrayKey: dataIndex || name }))
  const theme = useTheme()

  return (
    <div style={{ margin: '.7rem 0' }}>
      <Typography component='h5' variant='h5' style={{ color: theme.palette.info.main }}>
        {label}
      </Typography>

      {fields.map((item, index) => (
        <React.Fragment key={item.id}>
          <Grid container spacing={1}>
            <Grid item xs={11}>
              {
                getFields({ fields: arrayFields, itemInArray: item, indexInArray: index, formMethods, moduleStructure, noTabs: true })
              }
            </Grid>

            <Grid item xs={1}>
              <Box display='flex' alignItems='center' justifyContent='center' height='100%'>
                <IconButton onClick={() => remove(index)}>
                  <RemoveIcon color='secondary' />
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        </React.Fragment>
      ))}

      <Button
        color='primary'
        variant='outlined'
        disableElevation
        disableRipple
        fullWidth
        onClick={() => {
          const fields = arrayFields.map((field) => field.key)
          const defaultValues = fields.reduce((acc, item) => ({ ...acc, [item]: undefined }), {})
          append(defaultValues)
        }}
      >
        <AddIcon />
      </Button>

      <Typography variant='h6' component='label' style={{ color: theme.palette.info.main, fontWeight: 300 }}> {helperText} </Typography>
    </div>
  )
})

export default ArrayComponent
