export const LOGIN = `
  mutation login($username: String!, $password: String!, $accountType: String!) {
    login(
      username: $username,
      password: $password,
      accountType: $accountType,
    )
  }
`

export const GET_CURRENT_STAFF = `
  query getCurrentStaff {
    getCurrentStaff {
      _id
      name
    }
  }
`

export const GET_STAFFS = `
  query getStaffsUsernames {
    getStaffsUsernames
  }
`
