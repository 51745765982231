import React, { useEffect } from 'react'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import LinearProgress from '@material-ui/core/LinearProgress'
import * as endpoints from '../../../config/endpoints'
import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import { makeStyles } from '@material-ui/core/styles'
import { Grid, Container } from '@material-ui/core'
import { path, pathOr } from 'ramda'
import { GET_REQUEST } from '../../../gql/queries'

export default function SingleRequest ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()
  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()

  const { isLoading: loading, data: fetchedRequest, error } = useQuery(['trip', key], () => request({ query: GET_REQUEST, variables: { _id: key }, endpoint: endpoints[moduleStructure.endpoint] }))

  useEffect(() => {
    action({ title: 'requests' })
  }, []) // eslint-disable-line

  if (loading && !fetchedRequest) return <div style={{ width: '95%', position: 'relative', margin: 'auto' }}><LinearProgress /></div>
  if (error) return <div>failed to fetch request</div>

  return (
    <Container maxWidth='lg' style={{ padding: 0 }}>
      <div className={cardClasses.card}>
        <Grid container spacing={4}>
          <Grid item className='part' xs={12} md={4}>
            <div className={textClasses.key}>User</div>
            <div className={textClasses.value}>{pathOr('-', ['getRequest', 'user', 'phone'], fetchedRequest)}</div>
          </Grid>
          <Grid item className='part' xs={12} md={4}>
            <div className={textClasses.key}>Status</div>
            <div className={textClasses.value}>{pathOr('-', ['getRequest', 'status'], fetchedRequest)}</div>
          </Grid>
          <Grid item className='part' xs={12} md={4}>
            <div className={textClasses.key}>Payment Method</div>
            <div className={textClasses.value}>{pathOr('-', ['getRequest', 'paymentMethod'], fetchedRequest)}</div>
          </Grid>
        </Grid>
        <Grid container spacing={4}>
          <Grid item className='part' xs={12} md={4}>
            <div className={textClasses.key}>estimated delivery amount</div>
            <div className={textClasses.value}>{pathOr(0, ['getRequest', 'estimatedDeliveryAmount'], fetchedRequest).toFixed(2)}</div>
          </Grid>
          <Grid item className='part' xs={12} md={4}>
            <div className={textClasses.key}>estimated driver earnings</div>
            <div className={textClasses.value}>{pathOr(0, ['getRequest', 'estimatedDriverFees'], fetchedRequest).toFixed(2)}</div>
          </Grid>
          <Grid item className='part' xs={12} md={4}>
            <div className={textClasses.key}>Delivery Time</div>
            <div className={textClasses.value}>
              {
                path(['getRequest', 'deliveryTime'], fetchedRequest)
                  ? new Date(Number(path(['getRequest', 'deliveryTime'], fetchedRequest))).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                  : '-'
              }
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={textClasses.mainHeader}>STEPS</div>
      <div className={cardClasses.card} style={{ minHeight: '13rem' }}>
        {Array.isArray(path(['getRequest', 'steps'], fetchedRequest)) && pathOr('-', ['getRequest', 'steps'], fetchedRequest).map((step) => (
          <div key={step.name}>

            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>Name</div>
                <div className={textClasses.value}>{pathOr('-', ['name'], step)}</div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>type</div>
                <div className={textClasses.value}>{pathOr('-', ['type'], step)}</div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>status</div>
                <div className={textClasses.value}>{pathOr('-', ['status'], step)}</div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>expected amount range</div>
                <div className={textClasses.value}>{pathOr(0, ['expectedAmountRange', 'min'], step).toFixed(2)} - {pathOr(0, ['expectedAmountRange', 'max'], step).toFixed(2)}</div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>order</div>
                <div className={textClasses.value}>{pathOr('-', ['order'], step)}</div>
              </Grid>
              <Grid item className='part' xs={12} md={4}>
                <div className={textClasses.key}>comment</div>
                <div className={textClasses.value}>{pathOr('-', ['comment'], step)}</div>
              </Grid>
            </Grid>
            <Grid container spacing={4}>
              <Grid item className='part' xs={12} md={12}>
                <div className={textClasses.key}>content</div>
                <div className={textClasses.value}>{pathOr('-', ['content'], step)}</div>
              </Grid>
            </Grid>
          </div>
        ))}
      </div>
    </Container>
  )
}

const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.6rem',
  },
  subKey: {
    color: theme.palette.info.main,
    fontSize: '1.5rem',
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
    fontWeight: 300,
  },
  valueRight: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
    fontWeight: 300,
    textAlign: 'end',
  },
  mainHeader: {
    color: theme.palette.info.main,
    fontSize: '2rem',
    fontWeight: 400,
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  header: {
    color: theme.palette.info.main,
    fontSize: '1.7rem',
    marginTop: '.56rem',
    marginBottom: '3.6rem',
  },
  totalsKey: {
    fontSize: '1.6rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

const useCardStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    padding: '2rem',
    marginTop: '1.5rem',
    minHeight: '13rem',
  },
  divider: {
    background: '#82b3d626',
    color: '#82b3d626',
    height: 1,
    margin: 'auto',
    marginTop: '1rem',
    marginBottom: '1rem',
    width: '65%',
  },
}))
