import React, { useState, useEffect, forwardRef } from 'react'
import { useQuery } from 'react-query'
import { makeStyles } from '@material-ui/core/styles'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import FormHelperText from '@material-ui/core/FormHelperText'

import useRequest from '../../../helpers/useRequest'
import * as gql from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'

const SelectInputBase = forwardRef(({
  label = '',
  error = false,
  helperText = ' ',
  fullWidth = true,
  onChange = () => {},
  onBlur = () => {},
  onMounted = () => {},
  value = '',
  disabled = false,
  multiple = false,
  classes = undefined,
  query,
  variables = {},
  name = '',
  className,
}) => {
  useEffect(() => onMounted(name), []) // eslint-disable-line
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState([])
  const defaultClasses = useSelectStyles()
  const { request } = useRequest()
  const { data: optionsData } = useQuery([name || 'selectAsync', variables], async () => await request({ query: gql[query.query], variables, endpoint: endpoints[query.endpoint] }))

  useEffect(() => {
    if (optionsData) {
      if (Array.isArray(optionsData[Object.keys(optionsData)[0]])) {
        setOptions(optionsData[Object.keys(optionsData)[0]])
      } else if (Array.isArray(optionsData[Object.keys(optionsData)[0]].items)) {
        setOptions(optionsData[Object.keys(optionsData)[0]].items)
      }
    }
  }, [optionsData])

  return (
    <div>
      <FormControl fullWidth={fullWidth}>
        <InputLabel>{label}</InputLabel>
        <Select
          multiple={multiple}
          open={open}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          value={value || (multiple ? [] : '')}
          onChange={onChange}
          onBlur={onBlur}
          classes={classes}
          className={`${defaultClasses.root} ${className || ''}`}
          fullWidth
          disabled={disabled}
        >
          {options.map((option) => typeof option === 'string' ? <MenuItem key={option} value={option}>{option.toUpperCase()}</MenuItem> : <MenuItem key={option.value} value={option.value}>{option.label?.toUpperCase()}</MenuItem>)}
        </Select>
        <FormHelperText style={{ ...error && { color: 'red' } }}>{helperText}</FormHelperText>
      </FormControl>
    </div>
  )
})

export default SelectInputBase

const useSelectStyles = makeStyles(() => ({
  root: {
  },
}))
