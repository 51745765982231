import React from 'react'
import { useQuery } from 'react-query'
import { pathOr } from 'ramda'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Box from '@material-ui/core/Box'
import Skeleton from '@material-ui/lab/Skeleton'

import StorefrontIcon from '@material-ui/icons/Storefront'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows'

import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { SUP_GET_TOTALS_REPORT } from '../../../gql/queries'

export default function Totals ({ moduleStructure, minDate, maxDate }) {
  const { request } = useRequest()
  const variables = { ...(minDate && maxDate) && { dateRange: { min: `${new Date(minDate).setHours(0, 0, 0, 0).valueOf()}`, max: `${new Date(maxDate).setHours(23, 59, 59, 0).valueOf()}` } } }
  const { isLoading, data } = useQuery(['sup_reports_totals', variables], () => request({ query: SUP_GET_TOTALS_REPORT, variables, endpoint: endpoints[moduleStructure.endpoint] }))
  const classes = useStyles()

  return (
    <>
      <Box display='flex' justifyContent='space-between' flexWrap='wrap'>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant='h5' component='h4'>
                  TOTAL PURCHASING
                </Typography>
                <Typography className={classes.pos}>
                  {isLoading ? <Skeleton /> : pathOr(0, ['getSupTotalsReport', 'purchases'], data)}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <StorefrontIcon />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant='h5' component='h4'>
                  TOTAL EXPENSE
                </Typography>
                <Typography className={classes.pos}>
                  {isLoading ? <Skeleton /> : `${pathOr(0, ['getSupTotalsReport', 'expanses'], data).toFixed(2)} AED`}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <LocalOfferIcon />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
        <Card className={classes.root}>
          <CardContent className={classes.content}>
            <Grid container>
              <Grid item xs={10}>
                <Typography variant='h5' component='h4'>
                  MARKETPLACE VALUE
                </Typography>
                <Typography className={classes.pos}>
                  {isLoading ? <Skeleton /> : `${pathOr(0, ['getSupTotalsReport', 'marketplaceValue'], data).toFixed(2)} AED`}
                </Typography>
              </Grid>
              <Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <DesktopWindowsIcon />
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  )
}

const useStyles = makeStyles({
  root: {
    marginTop: '1em',
    marginBottom: '1em',
    width: '330px',
  },
  content: {
    padding: '10px',
    '&:last-child': {
      'padding-bottom': '10px',
    },
  },
  pos: {
    color: 'gray',
    letterSpacing: '1px',
  },
})
