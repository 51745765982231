import { Quill } from 'react-quill'

import 'react-quill/dist/quill.snow.css'
import ImageResize from 'quill-image-resize-module-react'
import VideoResize from '@jarios71/quill-video-resize-module'

Quill.register('modules/imageResize', ImageResize)
Quill.register('modules/VideoResize', VideoResize)

const BaseImageFormat = Quill.import('formats/image')
const BaseVideoFormat = Quill.import('formats/video')

const ImageFormatAttributesList = new Set([
  'alt',
  'height',
  'width',
  'style',
])

function formats (domNode, attributesList) {
  return [...attributesList].reduce((formats, attribute) => {
    if (domNode.hasAttribute(attribute)) {
      formats[attribute] = domNode.getAttribute(attribute)
    }

    return formats
  }, {})
}

class ImageFormat extends BaseImageFormat {
  static formats (domNode) {
    return formats(domNode, ImageFormatAttributesList)
  }

  format (name, value) {
    if (ImageFormatAttributesList.has(name)) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

const VideoFormatAttributesList = new Set([
  'alt',
  'height',
  'width',
  'style',
])

class VideoFormat extends BaseVideoFormat {
  static formats (domNode) {
    return formats(domNode, VideoFormatAttributesList)
  }

  format (name, value) {
    if (VideoFormatAttributesList.has(name)) {
      if (value) {
        this.domNode.setAttribute(name, value)
      } else {
        this.domNode.removeAttribute(name)
      }
    } else {
      super.format(name, value)
    }
  }
}

Quill.register(ImageFormat, true)
Quill.register(VideoFormat, true)
