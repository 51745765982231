import React, { forwardRef, useEffect, useState } from 'react'
import TextInput from '../../core/Text'
import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@material-ui/core'
import SelectSync from '../../core/SelectSync/SelectSync'
import { SelectFields } from './DynamicSelectFields'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { UniversityFields } from './UniversityFields'
import { SubjectsFields } from './SubjectsFields'
import { pick, lensPath, set } from 'ramda'
import { ArrayFields } from './ArrayFields'
import { ExamFields } from './ExamFields'

const noop = () => {
}

const types = [
  'text',
  'select',
  'university',
  'nationality',
  'date',
  'subjects',
  'exam',
  'city',
  'district',
  'round',
  'address',
]

const typesWithArray = [...types, 'array']

export const DynamicField = forwardRef(({
  value,
  onChange = noop,
  disabled = false,
  label = '',
  hasArray = true,
  endpoint,
}, ref) => {
  const [state, setState] = useState({ name: '', label: '', type: 'text', ...value })

  const [expanded, setExpanded] = useState(!(state.label || state.name))

  useEffect(() => {
    onChange(state)
  }, [state])

  const setValue = (name, formatter) => (e) => {
    setState((s) => {
      const val = e.target.value
      const nameLens = lensPath(name.split('.'))
      const updatedState = set(nameLens, formatter ? formatter(val) : val, s)

      return name === 'type' ? pick(['name', 'label', 'type', 'id'], updatedState) : updatedState
    })
  }
  const name = state.label || state.name

  return (
    <Accordion expanded={expanded} style={{ marginBottom: '1rem' }}>
      <AccordionSummary
        onClick={() => name && setExpanded((e) => !e)}
        expandIcon={!!name && <ExpandMoreIcon />}
      >
        <Typography>{name || (`Unnamed ${state.type} field`)}</Typography>
      </AccordionSummary>
      <AccordionDetails>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextInput
              name='name'
              value={state.name}
              onChange={setValue('name')}
              label='Name'
              helperText='This is the name that will show up in the export'
            />
          </Grid>
          <Grid item xs={6}>
            <TextInput
              name='label'
              onChange={setValue('label')}
              value={state.label}
              label='Label'
              helperText='This is the name that will show up to the students'
            />
          </Grid>
          <Grid item xs={12}>
            <SelectSync
              value={state.type}
              onChange={setValue('type')}
              label='Field Type'
              name='type'
              fullWidth
              options={hasArray ? typesWithArray : types}
            />
          </Grid>

          {state.type === 'exam' && (
            <ExamFields setValue={setValue} state={state} />
          )}

          {state.type === 'select' && (
            <SelectFields
              setValue={setValue}
              state={state}
              setState={setState}
            />
          )}

          {state.type === 'university' && (
            <UniversityFields setValue={setValue} state={state} />
          )}

          {state.type === 'subjects' && (
            <SubjectsFields setValue={setValue} state={state} />
          )}

          {state.type === 'array' && (
            <ArrayFields setState={setState} setValue={setValue} state={state} />
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  )
})
