import React, { useMemo } from 'react'
import Grid from '@material-ui/core/Grid'
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import Typography from '@material-ui/core/Typography'
import Table from '../../../core/Table'
import { path, pathOr, propOr } from 'ramda'
import { CardCells } from '../InfoCardTab'
import { makeStyles } from '@material-ui/core/styles'
import { ExamInfoFields, subjectsTable } from './examInfoFields'

const useGetExamsInfo = (fetchedData) => useMemo(() => {
  const events = pathOr([], ['getAdmission', 'events'], fetchedData)

  return events
    .map((event) => ({
      ...event,
      tickets: propOr([], 'tickets', event)
        .map((t) => ({
          ticket: t,
          ...t.checkedIn && {
            checkedIn: {
              originalRoom: event.room,
              ...t.checkedIn,
            },
          },
          ...t.checkedOut && { checkedOut: t.checkedOut },
          price: propOr(path(['ticket', 'price'], t), 'price', t),
          name: path(['ticket', 'name'], t),
          isPaid: t.isPaid,
        })),
    }))
}, [fetchedData])

export function ExamsInfo ({ fetchedData }) {
  const styles = useTextStyles()
  const events = useGetExamsInfo(fetchedData)

  return (
    <Grid container xs={12} style={{ marginTop: 8, gap: 8 }}>
      {events.map((e) => (
        <Grid item xs={12} key={e._id}>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon className={styles.summary} />} className={styles.summary}>
              <Typography variant='h5'>{e.event.name.en}</Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container xs={12}>
                <Grid item xs={12} style={{ padding: '0 1rem' }}>
                  <CardCells spacing={2} data={e} cells={ExamInfoFields} textClasses={styles} />
                </Grid>

                <Table fields={subjectsTable} records={e.tickets} className={styles.table} />
              </Grid>
            </AccordionDetails>
          </Accordion>

        </Grid>
      ))}
    </Grid>
  )
}

export const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.6rem',
  },
  value: {
    color: theme.palette.text.primary,
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  summary: {
    color: theme.palette.text.secondary,
    background: theme.palette.secondary.main,
  },
  table: {
    height: 'auto !important',
  },
}))
