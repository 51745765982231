import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { path } from 'ramda'

import Button from '../../core/Button'
import useRequest from '../../../helpers/useRequest'
import { REJECT_PRODUCT_REQUEST } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'

const RejectProductRequest = ({ field, record, refetch = () => {} }) => {
  const [loading, setLoading] = useState(false)
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const reject = async () => {
    if (loading) return
    try {
      await request({ query: REJECT_PRODUCT_REQUEST, endpoint: endpoints[field.endpoint], variables: { _id: path(['key'], record) } })
      setLoading(false)
      refetch()
      enqueueSnackbar('Product Request Rejected')
    } catch (err) {
      console.log(err)
      setLoading(false)
      if (path(['response', 'errors', 0, 'message'], err)) {
        enqueueSnackbar(path(['response', 'errors', 0, 'message'], err), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    }
  }

  return (
    <>
      <Button
        onClick={reject}
        loading={loading}
        disabled={path(['status'], record) !== 'pending'}
      >
        Reject
      </Button>
    </>
  )
}

export default RejectProductRequest
