
import React, { useState } from 'react'
import { useStateMachine } from 'little-state-machine'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'

import UserMenu from './UserMenu'
import * as Icons from '../../../Icons'
const AccountCircleIcon = Icons.AccountCircle

export default function Topbar () {
  const classes = useStyles()
  const [menuAnchor, setMenuAnchor] = useState()
  const { state } = useStateMachine()

  return (
    <AppBar
      position='static'
      className={classes.appBar}
    >
      <Toolbar>
        <Grid container alignItems='center' justify='center'>
          <Grid item xs={6}>
            <Typography variant='h4' component='h4' noWrap className={classes.h1}>
              {state.title}
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Box display='flex' alignItems='center' justifyContent='flex-end'>
              {false && <Box style={{ borderRight: '1px solid #5d616a' }} display='flex' alignItems='center' />}
              <AccountCircleIcon className={classes.settings} onClick={(e) => setMenuAnchor(e.currentTarget)} />
              <UserMenu anchor={menuAnchor} setAnchor={setMenuAnchor} />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[26],
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
  },
  h1: {
    textTransform: 'uppercase',
    color: theme.palette.text.secondary,
    fontWeight: 400,
  },
  img: {
    width: '3rem',
    height: '3rem',
    borderRadius: '50px',
    marginRight: '.5rem',
    marginTop: '.25rem',
    verticalAlign: 'top',
  },
  settings: {
    marginLeft: 10,
    fontSize: theme.typography.h3.fontSize,
    cursor: 'Pointer',
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
}))
