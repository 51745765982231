import React from 'react'
import { useTheme, makeStyles } from '@material-ui/core/styles'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'

import * as Icons from '../../../Icons'
const CloseIcon = Icons.Close

export default function DialogComponent ({
  label = '',
  open = false,
  onClose = () => {},
  contentHeight,
  contentTopSpacing,
  style,
  children,
  actions,
}) {
  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'))
  const dialogClasses = useDialogStyles()
  const dialogTitleClasses = useDialogTitleStyles()
  const dialogTitleTypographyClasses = useDialogTitleTypographyStyles()
  const iconButtonClasses = useIconButtonStyles()

  return (
    <>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={() => onClose()}
        fullWidth
        classes={dialogClasses}
        scroll='paper'
        disableBackdropClick
      >
        <DialogTitle classes={dialogTitleClasses}>
          <IconButton classes={iconButtonClasses} onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
          <Typography classes={dialogTitleTypographyClasses}>{label}</Typography>
        </DialogTitle>

        <DialogContent style={{ height: contentHeight, paddingTop: contentTopSpacing, ...style }}>
          {children}
        </DialogContent>

        <DialogActions>
          {actions}
        </DialogActions>
      </Dialog>
    </>
  )
}

const useDialogStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 16,
    background: theme.palette.background,
  },
}))

const useDialogTitleStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    textTransform: 'uppercase',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: 500,
    letterSpacing: '0',
    backgroundColor: theme.palette.secondary.main,
  },
}))

const useDialogTitleTypographyStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    textTransform: 'uppercase',
    fontSize: theme.typography.h4.fontSize,
    color: theme.palette.text.secondary,
    fontWeight: 500,
    letterSpacing: '0',
  },
}))

const useIconButtonStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
}))
