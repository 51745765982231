import React, { useEffect } from 'react'
import useRequest from '../../../helpers/useRequest'
import { useStateMachine } from 'little-state-machine'
import { useParams } from 'react-router-dom'
import { setTitle } from '../../../store/actions'
import { useQuery } from 'react-query'
import { GET_GUIDE_BOOK_REQUEST } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import { CardTab } from '../Admission/InfoCardTab'
import { requestInfoCells } from './fields'
import { Grid } from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'
import { RequestFieldTable } from './RequestFieldTable'

export default function GuideBookRequestPage ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()

  const { isLoading: loading, data: fetchedData } = useQuery(
    ['bookRequest', key],
    () => request({
      query: GET_GUIDE_BOOK_REQUEST,
      variables: { _id: key },
      endpoint: endpoints[moduleStructure.endpoint],
    }))

  useEffect(() => {
    action({ title: 'request' })
  }, []) // eslint-disable-line

  if (loading) {
    return (
      <div style={{ width: '95%', position: 'relative', margin: 'auto' }}>
        <LinearProgress />
      </div>
    )
  }
  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} style={{ minHeight: '20rem' }}>
          <CardTab fetchedData={fetchedData} cells={requestInfoCells} />
        </Grid>
        <Grid container item xs={12}>
          <RequestFieldTable shippingAddress={fetchedData.getGuideBookRequest.shippingAddress} />
        </Grid>
      </Grid>
    </div>
  )
}
