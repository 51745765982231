import React, { forwardRef, useEffect, useState, Fragment } from 'react'
import Grid from '@material-ui/core/Grid'
import { isEmpty, equals, pluck } from 'ramda'
import { useMutation } from 'react-query'

import AutoComplete from '../../core/AutoComplete'
import { GET_SORTED_TAXONOMIES } from '../../../gql/queries'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'

const Composite = forwardRef(({ endpoint, ...props }, ref) => {
  const [componentValue, setComponentValue] = useState()
  const [state, setState] = useState([null])
  const [parentState, setParentState] = useState()
  const { request } = useRequest()
  const [fetchData, { data }] = useMutation(() => request({ query: GET_SORTED_TAXONOMIES, variables: { taxonomies: props.value }, endpoint: endpoints[endpoint] }))

  useEffect(() => {
    if ((props.value && !isEmpty(props.value) && !equals(props.value, componentValue))) {
      fetchData()
      setComponentValue(props.value)
    }
  }, [props.value]) // eslint-disable-line

  useEffect(() => {
    if (data && data.sortTaxonomies) {
      const sortedTaxonomies = data.sortTaxonomies
      setParentState(sortedTaxonomies.parent._id)
      const pluckedSortedTaxonomies = []
      sortedTaxonomies.children.forEach((child) => {
        pluckedSortedTaxonomies.push(pluck('_id', child))
      })
      setState([...pluckedSortedTaxonomies, null])
    }
  }, [data]) // eslint-disable-line

  const handleParentChange = (val) => {
    setState([null])
    setParentState(val)
    triggerChange([val])
  }

  const handleValueChange = (i, val) => {
    let newState = state.map((child, index) => (i !== index) ? child : val)
    newState = newState.slice(0, i + 1)
    if (newState[i] && newState[i][0]) newState = [...newState, null]
    setState(newState)
    triggerChange([parentState, ...newState])
  }

  const triggerChange = (newValue) => {
    if (props.onChange) props.onChange(newValue)
  }

  const children = state.map((child, index) => {
    return (
      <Grid key={index} item xs={12}>
        <AutoComplete
          name='child'
          label='Sub Category'
          valueFormatter={(_, taxonomy) => taxonomy?.key}
          multiple
          displayFormatter={(_, taxonomy) => taxonomy?.name?.en}
          dependencyValue={index ? { parent: state[index - 1] } : parentState ? { parent: [parentState] } : undefined}
          value={state[index] || undefined}
          onChange={(value) => handleValueChange(index, value)}
          searchQuery={{ query: 'GET_TAXONOMIES_SLIM', endpoint: endpoint }}
          singleQuery={{ query: 'GET_TAXONOMY', endpoint: endpoint }}
          module='taxonomy'
        />
      </Grid>
    )
  })

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AutoComplete
            valueFormatter={(_, taxonomy) => taxonomy?.key}
            displayFormatter={(_, taxonomy) => taxonomy?.name?.en}
            dependencyValue={{ parent: [undefined] }}
            value={parentState}
            onChange={handleParentChange}
            searchQuery={{ query: 'GET_TAXONOMIES_SLIM', endpoint: endpoint }}
            singleQuery={{ query: 'GET_TAXONOMY', endpoint: endpoint }}
            module='taxonomy'
            name='parent'
            ref={ref}
            label='Parent Category'
          />
        </Grid>
        {children}
      </Grid>
    </>
  )
})

export default Composite
