import React, { useState, useMemo } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Typography, DialogContentText } from '@material-ui/core'
import Button from '../../core/Button/Button'
import { useQuery } from 'react-query'
import useRequest from '../../../helpers/useRequest'
import { GET_EXAM_COMMENTS } from '../../../gql/queries'
import { reduceBy } from 'ramda'
import Table from '../../core/Table'
import * as endpoints from '../../../config/endpoints'

const ExamCommentField = ({ record }) => {
  const [show, setShow] = useState(false)
  const { request } = useRequest()

  const { data } = useQuery(
    ['examComments', record._id],
    () => request({ query: GET_EXAM_COMMENTS, endpoint: endpoints[process.env.REACT_APP_ENDPOINT], variables: { exam: record._id } }))

  const handleClick = () => {
    setShow(!show)
  }
  const subjects = record.tickets ?? []
  const toSubject = (comment) => {
    return subjects.filter((subject) => subject?._id === comment?.subject?._id)?.[0]?.name ?? 'No Exam Name'
  }
  const groupComments = (acc, { comment, room, proctorAssignment, createdAt }) => {
    return acc.concat({ comment, room, createdAt: dateFormatter(createdAt), proctor: proctorAssignment?.player?.name })
  }
  const dateFormatter = (value) => {
    return new Date(parseInt(value)).toLocaleString('en-us', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    })
  }

  const refactoredData = useMemo(() => {
    if (data?.getEventComments) {
      return reduceBy(groupComments, [], toSubject, data.getEventComments.items)
    }
    return []
  }, [data])
  const noCommentStyle = {
    color: 'black',
    display: 'flex',
    justifyContent: 'center',
    padding: '50px',
  }
  return (
    <div>
      <Button onClick={handleClick}>Click Here</Button>
      {show &&
        <Dialog open={open} onClose={handleClick}>
          {Object.keys(refactoredData).map((key) => (refactoredData[key].length !== 0 &&
            <div key={key}>
              <DialogTitle>
                <Typography variant='h5'>{key}</Typography>
              </DialogTitle>
              <DialogContent>
                <Table fields={fieldsTable} records={refactoredData[key]} />
              </DialogContent>
            </div>))}
          {data?.getEventComments.totalCount === 0 && <DialogContentText style={noCommentStyle}>No Comments</DialogContentText>}
          <DialogActions>
            <Button onClick={handleClick}>Close</Button>
          </DialogActions>
        </Dialog>}

    </div>
  )
}

const fieldsTable = [
  {
    label: 'Proctor',
    key: 'proctor',
  },
  {
    label: 'Comment',
    key: 'comment',
  },
  {
    label: 'Room',
    key: 'room',
  },
  {
    label: 'time',
    key: 'createdAt',
  },
]
export default ExamCommentField
