import React, { forwardRef, useState, useEffect, Fragment, useCallback } from 'react'
import { equals, pathOr } from 'ramda'
import Grid from '@material-ui/core/Grid'

import Select from '../SelectSync'
import Text from '../Text'
import Upload from '../UploadFiles'
import RichText from '../RichText'

const CustomAttributes = forwardRef(({
  value,
  name,
  label,
  disabled = false,
  onChange = () => {},
}, ref) => {
  const [state, setState] = useState(value || { key: '', value: undefined, valueType: 'text' })
  const [initialValue, setInitialValue] = useState(value || { key: '', value: undefined, valueType: 'text' })
  const [initialValueFlag, setInitialValueFlag] = useState(false)
  const [Comp, setComp] = useState(Text)
  const [stateUpdated, setStateUpdated] = useState(false)

  useEffect(() => {
    if (value && !equals(value, state)) {
      if (value.value || !initialValueFlag) {
        applyChange(value)
        setInitialValue(value)
        setInitialValueFlag(true)
      }
    }
  }, [value]) // eslint-disable-line

  useEffect(() => {
    if (!stateUpdated) {
      applyChange({ ...state, key: state.key })
      setStateUpdated(true)
    } else {
      setStateUpdated(false)
    }
  }, [state]) // eslint-disable-line

  const applyChange = useCallback((val) => {
    val = pathOr(val, ['target', 'value'], val)
    setState(val)
    if (onChange) onChange(val, name)
  }, [name, onChange])

  const onKeyChange = useCallback((val) => {
    applyChange({ ...state, key: pathOr(val, ['target', 'value'], val) })
  }, [state, applyChange])

  const TextArea = forwardRef((props, ref) => <Text ref={ref} textAreaSize={4} {...props} />)

  const onTypeChange = useCallback((val) => {
    val = pathOr(val, ['target', 'value'], val)
    if (val === 'text') setComp(Text)
    else if (val === 'upload') setComp(Upload)
    else if (val === 'text area') setComp(TextArea)
    else if (val === 'rich text') setComp(RichText)
    applyChange({ ...state, valueType: val })
  }, [applyChange, state])

  const onValueChange = useCallback((val) => {
    applyChange({ ...state, value: pathOr(val, ['target', 'value'], val) })
  }, [applyChange, state])

  const onValueMounted = useCallback((valueDataIndex) => {
    if (valueDataIndex === valueDataIndex?.value && !equals(state, initialValue)) {
      setState({ ...state, value: initialValue?.value })
    }
  }, [state, initialValue])

  const valueTypes = ['upload', 'text', 'text area', 'rich text']

  return (
    <>
      <Grid container alignItems='center' alignContent='center' justify='center' spacing={2}>
        <Grid item xs={6}>
          <Text value={state.key} name={name.name} label={label.key} disabled={disabled} onChange={onKeyChange} />
        </Grid>
        <Grid item xs={6}>
          <Select
            value={valueTypes.includes(state.valueType) ? state.valueType : 'text'}
            onChange={onTypeChange}
            options={valueTypes}
            name={name.valueType}
            label={label.valueType}
            disabled={disabled}
          />
        </Grid>
        <Grid item xs={12}>
          <Comp value={state.value} onMounted={onValueMounted} title={label.value} name={name.value} onChange={onValueChange} />
        </Grid>
      </Grid>
    </>
  )
})

export default CustomAttributes
