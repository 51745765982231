import React from 'react'
import { path } from 'ramda'

import definitions from '../../../config/definitions'

export const getSimpleVariations = () => {
  return [
    {
      label: 'Image',
      key: 'variation.images',
      formatField: (R, cell, record) => cell && cell.length > 0 ? <span><img style={{ height: '8vh', display: 'inline' }} src={`${definitions.cloudinaryUrl}${cell[0]}`} alt={path(['variation', 'product', 'name', 'en'], record)} /></span> : '-',
    },
    {
      label: 'Name',
      key: 'product.name.en',
    },
    {
      label: 'Quantity',
      key: 'quantity',
      formatField: (R, cell) => cell ? cell.toString() : '',
    },
    {
      label: 'Price',
      key: 'totalPrice',
      formatField: (R, cell) => cell ? Number(cell).toFixed(2).toString() : '',
    },
  ]
}
