import React, { useState } from 'react'
import { useSnackbar } from 'notistack'
import { path } from 'ramda'
import Dialog from '../../core/Dialog'
import Button from '../../core/Button'
import SelectSync from '../../core/SelectSync'
import Text from '../../core/Text'
import useRequest from '../../../helpers/useRequest'
import { APPROVE_ACCOUNT } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'

const ApprovalModal = ({ field, record, refetch = () => {} }) => {
  const [open, setOpen] = useState(false)
  const [status, setStatus] = useState()
  const [note, setNote] = useState()
  const [loading, setLoading] = useState(false)
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const updateStatus = async () => {
    if (loading) return
    try {
      await request({ query: APPROVE_ACCOUNT, endpoint: endpoints[field.endpoint], variables: { user: path(['key'], record), status, notes: '' } })
      setLoading(false)
      setOpen(false)
      refetch()
      enqueueSnackbar('Approval Status Updated Successfully', { variant: 'success' })
    } catch (err) {
      console.log(err)
      setLoading(false)
      if (path(['response', 'errors', 0, 'message'], err)) {
        enqueueSnackbar(path(['response', 'errors', 0, 'message'], err), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
    }
  }
  if (!path(['approved'], record)) return <p>no-account</p>
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
      >
        {path(['approved', 'status'], record)}
      </Button>
      <Dialog label='Approve/Reject User' open={open} onClose={() => setOpen(false)}>
        <SelectSync
          label='Status'
          value={status}
          onChange={(e) => setStatus(e.target.value)}
          options={['approved', 'rejected']}
        />
        <Text
          label='Notes'
          value={note}
          textAreaSize={4}
          onChange={(e) => setNote(e.target.value)}
        />
        <Button
          onClick={updateStatus}
          variant='contained'
          style={{ margin: '2rem 0 0 0' }}
          fullWidth
          loading={loading}
        >
          Update Status
        </Button>
      </Dialog>
    </>
  )
}
export default ApprovalModal
