import { prop, propOr, path, last } from 'ramda'
import { useMutation } from 'react-query'

import useUpload from './useUpload'
import useRequest from './useRequest'
import useFields from './useFields'
import * as endpoints from '../config/endpoints'
import * as gql from '../gql/queries'

export default function useAddForm ({ addMutations, editMutation, entity, entityIdPath, uploadFields = [] }) {
  const { request } = useRequest()
  const { emptyFields } = useFields()

  const [firstAddData, { isLoading: isLoading1, error: error1 }] = useMutation((variables) => request({ query: gql[addMutations[0].query], variables, endpoint: endpoints[addMutations[0].endpoint] }))
  const [secondAddData, { isLoading: isLoading2, error: error2 }] = useMutation((variables) => request({ query: gql[last(addMutations).query], variables, endpoint: endpoints[last(addMutations).endpoint] }))
  const addMutationsActions = [firstAddData, secondAddData]

  const [editData, { error: editError }] = useMutation((variables) => request({ query: gql[editMutation.query], variables, endpoint: endpoints[editMutation.endpoint] }))
  const uploadFn = useUpload({ entity, uploadFields })

  async function submit (passedValues, addMutationIndex = 0) {
    const addData = addMutationsActions[addMutationIndex]

    const paths = uploadFields.map((path) => path?.split('.'))
    let values = paths.reduce((acc, path) => emptyFields(path, acc), passedValues)
    const valuesWithFiles = { ...passedValues }

    const addedData = await addData(values)
    if (!addedData) return undefined

    const firstKey = Object.keys(addedData)[0]
    let entityId = propOr(prop('key', addedData[firstKey]), '_id', addedData[firstKey])
    if (!entityId) {
      entityId = propOr(prop('key', addedData[firstKey].player), '_id', addedData[firstKey].player)
    }
    if (entityIdPath && entityIdPath[addMutationIndex]) entityId = path(entityIdPath[addMutationIndex], addedData[Object.keys(addedData)[0]])

    values = await uploadFn(valuesWithFiles, entityId)

    const editedData = await editData({ ...values, _id: entityId })
    if (!editedData) return undefined

    return editedData
  }

  return [submit, { isLoading: isLoading1 || isLoading2, error: error1 || error2 || editError }]
}
