import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { formatAttributes } from './formatAttributes'
import Button from '../../core/Button/Button'
import SelectSync from '../../core/SelectSync/SelectSync'
import * as R from 'ramda'
import { dissoc, map, propOr } from 'ramda'
import TextInput from '../../core/Text'
import useRequest from '../../../helpers/useRequest'
import { useMutation } from 'react-query'
import { EDIT_ADMISSION_SERVICE_REQUEST_ATTRIBUTES } from '../../../gql/queries'
import { useParams } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { queryCache } from '../../../index'
import { governorateCities, governorates } from './locations-data'
import * as endpoints from '../../../config/endpoints'

const useStyles = makeStyles((theme) => ({
  container: {
    height: 'calc(100vh - 284px)',
  },
  bodyCell: {
    fontSize: theme.typography.h5.fontSize,
    height: 90,
    padding: 0,
    '& p': {
      margin: 0,
    },
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h5.fontSize,
    },
  },
  headerCell: {
    fontSize: theme.typography.h5.fontSize,
    padding: 0,
    height: 90,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.info.main,
  },
}))

const renameKeys = R.curry((keysMap, obj) =>
  R.reduce((acc, key) => R.assoc(keysMap[key] || key, obj[key], acc), {}, R.keys(obj)),
)

function AttributeCell ({ row, handleChange }) {
  const { field, attribute, value, key, name } = row

  /**
   * @param value {any}
   * @param valueText {string}
   * @param valueId {string}
   */
  const onChange = ({ value = undefined, valueText = undefined, valueId = undefined }) => {
    const attrId = attribute.attrId
    const { arrayId } = row
    const id = arrayId ? { arrayId, attrId } : attrId
    handleChange(id, { value, valueText, valueId })
  }

  if (field.type === 'select') {
    const options = R.map(renameKeys({ id: 'key', name: 'label' }), field.options)

    return (
      <SelectSync
        value={attribute.valueId}
        options={options}
        onChange={(e) => {
          const option = R.find(R.propEq('id', e.target.value), field.options)
          onChange({ valueId: option.id, valueText: option.name })
        }}
      />
    )
  }

  if (field.type === 'text') {
    return (
      <TextInput
        value={attribute.valueText}
        onChange={(e) => onChange({ valueText: e.target.value })}
      />
    )
  }

  if (field.type === 'address') {
    if (name.includes('address')) return value
    if (key === 'governorate') {
      return (
        <SelectSync
          value={value}
          options={governorates}
          onChange={(e) => {
            onChange({ value: { ...attribute.value, [key]: e.target.value, city: '' } })
          }}
        />
      )
    }
    if (key === 'city') {
      const goverment = attribute.value.governorate
      return (
        <SelectSync
          value={value}
          options={governorateCities[goverment].map((city) => ({ key: city._id, label: city.label }))}
          onChange={(e) => {
            onChange({ value: { ...attribute.value, [key]: e.target.value }, valueId: attribute.valueId })
          }}
        />
      )
    }
    return (
      <TextInput
        value={value}
        onChange={(e) => onChange({ value: { ...attribute.value, [key]: e.target.value }, valueId: attribute.valueId })}
      />
    )
  }

  return value
}

function RequestAttributesBody ({ attributesTable, editing, handleChange }) {
  const styles = useStyles()
  return attributesTable.map((attribute, index) => (
    <TableRow key={index}>
      <TableCell align='center' className={styles.bodyCell}>{attribute.name}</TableCell>
      <TableCell align='center' className={styles.bodyCell}>
        {editing ? <AttributeCell row={attribute} handleChange={handleChange} /> : attribute.value}
      </TableCell>
      <TableCell align='center' className={styles.bodyCell}>{attribute.price}</TableCell>
    </TableRow>
  ))
}

const formatAddressText = (addr) => {
  const note = propOr('', 'landmark', addr)
    .trim()

  return `
    ${addr.address}
    Apartment: ${addr.apartment}, Floor: ${addr.floor}
    ${addr.governorate}, ${addr.city}
    ${note ? `Note: ${note}` : ''}
  `.split('\n')
    .map((line) => line.trim())
    .join('\n')
    .trim() // gotta keep the addresses crispy clean
}

const isNumber = (value) => {
  return parseInt(value).toString() === value && value >= 0
}

const checkValidations = (addr) => {
  const errorArray = []
  if (!addr[0].value.apartment || !isNumber(addr[0].value.apartment)) errorArray.push('apartment number is invalid')
  if (!addr[0].value.floor || !isNumber(addr[0].value.floor)) {
    errorArray.push('Floor Number is invalid')
  }
  if (addr[0].value.phone) {
    const isPhoneMatch = addr[0].value.phone.match(/^01[0125]{1}[0-9]{8}$/)
    if (!isPhoneMatch) errorArray.push('phone is invalid')
  }
  if (addr[0].value.recipient) {
    const isArabic = addr[0].value.recipient.match(/^[ء-ي ]*$/)
    const isFullName = addr[0].value.recipient.split(' ').filter((v) => v.trim() !== '').length >= 3
    if (!isArabic) errorArray.push('recipient name should be arabic')
    if (!isFullName) errorArray.push('recipient name should be 3 parts')
  }
  if (addr[0].value.city === '') errorArray.push('city is required')
  if (addr[0].value.governorate === '') errorArray.push('governorate is required')
  if (addr[0].value.area === '') errorArray.push('area is required')
  if (addr[0].value.street === '') errorArray.push('street is required')

  return errorArray
}

export function RequestFieldTable ({ attributes, fields, handleChange }) {
  const { request } = useRequest()
  const { key } = useParams()
  const { enqueueSnackbar } = useSnackbar()

  const [edit, { isLoading: loading }] = useMutation(
    (variables) => request({
      query: EDIT_ADMISSION_SERVICE_REQUEST_ATTRIBUTES,
      variables,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    }), {
      onSuccess: () => queryCache.invalidateQueries(['request', key]),
    })

  const [editing, setEditing] = useState(false)
  const styles = useStyles()

  const attributesTable = useMemo(() => {
    if ((!fields || fields.length === 0) && attributes && attributes.length > 0) {
      return attributes.map((attr) => {
        return {
          name: attr.name,
          value: Object.entries(attr.value).map(([name, value]) => (
            <div key={name} style={{ padding: '5px' }}>
              {name}: <b style={{ paddingLeft: '8px' }}>{value}</b>
            </div>
          )),
        }
      })
    }

    const fieldsMap = Object.fromEntries(fields.map((f) => [f.field.name, f.field]))
    const entries = Object.entries(formatAttributes(fieldsMap, attributes))
    return entries.map(([name, value]) => ({ name, ...value }))
  }, [attributes, fields])

  const handleEdit = () => {
    if (!editing) {
      setEditing(true)
      return
    }

    const attrs = attributes.map((attr) => {
      if (attr?.name?.includes('shipping')) {
        return {
          ...attr,
          attrId: undefined,
          valueText: formatAddressText(attr.value),
        }
      }
      if (attr?.value?.attributes) {
        return {
          ...attr,
          attrId: undefined,
          value: { ...attr.value, attributes: map(dissoc('attrId'), attr.value.attributes) },
        }
      }

      return { ...attr, attrId: undefined }
    })
    const validationErrorArray = checkValidations(attrs.filter((obj) => { return obj.name.includes('shipping') }))
    if (validationErrorArray.length !== 0) return validationErrorArray.map((error) => enqueueSnackbar(error, { variant: 'error' }))

    edit({ _id: key, attributes: attrs })
      .then(() => {
        enqueueSnackbar('Saved successfully', { variant: 'success' })
        setEditing(false)
      })
  }
  return (
    <TableContainer className={styles.container}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell align='center' className={styles.headerCell}>Name</TableCell>
            <TableCell align='center' className={styles.headerCell}>Value</TableCell>
            <TableCell align='center' className={styles.headerCell}>
              Price (EGP)

              <Button
                variant='contained'
                style={{ marginLeft: 20, marginBottom: 10 }}
                onClick={handleEdit}
              >
                {editing ? (loading ? 'saving' : 'Save') : 'Edit'}
              </Button>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <RequestAttributesBody attributesTable={attributesTable} editing={editing} handleChange={handleChange} />
        </TableBody>
      </Table>
    </TableContainer>
  )
}
