import React, { useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { path, pathOr } from 'ramda'
import Typography from '@material-ui/core/Typography'
import { useForm } from 'react-hook-form'
import { useSnackbar } from 'notistack'
import { useHistory, useParams } from 'react-router-dom'

import Checkbox from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import Button from '../../core/Button'
import { makeStyles } from '@material-ui/core/styles'
import AutoComplete from '../../core/AutoComplete'
import { AdmissionEventFieldArray } from './AdmissionModal'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import * as Icons from '../../../Icons'
import { useMutation, useQuery } from 'react-query'
import { ADD_ADMISSION, GET_PLAYER } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import useRequest from '../../../helpers/useRequest'
import Input from '@material-ui/core/Input'

const StopRoundedIcon = Icons.StopRounded
const CropSquareRoundedIcon = Icons.CropSquareRounded

export const addESTAdmission = () => {
  const textClasses = useTextStyles()
  const formControlLabelClasses = useFormControlLabelStyles()
  const checkBoxClasses = useCheckBoxStyles()
  const iconClasses = useIconStyles()

  const { key: studentId } = useParams()
  const { enqueueSnackbar } = useSnackbar()
  const { request } = useRequest()
  const history = useHistory()

  const { register, handleSubmit, setValue, getValues, errors, watch, control } = useForm({
    defaultValues: {
      city: '',
      universities: [],
      district: '',
      round: '',
      allowSharing: false,
      hasSpecialNeeds: false,
    },
  })
  const events = watch('events')
  const selectedRound = watch('round')
  const selectedCity = watch('city')
  const selectedDistrict = watch('district')

  const { data: fetchedStudent } = useQuery(['student', studentId], () => request({
    query: GET_PLAYER,
    variables: { _id: studentId },
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
    enabled: !!studentId,
  }))
  const studentName = path(['getPlayer', 'name'], fetchedStudent) || pathOr(null, ['getPlayer', 'nameObject', 'en'], fetchedStudent)

  const [addAdmission, { isLoading, isSuccess, isError, error }] = useMutation(
    (variables) => request({
      query: ADD_ADMISSION,
      endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
      variables,
    }))

  useEffect(() => {
    register({ name: 'round' }, {
      required: 'Round is required',
    })
    register({ name: 'city' })
    register({ name: 'universities' })
    register({ name: 'district' })
    register({ name: 'hasSpecialNeeds' })
    register({ name: 'allowSharing' })
  }, [register])

  useEffect(() => {
    setValue('events', [])
  }, [selectedRound])
  useEffect(() => {
    setValue('district', '')
  }, [selectedCity])
  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Added successfully', { variant: 'success' })
      history.push('/admissions')
    } else if (isError && error.message) {
      enqueueSnackbar(path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const onCheckBoxChange = (event) => {
    const { name, checked } = event.target
    setValue(name, checked)
  }

  const onSubmit = async (data) => {
    const payload = {
      ...data,
      player: studentId,
      events: data.events.map((event) => ({
        ...event,
        tickets: typeof event.tickets === 'string' ? [{ ticket: event.tickets }] : event.tickets.map((ticket) => ({ ticket })),
      })),
    }
    addAdmission(payload)
  }

  return (
    <Grid
      container
      direction='column'
      alignItems='center'
      style={{ minHeight: '100vh' }}
    >
      <form onSubmit={handleSubmit(onSubmit)} style={{ width: '80%' }}>
        <Grid item style={{ paddingTop: '2.5rem' }}>
          <Typography component='h2' variant='h2' gutterBottom align='center' className={textClasses.mainHeader}>
            Add Admission
          </Typography>
        </Grid>
        {studentName && (
          <Grid item style={{ marginBottom: '16px' }}>
            <Typography component='h4' variant='h4' gutterBottom align='left' className={textClasses.label}>
              Student
            </Typography>
            <Input
              placeholder='Student'
              autoComplete='no'
              disabled
              value={studentName}
              fullWidth
            />
          </Grid>
        )}
        <Grid item style={{ marginBottom: '16px' }}>
          <AutoComplete
            name='city'
            label='City'
            valueFormatter={(_, city) => path(['key'], city)}
            displayFormatter={(_, city) => pathOr(name, ['name', 'en'], city)}
            value={getValues('city')}
            onChange={(value) => setValue('city', value)}
            searchQuery={{ query: 'GET_TEST_CITIES', endpoint: process.env.REACT_APP_ENDPOINT }}
            module='city'
          />
        </Grid>
        <Grid item style={{ marginBottom: '16px' }}>
          <AutoComplete
            name='district'
            label='District'
            valueFormatter={(_, district) => path(['key'], district)}
            displayFormatter={(_, district) => pathOr(name, ['name', 'en'], district)}
            value={selectedDistrict}
            onChange={(value) => setValue('district', value)}
            searchQuery={{ query: 'GET_TEST_DISTRICTS', endpoint: process.env.REACT_APP_ENDPOINT }}
            module='district'
            dependencyValue={{ city: selectedCity ? [selectedCity] : [] }}
          />
        </Grid>
        <Grid item style={{ marginBottom: '16px' }}>
          <AutoComplete
            label='Universities'
            multiple
            valueFormatter={(_, university) => path(['key'], university)}
            displayFormatter={(_, university) => pathOr(name, ['name', 'en'], university)}
            value={getValues('universities')}
            onChange={(value) => setValue('universities', value)}
            searchQuery={{ query: 'GET_UNIVERSITIES', endpoint: process.env.REACT_APP_ENDPOINT }}
            singleQuery={{ query: 'GET_UNIVERSITY', endpoint: process.env.REACT_APP_ENDPOINT }}
          />
        </Grid>
        <Grid item style={{ marginBottom: '16px' }}>
          <AutoComplete
            name='round'
            label='Round'
            valueFormatter={(_, round) => path(['key'], round)}
            displayFormatter={(_, round) => pathOr(name, ['name', 'en'], round)}
            value={getValues('round')}
            onChange={(value) => setValue('round', value, { shouldValidate: true })}
            searchQuery={{ query: 'GET_ROUNDS', endpoint: process.env.REACT_APP_ENDPOINT }}
            module='round'
            error={!!errors.round}
            helperText={errors?.round?.message}
          />
        </Grid>
        <Grid item style={{ marginBottom: '16px' }}>
          <AdmissionEventFieldArray control={control} setValue={setValue} />
          {(selectedRound && (errors?.events || !events?.length)) && <Typography style={{ color: 'red' }}>Exams and subjects are required.</Typography>}
        </Grid>
        <Grid item style={{ marginBottom: '16px' }}>
          <FormControlLabel
            classes={formControlLabelClasses}
            name='hasSpecialNeeds'
            control={
              <Checkbox
                classes={checkBoxClasses}
                onChange={onCheckBoxChange}
                icon={<CropSquareRoundedIcon className={iconClasses.root} />}
                checkedIcon={<StopRoundedIcon className={iconClasses.root} />}
                color='primary'
              />
              }
            label='Has Special Needs ?'
          />
        </Grid>
        <Grid item style={{ marginBottom: '16px' }}>
          <FormControlLabel
            classes={formControlLabelClasses}
            name='allowSharing'
            control={
              <Checkbox
                classes={checkBoxClasses}
                onChange={onCheckBoxChange}
                icon={<CropSquareRoundedIcon className={iconClasses.root} />}
                checkedIcon={<StopRoundedIcon className={iconClasses.root} />}
                color='primary'
              />
              }
            label='Allow Sharing ?'
          />
        </Grid>
        <Box display='flex' justifyContent='flex-end' style={{ marginTop: 40 }}>
          <Button
            color='primary'
            variant='contained'
            disableElevation
            type='submit'
            size='large'
            style={{
              margin: '0 1rem',
              height: '4.2rem',
              width: '9rem',
              fontSize: '2.5rem',
            }}
            loading={isLoading}
            disabled={!events?.length}
          >
            ADD
          </Button>
        </Box>
      </form>
    </Grid>
  )
}

const useTextStyles = makeStyles((theme) => ({
  mainHeader: {
    color: theme.palette.info.main,
    fontSize: '2rem',
    fontWeight: 400,
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  label: {
    color: theme.palette.info.main,
    fontSize: '1rem',
    fontWeight: 400,
    marginBottom: '1rem',
  },
}))

const useFormControlLabelStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    margin: 0,
    paddingTop: 10,
  },
  label: {
    marginLeft: 5,
    paddingTop: 3,
    fontFamily: 'inherit',
    color: theme.palette.text.primary,
  },
}))

const useCheckBoxStyles = makeStyles((theme) => ({
  root: {
    textTransform: 'uppercase',
    fontFamily: 'Barlow Condensed',
    fontWeight: 300,
    color: theme.palette.primary.main,
    boxShadow: theme.shadows[3],
    borderRadius: 0,
    padding: 0,
  },
}))

const useIconStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 3,
    padding: 3,
    borderStyle: 'solid',
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
  },
}))
