import React, { useEffect, useState } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useQuery } from 'react-query'
import { path, pathOr } from 'ramda'
import { useParams } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import Typography from '@material-ui/core/Typography'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Table from '../../core/Table'

import Tabs from '../../core/Tabs'
import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { EDIT_ORDER, GET_ORDER_CLEVERSELL } from '../../../gql/queries'
import Button from '../../core/Button/Button'
import { ShippingSlotsModal } from '../../common/ShippingSlotsModal'
import { useSnackbar } from 'notistack'
import EditGemmaOrderStatus from '../EditGemmaOrderStatus'
import { getSimpleVariations } from './fields'

export default function GemmaSingleOrder ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()
  const [currentActiveTab, setCurrentActiveTAb] = useState(1)
  const { isLoading: loading, data: fetchedData } = useQuery(['order', key], () => request({ query: GET_ORDER_CLEVERSELL, variables: { _id: key }, endpoint: endpoints[moduleStructure.endpoint] }))
  const [showShippingModal, setShowShippingModal] = useState(false)
  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()
  const { enqueueSnackbar } = useSnackbar()

  const selectSlot = async (slot) => {
    try {
      await request({
        query: EDIT_ORDER,
        endpoint: endpoints[moduleStructure.endpoint],
        variables: { _id: key, selectedSlot: slot },
      })
      enqueueSnackbar('Slot Selected', { variant: 'success' })
    } catch (err) {
      enqueueSnackbar(pathOr('Unexpected Error', ['response', 'errors', 0, 'message'], err), { variant: 'error' })
    }
  }

  const slots = path(['getOrder', 'shippingSlots'], fetchedData)

  useEffect(() => {
    action({ title: 'orders' })
  }, []) // eslint-disable-line

  if (loading) return <div style={{ width: '95%', position: 'relative', margin: 'auto' }}> <LinearProgress /> </div>

  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ minHeight: '28rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <Tabs currentActive={currentActiveTab} setCurrentActive={setCurrentActiveTAb}>
                <div>
                  <span>Order summary</span>
                  <span>Address</span>
                  <span>Info</span>
                </div>
                <div>
                  <div className={cardClasses.card}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>ORDER ID </Typography>
                        <Typography className={textClasses.value}>
                          {path(['getOrder', 'orderId'], fetchedData)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Status</Typography>
                        <EditGemmaOrderStatus field={moduleStructure} cell={path(['getOrder', 'status'], fetchedData)} record={path(['getOrder'], fetchedData)} />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Payment Method</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getOrder', 'paymentMethod'], fetchedData)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Date</Typography>
                        <Typography className={textClasses.value}>
                          {
                            path(['getOrder', 'createdAt'], fetchedData)
                              ? new Date(path(['getOrder', 'createdAt'], fetchedData)).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                              : '-'
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Customer</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getOrder', 'player', 'name'], fetchedData)}</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getOrder', 'player', 'phone'], fetchedData)}</Typography>
                      </Grid>

                    </Grid>
                  </div>
                  <div className={cardClasses.card}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>Shipping</Typography>
                        <Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Phone:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'phone'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Email:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'email'], fetchedData)} </Typography>
                          <br />
                          <Typography variant='overline' className={textClasses.subKey}>Country:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'country'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>State:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'state'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>City:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'city'], fetchedData)} </Typography>
                          <br />
                          <Typography variant='overline' className={textClasses.subKey}>Postal Code:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'postalCode'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Street:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'street'], fetchedData)} </Typography>
                          <br />
                          <Typography variant='overline' className={textClasses.subKey}>Building:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'building'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Floor:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'floor'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Apartment:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'shipping', 'apartment'], fetchedData)} </Typography>
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>Billing</Typography>
                        <Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Phone:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'phone'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Email:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'email'], fetchedData)} </Typography>
                          <br />
                          <Typography variant='overline' className={textClasses.subKey}>Country:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'country'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>State:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'state'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>City:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'city'], fetchedData)} </Typography>
                          <br />
                          <Typography variant='overline' className={textClasses.subKey}>Postal Code:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'postalCode'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Street:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'street'], fetchedData)} </Typography>
                          <br />
                          <Typography variant='overline' className={textClasses.subKey}>Building:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'building'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Floor:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'floor'], fetchedData)} </Typography>
                          <Typography variant='overline' className={textClasses.subKey}>Apartment:</Typography> <Typography variant='overline' className={textClasses.value}> {pathOr('-', ['getOrder', 'billing', 'apartment'], fetchedData)} </Typography>
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={cardClasses.card}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>Shipping Date</Typography>
                        <Typography className={textClasses.value}>
                          {
                            path(['getOrder', 'shippingDate'], fetchedData)
                              ? new Date(Number(path(['getOrder', 'shippingDate'], fetchedData))).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                              : '-'
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>Shipping Method</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getOrder', 'shippingMethod'], fetchedData)}</Typography>
                      </Grid>
                      {slots?.length > 0 &&
                        <Grid item xs={12} md={6}>
                          <Typography className={textClasses.key}>Shipping Slots</Typography>
                          <Button onClick={() => setShowShippingModal(true)}>Show shipping slots</Button>
                          <ShippingSlotsModal
                            open={showShippingModal}
                            slots={slots}
                            selectedSlot={path(['getOrder', 'selectedSlot'], fetchedData)}
                            submit={selectSlot}
                            onClose={() => setShowShippingModal(false)}
                          />
                        </Grid>}
                    </Grid>
                  </div>
                </div>
              </Tabs>
            </Grid>
            <Grid item xs={12} md={3}>
              <div className={cardClasses.card} style={{ height: '24.37rem' }}>
                <Typography className={textClasses.header}>Payment</Typography>
                <div>
                  <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={4}>
                      <Typography className={textClasses.totalsKey}>Subtotal</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display='flex' justifyContent='flex-end'><Typography className={textClasses.totalsKey}>{Number(pathOr(0, ['getOrder', 'totals', 'subtotal'], fetchedData)).toFixed(2)}</Typography></Box>
                    </Grid>
                  </Grid>
                  <Divider className={cardClasses.divider} />
                  <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={4}>
                      <Typography className={textClasses.totalsKey}>Shipping</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display='flex' justifyContent='flex-end'><Typography className={textClasses.totalsKey}>{Number(pathOr(0, ['getOrder', 'totals', 'shipping'], fetchedData)).toFixed(2)}</Typography></Box>
                    </Grid>
                  </Grid>
                  <Divider className={cardClasses.divider} />
                  <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={4}>
                      <Typography className={textClasses.totalsKey}>Margin</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display='flex' justifyContent='flex-end'><Typography className={textClasses.totalsKey}>{Number(pathOr(0, ['getOrder', 'totals', 'margin'], fetchedData)).toFixed(2)}</Typography></Box>
                    </Grid>
                  </Grid>
                  <Divider className={cardClasses.divider} />
                  <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={4}>
                      <Typography className={textClasses.totalsKey}>Discount</Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box display='flex' justifyContent='flex-end'>
                        <Typography className={textClasses.totalsKey}>
                          {Number(pathOr(0, ['getOrder', 'totals', 'discount', 'amount'], fetchedData)).toFixed(2)}
                          {path(['getOrder', 'totals', 'discount', 'reference'], fetchedData)
                            ? ` (${path(['getOrder', 'totals', 'discount', 'reference', 'code'], fetchedData)} - ${path(['getOrder', 'totals', 'discount', 'reference', 'valueType'], fetchedData) === 'Percentage'
                            ? `${path(['getOrder', 'totals', 'discount', 'reference', 'value'], fetchedData)}%)`
                            : `${path(['getOrder', 'totals', 'discount', 'reference', 'value'], fetchedData)})`}`
                            : ''}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                  <Divider className={cardClasses.divider} />
                  <Grid container justify='space-between' alignItems='center'>
                    <Grid item xs={4}>
                      <Typography className={textClasses.totalsKey}>Total</Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Box display='flex' justifyContent='flex-end'><Typography className={textClasses.totalsKey}>{Number(pathOr(0, ['getOrder', 'totals', 'total'], fetchedData)).toFixed(2)}</Typography></Box>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid>
            <Table fields={getSimpleVariations()} records={path(['getOrder', 'products'], fetchedData)} />
          </Grid>
        </Grid>
      </Grid>
    </div>
  )
}

const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.3rem',
  },
  subKey: {
    color: theme.palette.info.main,
    fontSize: '1.2rem',
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: '1.2rem',
    fontWeight: 300,
  },
  header: {
    color: theme.palette.info.main,
    fontSize: '1.7rem',
    marginTop: '.56rem',
    marginBottom: '3.6rem',
  },
  totalsKey: {
    fontSize: '1.2rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

const useCardStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    padding: '1rem',
    minHeight: '18rem',
  },
  divider: {
    background: 'gray',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}))
