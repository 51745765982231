import { useParams } from 'react-router-dom'
import useRequest from '../../../helpers/useRequest'
import { useMutation, useQuery } from 'react-query'
import { GET_PAYMENTS_TRANSACTIONS, GET_PAYMOB_TRANSACTIONS, MARK_ADMISSION_AS_PAID } from '../../../gql/queries'
import React, { useMemo } from 'react'
import * as R from 'ramda'
import { path, pathOr } from 'ramda'
import { useTextStyles } from './InfoCardTab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Dialog,
  DialogContent,
  DialogTitle,
  makeStyles,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import Button from '../../core/Button/Button'
import Table from '../../core/Table'
import { OrderItemsTable, transactionDetails } from './fields'
import Grid from '@material-ui/core/Grid'
import { queryCache } from '../../../index'
import * as endpoints from '../../../config/endpoints'

const useFetchPaymobTransactions = (paymentIds, admissionId) => {
  const { request } = useRequest()

  const { data: transactionsData } = useQuery(['payments_transactions', admissionId], () => request({
    query: GET_PAYMENTS_TRANSACTIONS(paymentIds),
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
  }))

  const externalReferences = useMemo(() => transactionsData ? R.pluck('externalReference', R.flatten(R.values(R.pluck('items', transactionsData)))) : null, [transactionsData])

  const {
    data: paymobTransactions,
  } = useQuery(['paymob_transactions', admissionId], () => request({
    query: GET_PAYMOB_TRANSACTIONS(externalReferences),
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
  }), { enabled: externalReferences })

  return paymobTransactions
}

function TransactionDetails ({ cells, record }) {
  const textClasses = useTextStyles()

  return (
    <Grid container alignContent='center' alignItems='center'>
      {cells.map(({ key, path: dataPath, formatter }) => (
        <Grid item xs={12} md={3} key={key} style={{ textAlign: 'center' }}>
          <Typography className={textClasses.key}>{key}</Typography>
          <Typography className={textClasses.value}>
            {formatter?.(path(dataPath, record)) ?? pathOr('-', dataPath, record)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

const formatTransactions = (transactions) => {
  const getFields = R.pick(['id', 'pending', 'amount_cents', 'success', 'is_refund', 'is_auth', 'is_capture', 'is_standalone_payment', 'is_voided', 'order', 'created_at', 'currency'])

  const getStatus = (value) => {
    if (value?.is_refund) return 'refund'
    if (value?.pending) return 'pending'
    if (value?.is_voided) return 'voided'
    if (value?.success) return 'successful'

    return 'failed'
  }

  const format = (row) => ({
    ...row,
    amount: `${Number((row.amount_cents ?? 0) / 100)
      .toFixed(2)} ${row.currency}`,
    status: getStatus(row),
    items: R.path(['order', 'items'], row),
  })

  return R.map(R.pipe(getFields, format), R.values(transactions))
}

const useStyles = makeStyles(() => ({
  table: {
    height: '100% !important',
  },
}))

export function PaymentTable ({ onClose, open, records }) {
  const classes = useStyles()
  const textClasses = useTextStyles()
  const { key: admissionId } = useParams()

  const { request } = useRequest()
  const transactions = useFetchPaymobTransactions(R.pluck('_id', records), admissionId)

  const [markAsPaid, { isLoading: markAsPaidLoading }] = useMutation(() => request({
    query: MARK_ADMISSION_AS_PAID,
    variables: { _id: admissionId },
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
  }), {
    onSuccess: () => queryCache.invalidateQueries(['admission', admissionId], { refetchActive: true }),
  })

  const formattedTransactions = formatTransactions(transactions)

  return (
    <Dialog open={open} fullWidth maxWidth='md' onClose={onClose}>

      <DialogTitle>
        <div style={{ textAlign: 'center', display: 'flex' }}>
          <Typography
            className={textClasses.header}
            style={{ alignSelf: 'center', marginBottom: 0 }}
          >Payments
          </Typography>
          <Button
            loading={markAsPaidLoading}
            onClick={markAsPaid}
            variant='contained' style={{
              alignSelf: 'center',
              margin: '0 0 0 auto',
              padding: '1rem',
            }}
          >
            Mark As Paid
          </Button>
        </div>
      </DialogTitle>

      <DialogContent>
        {formattedTransactions.map((transaction) => (
          <Accordion key={transaction.id}>
            <AccordionSummary>
              <TransactionDetails cells={transactionDetails} record={transaction} />
            </AccordionSummary>
            <AccordionDetails>
              <Table fields={OrderItemsTable} records={transaction?.items} className={classes.table} />
            </AccordionDetails>
          </Accordion>
        ))}
      </DialogContent>

    </Dialog>
  )
}
