import React, { useState } from 'react'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Tabs from '@material-ui/core/Tabs'
import Tab from '@material-ui/core/Tab'
import Grid from '@material-ui/core/Grid'
import useMediaQuery from '@material-ui/core/useMediaQuery'

export default function VerticalTabs ({
  tabs = [], // { key, label, content }
  inModal = false,
}) {
  const theme = useTheme()
  const tabsClasses = useTabsStyles()
  const tabClasses = useTabStyles()
  const [selectedIndex, setSelectedIndex] = useState(0)
  const lowerThanSM = useMediaQuery(theme.breakpoints.down('sm'))

  const handleChange = (event, newValue) => {
    setSelectedIndex(newValue)
  }

  const isSmall = lowerThanSM || inModal

  return (
    <>
      <Grid container justify='center' spacing={2}>
        <Grid item xs={isSmall ? 12 : 4} sm={isSmall ? 12 : 2}>
          <Tabs
            orientation={isSmall ? 'horizontal' : 'vertical'}
            centered
            value={selectedIndex}
            indicatorColor='primary'
            onChange={handleChange}
            classes={isSmall ? null : tabsClasses}
            scrollButtons='auto'
          >
            {
              tabs.map((tab) => (
                <Tab classes={tabClasses} key={tab.key} label={tab.label} />
              ))
            }
          </Tabs>
        </Grid>
        <Grid item xs={inModal ? 12 : 8} sm={inModal ? 12 : 10}>
          {
            tabs.map((tab, index) => (
              <Grid key={tab.key} style={{ display: selectedIndex === index ? 'flex' : 'none' }} container justify='center' direction='column'>
                {tab.content}
              </Grid>
            ))
          }
        </Grid>
      </Grid>
    </>
  )
}

const useTabsStyles = makeStyles((theme) => ({
  root: {
    borderRight: `1px solid ${theme.palette.divider}`,
    maxWidth: 265,
  },
}))

const useTabStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.body1.fontSize,
  },
}))
