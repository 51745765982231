import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import Checkbox from '../../core/Checkbox/Checkbox'
import TextInput from '../../core/Text'
import { lensPath, set } from 'ramda'

const minZero = (v) => Math.max(v, 0)

// used because there's a bug with the initial value of the state,
// the value on the initial render is undefined, so we need to set the initial value to the default value
// and so that we can later compare with the default value
const DEFAULT_VALUE = { hasFees: false, price: 0 }

const noop = () => {
}

function HasExtraFeesForRound ({ value, onChange = noop }) {
  const [state, setState] = useState(DEFAULT_VALUE)
  const setValue = (name, formatter) => (e) => {
    setState((prevState) => {
      const val = e.target.value
      const nameLens = lensPath(name.split('.'))
      return set(nameLens, formatter ? formatter(val) : val, prevState)
    })
  }

  useEffect(() => {
    if (Object.is(state, DEFAULT_VALUE) && value) {
      setState(value)
    }
  }, [value])

  useEffect(() => {
    onChange(state)
  }, [state])

  return (
    <>
      <Grid item xs={6}>
        <Checkbox
          value={Boolean(state.hasFees)}
          label='Has Round Fees ?'
          onChange={setValue('hasFees')}
        />
      </Grid>

      {state.hasFees && (
        <Grid item xs={12}>
          <TextInput
            inputType='number'
            label='price'
            onChange={setValue('price', minZero)}
            value={state.price ?? 0}
            helperText='the price of round '
          />
        </Grid>
      )}
    </>
  )
}

export default HasExtraFeesForRound
