import React, { Fragment } from 'react'
import * as R from 'ramda'
import { makeStyles, lighten, useTheme } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableContainer from '@material-ui/core/TableContainer'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import CheckboxInput from '@material-ui/core/Checkbox'
import Box from '@material-ui/core/Box'
import LinearProgress from '@material-ui/core/LinearProgress'

// import BulkAction from './BulkAction'
import getTableFields from '../../../helpers/getTableFields'

export default function StickyHeadTable ({
  fields = [],
  onDelete = () => {},
  onVerify = () => {},
  records = [],
  isLoading = false,
  hasSelect,
  selected = [],
  setSelected,
  bulkActions,
  onBulkActionSuccess,
  refetch,
  className = '',
  nestedRecordsKey,
  nestedRecordsFields = [],
}) {
  const classes = useStyles()
  const checkboxClasses = useCheckboxStyles()
  const theme = useTheme()

  const columns = getTableFields({ fields, onDelete, onVerify, refetch, theme })

  // const BulkActions = Array.isArray(bulkActions) ? bulkActions.map((bulkAction) => <BulkAction key={bulkAction.title} {...bulkAction} ids={selected} onSuccess={onBulkActionSuccess} />) : null

  const handleSelect = (key, e) => {
    let newSelected = []
    if (e.target.checked) {
      newSelected = [...selected, key]
    } else {
      newSelected = selected.filter((item) => item !== key)
    }
    setSelected(newSelected)
  }

  const handleSelectAll = (e) => {
    if (e.target.checked) setSelected([...R.pluck('key', records)])
    else setSelected([])
  }

  return (
    <>
      {isLoading ? <LinearProgress /> : <div style={{ height: 4 }} />}
      <TableContainer className={classes.container + ' ' + className}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {!hasSelect
                ? null
                : (
                  <TableCell className={classes.headerCell} padding='checkbox'>
                    <CheckboxInput
                      classes={checkboxClasses}
                      color='primary'
                      indeterminate={selected.length > 0 && records.length > selected.length}
                      checked={selected.length > 0 && records.length === selected.length}
                      onChange={(e) => handleSelectAll(e)}
                    />
                  </TableCell>
                  )}
              {selected.length
                ? null
                : columns.map((column) => (
                  <TableCell
                    key={column.key}
                    align='center'
                    className={classes.headerCell}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              {!selected.length
                ? null
                : (
                  <TableCell
                    colSpan={columns.length}
                    className={classes.headerCell}
                  >
                    <Box display='flex' justifyContent='flex-end'>
                      {/* {BulkActions} */}
                    </Box>
                  </TableCell>
                  )}
            </TableRow>
          </TableHead>
          <TableBody>
            {records.map((row) => {
              return (
                <Fragment key={row.key}>
                  <TableRow hover role='checkbox' tabIndex={-1} className={`${selected.indexOf(row.key) > -1 ? classes.selectedRow : ''}`}>
                    {!hasSelect
                      ? null
                      : (
                        <TableCell padding='checkbox'>
                          <CheckboxInput
                            color='primary'
                            classes={checkboxClasses}
                            disableRipple
                            onChange={(e) => handleSelect(row.key, e)}
                            checked={selected.indexOf(row.key) > -1}
                          />
                        </TableCell>
                        )}
                    {columns.map((column) => {
                      const value = R.path(column.key.split('.'), row)
                      return (
                        <TableCell key={column.key} align='center' className={classes.bodyCell}>
                          {column.format ? column.format(value, row) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                  {
                    nestedRecordsKey && nestedRecordsFields.length && (
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                          <Table size='small' style={{ height: 'unset' }}>
                            <TableHead>
                              <TableRow>
                                {
                                  nestedRecordsFields.map((field) => (
                                    <TableCell key={field.key} align='center' className={classes.headerCell} style={{ height: 'unset', fontSize: '16px' }}>
                                      {field.label}
                                    </TableCell>
                                  ))
                                }
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {(R.path(nestedRecordsKey.split('.'), row)).map((nestedRow, index) => (
                                <TableRow key={index}>
                                  {
                                    nestedRecordsFields.map((field) => {
                                      const value = R.path(field.key.split('.'), R.path(nestedRecordsKey.split('.'), row)[index])
                                      return (
                                        <TableCell key={field.key} align='center'>
                                          {field.format ? field.format(value, row) : value}
                                        </TableCell>
                                      )
                                    })
                                  }
                                </TableRow>
                              ))}
                            </TableBody>
                          </Table>
                        </TableCell>
                      </TableRow>
                    )
                  }
                </Fragment>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  container: {
    height: 'calc(100vh - 284px)',
  },
  bodyCell: {
    fontSize: theme.typography.h5.fontSize,
    height: 90,
    padding: 0,
    '& p': {
      margin: 0,
    },
    '& svg': {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h5.fontSize,
    },
  },
  selectedRow: {
    backgroundColor: lighten(theme.palette.secondary.main, 0.1),
  },
  headerCell: {
    fontSize: theme.typography.h5.fontSize,
    padding: 0,
    height: 90,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.info.main,
  },
}))

const useCheckboxStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    fontSize: theme.typography.h5.fontSize,
  },
}))
