import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { pathOr, path } from 'ramda'
import React from 'react'

export const CardTab = ({ fetchedData, cells }) => {
  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()
  return (
    <div className={cardClasses.card}>
      <Grid container>
        {cells.map(({ key, path: dataPath, formatter, value, fullWidth = false, showFn }) => {
          const cellValue = value || (formatter?.(path(dataPath, fetchedData)) ?? pathOr('-', dataPath, fetchedData))
          const show = showFn?.(path(dataPath, fetchedData))
          if (typeof show === 'boolean' && !show) return null

          return (
            <Grid item xs={12} md={fullWidth ? 12 : 6} key={key}>
              <Typography className={textClasses.key}>{key}</Typography>
              <Typography className={textClasses.value}>
                {cellValue}
              </Typography>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.6rem',
  },
  subKey: {
    color: theme.palette.info.main,
    fontSize: '1.5rem',
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  valuePrimary: {
    color: theme.palette.text.primary,
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  header: {
    color: theme.palette.info.main,
    fontSize: '1.7rem',
    marginTop: '.56rem',
    marginBottom: '1.6rem',
  },
  totalsKey: {
    fontSize: '1rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

export const useCardStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    padding: '1rem',
    minHeight: '20rem',
    height: 'calc(100% - 2rem)',
  },
  divider: {
    background: 'gray',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}))
