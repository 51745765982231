import React, { useState } from 'react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import InputBase from '@material-ui/core/InputBase'

import useDebounce from '../../../helpers/useDebounce'
import * as Icons from '../../../Icons'
const SearchIcon = Icons.Search

export default function SearchInput () {
  const classes = useStyles()
  const theme = useTheme()
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const [searchTerm, setSearchTerm] = useState(query.searchTerm)
  const search = useDebounce((value) => {
    query.searchTerm = value
    query.page = 1
    history.push({ search: queryString.stringify(query) })
  }, 900)

  const onChange = (e) => {
    setSearchTerm(e.target.value)
    search(e.target.value)
  }

  return (
    <AppBar
      position='static'
      className={classes.appBar}
    >
      <Toolbar>
        <Grid container alignItems='center' justify='center'>
          <Grid item xs={12}>
            <Box display='flex'>
              <Box display='flex' justifyContent='center' alignItems='center'>
                <SearchIcon style={{ fontSize: theme.typography.h3.fontSize }} />
              </Box>
              <InputBase className={classes.input} placeholder='Search' onChange={onChange} value={searchTerm} />
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[26],
    borderRadius: 8,
    color: theme.palette.text.secondary,
  },
  input: {
    width: '100%',
    color: theme.palette.text.secondary,
    fontSize: theme.typography.h3.fontSize,
    fontWeight: 300,
  },
}))
