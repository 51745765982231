import React from 'react'
import { path } from 'ramda'
import { useQuery } from 'react-query'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Rating from '@material-ui/lab/Rating'

import { GET_REVIEWS } from '../../../gql/queries'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'

const OrderReview = ({ endpoint, orderDbId }) => {
  const { request } = useRequest()
  const { data } = useQuery(['reviews', orderDbId], () => request({ query: GET_REVIEWS, variables: { reviewee: orderDbId }, endpoint: endpoints[endpoint] }))

  if (Array.isArray(path(['getReviews', 'items'], data)) && !data.getReviews.items.length) {
    return (
      <Box display='flex' justifyContent='center' alignContent='center' width='100%' height='100%'>
        <Typography variant='h5'>
          Order has not been reviewed yet
        </Typography>
      </Box>
    )
  }

  return (
    <Box display='flex' flexDirection='column'>
      <Rating
        readOnly
        value={path(['getReviews', 'items', 0, 'rating'], data) ?? 0}
      />
      {
        path(['getReviews', 'items', 0, 'title'], data) && (
          <Typography variant='h5' style={{ marginTop: '1rem' }}>
            {path(['getReviews', 'items', 0, 'title'], data)}
          </Typography>
        )
      }
      {
        path(['getReviews', 'items', 0, 'content'], data) && (
          <Typography variant='body2' style={{ marginTop: '1rem' }}>
            {path(['getReviews', 'items', 0, 'content'], data)}
          </Typography>
        )
      }
    </Box>
  )
}

export default OrderReview
