import React, { useEffect, useMemo, useState } from 'react'
import * as R from 'ramda'
import { path, pathOr, propOr, reject } from 'ramda'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import { useDialog } from 'muibox'
import { useTheme } from '@material-ui/core/styles'
import queryString from 'query-string'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import useRequest from '../../helpers/useRequest'
import * as endpoints from '../../config/endpoints'
import definitions from '../../config/definitions'
import Table from '../core/Table'
import Search from '../core/Search'
import Button from '../core/Button'
import Actions from '../core/Actions'
import Pagination from '../core/Pagination'
import * as gql from '../../gql/queries'
import * as Icons from '../../Icons'
import { saveAs } from 'file-saver'
import usePermissions from './../../helpers/usePermissions'

const DeleteIcon = Icons.Delete
const CheckIcon = Icons.Check

const View = ({ moduleStructure }) => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const parsedPageNumber = parseInt(query.page) || 1
  const variables = reject((item) => !item, { ...query, pageSize: definitions.defaultPageSize, page: parsedPageNumber })
  const [records, setRecords] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [sortOption, setSortOption] = useState('')
  const { request } = useRequest()
  const {
    data,
    isFetching,
    error,
    refetch,
  } = useQuery([moduleStructure.key, query], () => request({
    endpoint: endpoints[moduleStructure.viewQuery.endpoint],
    query: gql[moduleStructure.viewQuery.query],
    variables,
  }))
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()
  const dialog = useDialog()
  const { permissions, accountType } = usePermissions()

  const MainActionIcon = Icons[pathOr('Add', ['action', 'icon'], moduleStructure)]

  const onDelete = (key) => {
    dialog.confirm({
      title: 'Are You Sure?',
      message: <Typography style={{ color: theme.palette.primary.main }}>This action is permanent!</Typography>,
      ok: { variant: 'contained', color: 'primary', text: 'DELETE', startIcon: <DeleteIcon /> },
    })
      .then(() => request({
        endpoint: endpoints[moduleStructure.deleteMutation.endpoint],
        query: gql[moduleStructure.deleteMutation.query],
        variables: { _id: key },
      }))
      .then(() => {
        refetch()
        enqueueSnackbar('Deleted Item Successfully', { variant: 'success' })
      })
      .catch((error) => {
        if (error) enqueueSnackbar(R.pathOr('failed to delete', ['response', 'errors', 0, 'message'], error), { variant: 'error' })
      })
  }

  const onVerify = (key) => {
    dialog.confirm({
      title: 'Are You Sure?',
      message: <Typography style={{ color: theme.palette.primary.main }}>This action is permanent!</Typography>,
      ok: { variant: 'contained', color: 'primary', text: 'VERIFY', startIcon: <CheckIcon /> },
    })
      .then(() => request({
        endpoint: endpoints[moduleStructure.verifyMutation.endpoint],
        query: gql[moduleStructure.verifyMutation.query],
        variables: { _id: key },
      }))
      .then(() => {
        refetch()
        enqueueSnackbar('Verified Item Successfully', { variant: 'success' })
      })
      .catch((error) => {
        if (error) enqueueSnackbar(R.pathOr('failed to verify', ['response', 'errors', 0, 'message'], error), { variant: 'error' })
      })
  }

  const onSortChange = (e) => {
    setSortOption(e.target.value)
    enqueueSnackbar(`${e.target.value}`)
  }

  useEffect(() => {
    if (data) {
      setRecords(data[Object.keys(data)[0]].items)
      setTotalRecords(data[Object.keys(data)[0]].totalCount)
    }
  }, [data])

  const action = path(['action'], moduleStructure)

  /** @type {boolean} */
  const canAdd = useMemo(() => {
    if (accountType === 'staff') return true

    if (!action) return false

    const permissionSet = new Set(permissions)

    return propOr([], 'permissions', action).every((p) => permissionSet.has(p))
  }, [action, permissions])

  if (error && !error.graphQLErrors) return <div> an error occurred </div>

  const onClick = () =>
    action?.(R, {
      dialog,
      request,
      data,
      endpoints,
      gql,
      enqueueSnackbar,
      saveAs,
    })

  return (
    <>
      <Grid container direction='column' style={{ paddingTop: 8 }} spacing={1}>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item xs={12} md={canAdd ? 9 : 12}>
              <Search />
            </Grid>
            {canAdd && (
              <Grid item xs={12} md={3}>
                <Button
                  variant='contained'
                  style={{
                    height: 64,
                    width: '100%',
                    boxShadow: theme.shadows[26],
                    fontWeight: 300,
                    fontSize: theme.typography.h3.fontSize,
                  }}
                  {...!!action.action && { onClick }}
                  uri={path(['action', 'route'], moduleStructure)}
                >
                  <MainActionIcon style={{ fontSize: theme.typography.h3.fontSize }} />
                  {path(['action', 'label'], moduleStructure)}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <Grid item>
          <Actions sortOptions={moduleStructure.sortOptions} onSortChange={onSortChange} sortValue={sortOption} actions={moduleStructure.actions} />
        </Grid>

        <Grid item>
          <Table
            isLoading={isFetching}
            fields={moduleStructure.fields}
            records={records}
            onDelete={onDelete}
            refetch={refetch}
            onVerify={onVerify}
          />
        </Grid>

        <Grid item>
          <Pagination totalCount={totalRecords} pageSize={definitions.defaultPageSize} />
        </Grid>
      </Grid>
    </>
  )
}

export default View
