import React, { useState } from 'react'
import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import * as endpoints from '../../../config/endpoints'
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import Button from '../../core/Button/Button'
import { Email } from '@material-ui/icons'
import * as queries from '../../../gql/queries'

const SendESTAdmissionEmail = ({ field, record }) => {
  const { request } = useRequest()

  const [show, setShow] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { alertBody, alertTitle, endpoint, query, showAlert, label } = field

  const [mutate, { isLoading }] = useMutation((variables) => {
    return request({
      query: queries[query],
      variables,
      endpoint: endpoints[endpoint],
    })
  })

  const handleSend = () => {
    mutate({ _id: record._id })
      .then(() => {
        enqueueSnackbar('Email queued successfully')
        setShow(false)
      })
      .catch((e) => {
        enqueueSnackbar('Failed to send', { variant: 'error' })
      })
  }

  const handleClick = () => {
    if (showAlert) {
      setShow(true)
    } else {
      handleSend()
    }
  }

  return (
    <>
      <Button
        loading={isLoading}
        onClick={handleClick}
        style={{
          alignSelf: 'center',
          margin: '0 0 0 auto',
          padding: '1rem',
        }}
      >
        {label} <Email />
      </Button>
      <Dialog open={show} onClose={() => setShow(false)}>
        <DialogTitle>{alertTitle || 'Send Email'}</DialogTitle>
        <DialogContent>{alertBody || 'Are you sure you want to proceed?'}</DialogContent>
        <DialogActions style={{ marginBottom: '1rem' }}>
          <Button
            variant='contained'
            disabled={isLoading}
            style={{ padding: '1rem' }}
            onClick={handleSend}
          >
            Send
          </Button>
          <Button disabled={isLoading} style={{ padding: '1rem' }} onClick={() => setShow(false)}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default SendESTAdmissionEmail
