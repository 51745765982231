import React, { useEffect, useState } from 'react'
import SelectSync from '../../core/SelectSync/SelectSync'
import useRequest from '../../../helpers/useRequest'
import { useMutation } from 'react-query'
import { EDIT_ADMISSION_SERVICE_REQUEST } from '../../../gql/queries'
import { useSnackbar } from 'notistack'
import { makeStyles } from '@material-ui/core/styles'
import * as endpoints from '../../../config/endpoints'

export const table = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Value',
    key: 'value',
  },
  {
    label: 'Price',
    key: 'price',
  },
]

const useStyles = makeStyles({
  select: {
    color: 'white',
  },
})

const statuses = [
  { key: 'Unpaid', label: 'Unpaid' },
  { key: 'Paid', label: 'Paid' },
  { key: 'Completed', label: 'Completed' },
  { key: 'Reject', label: 'Canceled' },
]

const StatusSelector = ({ status: initialStatus, _id }) => {
  const styles = useStyles()
  const [status, setStatus] = useState(initialStatus)
  const { enqueueSnackbar } = useSnackbar()
  const { request } = useRequest()

  const [mutate, { isLoading }] = useMutation((variables) => request({
    query: EDIT_ADMISSION_SERVICE_REQUEST,
    variables,
    endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
  }))

  useEffect(() => {
    setStatus(initialStatus)
  }, [initialStatus])

  return (
    <SelectSync
      value={status}
      disabled={isLoading}
      className={styles.select}
      onChange={(e) => {
        const newStatus = e.target.value

        mutate({ _id, status: newStatus })
          .then(() => {
            setStatus(newStatus)
            enqueueSnackbar('status updated successfully')
          })
      }}
      options={statuses}
    />
  )
}
export const requestInfoCells = [
  { key: 'Request Id', path: ['getAdmissionServiceRequest', 'requestId'] },
  { key: 'English Name', path: ['getAdmissionServiceRequest', 'player', 'name'] },
  { key: 'Arabic Name', path: ['getAdmissionServiceRequest', 'player', 'nameObject', 'ar'] },
  { key: 'Phone', path: ['getAdmissionServiceRequest', 'player', 'phone'] },
  { key: 'Email', path: ['getAdmissionServiceRequest', 'player', 'email'] },
  { key: 'price', path: ['getAdmissionServiceRequest', 'price'] },
  { key: 'Order ID', path: ['getAdmissionServiceRequest', 'orderId'] },
  {
    key: 'Paid At',
    path: ['getAdmissionServiceRequest', 'receipt', 'createdAt'],
    formatter: (d) => {
      const date = new Date(d)

      if (isNaN(date.valueOf())) {
        return '-'
      }

      return date.toLocaleString('en-us', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      })
    },
  },
  { key: 'service', path: ['getAdmissionServiceRequest', 'serviceCopy', 'name', 'en'] },
  {
    key: 'Status',
    path: ['getAdmissionServiceRequest'],
    formatter: StatusSelector,
  },
]
