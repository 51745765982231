const store = {
  token: null,
  user: null,
  theme: 'light',
  lang: 'en',
  structure: null,
  lastUpdate: null,
  title: '',
  rem: '16px',
  accountType: 'staff',
  permissions: [],
}

export default store
