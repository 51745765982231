import React from 'react'
import { useStateMachine } from 'little-state-machine'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Fade from '@material-ui/core/Fade'

import { switchTheme, logout, setPermissions } from '../../../store/actions'
import * as Icons from '../../../Icons'
import UserName from './UserName'

const Brightness4 = Icons.Brightness4
const Lock = Icons.Lock
const AccountCircleIcon = Icons.AccountCircle

export default function CustomizedMenus ({
  anchor,
  setAnchor = () => {},
}) {
  const classes = useStyles()
  const menuClasses = useMenuStyles()
  const theme = useTheme()
  const { actions } = useStateMachine({ switchTheme, logout, setPermissions }, { shouldReRenderApp: false })
  const { action: switchThemeAction } = useStateMachine(switchTheme)

  const handleSwitchTheme = () => {
    setAnchor(null)
    switchThemeAction()
  }

  const handleLogout = () => {
    setAnchor(null)
    actions.logout()
    actions.setPermissions({ permissions: [] })
    window.location = '/'
  }

  return (
    <>
      <Menu
        classes={menuClasses}
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        marginThreshold={14}
        TransitionComponent={Fade}
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={() => setAnchor(null)}
      >
        <AccountCircleIcon className={classes.img} />
        <UserName />

        <MenuItem onClick={handleSwitchTheme}>
          <ListItemIcon style={{ margin: 'auto' }}>
            <Brightness4 style={{ color: theme.palette.text.secondary, fontSize: theme.typography.h4.fontSize, margin: 'auto' }} />
          </ListItemIcon>
          <ListItemText primary='Switch Theme' primaryTypographyProps={{ className: classes.menuItem }} />
        </MenuItem>

        <MenuItem onClick={handleLogout}>
          <ListItemIcon style={{ margin: 'auto' }}>
            <Lock style={{ color: theme.palette.text.secondary, fontSize: theme.typography.h4.fontSize, margin: 'auto' }} />
          </ListItemIcon>
          <ListItemText primary='Logout' primaryTypographyProps={{ className: classes.menuItem }} />
        </MenuItem>
      </Menu>
    </>
  )
}

const useStyles = makeStyles((theme) => ({
  img: {
    width: 92,
    height: 92,
    borderRadius: 8,
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    marginTop: 42,
  },
  h4: {
    fontSize: '1.3rem',
    textTransform: 'uppercase',
    fontWeight: 400,
    margin: '20px auto',
    color: theme.palette.text.secondary,
    letterSpacing: 1,
  },
  menuItem: {
    textTransform: 'uppercase',
    margin: 'auto',
    color: theme.palette.text.secondary,
  },
}))

const useMenuStyles = makeStyles((theme) => ({
  paper: {
    backgroundColor: theme.palette.secondary.main,
    width: 200,
    borderRadius: '.5rem',
    shadow: theme.shadows[2],
    marginTop: 20,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}))
