import React, { useEffect, useState } from 'react'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useHistory } from 'react-router-dom'
import * as R from 'ramda'
import { useSnackbar } from 'notistack'
import { useTheme } from '@material-ui/core/styles'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import useAddForm from '../../../helpers/useAddForm'
import getErrorMessages from '../../../helpers/getErrorMessages'
import Button from '../../core/Button'
import Text from '../../core/Text'
import RichText from '../../core/RichText'
import UploadImages from '../../core/Images/UploadImages'
import Checkbox from '../../core/Checkbox'
import AutoComplete from '../../core/AutoComplete'
import Number from '../../core/Number'

function AddLoyaltyProduct ({ moduleStructure }) {
  const formMethods = useForm({ mode: 'all' })
  const [loading, setLoading] = useState(false)
  const { handleSubmit, errors, control } = formMethods
  const [submit, { error }] = useAddForm({ addMutations: [{ query: 'ADD_PRODUCT_CLEVERSELL', endpoint: 'GEMMA' }], editMutation: { query: 'EDIT_PRODUCT_CLEVERSELL', endpoint: 'GEMMA' }, entity: 'product', uploadFields: ['images'] })
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()

  const errorMessages = getErrorMessages(errors)

  useEffect(() => {
    if (error) {
      if (R.path(['response', 'errors', 0, 'message'], error)) {
        enqueueSnackbar(R.path(['response', 'errors', 0, 'message'], error), { variant: 'error' })
      } else {
        enqueueSnackbar('Unexpected Error', { variant: 'error' })
      }
      setLoading(false)
      console.log(error)
    }
  }, [enqueueSnackbar, error])

  const add = async (values, mutationIndex = 0) => {
    const data = await submit(values, mutationIndex)
    if (data && data[Object.keys(data)[0]]) {
      const successMessage = R.propOr('Added Item Successfully', 'successMessage', moduleStructure)
      enqueueSnackbar(successMessage, { variant: 'success' })
      history.push('/loyaltyProducts')
    }
  }

  const onSubmit = (values) => {
    if (loading) return
    setLoading(true)
    add({ ...values, loyalty: { ...values.loyalty, isLoyalty: true, loyaltyOnly: true }, variations: values?.variations?.map((v) => ({ ...v, active: true })) })
  }

  return (
    <div style={{ paddingTop: '2.5rem' }}>
      <Box display='flex' justifyContent='center'>
        <Box style={{ width: 1100, padding: '0 1em' }} alignContent='center' alignItems='center' justify='center'>
          <FormProvider {...formMethods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Controller
                    name='name.en'
                    render={({ onChange, value }) => <Text value={value} onChange={onChange} label='Name English' error={!!errors?.name?.en} helperText={errors?.name?.en ? 'Name is Required' : ' '} />}
                    control={control}
                    rules={{ required: 'Name is Required' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='name.ar'
                    render={({ onChange, value }) => <Text value={value} onChange={onChange} label='Name Arabic' />}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='description.en'
                    render={({ onChange, value }) => <RichText value={value} onChange={onChange} label='Description English' error={!!errors?.description?.en} helperText={errors?.description?.en ? 'Description is Required' : ' '} />}
                    control={control}
                    rules={{ required: 'Description is Required' }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='description.ar'
                    render={({ onChange, value }) => <RichText value={value} onChange={onChange} label='Description Arabic' />}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='code'
                    render={({ onChange, value }) => <Text value={value} onChange={onChange} label='Product Code' placeholder='P113' error={!!errors?.code} helperText={errors?.code ? 'Product Code is Required' : ' '} />}
                    control={control}
                    rules={{ required: 'Product Code is Required' }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name='active'
                    render={({ onChange, value }) => <Checkbox value={value} onChange={onChange} label='Active' />}
                    control={control}
                    defaultValue={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name='images'
                    render={({ onChange, value }) => <UploadImages multiple value={value} onChange={onChange} label='Images' />}
                    control={control}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name='loyalty.points'
                    render={({ onChange, value }) => <Number value={value} onChange={onChange} label='Points' integer />}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='variations.0.stock.0.amount'
                    render={({ onChange, value }) => <Number value={value} onChange={onChange} label='Stock' integer />}
                    control={control}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name='variations.0.stock.0.branch'
                    render={({ onChange, value }) => (
                      <AutoComplete
                        value={value}
                        onChange={onChange}
                        label='Branch'
                        multiple={false}
                        singleQuery={{ query: 'GET_BRANCH_CLEVERSELL', endpoint: 'GEMMA' }}
                        searchQuery={{ query: 'GET_BRANCHES_CLEVERSELL', endpoint: 'GEMMA' }}
                        displayFormatter={(R, option) => option?.name?.en}
                        valueFormatter={(R, option) => option?._id}
                      />)}
                    control={control}
                    rules={{ required: 'Branch is Required' }}
                  />
                </Grid>
              </Grid>

              <Box display='flex' justifyContent='flex-end'>
                {
                  errorMessages.length
                    ? <ul> {errorMessages.map((message, index) => (<Typography style={{ color: 'red' }} key={index} component='li' gutterBottom align='left'> {message} </Typography>))} </ul>
                    : null
                }
                <Button
                  color='primary'
                  variant='contained'
                  disableElevation
                  type='submit'
                  size='large'
                  style={{
                    margin: '0 1rem',
                    height: '3.2rem',
                    width: '7rem',
                    fontSize: theme.typography.h3.fontSize,
                  }}
                  loading={loading}
                >
                  ADD
                </Button>
              </Box>
              <p style={{ color: 'red', textAlign: 'center' }}>{R.path(['graphQLErrors', '0', 'message'], error) || ''}</p>
            </form>
          </FormProvider>
        </Box>
      </Box>
    </div>
  )
}

export default AddLoyaltyProduct
