import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { converge, equals, find, identity, keys, omit, pick, propEq, propOr, useWith } from 'ramda'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import FormControl from '@material-ui/core/FormControl'
import Button from '../../core/Button'
import { makeStyles } from '@material-ui/core/styles'

const useRadioStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.primary.main,
    '&$checked': {
      color: theme.palette.primary.main,
    },
  },
  checked: {
    color: theme.palette.primary.main,
  },
}))

const useStyles = makeStyles((theme) => ({
  title: {
    margin: 0,
    textTransform: 'uppercase',
    fontFamily: 'Barlow Condensed',
    color: theme.palette.text.secondary,
    fontWeight: 500,
    letterSpacing: '0',
    backgroundColor: theme.palette.secondary.main,
  },
  label: {
    fontSize: theme.typography.body1.fontSize,
  },
  select: {
    color: theme.palette.text.primary,
  },
}))

const intersectObj = useWith(pick, [keys, identity])
const partialEquals = converge(equals, [identity, intersectObj])

const formatSlot = ({ hours, minutes }) => moment({ hours, minutes }).format('hh:mm A')

export const ShippingSlotsModal = ({ onClose, open, slots, submit, selectedSlot }) => {
  const styles = useStyles()
  const radioClasses = useRadioStyles()
  const [selectedSlotId, setSelectedSlotId] = useState('')

  useEffect(() => {
    if (selectedSlot) {
      const slot = find(partialEquals(selectedSlot), slots)
      setSelectedSlotId(propOr('', '_id', slot))
    }
  }, [])

  const handleSubmit = () => {
    const slot = find(propEq('_id', selectedSlotId), slots)
    submit(omit(['_id'], slot))
    onClose()
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle className={styles.title}>
        <Typography variant='h5'>Shipping Slots</Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl component='fieldset'>
          <RadioGroup
            value={selectedSlotId}
            onChange={(e) => setSelectedSlotId(e.target.value)}
          >
            {slots.map((slot) => (
              <FormControlLabel
                key={slot._id}
                value={slot._id}
                className={styles.select}
                classes={{ label: styles.label }}
                control={<Radio classes={radioClasses} />}
                label={`${formatSlot(slot.from)} - ${formatSlot(slot.to)}`}
              />
            ))}
          </RadioGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button color='primary' variant='contained' onClick={handleSubmit} disabled={!selectedSlotId}>
          SUBMIT
        </Button>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  )
}
