import React from 'react'

import ListItemComponent from './ListItem'

const ListItemsComponent = ({
  sidebarOpen = true,
  sidebarItems = [],
  sidebarItemsGroups = [],
}) => {
  const groups = sidebarItemsGroups.map((group) => ({
    key: group.key,
    label: group.label,
    icon: group.icon,
    order: group.order,
    groupItems: sidebarItems.filter((item) => item.group === group.key).map((item, index) => ({ ...item, order: item.order || index })).sort((firstItem, secondItem) => firstItem.order - secondItem.order),
  }))
  const itemsWithNoGroups = sidebarItems.filter((item) => !item.group).map((item) => ({ ...item, groupItems: [] }))
  const topLevelItems = [...groups, ...itemsWithNoGroups].map((item, index) => ({ ...item, order: item.order || index })).sort((firstItem, secondItem) => firstItem.order - secondItem.order)

  return (
    <>
      {
        topLevelItems.map((item) => (
          <ListItemComponent
            key={item.key}
            sidebarOpen={sidebarOpen}
            icon={item.icon}
            label={item.label}
            groupItems={item.groupItems}
            route={item.route}
          />
        ))
      }
    </>
  )
}

export default ListItemsComponent
