import { Grid } from '@material-ui/core'
import TextInput from '../../core/Text'
import React from 'react'

const minZero = (v) => Math.max(v, 0)
export const UniversityFields = ({ state, setValue }) => (
  <>
    <Grid item xs={4}>
      <TextInput
        inputType='number'
        label='min'
        onChange={setValue('min', minZero)}
        value={state.min ?? 0}
        helperText='min number of items a user can select'
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        inputType='number'
        label='max'
        onChange={setValue('max', minZero)}
        value={state.max ?? 0}
        helperText='max number of items a user can select (0 = no limit)'
      />
    </Grid>
    <Grid item xs={4}>
      <TextInput
        inputType='number'
        label='price'
        onChange={setValue('price', minZero)}
        value={state.price ?? 0}
        helperText='the price of each selection (total = num selections * price)'
      />
    </Grid>
  </>
)
