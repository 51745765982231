import { useCardStyles, useTextStyles } from './InfoCardTab'
import Typography from '@material-ui/core/Typography'
import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import Divider from '@material-ui/core/Divider'
import Button from '../../core/Button/Button'
import { PaymentTable } from './AdmissionPaymentTable'

const getFormatNum = (currency) => (num) => {
  const value = Number(num ?? 0).toFixed(2)

  return value ? `${value} ${currency}` : ''
}

export const AdmissionTotals = ({ totals, payments, status, currency }) => {
  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()
  const [showPaymentsModal, setShowPaymentModal] = useState(false)
  const { lateFees = 0, total = 0, paid = 0, unpaid = 0, fees = {} } = totals ?? {}

  const formatNum = getFormatNum(currency)

  const rows = [
    { name: 'Subtotal', value: total - lateFees, formatter: formatNum },
    { name: 'Paid', value: paid, formatter: formatNum },
    { name: 'Unpaid', value: unpaid, formatter: formatNum },
    { name: 'Late Fees', value: lateFees, formatter: formatNum },
    { name: 'Cancellation Fees', value: fees?.cancellationFee ?? 0, formatter: formatNum },
    { name: 'Total', value: total, formatter: formatNum },
    { name: 'status', value: status },
  ]

  return (
    <div className={cardClasses.card}>
      <div style={{ display: 'flex' }}>
        <Typography className={textClasses.header}>Payment</Typography>
        <Button
          onClick={() => setShowPaymentModal(true)}
          variant='contained' style={{
            alignSelf: 'center',
            margin: '0 0px 1.2rem auto',
            padding: '1rem',
          }}
        >
          Show Payments
        </Button>
        <PaymentTable
          open={showPaymentsModal}
          onClose={() => setShowPaymentModal(false)}
          records={payments}
        />
      </div>
      <div>
        {rows.map(({ name, value, formatter }, i) => (
          <Fragment key={name}>
            <Grid container justify='space-between' alignItems='center'>
              <Grid item xs={6}>
                <Typography className={textClasses.totalsKey}>{name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Box display='flex' justifyContent='flex-end'>
                  <Typography className={textClasses.totalsKey}>
                    {formatter?.(value) ?? value}
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <Divider className={cardClasses.divider} hidden={i === rows.length - 1} />
          </Fragment>
        ))}
      </div>
    </div>
  )
}
