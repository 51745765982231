import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import Sidebar from './Sidebar'
import Topbar from './Topbar'
import definitions from '../../config/definitions'

const Layout = ({ children }) => {
  const classes = useStyles()
  const [sidebarOpen, setSidebarOpen] = useState(true)
  const handleSidebarToggle = () => {
    setSidebarOpen((wasOpen) => !wasOpen)
  }

  return (
    <div className={classes.root}>
      <Sidebar sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle} />
      <Grid container direction='column' className={`${classes.grid} ${sidebarOpen ? classes.gridShift : ''}`}>
        <Grid item style={{ height: 'calc(8.4vh - 10)px' }}>
          <Topbar />
        </Grid>
        <Grid item style={{ height: 'calc(91.6vh - 10)px' }}>
          {children}
        </Grid>
      </Grid>
    </div>
  )
}

export default Layout

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  grid: {
    zIndex: theme.zIndex.drawer + 1,
    padding: 10,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${definitions.closedDrawerWidth}px)`,
  },
  gridShift: {
    width: `calc(100% - ${definitions.drawerWidth}px)`,
    transition: theme.transitions.create(['width'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}))
