import React, { useState } from 'react'
import queryString from 'query-string'
import { useLocation, useHistory } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import Select from '@material-ui/core/Select/Select'
import FormControl from '@material-ui/core/FormControl'
import FormLabel from '@material-ui/core/FormLabel'
import Grid from '@material-ui/core/Grid'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Box from '@material-ui/core/Box'
import MenuItem from '@material-ui/core/MenuItem'
import CustomComponents from '../../custom'

const ActionsComponent = ({
  sortOptions = [{ key: '', label: 'Default' }],
  actions = [],
}) => {
  const classes = useStyles()
  const history = useHistory()
  const location = useLocation()
  const query = queryString.parse(location.search)
  const [sort, setSort] = useState(query.sort || '')

  const onSortChange = (e) => {
    query.sort = e.target.value
    setSort(e.target.value)
    history.push({ search: queryString.stringify(query) })
  }

  return (
    <AppBar
      position='static'
      className={classes.appBar}
    >
      <Toolbar>
        <Grid container alignItems='center' justify='center' spacing={1}>
          <Grid item xs={12} md={9}>
            <Grid container>
              {
                actions.map((action) => {
                  const Comp = CustomComponents[action.component]
                  return <Grid key={action.key} item> <Comp /> </Grid>
                })
              }
            </Grid>
          </Grid>
          <Grid item xs={12} md={3} style={{ paddingLeft: 24 }}>
            <Box display='flex' alignItems='center' justifyContent='center'>
              <FormLabel className={classes.label}> Sort </FormLabel>
              <FormControl className={classes.select}>
                <Select
                  color='secondary'
                  disableUnderline
                  value={sort}
                  onChange={onSortChange}
                  displayEmpty
                  className={classes.select}
                >
                  {sortOptions.map((option) => {
                    return <MenuItem className={classes.selectItem} key={option.key} value={option.key}>{option.label}</MenuItem>
                  })}
                </Select>
              </FormControl>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default ActionsComponent

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    width: '100%',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[26],
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
  },
  label: {
    fontSize: theme.typography.button.fontSize,
    textTransform: 'uppercase',
    marginRight: 4,
  },
  select: {
    fontSize: theme.typography.button.fontSize,
    color: theme.palette.text.secondary,
    width: '100%',
    '& *:focus': {
      background: 'transparent',
    },
  },
  selectItem: {
    fontSize: theme.typography.body1.fontSize,
  },
}))
