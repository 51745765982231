import React, { useEffect } from 'react'
import { useStateMachine } from 'little-state-machine'
import Grid from '@material-ui/core/Grid'
import { setTitle } from '../../../store/actions'
import { exportAdmissionsCell } from './fields'
import { CardTab } from '../Admission/InfoCardTab'

export default function ESTExportsPage () {
  const { action } = useStateMachine({ setTitle })

  useEffect(() => {
    action({ title: 'exports' })
  }, []) // eslint-disable-line

  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ minHeight: '20rem' }}>
          <CardTab cells={exportAdmissionsCell} />
        </Grid>
      </Grid>
    </div>
  )
}
