import React, { useEffect, useState } from 'react'
import {
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@material-ui/core'
import { useMutation, useQuery } from 'react-query'
import Button from '../../core/Button/Button'
import useRequest from '../../../helpers/useRequest'
import { CANCEL_ADMISSION } from '../../../gql/queries'
import { useSnackbar } from 'notistack'
import { filter, path, pathOr, prop, propOr } from 'ramda'
import { queryCache } from '../../../index'
import * as endpoints from '../../../config/endpoints'
import { downloadFromUrl } from '../../../helpers/downloadFromUrl'

const GetTicketUrl = `
query GetTicketUrl($admissionId: ID!, $eventId: ID!) {
  getTicketUrl(admissionId: $admissionId, eventId: $eventId)
}
`

const CancelAdmission = (record) => {
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const [open, setOpen] = useState(false)
  const [fee, setFee] = useState(0)

  const id = record.getAdmission._id

  const [cancel, { isLoading, isSuccess, isError, error }] = useMutation(
    () =>
      request({
        query: CANCEL_ADMISSION,
        endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
        variables: { _id: id, cancellationFee: parseInt(fee) },
      }),
    {
      onSuccess: () => queryCache.invalidateQueries(['admission', id], { refetchActive: true }),
    },
  )

  useEffect(() => {
    if (isSuccess) {
      enqueueSnackbar('Canceled Admission', { variant: 'success' })
    } else if (isError && error.message) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [isError, isSuccess, error])

  const disabled = pathOr(false, ['getAdmission', 'canceled'], record)

  const handleCancel = () => {
    cancel()
    setOpen(500)
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        variant='contained'
        disabled={disabled}
        style={{
          alignSelf: 'center',
          margin: '0 0 0 auto',
          padding: '1rem',
        }}
      >
        CANCEL ADMISSION
      </Button>

      <Dialog open={open}>
        <DialogTitle>Cancellation Fee</DialogTitle>
        <DialogContent>
          <TextField
            value={fee}
            onChange={(e) => setFee(Math.max(parseInt(e.target.value), 0))}
            label='Cancellation Fee'
            type='number'
            placeholder='480'
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancel}
            disabled={disabled}
            variant='contained'
            loading={isLoading}
            style={{
              alignSelf: 'center',
              margin: '0 0 0 auto',
              padding: '1rem',
            }}
          >
            CANCEL ADMISSION
          </Button>

          <Button onClick={() => setOpen(false)}>close</Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

const getExamName = pathOr('', ['event', 'name', 'en'])

function TicketUrlBtn ({ event, admissionId }) {
  const [refetchInterval, setRefetchInterval] = useState(2000)
  const [loading, setLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const { request } = useRequest()
  const eventId = event?.event?._id
  const { refetch, isError, error } = useQuery(
    ['getTicketUrl', admissionId, eventId],
    () =>
      request({
        endpoint: endpoints[process.env.REACT_APP_ENDPOINT],
        query: GetTicketUrl,
        variables: { admissionId, eventId },
      }),
    {
      enabled: false,
      refetchInterval,
      onSuccess (data) {
        setRefetchInterval(false)
        const ticketUrl = path(['getTicketUrl'], data)
        downloadFromUrl(ticketUrl, `${getExamName(event)} Admission Ticket`)
        setLoading(false)
      },
    },
  )

  useEffect(() => {
    if (loading) enqueueSnackbar('File Downloading ...', { variant: 'success' })
    if (isError && error.message) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [isError, error, loading])

  const handleSubmit = () => {
    setLoading(true)
    refetch()
  }

  return (
    <Grid item xs={6}>
      <Button
        onClick={handleSubmit}
        disabled={loading}
        variant='contained'
        style={{ padding: '8px 16px' }}
      >
        {loading && (
          <CircularProgress size={12} style={{ marginRight: 4 }} />
        )}
        {getExamName(event)} Admission Ticket
      </Button>
    </Grid>
  )
}

const AdmissionTicketsButton = ({ admission }) => {
  const events = filter(prop('venue'), propOr([], 'events', admission))
  return (
    <Grid container>
      {events.map((event) => (
        <TicketUrlBtn event={event} admissionId={admission._id} key={event._id} />
      ))}
    </Grid>
  )
}

export const getStudentTabCells = ({ onOpen, onAddScore }) => [
  { key: 'Admission Id', path: ['getAdmission', 'admissionId'] },
  { key: 'Round', path: ['getAdmission', 'round', 'name', 'en'] },
  { key: 'English Name', path: ['getAdmission', 'player', 'name'] },
  { key: 'Arabic Name', path: ['getAdmission', 'player', 'nameObject', 'ar'] },
  { key: 'Phone', path: ['getAdmission', 'player', 'phone'] },
  { key: 'Email', path: ['getAdmission', 'player', 'email'] },
  { key: 'Gender', path: ['getAdmission', 'player', 'gender'] },
  { key: 'City', path: ['getAdmission', 'city', 'name', 'en'] },
  { key: 'District', path: ['getAdmission', 'district', 'name', 'en'] },
  {
    key: 'Share Test Score',
    path: ['getAdmission', 'allowSharing'],
    formatter: (val) => (typeof val === 'boolean' ? JSON.stringify(val) : 'false'),
  },
  {
    key: 'Special Needs',
    path: ['getAdmission', 'hasSpecialNeeds'],
    formatter: (val) => {
      return typeof val === 'boolean' ? JSON.stringify(val) : 'false'
    },
  },
  { key: 'Status', path: ['getAdmission', 'status'] },
  {
    key: 'Edit',
    path: [],
    formatter: () => (
      <Button
        variant='contained'
        style={{ cursor: 'pointer', padding: '1rem' }}
        display='flex'
        alignItems='center'
        justifyContent='center'
        onClick={onOpen}
      >
        Edit Admission
      </Button>
    ),
  },
  {
    key: 'Edit Scores',
    path: [],
    formatter: () => (
      <Button
        variant='contained'
        style={{ cursor: 'pointer', padding: '1rem' }}
        display='flex'
        alignItems='center'
        justifyContent='center'
        onClick={onAddScore}
      >
        Edit Score
      </Button>
    ),
  },
  { key: 'Cancel', path: [], formatter: CancelAdmission },
  {
    key: 'Admission Tickets',
    path: ['getAdmission'],
    fullWidth: true,
    showFn: (admission) => {
      const showTickets = pathOr(false, ['round', 'showTickets'], admission)
      if (!showTickets) return false

      const events = propOr([], 'events', admission)
      return filter(prop('venue'), events).length > 0
    },
    formatter: (record) => <AdmissionTicketsButton admission={record} />,
  },
]

export const transactionDetails = [
  {
    key: 'ID',
    path: ['id'],
  },
  {
    key: 'Amount',
    path: ['amount'],
  },
  {
    key: 'Status',
    path: ['status'],
  },
  {
    key: 'Date',
    path: ['created_at'],
    formatter: (date) => new Date(date).toLocaleString(),
  },
]

export const OrderItemsTable = [
  {
    label: 'Name',
    key: 'name',
  },
  {
    label: 'Amount',
    key: 'amount_cents',
    formatField: (R, amount) => {
      return `${(amount / 100).toFixed(2)}`
    },
  },
  {
    label: 'Desc',
    key: 'description',
  },
  {
    label: 'Quantity',
    key: 'quantity',
  },
]
