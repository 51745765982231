import React, { useEffect, useState } from 'react'
import { path, pathOr, reject } from 'ramda'
import { useLocation } from 'react-router-dom'
import { useQuery } from 'react-query'
import { useSnackbar } from 'notistack'
import { useTheme } from '@material-ui/core/styles'
import queryString from 'query-string'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'

import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import definitions from '../../../config/definitions'
import Table from '../../core/Table'
import Search from '../../core/Search'
import Button from '../../core/Button'
import Actions from '../../core/Actions'
import Pagination from '../../core/Pagination'
import * as gql from '../../../gql/queries'
import * as Icons from '../../../Icons'
import { fulfilmentCells, OrderFields, RequestFields, sortOptions } from './fields'
import { Accordion, AccordionDetails, AccordionSummary, makeStyles, Typography } from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { useTextStyles } from '../Admission/InfoCardTab'

const useStyles = makeStyles(() => ({
  table: {
    height: '100% !important',
  },
  requestsTable: { margin: '1rem 0' },
}))

function FulfilmentDetails ({ cells, record }) {
  const textClasses = useTextStyles({ text: { value: 'primary' } })

  return (
    <Grid container alignContent='center' alignItems='center'>
      {cells.map(({ key, path: dataPath, formatter }) => (
        <Grid item xs={12} md={4} key={key} style={{ textAlign: 'center' }}>
          <Typography className={textClasses.key}>{key}</Typography>
          <Typography className={textClasses.valuePrimary}>
            {formatter?.(path(dataPath, record)) ?? pathOr('-', dataPath, record)}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

const FulfillmentListing = ({ moduleStructure, RenderTable }) => {
  const location = useLocation()
  const query = queryString.parse(location.search)
  const parsedPageNumber = parseInt(query.page) || 1
  const variables = reject((item) => !item, { ...query, pageSize: definitions.defaultPageSize, page: parsedPageNumber })
  const [records, setRecords] = useState([])
  const [totalRecords, setTotalRecords] = useState(0)
  const [sortOption, setSortOption] = useState('')
  const { request } = useRequest()
  const {
    data,
    isFetching,
    error,
    refetch,
  } = useQuery([moduleStructure.key, query], () => request({
    endpoint: endpoints[moduleStructure.viewQuery.endpoint],
    query: gql[moduleStructure.viewQuery.query],
    variables,
  }))
  const { enqueueSnackbar } = useSnackbar()
  const theme = useTheme()

  const MainActionIcon = Icons[path(['action', 'icon'], moduleStructure) || 'Add']

  const onSortChange = (e) => {
    setSortOption(e.target.value)
    enqueueSnackbar(`${e.target.value}`)
  }

  useEffect(() => {
    if (data) {
      setRecords(data[Object.keys(data)[0]].items)
      setTotalRecords(data[Object.keys(data)[0]].totalCount)
    }
  }, [data])

  if (error && !error.graphQLErrors) return <div> an error occured </div>

  return (
    <>
      <Grid container direction='column' style={{ paddingTop: 8 }} spacing={1}>
        <Grid item>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <Search />
            </Grid>
            <Grid item xs={12} md={3}>
              <Button
                variant='contained'
                style={{
                  height: 64,
                  width: '100%',
                  boxShadow: theme.shadows[26],
                  fontWeight: 300,
                  fontSize: theme.typography.h3.fontSize,
                }}
                disabled={!moduleStructure.action}
                uri={path(['action', 'route'], moduleStructure)}
              >
                <MainActionIcon
                  style={{ fontSize: theme.typography.h3.fontSize }}
                /> {path(['action', 'label'], moduleStructure)}
              </Button>
            </Grid>
          </Grid>
        </Grid>

        <Grid item style={{ marginBottom: '2rem' }}>
          <Actions sortOptions={sortOptions} onSortChange={onSortChange} sortValue={sortOption} />
        </Grid>

        {isFetching ? <LinearProgress /> : <div style={{ height: 4 }} />}
        {records?.map((record, index) => (
          <Grid item key={record._id}>
            <Accordion elevation={4} defaultExpanded={!index}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <FulfilmentDetails cells={fulfilmentCells} record={record} />
              </AccordionSummary>
              <AccordionDetails>
                <RenderTable loading={isFetching} record={record} refetch={refetch} />
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}

        <Grid item>
          <Pagination totalCount={totalRecords} pageSize={definitions.defaultPageSize} />
        </Grid>
      </Grid>
    </>
  )
}

export const RenderOrderTable = ({ loading, record, refetch }) => {
  const styles = useStyles()

  return (
    <Table
      className={styles.table}
      isLoading={loading}
      fields={OrderFields}
      records={record?.orders}
      refetch={refetch}
    />
  )
}

export const RenderRequestsTable = ({ loading, record, refetch }) => {
  const styles = useStyles()
  const rows = record?.orderRequests.flatMap((arr) => arr.orderRequests)
  return (
    <Table
      className={styles.table}
      isLoading={loading}
      fields={RequestFields}
      records={rows}
      refetch={refetch}
    />
  )
}

export const FulfillmentRequestsListing = ({ moduleStructure }) => (
  <FulfillmentListing moduleStructure={moduleStructure} RenderTable={RenderRequestsTable} />
)

export const FulfillmentOrdersListing = ({ moduleStructure }) => (
  <FulfillmentListing RenderTable={RenderOrderTable} moduleStructure={moduleStructure} />
)
