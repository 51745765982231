import React, { useEffect, useState } from 'react'
import { path } from 'ramda'
import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import { RESET_PASSWORD_AS_ADMIN_EST } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import LockOpenIcon from '@material-ui/icons/LockOpen'
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core'
import Button from '../../core/Button/Button'
import TextInput from '../../core/Text'

const ResetPasswordAsAdmin = ({ field, record }) => {
  const { request } = useRequest()

  const [error, setError] = useState('')
  const [show, setShow] = useState(false)
  const [newPassword, setNewPassword] = useState()
  const { enqueueSnackbar } = useSnackbar()

  const [resetPasswordAsAdmin, { isLoading }] = useMutation((variables) => request({
    query: RESET_PASSWORD_AS_ADMIN_EST,
    variables,
    endpoint: endpoints[field.endpoint],
  }))

  useEffect(() => {
    if (newPassword && newPassword.length < 10 && newPassword.length !== 0) {
      setError('Password must be at least 10 characters')
    } else {
      setError('')
    }
  }, [newPassword]) // eslint-disable-line

  function clickHandler (e, method) {
    if (newPassword && newPassword.length < 10) {
      enqueueSnackbar('Password must be at least 10 characters')
      return
    }
    resetPasswordAsAdmin({
      user: path(['key'], record),
      method,
      ...newPassword && { newPassword },
      ...field?.customProps?.length && { length: field.customProps.length },
    }).then(() => {
      enqueueSnackbar('New password sent to User')
      setShow(false)
    }).catch(() => {
      enqueueSnackbar('Error sending new password')
      setShow(false)
    })
  }

  return (
    <>
      <IconButton onClick={() => setShow(true)}>
        <LockOpenIcon />
      </IconButton>
      <Dialog open={show} onClose={() => setShow(false)}>
        <DialogTitle>Reset Password </DialogTitle>
        <DialogContent>
          <TextInput
            style={{ width: '80%' }}
            label='New Password'
            placeholder='New Password'
            fullWidth={false}
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <DialogContent style={{ color: 'red' }}>{error}</DialogContent>
        </DialogContent>
        <DialogContent>Are you sure you want to reset the user password? </DialogContent>
        <DialogActions style={{ marginBottom: '1rem' }}>
          <Button
            variant='contained'
            disabled={isLoading}
            style={{ padding: '1rem' }}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => clickHandler(e, 'email')}
          >
            Send Email
          </Button>
          <Button
            variant='contained'
            disabled={isLoading}
            style={{ padding: '1rem' }}
            // eslint-disable-next-line react/jsx-no-bind
            onClick={(e) => clickHandler(e, 'sms')}
          >
            Send SMS
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default ResetPasswordAsAdmin
