import React, { useState, useEffect } from 'react'
import { path } from 'ramda'
import { useStateMachine } from 'little-state-machine'
import { useTheme } from '@material-ui/core/styles'
import LinearProgress from '@material-ui/core/LinearProgress'
import Box from '@material-ui/core/Box'

import Routes from './Routes'
import { setStructure, setPermissions } from './store/actions'
import useRequest from './helpers/useRequest'
import { CLEVERSHOP_CONTROL_ENDPOINT } from './config/endpoints'
import usePermissions from './helpers/usePermissions'

/**
 * @param {string} accountType
 * @param {string[]} permissions
 */
export function GET_DASHBOARD (accountType, permissions) {
  return `
    query getCurrentTenet { 
      getCurrentTenet { 
        dashboardSchema ${accountType === 'staff' || !permissions?.length ? '' : `(permissionsList: ${JSON.stringify(permissions)})`} 
      }
    }`
}

export const App = () => {
  const [structureError, setStructureError] = useState()
  const [structureFetched, setStructureFetched] = useState(false)
  const { action, state } = useStateMachine(setStructure)
  const { actions } = useStateMachine({ setPermissions }, { shouldReRenderApp: false })
  const { controlRequest } = useRequest()
  const { permissions } = usePermissions()

  const fetchStructure = async () => {
    try {
      const data = await controlRequest({
        endpoint: CLEVERSHOP_CONTROL_ENDPOINT,
        query: GET_DASHBOARD(state.accountType, state.permissions),
      })
      if (path(['getCurrentTenet'], data)) {
        action({ structure: data.getCurrentTenet.dashboardSchema })
        setStructureFetched(true)
      }
    } catch (e) {
      setStructureError(true)
    }
  }

  useEffect(() => {
    if (state.accountType === 'moderator') {
      actions.setPermissions({ permissions })
    }
  }, [structureFetched])

  useEffect(() => {
    fetchStructure()
  }, []) // eslint-disable-line

  if (structureError) return <p>failed to load structure</p>
  if (!state.structure || !structureFetched) return <LoadingScreen />
  return <Routes />
}

const LoadingScreen = () => {
  const theme = useTheme()

  return (
    <>
      <Box height='100vh' width='100vw' display='flex' alignContent='center' alignItems='center' justifyContent='center' style={{ background: theme.palette.background.paper }}>
        <Box width='20rem' height='30rem'>
          <img style={{ margin: '1rem 0 1rem -.5rem', width: '20rem' }} src={process.env.PUBLIC_URL + '/img/clevershop.png'} alt='clevershop logo' title='clevershop' />
          <LinearProgress />
        </Box>
      </Box>
    </>
  )
}
