import getTableField from './getTableField'

const getTableFields = ({ fields = [], onDelete = () => {}, onVerify = () => {}, refetch = () => {}, theme }) => {
  const formattedFields = fields.map((field) => ({
    ...field,
    format: (cell, record) => getTableField({ cell, record, field, onDelete, onVerify, refetch, theme }),
  })).sort((field1, field2) => field1.sortOrder - field2.sortOrder)

  return formattedFields
}

export default getTableFields
