import { useParams, useHistory } from 'react-router-dom'
import { useStateMachine } from 'little-state-machine'
import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { GET_DRIVER, APPROVE_ACCOUNT } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import LinearProgress from '@material-ui/core/LinearProgress'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Tabs from '../../core/Tabs'
import Typography from '@material-ui/core/Typography'
import { Alert } from '@material-ui/lab'
import { path, pathOr } from 'ramda'
import Divider from '@material-ui/core/Divider'
import { makeStyles } from '@material-ui/core/styles'
import { Avatar, Box, Snackbar } from '@material-ui/core'
import ImageView from '../../../components/core/Images/ImageView'
import Dialog from '../../../components/core/Dialog/index'
import Select from '../../core/SelectSync'
import TextArea from '../../core/Text'

export default function SingleDriver ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()
  const { isLoading: loading, data: fetchedDriver, refetch } = useQuery(['driver', key], () => request({ query: GET_DRIVER, variables: { _id: key }, endpoint: endpoints[moduleStructure.endpoint] }))
  const [approveAccount] = useMutation((variables) => request({ query: APPROVE_ACCOUNT, variables, endpoint: endpoints[moduleStructure.endpoint] }))
  const [currentActiveTab, setCurrentActiveTAb] = useState(1)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [alertOpen, setAlertOpen] = useState(false)
  const [approvedStatus, setApprovedStatus] = useState('approved')
  const [approvedNotes, setApprovedNotes] = useState('')
  const [approving, setApproving] = useState(false)
  const textClasses = useTextStyles()
  const cardClasses = useCardStyles()
  const history = useHistory()

  useEffect(() => {
    action({ title: 'drivers' })
  }, []) // eslint-disable-line

  const onApprove = async () => {
    setApproving(true)
    const result = await approveAccount({ user: key, notes: approvedNotes, status: approvedStatus })
    if (!result?.error) setAlertOpen(true)
    setDialogOpen(false)
    setApproving(false)
    refetch()
  }

  if (loading) return <div style={{ width: '95%', position: 'relative', margin: 'auto' }}> <LinearProgress /> </div>
  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} style={{ minHeight: '28rem' }}>
          <Grid container spacing={1}>
            <Grid item xs={12} md={9}>
              <Tabs currentActive={currentActiveTab} setCurrentActive={setCurrentActiveTAb}>
                <div>
                  <span>Info</span>
                  <span>ID / License</span>
                  <span>Vehicle</span>
                  <span>Address</span>
                </div>
                <div>
                  <div className={cardClasses.card}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>DRIVER PHOTO</Typography>
                        <div className='value image'>
                          <Avatar alt={path(['getDriver', 'name'], fetchedDriver)} src={path(['getDriver', 'photo'], fetchedDriver)} className='avatar' />
                        </div>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>NAME</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'name'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>PHONE NUMBER</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'phone'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>EMAIL</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'email'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>GENDER</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'gender'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>DATE OF BIRTH</Typography>
                        <Typography className={textClasses.value}>{path(['getDriver', 'dob'], fetchedDriver)
                          ? new Date(Number(path(['getDriver', 'dob'], fetchedDriver))).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                          : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>PHONE OS</Typography>
                        <Typography className={textClasses.value}>
                          {
                            pathOr('-', ['getDriver', 'phoneOS'], fetchedDriver)
}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>NATIONALITY</Typography>
                        <Typography className={textClasses.value}>
                          {pathOr('-', ['getDriver', 'nationality'], fetchedDriver)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>BANK NAME</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'bankName'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>BANK ACCOUNT NUMBER</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'bankAccountNumber'], fetchedDriver)}</Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={cardClasses.card}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>ID NUMBER</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'idNumber'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>DRIVING LICENSE NUMBER
                        </Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'drivingLicenseNumber'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>ID EXPIRY DATE</Typography>
                        <Typography className={textClasses.value}>{path(['getDriver', 'idExpiryDate'], fetchedDriver)
                          ? new Date(Number(path(['getDriver', 'idExpiryDate'], fetchedDriver))).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                          : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>DRIVING LICENSE EXPIRY DATE</Typography>
                        <Typography className={textClasses.value}>{path(['getDriver', 'drivingLicenseExpiryDate'], fetchedDriver)
                          ? new Date(Number(path(['getDriver', 'drivingLicenseExpiryDate'], fetchedDriver))).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                          : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>ID PHOTO</Typography>
                        <br />
                        <Typography className={textClasses.value}>{path(['getDriver', 'idPhoto'], fetchedDriver)
                          ? <ImageView imageName='ID Photo' width='100%' height={200} imageLink={path(['getDriver', 'idPhoto'], fetchedDriver)} />
                          : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>DRIVING LICENSE PHOTO</Typography>
                        <br />
                        <Typography className={textClasses.value}>{path(['getDriver', 'drivingLicensePhoto'], fetchedDriver)
                          ? <ImageView imageName='Driving License Photo' width='100%' height={200} imageLink={path(['getDriver', 'drivingLicensePhoto'], fetchedDriver)} />
                          : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={cardClasses.card}>
                    <Grid container>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>VEHICLE TYPE</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'vehicleType'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>VEHICLE LICENSE</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'vehicleLicense'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>VEHICLE PHOTO</Typography>
                        <br />
                        <Typography className={textClasses.value}>{path(['getDriver', 'vehiclePhoto'], fetchedDriver)
                          ? <ImageView imageName='Vehicle Photo' width='100%' height={200} imageLink={path(['getDriver', 'vehiclePhoto'], fetchedDriver)} />
                          : '-'}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>VEHICLE LICENSE PHOTO</Typography>
                        <br />
                        <Typography className={textClasses.value}>{path(['getDriver', 'vehicleLicensePhoto'], fetchedDriver)
                          ? <ImageView imageName='Vehicle License Photo' width='100%' height={200} imageLink={path(['getDriver', 'vehicleLicensePhoto'], fetchedDriver)} />
                          : '-'}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                  <div className={cardClasses.card}>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Country</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'address', 'country'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>City</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'address', 'city'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Postal Code</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'address', 'PostalCode'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Street</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'address', 'street'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Building</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'building'], fetchedDriver)}</Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Floor</Typography>
                        <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'address', 'floor'], fetchedDriver)}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <Typography className={textClasses.key}>Apartment</Typography>
                        <Typography className={textClasses.value}>
                          {
                            pathOr('-', ['getDriver', 'address', 'apartment'], fetchedDriver)
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <Typography className={textClasses.key}>Phone</Typography>
                        <Typography className={textClasses.value}>
                          {pathOr('-', ['getDriver', 'address', 'phone'], fetchedDriver)}
                        </Typography>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Tabs>
            </Grid>
            {console.log({ loading })}
            <Grid item xs={12} md={3}>
              <Button
                color='primary'
                variant='contained'
                disableElevation
                type='submit'
                size='large'
                style={{
                  margin: '0 0rem',
                  height: '5.7rem',
                  width: '100%',
                  fontSize: '2.5rem',
                }}
                loading={loading}
                onClick={() => history.push(`/drivers/${key}/edit`)}
              >
                EDIT
              </Button>
              <div className={cardClasses.card} style={{ height: '26.55rem', marginTop: '.7rem' }}>
                <Dialog label='Approve Account' open={dialogOpen} onClose={() => setDialogOpen(false)}>
                  <Select
                    label='Status'
                    value={approvedStatus}
                    onChange={(val) => setApprovedStatus(val.target.value)}
                    margin='normal'
                    key='accountType'
                    options={['approved', 'rejected']}
                    readOnly={approving}
                  />
                  <TextArea
                    label='Note'
                    value={approvedNotes}
                    onChange={(val) => setApprovedNotes(val.target.value)}
                    readOnly={approving}
                  />
                  <Button
                    color='primary'
                    variant='contained'
                    disableElevation
                    type='submit'
                    size='large'
                    style={{
                      margin: '0 0rem',
                      height: '5.7rem',
                      width: '100%',
                      fontSize: '2.5rem',
                    }}
                    loading={loading}
                    onClick={onApprove}
                  >
                    APPROVE / REJECT
                  </Button>
                </Dialog>
                <div>
                  <Grid container>
                    <Grid item xs={12}>
                      <Box display='flex' justifyContent='center' m={4}>
                        <Button color='secondary' onClick={() => setDialogOpen(true)}>
                          <Typography className={textClasses.totalsKey} style={{ color: '#EF414D' }}>APPROVE / REJECT</Typography>
                        </Button>
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={textClasses.key}>APPROVED STATUS</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'approved', 'status'], fetchedDriver)}</Typography>
                    </Grid>
                  </Grid>
                  <Divider className={cardClasses.divider} />
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography className={textClasses.key}>APPROVED NOTES</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={textClasses.value}>{pathOr('-', ['getDriver', 'approved', 'notes'], fetchedDriver) || '-'}</Typography>
                    </Grid>
                  </Grid>
                  <Divider className={cardClasses.divider} />
                  <Grid container>
                    <Grid item xs={8}>
                      <Typography className={textClasses.key}>APPROVED TIME</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <Typography className={textClasses.value}>{path(['getDriver', 'approved', 'time'], fetchedDriver)
                        ? new Date(Number(pathOr('-', ['getDriver', 'approved', 'time'], fetchedDriver))).toLocaleString('en-us', { day: 'numeric', month: 'short', year: 'numeric', hour: 'numeric', minute: 'numeric', hour12: false })
                        : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={alertOpen} autoHideDuration={5000} onClose={() => setAlertOpen(false)}>
        <Alert onClose={() => setAlertOpen(false)} severity='success'>
          Changed Approved Status Successfully
        </Alert>
      </Snackbar>
    </div>
  )
}

const useTextStyles = makeStyles((theme) => ({
  key: {
    color: theme.palette.info.main,
    fontSize: '1.6rem',
  },
  subKey: {
    color: theme.palette.info.main,
    fontSize: '1.5rem',
  },
  value: {
    color: theme.palette.text.secondary,
    fontSize: '1.4rem',
    fontWeight: 300,
  },
  header: {
    color: theme.palette.info.main,
    fontSize: '1.7rem',
    marginTop: '.56rem',
    marginBottom: '3.6rem',
  },
  totalsKey: {
    fontSize: '1.6rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))

const useCardStyles = makeStyles((theme) => ({
  card: {
    background: theme.palette.secondary.main,
    borderRadius: '.5rem',
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
    padding: '1rem',
    minHeight: '30rem',
  },
  divider: {
    background: 'gray',
    marginTop: '1rem',
    marginBottom: '1rem',
  },
}))
