import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import LinearProgress from '@material-ui/core/LinearProgress'
import { setTitle } from '../../../store/actions'
import useRequest from '../../../helpers/useRequest'
import * as endpoints from '../../../config/endpoints'
import { GET_ADMISSION_SERVICE_REQUEST } from '../../../gql/queries'
import { requestInfoCells } from './fields'
import { CardTab } from '../Admission/InfoCardTab'
import { RequestFieldTable } from './RequestFieldTable'
import { pathOr } from 'ramda'
import { nanoid } from 'nanoid'

export default function ServiceRequestPage ({ moduleStructure }) {
  const { key } = useParams()
  const { action } = useStateMachine({ setTitle })
  const { request } = useRequest()

  const { isLoading: loading, data: fetchedData } = useQuery(
    ['request', key],
    () => request({
      query: GET_ADMISSION_SERVICE_REQUEST,
      variables: { _id: key },
      endpoint: endpoints[moduleStructure.endpoint],
    }))

  useEffect(() => {
    action({ title: 'request' })
  }, []) // eslint-disable-line

  const [attributes, setAttributes] = useState()

  const fields = useMemo(() =>
    pathOr([], ['getAdmissionServiceRequest', 'serviceCopy', 'fields'], fetchedData),
  [fetchedData],
  )

  useEffect(() => {
    const serviceRequest = fetchedData?.getAdmissionServiceRequest
    if (!serviceRequest) return
    const attrsWithId = serviceRequest.attributes.map((attr) => ({
      ...attr,
      attrId: nanoid(),
      value:
        !Array.isArray(attr?.value?.attributes)
          ? attr.value
          : { ...attr.value, attributes: attr.value.attributes.map((a) => ({ ...a, attrId: nanoid() })) },
    }))

    setAttributes(attrsWithId)
  }, [fetchedData])

  /** @type {(function(string | { attrId: string, arrayId: string }, {valueId: string, valueText: string, value: any}): void)}  */
  const handleChange = useCallback((id, { valueId, valueText, value }) => {
    setAttributes((attributes) => {
      if (typeof id === 'string') {
        return attributes.map((a) => a.attrId === id ? ({ ...a, valueId, valueText, value }) : a)
      }

      return attributes.map((attrArr) => {
        // if attribute is not the array we're looking for. return early
        if (attrArr.attrId !== id.arrayId) {
          return attrArr
        }

        // for attributes in the array find the modified attribute and change it
        const updatedAttrs = attrArr.value.attributes.map((attr) => {
          if (attr.attrId !== id.attrId) {
            return attr
          }

          return { ...attr, value, valueId, valueText }
        })

        return { ...attrArr, value: { ...attrArr.value, attributes: updatedAttrs } }
      })
    })
  }, [setAttributes])

  if (loading || !attributes) {
    return (
      <div style={{ width: '95%', position: 'relative', margin: 'auto' }}>
        <LinearProgress />
      </div>
    )
  }

  return (
    <div style={{ paddingTop: 10 }}>
      <Grid container spacing={1}>
        <Grid item xs={12} md={12} style={{ minHeight: '20rem' }}>
          <CardTab fetchedData={fetchedData} cells={requestInfoCells} />
        </Grid>
        <Grid container item xs={12}>
          <RequestFieldTable attributes={attributes} fields={fields} handleChange={handleChange} />
        </Grid>
      </Grid>
    </div>
  )
}
