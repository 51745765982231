import React, { Fragment, useState } from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton'

import Dialog from './Dialog'
import * as Icons from '../../../Icons'
const VisibilityIcon = Icons.Visibility

export default function ImageList ({
  imageLink,
  width = 100,
  height = 100,
  imageName,
}) {
  const [dialogOpen, setDialogOpen] = useState(false)

  return (
    <>
      <Dialog label={imageName} open={dialogOpen} onClose={() => setDialogOpen(false)}><img src={imageLink} alt={imageName} /></Dialog>
      <Grid container spacing={1}>
        <ImageCard src={imageLink} setDialogOpen={setDialogOpen} width={width} height={height} />
      </Grid>
    </>
  )
}

const ImageCard = ({ setDialogOpen, src, width, height }) => {
  const outerClasses = useOuterStyles({ src, width, height })
  const containerClasses = useContainerStyles({ src })
  const contentClasses = useContentStyles({ src })
  const iconsClasses = useIconsStyles()

  const viewImage = () => {
    setDialogOpen(true)
  }

  return (
    <div className={outerClasses.root}>
      <div className={containerClasses.root}>
        <div className={contentClasses.root}>
          <IconButton onClick={viewImage} size='small'>
            <VisibilityIcon className={iconsClasses.root} />
          </IconButton>
        </div>
      </div>
    </div>
  )
}

const useOuterStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    background: 'transparent',
    fontFamily: 'inherit',
    border: `1px solid ${theme.palette.grey[100]}`,
    width: (props) => props.width,
    height: (props) => props.height,
    position: 'relative',
    padding: 2,
  },
}))

const useContainerStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    width: '100%',
    height: '100%',
    backgroundImage: (props) => `url(${props.src})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    '&:hover': {
      '& > div': {
        display: 'flex',
        opacity: 0.7,
      },
    },
  },
}))

const useContentStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    transition: 'opacity 1s ease-out',
    opacity: 0,
    background: theme.palette.common.black,
  },
}))

const useIconsStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.secondary,
  },
}))
