export const RecurringOrderFields = [
  {
    label: 'Order Id',
    key: 'checkoutId',
  },
  {
    label: 'Customer',
    key: 'customer.name',
  },
  {
    label: 'Vendor',
    key: 'vendor.name.en',
  },
  {
    label: 'Status',
    key: 'status',
  },
  {
    label: 'Total Price',
    key: 'totals.total',
    formatField: (R, cell, record) =>
      record.totalPrice.toFixed(2),
  },
]

export const RecurringOrderVariationsFields = [
  {
    label: 'Id',
    key: 'variation._id',
  },
  {
    label: 'Name',
    key: 'product',
    formatField: (R, cell, record) =>
      record?.name?.en || '-',
  },
  {
    label: 'price',
    key: 'price',
    formatField: (R, cell, record) =>
      record.discountedPrice && record.discountedPrice > 0 ? record.discountedPrice?.toFixed(2) : record.mainPrice?.toFixed(2) || '-',
  },
  {
    label: 'Qty',
    key: 'quantity',
  },
]
