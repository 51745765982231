import React from 'react'
import { propOr } from 'ramda'
import { useQuery } from 'react-query'
import { App as SendBirdApp } from 'sendbird-uikit'
import 'sendbird-uikit/dist/index.css'

import { GET_CURRENT_VENDOR } from '../../../gql/queries'
import * as endpoints from '../../../config/endpoints'
import useRequest from '../../../helpers/useRequest'
import { makeStyles } from '@material-ui/core/styles'

const sendBirdAppId = process.env.REACT_APP_SEND_BIRD_API_KEY

const SendBirdChat = ({ moduleStructure }) => {
  const { request } = useRequest()
  const classes = useStyles()

  const { data } = useQuery(['GET_CURRENT_VENDOR'], () => request({ query: GET_CURRENT_VENDOR, endpoint: endpoints[moduleStructure.endpoint] }))
  const { _id, name } = propOr({}, 'getCurrentVendor', data)

  return _id
    ? (
      <div className={classes.chatContainer}>
        <SendBirdApp
          appId={sendBirdAppId}
          userId={_id}
          nickname={name.en.toString()}
          colorSet={{
            '--sendbird-light-primary-400': '#083e48',
            '--sendbird-light-primary-300': '#f79427',
            '--sendbird-light-primary-100': '#f4c89c',
            '--sendbird-light-onlight-01': '#083e48',
            '--sendbird-light-onlight-02': '#083e48',
            '--sendbird-light-onlight-03': '#083e48',
            '--sendbird-message-input-border-active': '#083e48',
            '--sendbird-light-primary-200': '#f79427',
          }}
        />
      </div>
      )
    : null
}

export default SendBirdChat

const useStyles = makeStyles(() => ({
  chatContainer: {
    height: '90vh',
    backgroundColor: 'red',
    display: 'flex',
    marginTop: '10px',
  },
}))
