import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Drawer from '@material-ui/core/Drawer'
import Grid from '@material-ui/core/Grid'
import { useStateMachine } from 'little-state-machine'

import Logo from './Logo'
import ListItems from './ListItems'
import SidebarToggle from './SidebarToggle'
import definitions from '../../../config/definitions'
import parse from '../../../helpers/parseStructure'

const SidebarComponent = ({
  sidebarOpen,
  handleSidebarToggle,
}) => {
  const classes = useStyles()
  const { state } = useStateMachine()
  const structure = parse(state.structure)
  const sidebarItems = structure.sidebarItems
  const sidebarItemsGroups = structure.sidebarItemsGroups

  return (
    <>
      <Drawer
        variant='permanent'
        style={{ width: sidebarOpen ? definitions.drawerWidth : definitions.closedDrawerWidth }}
        classes={{ paper: `${classes.drawer} ${sidebarOpen ? classes.drawerOpen : classes.drawerClose}` }}
      >
        <Grid container direction='column' justify='space-between' alignItems='stretch' style={{ height: '100vh' }}>
          <Grid item style={{ width: '100%', maxWidth: 'none', height: '16.6vh' }}>
            <Logo />
          </Grid>
          <Grid item style={{ width: '100%', maxWidth: 'none', height: '75vh', overflowY: 'auto' }}>
            <ListItems sidebarOpen={sidebarOpen} sidebarItems={sidebarItems} sidebarItemsGroups={sidebarItemsGroups} />
          </Grid>
          <Grid item style={{ width: '100%', maxWidth: 'none', height: '8.4vh' }}>
            <SidebarToggle sidebarOpen={sidebarOpen} handleSidebarToggle={handleSidebarToggle} />
          </Grid>
        </Grid>
      </Drawer>
    </>
  )
}

export default SidebarComponent

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: definitions.drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    backgroundColor: theme.palette.secondary.main,
    boxShadow: theme.shadows[26],
    border: 'none',
  },
  drawerOpen: {
    width: definitions.drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: definitions.closedDrawerWidth,
  },
}))
