export const ADD_PRODUCT = `
mutation addProduct(
  $name: NameInput!
  $description: DescriptionInput
  $productCode: String!
  $taxonomies: [ID]
  $customAttributes: [CustomAttributeInput]
  $mainAttributes: [ID]
  $active: Boolean!
  $vendor: ID
  $initialPrice: Float
  $initialStock: [ProductStockInput]
  $tags: [String]
  $order: Int
  $similarProducts: [ID]
  $weight: Float
  $measurementUnit: String
) {
  addProduct(
    name: $name
    description: $description
    productCode: $productCode
    taxonomies: $taxonomies
    customAttributes: $customAttributes
    mainAttributes: $mainAttributes
    active: $active
    vendor: $vendor
    initialPrice: $initialPrice
    initialStock: $initialStock
    tags: $tags
    order: $order
    similarProducts: $similarProducts
    weight: $weight
    measurementUnit: $measurementUnit 
  ) {
    _id
    key: _id
    name {
      en
      ar
    }
  }
}
`

export const ADD_PRODUCT_CLEVERSELL = `
mutation addProduct(
  $name: ProductNameObjectInput!
  $description: ProductNameObjectInput
  $segmentId: String
  $code: String!
  $taxonomies: [String]
  $customAttributes: [ProductCustomAttributeInput]
  $active: Boolean!
  $hasStock: Boolean
  $tags: [String]
  $images: [String]
  $colors: [String]
  $sizes: [String]
  $variations: [ProductVariationInput]
  $loyalty: ProductLoyaltyInput
  $itemsPerBox: Int
  $isFeatured: Boolean
) {
  addProduct(
    name: $name
    description: $description
    code: $code
    taxonomies: $taxonomies
    customAttributes: $customAttributes
    active: $active
    hasStock: $hasStock
    tags: $tags
    images: $images
    colors: $colors
    sizes: $sizes
    variations: $variations
    segmentId: $segmentId
    loyalty: $loyalty
    itemsPerBox: $itemsPerBox
    isFeatured: $isFeatured
  ) {
    _id
    key: _id
    name {
      en
      ar
    }
  }
}
`

export const GET_PRODUCTS_CLEVERSELL = `
  query getProducts(
    $searchTerm: String
    $active: Boolean
    $page: Int
    $pageSize: Int
    $sort: ProductSort
  ) {
    getProducts(
      searchTerm: $searchTerm
      active: $active
      page: $page
      pageSize: $pageSize
      sort: $sort
      isLoyalty: false
    ) {
      totalCount
      items {
        key: _id
          name {
            en
            ar
          }
          code
          active
      }
    }
  }
`

export const GET_LOYALTY_PRODUCTS_CLEVERSELL = `
  query getProducts(
    $searchTerm: String
    $active: Boolean
    $page: Int
    $pageSize: Int
    $sort: ProductSort
  ) {
    getProducts(
      searchTerm: $searchTerm
      active: $active
      page: $page
      pageSize: $pageSize
      sort: $sort
      isLoyalty: true
    ) {
      totalCount
      items {
        key: _id
          name {
            en
            ar
          }
          code
          active
      }
    }
  }
`

export const GET_PRODUCT_CLEVERSELL = `
  query getProduct(
    $_id: ID!
  ) {
    getProduct(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
      description {
        en
        ar
      }
      code
      segmentId
      loyalty {
        isLoyalty
        points
        loyaltyOnly
      }
      isFeatured
      itemsPerBox
      taxonomies {
      _id
        key: _id
        parent {
        _id
        name {
        en
        }
        }
        name {
          en
          ar
        }
      }
      images
      tags
      customAttributes {
        key
        value
        valueType
      }
      colors {
        key: _id
        name {
          en
        }
        _id
      }
      sizes {
        key: _id
        name {
          en
        }
        _id
      }
      styles {
        key: _id
        name {
          en
        }
        _id
      }
      materials {
        key: _id
        name {
          en
        }
        _id
      }
      applications {
        key: _id
        name {
          en
        }
        _id
      }
      types {
        key: _id
        name {
          en
        }
        _id
      }
      finishes {
        key: _id
        name {
          en
        }
        _id
      }
      grades {
        key: _id
        name {
          en
        }
        _id
      }
      variations {
      _id
      active
      code
      color {
              key: _id
        name {
          en
        }
        _id
      }
      size {
              key: _id
        name {
          en
        }
        _id
      }
      images
      price {
      discount
         mainPrice 
      }
      area
      grade {
        _id
        key: _id
        name {
          en
        }
      }
      stock {
      branch {
      _id
      key: _id
      name {
      en
      }
      }
      amount
      }
      barcode {
      value
      }
      }
      active
    }
  }
`

export const EDIT_PRODUCT_CLEVERSELL = `
  mutation editProduct(
    $_id: ID!
    $name: ProductNameObjectInput
    $description: ProductNameObjectInput
    $customAttributes: [ProductCustomAttributeInput]
    $segmentId: String
    $images: [String]
    $active: Boolean
    $variations: [ProductVariationInput]
    $taxonomies: [String]
    $tags: [String]
    $loyalty: ProductLoyaltyInput
    $colors: [String]
    $sizes: [String]
    $itemsPerBox: Int
    $isFeatured: Boolean
  ) {
    editProduct(
      _id: $_id
      name: $name
      description: $description
      customAttributes: $customAttributes
      images: $images
      active: $active
      variations: $variations
      tags: $tags
      taxonomies: $taxonomies
      segmentId: $segmentId
      loyalty: $loyalty
      colors: $colors
      sizes: $sizes
      itemsPerBox: $itemsPerBox
      isFeatured: $isFeatured
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
      active
    }
  }
`

export const DELETE_PRODUCT_CLEVERSELL = `
  mutation deleteProduct(
    $_id: ID!
  ) {
    deleteProduct(
      _id: $_id
    ) {
      _id
    }
  }
`

export const ADD_PRODUCT_COLOR = `
mutation addProductColor(
  $name: ProductNameObjectInput!
  $code: String!
  $value: String!
  $images: [String]
) {
  addProductColor(
    name: $name
    code: $code
    value: $value
    images: $images
  ) {
    _id
    key: _id
    name {
      en
      ar
    }
  }
}
`

export const GET_PRODUCT_COLORS = `
query getProductColors (
  $searchTerm: String
  $page: Int
  $pageSize: Int
  $sort: ProductColorSort
) {
  getProductColors (
    searchTerm: $searchTerm
    page: $page
    pageSize: $pageSize
    sort: $sort
  ) {
    totalCount
    items {
      _id
      key: _id
      name {
        en
        ar
      }
      value
      code
      images
    }
  }
}
`

export const GET_PRODUCT_COLOR = `
query getProductColor ($_id: ID!){
  getProductColor (_id: $_id) {
    _id
    key: _id
    name {
      en
      ar
    }
    value
    code
    images
  }
}
`

export const EDIT_PRODUCT_COLOR = `
mutation EDIT_PRODUCT_COLOR(
  $_id: ID!
  $name: ProductNameObjectInput
  $code: String
  $value: String
  $images: [String]
) {
  editProductColor(
    _id: $_id 
    name: $name
    code: $code
    value: $value
    images: $images
  ) {
    _id
    key: _id
  }
}
`

export const DELETE_PRODUCT_COLOR = `
  mutation deleteProductColor($_id: ID!) {
    deleteProductColor(_id: $_id) {
      _id
      key: _id
    }
  }
`

const createListingForProductAttribute = (name, sort) => `
query ${name} (
  $searchTerm: String
  $page: Int
  $pageSize: Int
  ${sort ? `$sort: ${sort}` : ''}
) {
  ${name} (
    searchTerm: $searchTerm
    page: $page
    pageSize: $pageSize
    ${sort ? 'sort: $sort' : ''}
  ) {
    totalCount
    items {
      _id
      key: _id
      name {
        en
        ar
      }
      code
      images
    }
  }
}
`

const createSingleForProductAttribute = (name) => `
query ${name} ($_id: ID!){
  ${name} (_id: $_id) {
    _id
    key: _id
    name {
      en
      ar
    }
    code
    images
  }
}
`

const createDeleteForProductAttribute = (name) => `
  mutation ${name}($_id: ID!) {
    ${name} (_id: $_id) {
      _id
      key: _id
    }
  }
`

const createAddForProductAttribute = (name) => `
mutation ${name} (
  $name: ProductNameObjectInput!
  $code: String!
  $images: [String]
) {
  ${name} (
    name: $name
    code: $code
    images: $images
  ) {
    _id
    key: _id
  }
}
`

const createEditForProductAttribute = (name) => `
mutation ${name}(
  $_id: ID!
  $name: ProductNameObjectInput
  $code: String
  $images: [String]
) {
  ${name}(
    _id: $_id 
    name: $name
    code: $code
    images: $images
  ) {
    _id
    key: _id
  }
}
`

// Size
export const GET_PRODUCT_SIZES = createListingForProductAttribute('getProductSizes', 'ProductSizeSort')
export const GET_PRODUCT_SIZE = createSingleForProductAttribute('getProductSize')
export const ADD_PRODUCT_SIZE = createAddForProductAttribute('addProductSize')
export const EDIT_PRODUCT_SIZE = createEditForProductAttribute('editProductSize')
export const DELETE_PRODUCT_SIZE = createDeleteForProductAttribute('deleteProductSize')

// Application
export const GET_PRODUCT_APPLICATIONS = createListingForProductAttribute('getProductApplications', 'ProductApplicationSort')
export const GET_PRODUCT_APPLICATION = createSingleForProductAttribute('getProductApplication')
export const ADD_PRODUCT_APPLICATION = createAddForProductAttribute('addProductApplication')
export const EDIT_PRODUCT_APPLICATION = createEditForProductAttribute('editProductApplication')
export const DELETE_PRODUCT_APPLICATION = createDeleteForProductAttribute('deleteProductApplication')

// Type
export const GET_PRODUCT_TYPES = createListingForProductAttribute('getProductTypes', 'ProductTypeSort')
export const GET_PRODUCT_TYPE = createSingleForProductAttribute('getProductType')
export const ADD_PRODUCT_TYPE = createAddForProductAttribute('addProductType')
export const EDIT_PRODUCT_TYPE = createEditForProductAttribute('editProductType')
export const DELETE_PRODUCT_TYPE = createDeleteForProductAttribute('deleteProductType')

// Material
export const GET_PRODUCT_MATERIALS = createListingForProductAttribute('getProductMaterials', 'ProductMaterialSort')
export const GET_PRODUCT_MATERIAL = createSingleForProductAttribute('getProductMaterial')
export const ADD_PRODUCT_MATERIAL = createAddForProductAttribute('addProductMaterial')
export const EDIT_PRODUCT_MATERIAL = createEditForProductAttribute('editProductMaterial')
export const DELETE_PRODUCT_MATERIAL = createDeleteForProductAttribute('deleteProductMaterial')

// Grade
export const GET_PRODUCT_GRADES = createListingForProductAttribute('getProductGrades', 'ProductGradeSort')
export const GET_PRODUCT_GRADE = createSingleForProductAttribute('getProductGrade')
export const ADD_PRODUCT_GRADE = createAddForProductAttribute('addProductGrade')
export const EDIT_PRODUCT_GRADE = createEditForProductAttribute('editProductGrade')
export const DELETE_PRODUCT_GRADE = createDeleteForProductAttribute('deleteProductGrade')

// Grade
export const GET_PRODUCT_FINISHES = createListingForProductAttribute('getProductFinishes', 'ProductFinishSort')
export const GET_PRODUCT_FINISH = createSingleForProductAttribute('getProductFinish')
export const ADD_PRODUCT_FINISH = createAddForProductAttribute('addProductFinish')
export const EDIT_PRODUCT_FINISH = createEditForProductAttribute('editProductFinish')
export const DELETE_PRODUCT_FINISH = createDeleteForProductAttribute('deleteProductFinish')

export const GET_COLLECTIONS = `
query GET_COLLECTIONS (
  $searchTerm: String
  $page: Int
  $pageSize: Int
  $sort: CollectionsSort
) {
  getCollections (
    searchTerm: $searchTerm
    page: $page
    pageSize: $pageSize
    sort: $sort
  ) {
    totalCount
    items {
      _id
      key: _id
      code
      name {
        en
      }
    }
  }
}
`
export const DELETE_COLLECTION = `
  mutation deleteCollection($_id: ID!) {
    deleteCollection(_id: $_id) {
      _id
    }
  }
`

export const GET_COLLECTION = `
query GET_COLLECTION($_id: ID!) {
  getCollection(_id: $_id) {
    key: _id
    name {
      en
      ar
    }
    description {
      en
      ar
    }
    code
    taxonomies {
      key: _id
      name {
        en
        ar
      }
    }
    images
    isFeatured
    customAttributes {
      key
      value
      valueType
    }
    colors {
      key: _id
      name {
        en
      }
      _id
    }
    sizes {
      key: _id
      name {
        en
      }
      _id
    }
    styles {
      key: _id
      name {
        en
      }
      _id
    }
    materials {
      key: _id
      name {
        en
      }
      _id
    }
    applications {
      key: _id
      name {
        en
      }
      _id
    }
    types {
      key: _id
      name {
        en
      }
      _id
    }
    finishes {
      key: _id
      name {
        en
      }
      _id
    }
    grades {
      key: _id
      name {
        en
      }
      _id
    }
    products {
      key: _id
      name {
        en
      }
    }
    price {
      mainPrice
      discount
    }
    active
    tags
  }
}
`

export const EDIT_COLLECTION = `
mutation EDIT_COLLECTION(
  $_id: ID!
  $name: ProductNameObjectInput!
  $description: ProductNameObjectInput
  $code: String!
  $taxonomies: [String]
  $customAttributes: [ProductCustomAttributeInput]
  $active: Boolean!
  $tags: [String]
  $images: [String]
  $colors: [String]
  $sizes: [String]
  $materials: [String]
  $applications: [String]
  $finishes: [String]
  $grades: [String]
  $styles: [String]
  $types: [String]
  $products: [String]
  $price: ProductPriceInput
  $isFeatured: Boolean
) {
  editCollection(
    _id: $_id
    name: $name
    description: $description
    customAttributes: $customAttributes
    code: $code
    active: $active
    tags: $tags
    images: $images
    colors: $colors
    sizes: $sizes
    grades: $grades
    taxonomies: $taxonomies
    applications: $applications
    materials: $materials
    finishes: $finishes
    styles: $styles
    types: $types
    products: $products
    price: $price
    isFeatured: $isFeatured
  ) {
    key: _id
    _id
    name {
      en
      ar
    }
  }
}
`

export const ADD_COLLECTION = `
mutation ADD_COLLECTION(
  $name: ProductNameObjectInput!
  $description: ProductNameObjectInput
  $code: String!
  $taxonomies: [String]
  $customAttributes: [ProductCustomAttributeInput]
  $active: Boolean!
  $tags: [String]
  $images: [String]
  $colors: [String]
  $sizes: [String]
  $materials: [String]
  $applications: [String]
  $finishes: [String]
  $grades: [String]
  $styles: [String]
  $types: [String]
  $products: [String]
  $price: ProductPriceInput
  $isFeatured: Boolean
) {
  addCollection(
    name: $name
    description: $description
    code: $code
    taxonomies: $taxonomies
    customAttributes: $customAttributes
    active: $active
    tags: $tags
    images: $images
    colors: $colors
    sizes: $sizes
    grades: $grades
    applications: $applications
    materials: $materials
    finishes: $finishes
    styles: $styles
    types: $types
    products: $products
    price: $price
    isFeatured: $isFeatured
  ) {
    _id
    key: _id
    name {
      en
      ar
    }
  }
}
`

export const EDIT_PRODUCT = `
  mutation editProduct(
    $_id: ID!
    $name: NameInput
    $description: DescriptionInput
    $productCode: String!
    $taxonomies: [ID]
    $customAttributes: [CustomAttributeInput]
    $images: [String]
    $mainAttributes: [ID]
    $active: Boolean
    $vendor: ID
    $tags: [String]
    $order: Int
    $similarProducts: [ID]
    $marginPercentage: Float
    $weight: Float
    $measurementUnit: String
  ) {
    editProduct(
      _id: $_id
      name: $name
      description: $description
      productCode: $productCode
      taxonomies: $taxonomies
      customAttributes: $customAttributes
      images: $images
      mainAttributes: $mainAttributes
      active: $active
      vendor: $vendor
      tags: $tags
      order: $order
      similarProducts: $similarProducts
      marginPercentage: $marginPercentage
      weight: $weight
      measurementUnit: $measurementUnit
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const EDIT_PRODUCT_WITH_SELF_REDUNDANCIES = `
  mutation editProduct(
    $_id: ID!
    $name: NameInput
    $description: DescriptionInput
    $productCode: String!
    $taxonomies: [ID]
    $customAttributes: [CustomAttributeInput]
    $images: [String]
    $mainAttributes: [ID]
    $active: Boolean
    $vendor: ID
    $tags: [String]
    $order: Int
    $similarProducts: [ID]
    $marginPercentage: Float
    $weight: Float
    $measurementUnit: String
  ) {
    editProduct(
      _id: $_id
      name: $name
      description: $description
      productCode: $productCode
      taxonomies: $taxonomies
      customAttributes: $customAttributes
      images: $images
      mainAttributes: $mainAttributes
      active: $active
      vendor: $vendor
      tags: $tags
      order: $order
      similarProducts: $similarProducts
      marginPercentage: $marginPercentage
      weight: $weight
      measurementUnit: $measurementUnit
      updateSelfRedundancies: true
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const GET_PRODUCT = `
  query getProduct(
    $_id: ID!
  ) {
    getProduct(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
      order
      description {
        en
        ar
      }
      productCode
      taxonomies {
        key: _id
        name {
          en
          ar
        }
      }
      images
      weight
      mainAttributes {
        key: _id
        name {
          en
          ar
        }
      }
      similarProducts {
        key: _id
      }
      customAttributes {
        key
        value
        valueType
      }
      active
      tags
      vendor {
        key: _id
        name {
          en
        }
      }
      marginPercentage
      measurementUnit
    }
  }
`

export const DELETE_PRODUCT = `
  mutation deleteProduct(
    $_id: ID!
  ) {
    deleteProduct(
      _id: $_id
    ) {
      _id
    }
  }
`

export const GET_PRODUCTS = `
  query getProducts(
    $name: String
    $searchTerm: String
    $vendor: ID
    $active: Boolean
    $page: Int
    $pageSize: Int
    $sort: ProductSort
  ) {
    getProducts(
      name: $name
      searchTerm: $searchTerm
      active: $active
      vendor: $vendor
      page: $page
      pageSize: $pageSize
      sort: $sort
      getProductsWithNoVariations: true
    ) {
      totalCount
      items {
        key: _id
        product {
          key: _id
          name {
            en
            ar
          }
          productCode
          active
          vendor {
            name {
              en
              ar
            }
          }
          marginPercentage
          measurementUnit
        }
      }
    }
  }
`

export const GET_PLAYERS_CLEVERSELL = `
query GET_PLAYERS(
  $searchTerm: String
  $sort: PlayersSort
  $page: Int
  $pageSize: Int
) {
  getPlayers(
    searchTerm: $searchTerm
    sort: $sort
    page: $page
    pageSize: $pageSize
  ) {
    totalCount
    items {
      _id
      key: _id
      type
      name
      nameObject {
        en
        ar
      }
      displayName
      phone
      email
      gender
      createdAt
    }
  }
}
`

export const GET_SERVICE_PROVIDERS = `
query GET_PLAYERS(
  $searchTerm: String
  $sort: PlayersSort
  $page: Int
  $pageSize: Int
) {
  getPlayers(
    searchTerm: $searchTerm
    sort: $sort
    page: $page
    pageSize: $pageSize
    type: "serviceProvider"
    isApproved: true
  ) {
    totalCount
    items {
      _id
      key: _id
      type
      name
      nameObject {
        en
        ar
      }
      displayName
      phone
      email
      gender
      createdAt
    }
  }
}
`

export const GET_SERVICE_PROVIDERS_UNAPPROVED = `
query GET_PLAYERS(
  $searchTerm: String
  $sort: PlayersSort
  $page: Int
  $pageSize: Int
) {
  getPlayers(
    searchTerm: $searchTerm
    sort: $sort
    page: $page
    pageSize: $pageSize
    type: "serviceProvider"
    isApproved: false
  ) {
    totalCount
    items {
      _id
      key: _id
      type
      name
      nameObject {
        en
        ar
      }
      displayName
      phone
      email
      gender
      createdAt
    }
  }
}
`

export const LOGIN = `
  mutation login($username: String!, $password: String!, $accountType: String!) {
    login(
      username: $username,
      password: $password,
      accountType: $accountType,
    )
  }
`

export const FORGET_PASSWORD_SERVICES = `
mutation forgotPassword(
  $username: String!
  $verificationStrategy: String!
  $accountType: String!
) {
  forgotPassword(
    username: $username
    verificationStrategy: $verificationStrategy
    accountType: $accountType
  )
}`

export const VERIFY_PIN = `
mutation verifyPin(
  $username: String!
  $verificationStrategy: String!
  $accountType: String!
  $pin: String!
) {
  verifyPin(
    username: $username
    verificationStrategy: $verificationStrategy
    accountType: $accountType
    pin: $pin
  )
}`

export const RESET_PASSWORD_SERVICES = `
mutation resetPassword(
  $token: String!
  $password: String!
  $accountType: String!
) {
  resetPassword(
    token: $token
    password: $password
    accountType: $accountType
  )
}`

export const GET_MAIN_ATTRIBUTES = `
  query getMainAttributes(
    $taxonomies: [ID]
    $searchTerm: String
    $mainAttributeTypeName: NameInput
    $page: Int
    $pageSize: Int
  ) {
    getMainAttributes(
      taxonomies: $taxonomies
      searchTerm: $searchTerm
      mainAttributeTypeName: $mainAttributeTypeName
      pageSize: $pageSize
      page: $page
    ) {
      totalCount
      items{
        _id
        key: _id
        name {
          en
          ar
        }
        value
        mainAttributeType {
          _id
          name{
            en
            ar
          }
        }
        meta {
          key
          value
        }
        code
      }
    }
  }
`

export const GET_MAIN_ATTRIBUTES_COUNTRIES = `
  query getMainAttributesCountries($searchTerm: String, $sort: MainAttributeSort, $page: Int = 1) {
    getMainAttributes(
      searchTerm: $searchTerm
      mainAttributeTypeName: { en: "country of origin" }
      pageSize: 30
      page: $page
      sort: $sort
    ) {
      totalCount
      items{
        _id
        key: _id
        name {
          en
          ar
        }
        code
      }
    }
  }
`

export const GET_MAIN_ATTRIBUTE = `
  query getMainAttribute(
    $_id: ID!
  ){
    getMainAttribute(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
      code
      value
      mainAttributeType {
        key: _id
        name {
          en
          ar
        }
      }
      meta {
        key
        value
      }
    }
  }
`

export const GET_MAIN_ATTRIBUTE_SLIM = `
  query getMainAttribute(
    $_id: ID!
  ){
    getMainAttribute(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
      code
      value
    }
  }
`

export const GET_MAIN_ATTRIBUTES_SLIM = `
  query getMainAttributes(
    $taxonomies: [ID]
    $searchTerm: String
  ) {
    getMainAttributes(
      taxonomies: $taxonomies
      searchTerm: $searchTerm
      pageSize: 30
      page: 1
    ) {
      items {
        key: _id
        name {
          en
        }
      }
    }
  }
`

export const GET_VENDOR_SLIM = `
  query getVendor(
    $_id: ID!
  ){
    getVendor(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
    }
  }
`

export const GET_VENDORS_SLIM = `
  query getVendors ($searchTerm: String) {
    getVendors (searchTerm: $searchTerm page:1 pageSize: 30) {
      items {
        key: _id
        name {
          en
        }
      }
    }
  }
`

export const GET_BRANCH_SLIM = `
  query getBranch (
    $_id: ID!
  ) {
    getBranch (
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
    }
  }
`

export const GET_BRANCHES_SLIM = `
  query getBranches (
    $searchTerm: String
  ) {
    getBranches (
      page: 1
      searchTerm: $searchTerm
      pageSize: 30
    ) {
      totalCount
      items {
        key: _id
        name {
          en
          ar
        }
      }
    }
  }
`

export const GET_PRODUCTS_SLIM = `
  query getProducts(
    $searchTerm: String
  ) {
    getProducts(
      searchTerm: $searchTerm
      page: 1
      pageSize: 30
      getProductsWithNoVariations: true
    ) {
      totalCount
        items {
          key: _id
          product {
            key: _id
            name {
              en
              ar
            }
          }
      }
    }
  }
`

export const GET_PRODUCT_SLIM = `
  query getProduct(
    $_id: ID!
  ) {
    getProduct(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
    }
  }
`

export const GET_PRODUCT_WITH_VARIATIONS_SLIM = `
  query getProductWithVariations(
    $_id: ID!
  ) {
    getProductWithVariations(
      _id: $_id
    ) {
      key: _id
      product {
        name {
          en
          ar
        }
      }
    }
  }
`

export const NEW_UPLOAD = `
mutation NEW_UPLOAD($files: [Upload!]!) {
  uploadFiles(files: $files, type: "default", token: "xtnxdNZU9IK4Kr6cPSk5P") {
    urls
  }
}
`

export const UPLOAD = `
  mutation upload (
    $files: [FileInput]
  ) {
    upload (
      files: $files
    )
  }
`

export const GET_VARIATIONS = `
  query getVariations(
    $searchTerm: String
    $product: String
    $page: Int
    $pageSize: Int
    $sort: VariationSort
  ) {
    getVariations(
      searchTerm: $searchTerm
      product: $product
      page: $page
      pageSize: $pageSize
      sort: $sort
      isCustom: false
    ) {
      totalCount
      items {
        key: _id
        product {
          name {
            en
          }
          vendor {
            name {
              en
            }
          }
        }
        price {
          mainPrice
          discountedPrice
        }
        stock {
          branch {
            name {
              en
            }
          }
          amount
        }
        mainAttributes {
          name {
            en
            ar
          }
          mainAttributeType {
            name {
              en
              ar
            }
          }
        }
        serialNumber
        active
      }
    }
  }
`

export const GET_VARIATION = `
  query getVariation(
    $_id: ID!
  ) {
    getVariation(
      _id: $_id
    ) {
      key: _id
      product {
        key: _id
        name {
          en
          ar
        }
      }
      images
      mainAttributes {
        key: _id
        name {
          en
          ar
        }
      }
      price {
        mainPrice
        discountedPrice
      }
      currencyBasedPrices {
        mainPrice
        discountedPrice
        currency
      }
      stock {
        amount
        branch {
          key: _id
          name {
            en
            ar
          }
        }
      }
      hasStock
      active
      serialNumber
    }
  }
`

export const DELETE_VARIATION = `
  mutation deleteVariation(
    $_id: ID!
  ) {
    deleteVariation(
      _id: $_id
    ) {
      _id
    }
  }
`

export const EDIT_VARIATION = `
  mutation editVariation(
    $_id: ID!
    $product: String
    $mainAttributes: [ID]
    $price: PriceInput
    $currencyBasedPrices: [CurrencyBasedPricesInput]
    $stock: [StockInput]
    $active: Boolean
    $images: [String]
    $serialNumber: String
    $hasStock: Boolean
  ) {
    editVariation(
      _id: $_id
      product: $product
      mainAttributes: $mainAttributes
      price: $price
      currencyBasedPrices: $currencyBasedPrices
      stock: $stock
      active: $active
      images: $images
      serialNumber: $serialNumber
      hasStock: $hasStock
    ) {
      key: _id
      _id
    }
  }
`

export const GET_PRODUCT_REQUEST = `
  query getProductRequest($_id: ID!) {
    getProductRequest(_id: $_id) {
      key: _id
      name {
        en
        ar
      }
      description {
        en
        ar
      }
      price {
        mainPrice
        discountedPrice
      }
      images
      hasStock
      stock
      status
      note
      countryOfOrigin {
        _id
        name {
          en
        }
      }
      owner {
        ... on Vendor {
          key: _id
          name {
            en
            ar
          }
        }
      }
      ownerType
      createdAt
    }
  }
`

export const GET_PRODUCT_REQUESTS = `
  query getProductRequests (
    $searchTerm: String
    $page: Int
    $pageSize: Int
    $sort: ProductRequestSort
  ) {
    getProductRequests (
      searchTerm: $searchTerm
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        key: _id
        name {
          en
          ar
        }
        countryOfOrigin {
          _id
          name {
            en
          }
        }
        owner {
          ...on Vendor {
            name {
              en
              ar
            }
          }
        }
        status
        ownerType
        price {
          mainPrice
          discountedPrice
        }
      }
    }
  }
`

export const EDIT_PRODUCT_REQUEST = `
  mutation editProductRequest (
    $_id: ID!
    $name: ProductRequestEnArInput
    $description: ProductRequestEnArInput
    $price: ProductRequestPriceInput
    $images: [String]
    $status: String
    $note: String
    $hasStock: Boolean
    $stock: Int
    $countryOfOrigin: ID
  ) {
    editProductRequest (
      _id: $_id
      name: $name
      description: $description
      price: $price
      images: $images
      status: $status
      note: $note
      hasStock: $hasStock
      stock: $stock
      countryOfOrigin: $countryOfOrigin
    ) {
      key: _id
    }
  }
`

export const DELETE_PRODUCT_REQUEST = `
  mutation deleteProductRequest (
    $_id: ID!
  ) {
    deleteProductRequest (
      _id: $_id
    ) {
      key: _id
    }
  }
`

export const GET_FULFILLMENTS = `
query GET_FULLFILMENTS(
  $searchTerm: String
  $page: Int
  $pageSize: Int
  $sort: FulfillmentSort
  $status: String
) {
  getFulfillments(
    searchTerm: $searchTerm
    status: $status
    hasOrders: true
    page: $page
    pageSize: $pageSize
    sort: $sort
  ) {
    totalCount
    items {
      _id
      key: _id
      checkoutId
      totals {
        total
        subtotal
      }

      paymentMethod
      status
      createdAt

      orders {
        key: _id
        paymentMethod
        createdAt
        orderId
        customer {
          name
          phone
        }
        checkoutId
        status
        totals {
          total
          subtotal
          shipping
        }
        statusList
        vendorCopy {
          name {
            en
            ar
          }
        }
      }
    }
  }
}
`

export const GET_REQUESTS_FULFILLMENT = `
query GET_FULLFILMENTS(
  $searchTerm: String
  $page: Int
  $pageSize: Int
  $sort: FulfillmentSort
) {
  getFulfillments(
    searchTerm: $searchTerm
    status: "pending"
    page: $page
    pageSize: $pageSize
    sort: $sort
  ) {
    totalCount
    items {
      _id
      key: _id
      checkoutId
      totals {
        total
        subtotal
      }

      paymentMethod
      status
      createdAt

    orderRequests {
      orderRequests {
        key: _id
        variation {
          product {
            name {
              en
            }
          }
        }
        quantity
        price
        vendor {
          name {
            en
          }
        }
        checkoutId
        paymentMethod
        shippingSlots {
          _id
          from {
            hours
            minutes
          }
          to {
            hours
            minutes
          }
        }
      }
    }
    }
  }
}
`

export const GET_FULFILLMENT_STATUS_LIST = `
  query GET_FULFILLMENT_STATUS_LIST {
    getFulfillmentStatusList
  }
`

export const GET_OFFLINE_FULFILLMENT = `
query GET_FULFILMENT($offlineToken: String) {
  getFulfillment(offlineToken: $offlineToken) {
    _id
    key: _id
    status
  }
}
`

export const EDIT_FULFILLMENT = `
mutation EDIT_FULFILLMENT(
  $_id: String
  $status: String
  $shippingDate: String
) {
  editFulfillment(
    offlineToken: $_id
    status: $status
    shippingDate: $shippingDate
  ) {
    _id
    key: _id
  }
}
`

export const EDIT_FULFILLMENT_WITH_ID = `
mutation EDIT_FULFILLMENT(
  $_id: ID
  $status: String
  $shippingDate: String
) {
  editFulfillment(
    _id: $_id
    status: $status
    shippingDate: $shippingDate
  ) {
    _id
    key: _id
  }
}
`

export const GET_FULFILLMENT = `
query GET_FULLFILMENT($_id: ID!) {
  getFulfillment(_id: $_id) {
    _id
    key: _id
    checkoutId
    totals {
      total
      subtotal
      shipping
      fees
      margin
      discount {
        discountType
        amount
      }
    }

    customer {
      name
      phone
      isBusiness
    }

    shipping {
      apartment
      email
      floor
      street
      building
      phone
      postalCode
      city
      country
      state
      location {
        longitude
        latitude
      }
    }

    paymentMethod
    status
    createdAt
    orderRequests {
      orderRequests {
        key: _id
        variation {
          product {
            name {
              en
            }
          }
        }
        quantity
        price
        vendor {
          name {
            en
          }
        }
        checkoutId
        paymentMethod
        shippingSlots {
          _id
          from {
            hours
            minutes
          }
          to {
            hours
            minutes
          }
        }
      }
    }
    orders {
      key: _id
      paymentMethod
      createdAt
      orderId
      customer {
        name
        phone
      }
      checkoutId
      status
      totals {
        total
        subtotal
        shipping
      }
      statusList
      vendorCopy {
        name {
          en
          ar
        }
      }
    }
  }
}
`

export const GET_ORDERS = `
  query getOrders(
    $orderId: String
    $searchTerm: String
    $includeTemp: Boolean
    $page: Int
    $pageSize: Int
    $sort: OrderSort
    $isDeleted: Boolean
  ) {
    getOrders(
      orderId: $orderId
      searchTerm: $searchTerm
      includeTemp: $includeTemp
      page: $page
      pageSize: $pageSize
      sort: $sort
      isDeleted: $isDeleted
    ) {
      totalCount
      items {
        key: _id
        paymentMethod
        createdAt
        orderId
        customer {
          name
          phone
        }
        checkoutId
        status
        totals {
          total
          subtotal
          shipping
        }
        statusList
        vendorCopy {
          name {
            en
            ar
          }
        }
      }
    }
  }
`

export const GET_ORDERS_CLEVERSELL = `
  query getOrders(
    $searchTerm: String
    $page: Int
    $pageSize: Int
    $sort: OrderSort
  ) {
    getOrders(
      searchTerm: $searchTerm
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        key: _id
        paymentMethod
        createdAt
        orderId
        player {
          name
          phone
        }
        status {
        name
        type
        }
        totals {
          total
          subtotal
          shipping
        }
      }
    }
  }
`

export const GET_ORDER_CLEVERSELL = `
  query getOrder($_id: ID){
  getOrder(_id: $_id){
  _id
  createdAt
  orderId
  offlineOrderId
  original {
  isOriginal
  original
  }
  player {
  _id
  name
  email
  phone
  }
  paymentMethod
  products {
  itemPrice
  product {
  _id
  name {
  ar
  en
  }
  }
  quantity
  totalPrice
  variation {
  _id
    price {
    discount
    mainPrice
    }
    images
  }
  }
  status {
  name
  type
  }
  totals {
  discount {
  amount
  }
  total
  shipping
  subtotal
  taxes
  }
  }
  }
`

export const EDIT_ORDER = `
  mutation editOrder($_id: ID! $status: String $shippingDate: String $pickUpDate: String $pickUpDuration: Float $staffComment: String $alternativeShippingDates: [String] $totals: OrderTotalsInput $weight: Float $selectedSlot: CustomerShippingSlotInput) {
    editOrder(_id: $_id status: $status shippingDate: $shippingDate pickUpDate: $pickUpDate pickUpDuration: $pickUpDuration staffComment: $staffComment alternativeShippingDates: $alternativeShippingDates totals: $totals weight: $weight selectedSlot: $selectedSlot) {
      _id
      key: _id
    }
  }
`

export const EDIT_ORDER_CLEVERSELL = `
  mutation editOrder($_id: ID! $status: String $shippingDate: DateTime) {
    editOrder(_id: $_id status: $status shippingDate: $shippingDate) {
      _id
      key: _id
      status {
      type
      name
    }
    }
  }
`

export const EDIT_OFFLINE_ORDER = `
  mutation editOrder($_id: String!, $status: String) {
    editOrder(offlineToken: $_id, status: $status) {
      _id
      key: _id
    }
  }
`

export const GET_ORDER_STATUS_LIST = `
  query getOrderStatusList{
    getOrderStatusList
  }
`

export const VERIFY_CUSTOMER = `
  mutation verifyCustomerAsStaff ($username: String!) {
    verifyCustomerAsStaff (username: $username)
  }
`

export const GET_CUSTOMERS = `
query getCustomers(
  $page: Int
  $searchTerm: String
  $pageSize: Int
  $sort: CustomerSort
) {
  getCustomers(
    page: $page
    searchTerm: $searchTerm
    pageSize: $pageSize
    sort: $sort
  ) {
    totalCount
    items {
      key:_id
      email
      name
      phone
      dob
      createdAt
      username
    }
  }
}
`

export const DELETE_CUSTOMER = `
  mutation deleteCustomerAccount(
    $_id: ID!
  ) {
    deleteCustomerAccount(
      _id: $_id
    ) {
      key: _id
    }
  }
`

export const ADD_CUSTOMER = `
  mutation addCustomer(
    $email: String
    $name: String!
    $phone: String!
    $dob: String
    $gender: String
    $taxonomies: [ID]
    $customAttributes: [CustomAttributeInput]
    $address: [CustomerAddressInput]
    $isBusiness: Boolean
  ) {
    addCustomer(
      email: $email
      name: $name
      phone: $phone
      dob: $dob
      gender: $gender
      taxonomies: $taxonomies
      customAttributes: $customAttributes
      address: $address
      isBusiness: $isBusiness
    ) {
      _id
      key: _id
      name
      phone
    }
  }
`

export const ADD_CUSTOMER_ACCOUNT = `
  mutation addCustomerAccount(
    $username: String!
    $password: String!
    $email: String
    $name: String
    $phone: String!
    $dob: String
    $gender: String
    $taxonomies: [ID]
    $customAttributes: [CustomAttributeInput]
    $address: [CustomerAddressInput]
    $isBusiness: Boolean
  ) {
    addCustomerAccount(
      username: $username
      password: $password
      email: $email
      name: $name
      phone: $phone
      dob: $dob
      gender: $gender
      taxonomies: $taxonomies
      customAttributes: $customAttributes
      address: $address
      isBusiness: $isBusiness
    ) {
      customer {
        key: _id
      }
    }
  }
`

export const EDIT_CUSTOMER = `
  mutation editCustomer(
    $_id: ID!
    $email: String
    $name: String!
    $phone: String!
    $dob: String
    $gender: String
    $taxonomies: [ID]
    $customAttributes: [CustomAttributeInput]
    $address: [CustomerAddressInput]
    $isBusiness: Boolean
  ) {
    editCustomer(
      _id: $_id
      email: $email
      name: $name
      phone: $phone
      dob: $dob
      gender: $gender
      taxonomies: $taxonomies
      customAttributes: $customAttributes
      address: $address
      isBusiness: $isBusiness
    ) {
      _id
      phone
      key: _id
      name
      customAttributes {
        key
        value
      }
    }
  }
`

export const GET_CUSTOMER = `
  query getCustomer (
     $_id: ID!
  ) {
    getCustomer (
      _id: $_id
    ) {
      _id
      key: _id
      email
      name
      phone
      dob
      gender
      createdAt
      taxonomies {
        key:_id
        name {
          en
          ar
        }
      }
      isBusiness
      cart {
        variations {
          variation {
            key: _id
            serialNumber
            product {
              images
              name {
                en
              }
            }
            price {
              mainPrice
            }
            mainAttributes {
              name {
                en
              }
            }
          }
          quantity
          images
          attributes {
            key
            value
          }
        }
      }
      wishlist {
        name
        products {
          product {
            key: _id
            name {
              en
            }
            images
            productCode
          }
        }
      }
      customAttributes {
        key
        value
        valueType
      }
      orderIds
      address {
        countryCode
        city
        area
        zip
        address1
        address2
        phone
        addressType
        name
      }
    }
  }
`

export const ADD_PRODUCT_REQUEST = `
  mutation addProductRequest (
    $name: ProductRequestEnArInput
    $description: ProductRequestEnArInput
    $price: ProductRequestPriceInput
    $images: [String]
    $status: String
    $note: String
    $hasStock: Boolean
    $stock: Int
    $countryOfOrigin: ID!
  ) {
    addProductRequest (
      name: $name
      description: $description
      price: $price
      images: $images
      status: $status
      note: $note
      hasStock: $hasStock
      stock: $stock
      countryOfOrigin: $countryOfOrigin
    ) {
      key: _id
    }
  }
`

export const ADD_VENDOR = `
  mutation addVendor(
    $name: NameInput!,
    $isBusiness: Boolean,
    $email1: String,
    $email2: String,
    $phoneNumber1: String,
    $phoneNumber2: String,
    $agreementType: AgreementType,
    $contactFirstName: NameInput,
    $contactLastName: NameInput,
    $featured: Boolean,
    $description: NameInput,
    $address: AddressInput,
    $taxonomies: [ID]
    $active: Boolean
    $logo: String
    $cover: String
    $customAttributes: [CustomAttributeInput]
    $paymentTerms: [String]
  ){
    addVendor(
      name: $name
      isBusiness: $isBusiness,
      email1: $email1,
      email2: $email2,
      phoneNumber1: $phoneNumber1,
      phoneNumber2: $phoneNumber2,
      agreementType: $agreementType,
      contactFirstName: $contactFirstName,
      contactLastName: $contactLastName,
      featured: $featured,
      description: $description,
      address: $address
      taxonomies: $taxonomies
      active: $active
      logo: $logo
      cover: $cover
      customAttributes: $customAttributes
      paymentTerms: $paymentTerms
    ) {
      key: _id
      _id
      name {
          en
          ar
      }
    }
  }
`

export const ADD_VENDOR_ACCOUNT = `
  mutation addVendorAccount(
    $username: String!
    $password: String!
    $role: String
    $name: NameInput!
    $isBusiness: Boolean,
    $email1: String,
    $email2: String,
    $phoneNumber1: String,
    $phoneNumber2: String,
    $agreementType: AgreementType,
    $contactFirstName: NameInput,
    $contactLastName: NameInput,
    $featured: Boolean,
    $description: NameInput,
    $address: AddressInput,
    $taxonomies: [ID]
    $active: Boolean
    $logo: String
    $cover: String
    $customAttributes: [CustomAttributeInput]
    $paymentTerms: [String]
  ){
    addVendorAccount(
      username: $username
      password: $password
      role: $role
      name: $name
      isBusiness: $isBusiness,
      email1: $email1,
      email2: $email2,
      phoneNumber1: $phoneNumber1,
      phoneNumber2: $phoneNumber2,
      agreementType: $agreementType,
      contactFirstName: $contactFirstName,
      contactLastName: $contactLastName,
      featured: $featured,
      description: $description,
      address: $address
      taxonomies: $taxonomies
      active: $active
      logo: $logo
      cover: $cover
      customAttributes: $customAttributes
      paymentTerms: $paymentTerms
    ) {
      vendor {
        key: _id
        _id
        name {
            en
            ar
        }
      }
      token
    }
  }
`

export const EDIT_VENDOR = `
  mutation editVendor(
    $_id: ID!
    $name: NameInput!,
    $email1: String,
    $isBusiness: Boolean,
    $email2: String,
    $phoneNumber1: String,
    $phoneNumber2: String,
    $agreementType: AgreementType,
    $contactFirstName: NameInput,
    $contactLastName: NameInput,
    $featured: Boolean,
    $description: NameInput,
    $address: AddressInput
    $taxonomies: [ID]
    $active: Boolean
    $logo: String
    $cover: String
    $customAttributes: [CustomAttributeInput]
    $paymentTerms: [String]
    ){
      editVendor(
        _id: $_id
        name: $name
        email1: $email1,
        isBusiness: $isBusiness,
        email2: $email2,
        phoneNumber1: $phoneNumber1,
        phoneNumber2: $phoneNumber2,
        agreementType: $agreementType,
        contactFirstName: $contactFirstName,
        contactLastName: $contactLastName,
        featured: $featured,
        description: $description,
        address: $address
        taxonomies: $taxonomies
        active: $active
        logo: $logo
        cover: $cover
        customAttributes: $customAttributes
        paymentTerms: $paymentTerms
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const DELETE_VENDOR = `
  mutation deleteVendor(
    $_id: ID!
  ){
    deleteVendor(
      _id: $_id
    ) {
      key: _id
    }
  }
`

export const GET_VENDOR = `
  query getVendor(
    $_id: ID!
  ){
    getVendor(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
      taxonomies {
        name {
          en
          ar
        }
        key: _id
        _id
      }
      isBusiness
      paymentTerms
      email1
      email2
      phoneNumber1
      phoneNumber2
      agreementType
      contactFirstName{
        en
        ar
      }
      contactLastName{
        en
        ar
      }
      featured
      description{
        en
        ar
      }
      address {
        apartment
        email
        floor
        street
        building
        phone
        postalCode
        city
        country
        state
      }
      logo
      cover
      customAttributes {
        key
        value
        valueType
      }
     role {
      key: _id
      _id
      name
    }
    }
  }
`

export const GET_VENDORS = `
  query getVendors($searchTerm: String $name: String $page: Int $pageSize: Int $sort: VendorSort){
    getVendors(
      searchTerm: $searchTerm
      name: $name
      page: $page
      pageSize: $pageSize
      sort: $sort
  ) {
      totalCount
      items {
        active
        key: _id
        name {
          en
          ar
        }
        approved {
          status
          notes
        }
        email1
        phoneNumber1
        taxonomies {
          name {
            en
            ar
          }
          key: _id
          _id
        }
      }
    }
  }
`

export const GET_TAXONOMIES = `
  query getTaxonomies(
    $parent: [ID]
    $searchTerm: String
    $name: String
    $page: Int
    $pageSize: Int
    $sort: TaxonomySort
  ) {
    getTaxonomies(
      parent: $parent
      searchTerm: $searchTerm
      name: $name
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        key: _id
        _id
        name {
          en
          ar
        }
        code
        tags
        parent {
          name {
            en
            ar
          }
        }
        taxonomyType {
          key: _id
          name {
            en
            ar
          }
        }
        featured
        active
      }
    }
  }
`

export const GET_TAXONOMIES_MS = `
  query getTaxonomies(
    $parent: [ID]
    $searchTerm: String
    $name: String
    $page: Int
    $pageSize: Int
    $sort: TaxonomySort
  ) {
    getTaxonomies(
      parent: $parent
      searchTerm: $searchTerm
      name: $name
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        key: _id
        _id
        name {
          en
          ar
        }
        relatedTo
        code
        parent {
          name {
            en
            ar
          }
        }
      }
    }
  }
`

export const GET_TAXONOMIES_CLEVERSELL = `
  query getTaxonomies(
    $parent: [ID]
    $searchTerm: String
    $name: String
    $page: Int
    $pageSize: Int
    $sort: TaxonomySort
  ) {
    getTaxonomies(
      parent: $parent
      searchTerm: $searchTerm
      name: $name
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        key: _id
        _id
        name {
          en
          ar
        }
        code
        parent {
          _id
          name {
            en
            ar
          }
        }
        children {
        key: _id
        _id
        }
      }
    }
  }
`

export const GET_TAXONOMIES_SLIM = `
  query getTaxonomies ($searchTerm: String $parent: [ID]){
    getTaxonomies (searchTerm: $searchTerm page: 1 pageSize: 30 parent: $parent) {
      items {
        _id
        key: _id
        name{
          en
          ar
        }
      }
    }
  }
`

export const GET_TAXONOMIES_SLIM_VENDOR = `
  query getTaxonomies ($searchTerm: String $parent: [ID]){
    getTaxonomies (searchTerm: $searchTerm page: 1 pageSize: 30 parent: $parent relatedTo: "vendor") {
      items {
        key: _id
        name{
          en
          ar
        }
      }
    }
  }
`

export const ADD_TAXONOMY = `
  mutation addTaxonomy(
    $name: NameInput!
    $code: String!
    $parent: ID
    $tags: [String]
    $taxonomyType: ID!
    $mainAttributes: [MainAttributesInput]
    $customAttributes: [CustomAttributeInput]
    $images: [String]
    $order: Int
    $active: Boolean
  ) {
    addTaxonomy(
      code: $code
      name: $name
      tags: $tags
      parent: $parent
      taxonomyType: $taxonomyType
      mainAttributes: $mainAttributes
      customAttributes: $customAttributes
      images: $images
      order: $order
      active: $active
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const ADD_TAXONOMY_CLEVERSELL = `
  mutation addTaxonomy(
    $name: TaxonomyEnArInput!
    $code: String!
    $relatedTo: String!
    $parent: ID
    $tags: [String]
    $customAttributes: [TaxonomyCustomAttributeInput]
    $images: [String]
    $order: Int
    $metaTags: [TaxonomyMetaInput]
    $keywords: [String]
    $slug: String
  ) {
    addTaxonomy(
      code: $code
      relatedTo: $relatedTo
      name: $name
      tags: $tags
      parent: $parent
      customAttributes: $customAttributes
      images: $images
      order: $order
      metaTags: $metaTags
      keywords: $keywords
      slug: $slug
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const ADD_TAXONOMY_MS = `
  mutation addTaxonomy(
    $name: TaxonomyEnArInput!
    $code: String!
    $relatedTo: String!
    $parent: ID
    $tags: [String]
    $images: [String]
    $order: Int
    $featured: Boolean
  ) {
    addTaxonomy(
      code: $code
      name: $name
      tags: $tags
      relatedTo: $relatedTo
      parent: $parent
      images: $images
      order: $order
      featured: $featured
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const EDIT_TAXONOMY_MS = `
  mutation editTaxonomy(
    $_id: ID!
    $name: TaxonomyEnArInput!
    $code: String!
    $relatedTo: String!
    $parent: ID
    $tags: [String]
    $images: [String]
    $order: Int
    $featured: Boolean
  ) {
    editTaxonomy(
      _id: $_id
      name: $name
      code: $code
      relatedTo: $relatedTo
      parent: $parent
      tags: $tags
      images: $images
      order: $order
      featured: $featured
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const EDIT_TAXONOMY = `
  mutation editTaxonomy(
    $_id: ID!
    $code: String!
    $name: NameInput!
    $parent: ID
    $taxonomyType: ID!
    $mainAttributes: [MainAttributesInput]
    $customAttributes: [CustomAttributeInput]
    $images: [String]
    $tags: [String]
    $featured: Boolean
    $order: Int
    $active: Boolean
  ) {
    editTaxonomy(
      _id: $_id
      code: $code
      name: $name
      tags: $tags
      parent: $parent
      taxonomyType: $taxonomyType
      mainAttributes: $mainAttributes
      customAttributes: $customAttributes
      images: $images
      featured: $featured
      order: $order
      active: $active
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const EDIT_TAXONOMY_CLEVERSELL = `
  mutation editTaxonomy(
    $_id: ID!
    $code: String!
    $name: TaxonomyEnArInput!
    $parent: ID
    $customAttributes: [TaxonomyCustomAttributeInput]
    $images: [String]
    $tags: [String]
    $featured: Boolean
    $order: Int
    $metaTags: [TaxonomyMetaInput]
    $keywords: [String]
  ) {
    editTaxonomy(
      _id: $_id
      code: $code
      name: $name
      tags: $tags
      parent: $parent
      customAttributes: $customAttributes
      images: $images
      featured: $featured
      order: $order
      metaTags: $metaTags
      keywords: $keywords
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const GET_TAXONOMY = `
  query getTaxonomy(
    $_id: ID!
  ) {
    getTaxonomy(
      _id: $_id
    ) {
      _id
      key: _id
      name {
        en
        ar
      }
      code
      order
      parent {
        key: _id
        name {
          en
          ar
        }
      }
      taxonomyType {
        key: _id
        name {
          en
          ar
        }
      }
      tags
      images
      active
      mainAttributes {
        mainAttributeType {
          key: _id
          name {
            en
            ar
          }
        }
        mainAttributes {
          key: _id
          name {
            en
            ar
          }
        }
      }
      customAttributes {
        key
        value
        valueType
      }
    }
  }
`

export const GET_TAXONOMY_CLEVERSELL = `
  query getTaxonomy(
    $_id: ID!
  ) {
    getTaxonomy(
      _id: $_id
    ) {
      _id
      key: _id
      name {
        en
        ar
      }
      code
      order
      parent {
        key: _id
        name {
          en
          ar
        }
      }
      tags
      images
      tags
      customAttributes {
        key
        value
        valueType
      }
      metaTags {
      key
      value
      }
      keywords
      slug
    }
  }
`

export const GET_TAXONOMY_MS = `
  query getTaxonomy(
    $_id: ID!
  ) {
    getTaxonomy(
      _id: $_id
    ) {
      _id
      key: _id
      name {
        en
        ar
      }
      code
      order
      parent {
        key: _id
        name {
          en
          ar
        }
      }

      tags
      images
    }
  }
`

export const GET_TAXONOMY_SLIM = `
  query getTaxonomy(
    $_id: ID!
  ) {
    getTaxonomy(
      _id: $_id
    ) {
      _id
      key: _id
      name {
        en
        ar
      }
    }
  }
`

export const DELETE_TAXONOMY = `
  mutation deleteTaxonomy(
    $_id: ID!
  ) {
    deleteTaxonomy(
      _id: $_id
    ) {
      key: _id
    }
  }
`

export const GET_TAXONOMY_TYPES_SLIM = `
  query getTaxonomyTypes(
    $searchTerm: String
  ){
    getTaxonomyTypes(
      searchTerm: $searchTerm
      page: 1
      pageSize: 30
    ) {
      totalCount
      items {
        key: _id
        name {
            en
            ar
        }
        relatedTo
      }
    }
  }
`

export const GET_TAXONOMY_TYPE_SLIM = `
  query getTaxonomyType(
    $_id: ID!
  ) {
    getTaxonomyType(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
      isHierarchical
      hasCustomAttributes
      relatedTo
    }
  }
`

export const GET_CMS = `
  query cms {
    cms {
      #key: _id
      menu {
        name
        url
        imageURL
        external
      }
      slider {
        name
        url
        imageURL
        external
      }
      secondarySlider {
        name
        url
        imageURL
        external
      }
      pages {
        name
        url
        imageURL
        external
      }
      homepage
      timeSlots {
        from {
          hours
          minutes
        }
        to {
          hours
          minutes
        }
      }
    }
  }
`

export const EDIT_CMS = `
  mutation editCms (
    $menu: [ContentInput]
    $slider: [ContentInput]
    $secondarySlider: [ContentInput]
    $pages: [ContentInput]
    $homepage: [String]
    $timeSlots: [CMSTimeSlotInput]
  ) {
    editCms (
      menu: $menu
      slider: $slider
      secondarySlider: $secondarySlider
      pages: $pages
      homepage: $homepage
      timeSlots: $timeSlots
    ) {
      key: _id
      menu {
        name
        url
        imageURL
        external
      }
      slider {
        name
        url
        imageURL
        external
      }
      secondarySlider {
        name
        url
        imageURL
        external
      }
      pages {
        name
        url
        imageURL
        external
      }
      homepage
      timeSlots {
        from {
          hours
          minutes
        }
        to {
          hours
          minutes
        }
      }
    }
  }
`

export const GET_SORTED_TAXONOMIES = `
  query sortTaxonomies(
    $taxonomies: [ID]!
  ) {
    sortTaxonomies(
      taxonomies: $taxonomies
    ) {
      parent {
        _id
      }
      children {
        _id
      }
    }
  }
`

export const GET_CURRENT_VENDOR = `
  query getCurrentVendor {
    getCurrentVendor {
      _id
      role {
        permissions {
          key: _id
          name
        }
      }
      name {
        en
      }
    }
  }
`

export const GET_CURRENT_BRANCH = `
  query getCurrentBranch {
    getCurrentBranch {
      role {
        permissions {
          key: _id
          name
        }
      }
    }
  }
`

export const ACCEPT_PRODUCT_REQUEST = `
  mutation acceptProductRequest (
    $_id: ID!
    $product: ID!
  ) {
    acceptProductRequest (
      _id: $_id
      product: $product
    ) {
      variationId
      productId
    }
  }
`

export const REJECT_PRODUCT_REQUEST = `
  mutation rejectProductRequest (
    $_id: ID!
    $note: String
  ) {
    rejectProductRequest (
      _id: $_id
      note: $note
    ) {
      key: _id
    }
  }
`

export const GET_ORDER_REQUESTS = `
  query getOrderRequests (
    $searchTerm: String
    $page: Int
    $pageSize: Int
    $sort: OrderRequestSort
  ) {
    getOrderRequests (
      searchTerm: $searchTerm
      page: $page
      pageSize: $pageSize
      sort: $sort
      status: pending
    ) {
      totalCount
      items {
        key: _id
        variation {
          product {
            name {
              en
            }
          }
        }
        quantity
        price
        vendor {
          name {
            en
          }
        }
        checkoutId
        paymentMethod
        shippingSlots {
          _id
          from {
            hours
            minutes
          }
          to {
            hours
            minutes
          }
        }
      }
    }
  }
`

export const ACCEPT_ORDER_REQUEST = `
  mutation acceptOrderRequest (
    $_id: ID!
    $selectedSlot: CustomerShippingSlotInput
  ) {
    acceptOrderRequest (
      _id: $_id
      selectedSlot: $selectedSlot
    ) {
      key: _id
    }
  }
`

export const REJECT_ORDER_REQUEST = `
  mutation rejectOrderRequest (
    $_id: ID!
  ) {
    rejectOrderRequest (
      _id: $_id
    ) {
      key: _id
    }
  }
`

export const GET_ROLE_SLIM = `
  query getRole (
    $_id: ID!
  ) {
    getRole (
      _id: $_id
    ) {
      key: _id
      name
    }
  }
`

export const GET_ROLES_SLIM = `
  query getRoles (
    $searchTerm: String
  ) {
    getRoles (
      searchTerm: $searchTerm
      page: 1
      pageSize: 30
    ) {
      totalCount
      items {
        key: _id
        _id
        name
      }
    }
  }
`

export const GET_ROLE = `
  query getRole(
    $_id: ID!
  ){
    getRole(
      _id: $_id
    ) {
      key: _id
      name
      permissions {
        key: _id
        name
      }
    }
  }
`

export const GET_ROLES = `
  query getRoles(
    $name: String
    $searchTerm: String
    $page: Int
    $pageSize: Int
    $sort: RoleSort
  ) {
    getRoles(
      name: $name
      searchTerm: $searchTerm
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        key: _id
        name
        permissions {
          _id
          name
        }
      }
    }
  }
`

export const ADD_ROLE = `
  mutation addRole(
    $name: String!
    $permissions: [ID]
  ) {
    addRole(
      name: $name
      permissions: $permissions
    ) {
      key: _id
      _id
    name
    permissions {
      _id
      name
      description
      service {
        _id
      }
      isDeleted
    }
    }
  }
`

export const EDIT_ROLE = `
  mutation editRole(
    $_id: ID!
    $name: String
    $permissions: [ID]
  ) {
    editRole(
      _id: $_id
      name: $name
      permissions: $permissions
    ) {
      key: _id
      _id
    name
    }
  }
`

export const DELETE_ROLE = `
  mutation deleteRole (
    $_id: ID!
  ) {
    deleteRole (
      _id: $_id
    ) {
      key: _id
      _id
    }
  }
`

export const GET_ORDER = `
  query getOrder(
    $_id: ID!
  ) {
    getOrder(
      _id: $_id
    ) {
      key: _id
      _id
      orderId
      createdAt
      paymentMethod
      shippingMethod
      shippingDate
      pickUpDate
      pickUpDuration
      staffComment
      customerComment
      customerName
      alternativeShippingDates
      weight
      totals {
        total
        subtotal
        shipping
        fees
        margin
        discount{
          amount
          discountType
          reference {
            value
            valueType
            code
            name
          }
        }
      }
      checkoutId
      vendorCopy {
        name {
          en
          ar
        }
      }
      notOriginal {
        isOriginal
        original
      }
      customer {
        key: _id
        _id
        name
        phone
      }
      staff {
        _id
        name
      }

      status
      shipping {
        apartment
        email
        floor
        street
        building
        phone
        postalCode
        city
        country
        state
        location {
          longitude
          latitude
        }
      }
      billing {
        apartment
        email
        floor
        street
        building
        phone
        postalCode
        city
        country
        state
        location {
          longitude
          latitude
        }
      }
      offers{
        offer{
          _id
          key: _id
          name {
              en
              ar
          }
          description {
              en
              ar
          }
          item{
            item
            variation {
              _id
              key: _id
              serialNumber
              active
              images
              product {
                _id
                key: _id
                images
                name {
                  en
                  ar
                }
              }
              price{
                mainPrice
                discountedPrice
              }
              stock {
                branch {
                  name {
                    en
                  }
                }
                amount
              }
              mainAttributes {
                _id
                key: _id
                name {
                  en
                  ar
                }
              }
            }
            itemPrice
            quantity
          }
          items{
            item
            variation {
              _id
              key: _id
              serialNumber
              active
              images
              product {
                _id
                key: _id
                images
                name {
                  en
                  ar
                }
              }
              price{
                mainPrice
                discountedPrice
              }
              stock {
                branch {
                  name {
                    en
                  }
                }
                amount
              }
              mainAttributes {
                _id
                key: _id
                name {
                  en
                  ar
                }
              }
            }
            itemPrice
            quantity
          }
          offerItems{
            item
            variation {
              _id
              key: _id
              serialNumber
              active
              images
              product {
                _id
                key: _id
                images
                name {
                  en
                  ar
                }
              }
              price{
                mainPrice
                discountedPrice
              }
              stock {
                branch {
                  name {
                    en
                  }
                }
                amount
              }
              mainAttributes {
                _id
                key: _id
                name {
                  en
                  ar
                }
              }
            }
            sameItem
            itemType
            quantity
          }
          expiryDate
          offerType
          itemType
          images
          order
        }
        quantity
        price
        total
      }
      variations {
      addOns {
        addOn {
          _id
          name {
            en
          }
          images
          price {
            mainPrice
            discountedPrice
          }
        }
        quantity
      }
        quantity
        price
        status
        attributes {
          key
          value
        }
        total
        images
        marginPrice
        addOnObjects {
          name {
            en
            ar
          }
          description {
            en
            ar
          }
          price
          quantity
        }
        variationCopy {
          _id
          key: _id
          serialNumber
          images
          price{
            mainPrice
            discountedPrice
          }
          mainAttributesNames
          productName
          name {
            en
            ar
          }
          vendorName {
            en
            ar
          }
        }
        variation {
          _id
          key: _id
          serialNumber
          product {
            _id
            key: _id
            images
            name {
              en
              ar
            }
          }
          price{
            mainPrice
            discountedPrice
          }
          mainAttributes {
            _id
            key: _id
            name {
              en
              ar
            }
          }
        }
      }

      branchName {
        en
      }

      statusList

      shippingSlots {
        _id
        from {
          hours
          minutes
        }
        to {
          hours
          minutes
        }
      }
      selectedSlot {
        from {
          hours
          minutes
        }
        to {
          hours
          minutes
        }
      }
    }
  }
`

export const GET_ORDER_CLEVERSELL_COPY = `
  query getOrder ($_id: ID) {
    getOrder (_id: $_id) {
      _id
      createdAt
      orderId
      offlineOrderId
      original {
        isOriginal
        original
      }
      player {
        _id
        name
        email
        phone
      }
      paymentMethod
      productsCopy
      collectionsCopy
      status {
        name
        type
      }
      totals {
        discount {
          amount
        }
        total
        shipping
        subtotal
        taxes
      }
      shipping {
        countryCode
        city
        area
        postalCode
        notes
        address1
        address2
        phone
        type
        name
        apartment
        email
        floor
        street
        building
        country
        state
      }
    }
  }
`

export const GET_OFFLINE_ORDER = `
  query getOfflineOrder($_id: String) {
    getOrder(offlineToken: $_id) {
      key: _id
      _id
      offlineToken
      status
      statusList
    }
  }
`

export const APPROVE_ACCOUNT = `
  mutation approveAccount (
    $user: ID!
    $status: AccountApproveStatus!
    $notes: String
  ) {
    approveAccount (
      user: $user
      status: $status
      notes: $notes
    )
  }
`

export const GET_TAGS = `
  query getTags {
    getTags {
      totalCount
      items
    }
}`

export const ADD_MAIN_ATTRIBUTE = `
mutation addMainAttribute(
  $name: NameInput!
  $value: String
  $code: String!
  $mainAttributeType: ID!
  $meta: [MetaInput]
) {
  addMainAttribute(
    name: $name
    value: $value
    code: $code
    mainAttributeType: $mainAttributeType
    meta: $meta
  ) {
      _id
    name {
      en
      ar
    }
    value
    mainAttributeType {
      _id
      name {
        en
        ar
      }
    }
    meta {
      key
      value
    }
    code
  }
}
`
export const EDIT_MAIN_ATTRIBUTE = `
  mutation editMainAttribute(
    $_id : ID!
    $name: NameInput
    $code: String!
    $mainAttributeType: ID
    $meta: [MetaInput]
    $value: String
  ){
    editMainAttribute(
      _id: $_id
      name: $name
      code: $code
      mainAttributeType: $mainAttributeType
      meta: $meta
      value: $value
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const DELETE_MAIN_ATTRIBUTE = `
  mutation deleteMainAttribute(
    $_id: ID!
  ) {
    deleteMainAttribute(
      _id: $_id
    ) {
      _id
    }
  }
`

export const GET_PERMISSIONS = `
  query getPermissions {
    getPermissions {
      items {
        key: _id
        name
      }
    }
  }
`
export const GET_MAIN_ATTRIBUTE_TYPES = `
  query getMainAttributeTypes ($searchTerm: String
    $page: Int
    $pageSize: Int
    ) {
    getMainAttributeTypes (searchTerm: $searchTerm page: $page pageSize: $pageSize) {
      totalCount
      items {
        _id
        key: _id
        name {
          en
          ar
        }
      }
    }
  }
`

export const GET_MAIN_ATTRIBUTE_TYPE = `
  query getMainAttributeType ($_id: ID!) {
    getMainAttributeType (_id: $_id) {
        _id
        key: _id
        name {
          en
          ar
        }
    }
  }
`

export const DELETE_MAIN_ATTRIBUTE_TYPE = `
  mutation deleteMainAttributeType(
    $_id: ID!
  ) {
    deleteMainAttributeType(
      _id: $_id
    ) {
      key: _id
    }
  }
`

export const ADD_MAIN_ATTRIBUTE_TYPE = `
  mutation addMainAttributeType(
    $name: NameInput!
  ) {
    addMainAttributeType(
      name: $name
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const EDIT_MAIN_ATTRIBUTE_TYPE = `
  mutation editMainAttributeType(
    $_id: ID!
    $name: NameInput
  ) {
    editMainAttributeType(
      _id: $_id
      name: $name
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const GET_DRIVERS = `
  query getDrivers(
    $page: Int
    $pageSize: Int
    $searchTerm: String
    $sort: DriverSort
  ) {
    getDrivers(
      page: $page
      pageSize: $pageSize
      searchTerm: $searchTerm
      sort: $sort
    ) {
      totalCount
      items {
        key:_id
        _id
        email
        name
        phone
        dob
        username
        approved {
        status
        }
      }
    }
  }
`
export const DELETE_DRIVER = `
  mutation deleteDriverAccount(
    $_id: ID!
  ) {
    deleteDriverAccount(
      _id: $_id
    ) {
      key: _id
    }
  }
`
export const ADD_DRIVER = `
  mutation addDriver(
    $email: String
    $name: String
    $phone: String
    $dob: String
    $gender: String
    $address: DriverAddressInput
    $drivingLicenseExpiryDate: String
    $drivingLicensePhoto: String
    $idExpiryDate: String
    $idPhoto: String
    $drivingLicenseNumber: String
    $nationality: String
    $idNumber: String
    $phoneOS: String
    $bankName: String
    $bankAccountNumber: String
  ) {
    addDriver(
      email: $email
      name: $name
      phone: $phone
      dob: $dob
      gender: $gender
      address: $address
      drivingLicenseExpiryDate: $drivingLicenseExpiryDate
      drivingLicensePhoto: $drivingLicensePhoto
      idExpiryDate: $idExpiryDate
      idPhoto: $idPhoto
      drivingLicenseNumber: $drivingLicenseNumber
      nationality: $nationality
      idNumber: $idNumber
      phoneOS: $phoneOS
      bankName: $bankName
      bankAccountNumber: $bankAccountNumber
    ) {
      _id
      key: _id
      name
      phone
    }
  }
`
export const ADD_DRIVER_ACCOUNT = `
  mutation addDriverAccount(
    $email: String
    $username: String!
    $password: String!
    $name: String
    $phone: String
    $dob: String
    $gender: String
    $address: DriverAddressInput
    $drivingLicenseExpiryDate: String
    $drivingLicensePhoto: String
    $idExpiryDate: String
    $idPhoto: String
    $drivingLicenseNumber: String
    $nationality: String
    $idNumber: String
    $phoneOS: String
    $bankName: String
    $bankAccountNumber: String
  ) {
    addDriverAccount(
      email: $email
      username: $username
      password: $password
      name: $name
      phone: $phone
      dob: $dob
      gender: $gender
      address: $address
      drivingLicenseExpiryDate: $drivingLicenseExpiryDate
      drivingLicensePhoto: $drivingLicensePhoto
      idExpiryDate: $idExpiryDate
      idPhoto: $idPhoto
      drivingLicenseNumber: $drivingLicenseNumber
      nationality: $nationality
      idNumber: $idNumber
      phoneOS: $phoneOS
      bankName: $bankName
      bankAccountNumber: $bankAccountNumber
    ) {
      driver {
        _id
        key: _id
        name
        phone
      }
    }
  }
`
export const EDIT_DRIVER = `
  mutation editDriver(
    $_id: ID!
    $email: String
    $name: String
    $phone: String
    $dob: String
    $gender: String
    $address: DriverAddressInput
    $drivingLicenseExpiryDate: String
    $drivingLicensePhoto: String
    $idExpiryDate: String
    $idPhoto: String
    $drivingLicenseNumber: String
    $nationality: String
    $idNumber: String
    $phoneOS: String
    $bankName: String
    $bankAccountNumber: String
  ) {
    editDriver(
      _id: $_id
      email: $email
      name: $name
      phone: $phone
      dob: $dob
      gender: $gender
      address: $address
      drivingLicenseExpiryDate: $drivingLicenseExpiryDate
      drivingLicensePhoto: $drivingLicensePhoto
      idExpiryDate: $idExpiryDate
      idPhoto: $idPhoto
      drivingLicenseNumber: $drivingLicenseNumber
      nationality: $nationality
      idNumber: $idNumber
      phoneOS: $phoneOS
      bankName: $bankName
      bankAccountNumber: $bankAccountNumber
    ) {
      _id
      key: _id
      name
      phone
    }
  }
`

export const VERIFY_DRIVER = `
  mutation verifyDriverAsStaff ($username: String!) {
    verifyDriverAsStaff (username: $username)
  }
`

export const GET_DRIVER = `
  query getDriver ($_id: ID!) {
    getDriver (_id: $_id) {
      _id
      key: _id
      username
      name
      status
      phone
      dob
      email
      phoneOS
      bankName
      bankAccountNumber
      gender
      nationality
      idNumber
      idExpiryDate
      idPhoto
      photo
      vehicleType
      vehiclePhoto
      vehicleLicense
      vehicleLicensePhoto
      drivingLicenseNumber
      drivingLicenseExpiryDate
      drivingLicensePhoto
      address {
        apartment
        email
        floor
        street
        building
        phone
        postalCode
        city
        country
        state
      }
      approved {
        status
        notes
        time
      }
    }
  }
`
export const GET_TRIP = `
  query getTrip (
    $_id: ID!
  ) {
    getTrip (
      _id: $_id
    ) {
      key: _id
      _id
      status
      tripId
      user {
        _id
        phone
        name
      }
      driver {
        _id
        phone
        name
      }
      location {
        longitude
        latitude
      }
      acceptedTime
      fulfillmentTime
      totals {
        subtotal
        driverFees
        shipping
        discount{
          amount
          discountType
          reference {
            value
            valueType
            code
            name
          }
        }
        total
      }
      request {
        key: _id
        _id
        user {
          phone
          _id
        }
        status
        paymentMethod
        deliveryTime
        estimatedDeliveryAmount
        estimatedDriverFees
        steps {
          name
          comment
          content
          type
          status
          expectedAmount
          expectedAmountRange {
            min
            max
          }
        }
      }
    }
  }
`

export const GET_TRIPS = `
  query getTrips (
    $status: String
    $searchTerm: String
    $driver: ID
    $user: ID
    $page: Int
    $pageSize: Int
    $sort: TripSort
  ) {
    getTrips(
      status: $status
      searchTerm: $searchTerm
      driver: $driver
      user: $user
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        _id
        key: _id
        status
        tripId
        user {
          phone
          _id
        }
        driver {
          phone
          _id
        }
      }
    }
  }
`
export const GET_REQUESTS = `
  query getRequests (
    $searchTerm: String
    $pageSize: Int
    $page: Int
    $sort: RequestSort
  ) {
    getRequests (
      searchTerm: $searchTerm
      pageSize: $pageSize
      page: $page
      sort: $sort
    ) {
      items {
        key: _id
        _id
        user {
          phone
          _id
        }
      }
      totalCount
    }
  }
`

export const GET_REQUEST = `
  query getRequest (
    $_id: ID!
  ) {
    getRequest (
      _id: $_id
    ) {
      key: _id
      _id
      user {
        phone
        _id
      }
      status
      paymentMethod
      deliveryTime
      estimatedDeliveryAmount
      estimatedDriverFees
      steps {
        name
        comment
        content
        type
        status
        expectedAmount
        expectedAmountRange {
          min
          max
        }
      }
    }
  }
`

export const DELETE_REQUEST = `
  mutation deleteRequest (
    $_id: ID!
  ) {
    deleteRequest (
      _id: $_id
    ) {
      key: _id
      _id
    }
  }
`

export const ADD_PROMO_CODE = `
mutation addPromoCode (
  $name: String!
  $code: String!
  $value: Float!
  $valueType: PromoCodeValueType!
  $expiry: String!
  $active: Boolean!
  $uses: Int
  $usesPerUser: Int
) {
  addPromoCode (
    name: $name
    code: $code
    value: $value
    valueType: $valueType
    expiry: $expiry
    active: $active
    uses: $uses
    usesPerUser: $usesPerUser
  ) {
    _id
    key: _id
    name
    code
    expiry
    active
  }
}
`

export const EDIT_PROMO_CODE = `
mutation editPromoCode (
  $_id: ID!
  $name: String
  $code: String
  $value: Float
  $valueType: PromoCodeValueType
  $expiry: String
  $active: Boolean
  $uses: Int
  $usesPerUser: Int
) {
  editPromoCode (
    _id: $_id
    name: $name
    code: $code
    value: $value
    valueType: $valueType
    expiry: $expiry
    active: $active
    uses: $uses
    usesPerUser: $usesPerUser
  ) {
    key: _id
    _id
    name
    code
    expiry
    active
  }
}
`

export const GET_PROMO_CODES = `
query getPromoCodes(
  $searchTerm: String
  $sort: PromoCodeSort
) {
  getPromoCodes(
    searchTerm: $searchTerm
    sort: $sort
  ) {
    totalCount
    items {
      key: _id
    name
    code
    value
    valueType
    expiry
    active
    }
  }
}
`
export const GET_PROMO_CODE = `
query getPromoCode(
  $_id: ID!
) {
  getPromoCode(
    _id: $_id
  ) {
    key: _id
    name
    code
    value
    valueType
    expiry
    active
    uses
    usesPerUser
    currentUses
  }
}
`

export const DELETE_PROMO_CODE = `
mutation deletePromoCode (
  $_id: ID!
) {
  deletePromoCode (
    _id: $_id
  ) {
    key: _id
  }
}
`

export const GET_TAXONOMY_TYPES = `
  query getTaxonomyTypes(
    $name: String
    $searchTerm: String
    $isHierarchical: Boolean
    $page: Int
    $pageSize: Int
    $sort: TaxonomyTypeSort
  ){
    getTaxonomyTypes(
      searchTerm: $searchTerm
      name: $name
      isHierarchical:  $isHierarchical
      page: $page
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        key: _id
        name{
            en
            ar
        }
        isHierarchical
        hasCustomAttributes
        relatedTo
      }
    }
  }
`

export const ADD_TAXONOMY_TYPE = `
  mutation addTaxonomyType(
    $name: NameInput!
    $relatedTo: RelatedTo!
  ){
    addTaxonomyType(
      name: $name
      isHierarchical: true
      hasCustomAttributes: true
      relatedTo: $relatedTo
    ) {
      key: _id
      _id
      name{
          en
          ar
      }
      isHierarchical
      hasCustomAttributes
      relatedTo
    }
  }
`

export const EDIT_TAXONOMY_TYPE = `
  mutation editTaxonomyType(
    $_id: ID!
    $name: NameInput
    $isHierarchical: Boolean
    $hasCustomAttributes: Boolean
    $relatedTo: RelatedTo
  ) {
    editTaxonomyType(
      _id:$_id
      name: $name
      isHierarchical: $isHierarchical
      hasCustomAttributes: $hasCustomAttributes
      relatedTo: $relatedTo
    ) {
      key: _id
      _id
      name{
          en
          ar
      }
      isHierarchical
      hasCustomAttributes
      relatedTo
    }
  }
`

export const GET_TAXONOMY_TYPE = `
  query getTaxonomyType(
    $_id: ID!
  ) {
    getTaxonomyType(
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
      isHierarchical
      hasCustomAttributes
      relatedTo
    }
  }
`

export const DELETE_TAXONOMY_TYPE = `
  mutation deleteTaxonomyType(
    $_id: ID!
  ) {
    deleteTaxonomyType(
      _id: $_id
    ) {
      key: _id
    }
  }
`

export const GET_BRANCHES = `
  query getBranches (
    $name: String
    $searchTerm: String
    $page: Int
    $pageSize: Int
    $sort: BranchSort
  ) {
    getBranches (
      name: $name
      page: $page
      searchTerm: $searchTerm
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        key: _id
        name {
          en
          ar
        }
        location {
          longitude
          latitude
        }
        address {
          city
        }
        code
      }
    }
  }
`

export const GET_BRANCHES_CLEVERSELL = `
  query getBranches (
    $code: String
    $searchTerm: String
    $page: Int
    $pageSize: Int
    $sort: BranchesSort
  ) {
    getBranches (
      code: $code
      page: $page
      searchTerm: $searchTerm
      pageSize: $pageSize
      sort: $sort
    ) {
      totalCount
      items {
        _id
        key: _id
        name {
          en
          ar
        }
        location {
          longitude
          latitude
        }
        address {
          city
        }
        code
      }
    }
  }
`

export const ADD_BRANCH = `
  mutation addBranch (
    $name: NameInput!
    $code: String
    $address: AddressInput
    $location: LocationInput
    $workingHours: WorkingHoursInput
    $hasWorkingHours: Boolean
    $manuallyClosed: Boolean
    $vendor: ID
  ) {
    addBranch (
      name: $name
      code: $code
      address: $address
      location: $location
      workingHours: $workingHours
      hasWorkingHours: $hasWorkingHours
      manuallyClosed: $manuallyClosed
      vendor: $vendor
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const ADD_BRANCH_CLEVERSELL = `
  mutation addBranch (
    $name: BranchNameInput!
    $code: String!
    $address: BranchAddressInput
    $location: BranchLocationInput
  ) {
    addBranch (
      name: $name
      code: $code
      address: $address
      location: $location
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const ADD_BRANCH_ACCOUNT = `
  mutation addBranchAccount(
    $username: String!
    $password: String!
    $name: NameInput!
    $address: AddressInput,
    $code: String!
    $role: String
    $location: LocationInput
    $workingHours: WorkingHoursInput
    $hasWorkingHours: Boolean
    $manuallyClosed: Boolean
  ){
    addBranchAccount(
      username: $username
      password: $password
      name: $name
      address: $address
      code: $code
      role: $role
      location: $location
      workingHours: $workingHours
      hasWorkingHours: $hasWorkingHours
      manuallyClosed: $manuallyClosed
    ) {
      branch {
        _id
        key: _id
        name {
            en
            ar
        }
      }
      token
    }
  }
`

export const EDIT_BRANCH = `
  mutation editBranch (
    $_id: ID!
    $name: NameInput
    $address: AddressInput
    $location: LocationInput
    $workingHours: WorkingHoursInput
    $hasWorkingHours: Boolean
    $manuallyClosed: Boolean
    $vendor: ID
  ) {
    editBranch (
      _id: $_id
      name: $name
      address: $address
      location: $location
      workingHours: $workingHours
      hasWorkingHours: $hasWorkingHours
      manuallyClosed: $manuallyClosed
      vendor: $vendor
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const EDIT_BRANCH_CLEVERSELL = `
  mutation editBranch (
    $_id: ID!
    $name: BranchNameInput
    $address: BranchAddressInput
    $location: BranchLocationInput
  ) {
    editBranch (
      _id: $_id
      name: $name
      address: $address
      location: $location
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
    }
  }
`

export const DELETE_BRANCH = `
  mutation deleteBranch (
    $_id: ID!
  ) {
    deleteBranch(
      _id: $_id
    ) {
      key: _id
    }
  }
`

export const GET_BRANCH = `
  query getBranch (
    $_id: ID!
  ) {
    getBranch (
      _id: $_id
    ) {
      key: _id
      name {
        en
        ar
      }
      code
      vendor {
        key: _id
        _id
        name {
          en
        }
      }
      hasWorkingHours
      manuallyClosed
      workingHours {
        openingTime
        closingTime
      }
      address {
        country
        city
        state
        postalCode
        street
        building
        floor
        apartment
        email
        phone
      }
      location {
        longitude
        latitude
      }
      role {
        key: _id
        _id
        name
      }
    }
  }
`

export const GET_BRANCH_CLEVERSELL = `
  query getBranch (
    $_id: ID!
  ) {
    getBranch (
      _id: $_id
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
      code
      address {
        country
        city
        state
        postalCode
        street
        building
        floor
        apartment
        email
        phone
      }
      location {
        longitude
        latitude
      }
    }
  }
`

export const GET_CONFIGURATION = `
  query configuration {
    configuration {
      #key: _id
      configurationList{
        key
        value
        valueType
      }
      defaultProductSort
      defaultTaxonomySort
      orderItemStatusList {
        name
        sms
        sendSms
        returnStock
        isChangeable
      }
      orderStatusList {
        name
        sms
        sendSms
        returnStock
        isChangeable
        notification {
          sendNotification
          isPush
          isEmail
          isSms
          appUrl
          webUrl
          title {
            en
            ar
          }
          content {
            en
            ar
          }
          defaultLanguage
        }
      }
    }
  }
`

export const EDIT_CONFIGURATION = `
  mutation editConfiguration (
    $configurationList: [ConfigurationVariableInput]
    $orderStatusList: [ConfigurationOrderStatusInput]
    $orderItemStatusList: [ConfigurationOrderStatusInput]
    $defaultProductSort: ProductSort
    $defaultTaxonomySort: TaxonomySort
  ) {
    editConfiguration (
      configurationList: $configurationList
      orderStatusList: $orderStatusList
      orderItemStatusList: $orderItemStatusList
      defaultProductSort: $defaultProductSort
      defaultTaxonomySort: $defaultTaxonomySort
    ) {
      key: _id
      configurationList{
        key
        value
        valueType
      }
    }
  }
`

export const RESET_PASSWORD = `
  mutation resetPassword ($user: ID!) {
    resetPasswordAsAdmin (user: $user)
  }
`

export const RESET_PASSWORD_AS_ADMIN_EST = `
  mutation RESET_PASSWORD_AS_ADMIN(
    $user: ID!
    $method: ResetPasswordNotificationMethod
    $newPassword: String
    $length: Int
  ) {
    resetPasswordAsAdmin(user: $user, method: $method, bcc: true, newPassword: $newPassword, length: $length)
  }
`

export const SEND_CENTER_ASSIGNMENT_EMAIL = `
mutation SEND_CENTER_ASSIGNMENT_EMAIL($_id: ID!) {
  sendCenterAssignmentEmail(admissionId: $_id)
}
`
export const SEND_CENTER_ASSIGNMENT_EMAIL_FOR_ROUND = `
mutation SEND_CENTER_ASSIGNMENT_EMAIL_FOR_ROUND($_id: ID!) {
  sendCenterAssignmentEmailForRound(roundId: $_id)
}
`

export const SEND_SCORE_EMAIL = `
mutation SEND_SCORE_EMAIL($_id: ID!) {
  sendScoreReportEmail(admissionId: $_id)
}
`

export const SEND_SCORE_EMAIL_FOR_ROUND = `
mutation SEND_SCORE_EMAIL_FOR_ROUND($_id: ID!) {
  sendScoreReportEmailForRound(roundId: $_id)
}
`

const getPlayersForType = (type) => `
query GET_PLAYERS(
  $searchTerm: String
  $sort: PlayersSort
  $page: Int
  $pageSize: Int
) {
  getPlayers(
    searchTerm: $searchTerm
    sort: $sort
    page: $page
    pageSize: $pageSize
    type: "${type}"
  ) {
    totalCount
    items {
      _id
      key: _id
      type
      name
      active
      nameObject {
        en
        ar
      }
      displayName
      phone
      landline
      email
      about
      gender
      createdAt
    }
  }
}
`

export const GET_DOCTORS = getPlayersForType('doctor')
export const GET_REPS = getPlayersForType('rep')
export const GET_LINE_MANAGERS = getPlayersForType('line-manager')

// language=graphql
export const GET_PLAYER = `
  query GET_PLAYER($_id: ID!) {
    getPlayer(_id: $_id) {
      _id
      type
      name
      nameObject {
        en
        ar
      }
      dateOfBirth {
        year
        month
        day
      }
      displayName
      phone
      landline
      email
      about
      gender
      profilePicture
      dob
      batchName
      isConfirmedByAdmin
      nationality
      isFeatured
      isOnline
      isEntity
      entity
      addresses {
        countryCode
        city
        area
        postalCode
        notes
        address1
        address2
        phone
        type
        name
        apartment
        email
        floor
        street
        building
        country
        state
        isPrimary
        location {
          longitude
          latitude
        }
      }
      location {
        longitude
        latitude
      }
      taxonomies {
        _id
        parent {
          _id
        }
      }
      id {
        number
        photo
        expiryDate
        passportNumber

        passportPhoto
        passportExpiryDate
      }

      business {
        name
        type
        department
        isBusiness
        registrationForm
      }

      personToContact {
        name
        email
        position
        mobile
      }

      parentalInfo {
        name
        email
        relation
        useContact
      }

      portfolio {
        yearsOfExperience
        yearsOfExperienceApprox
        pastWork {
          name {
            en
            ar
          }
          description {
            en
            ar
          }
          pictures
        }
      }

      city {
        _id
        isCapital
        name {
          en
        }
      }

      country {
        _id
        name {
          en
        }
      }

      district {
        _id
        name {
          en
        }
      }

      customAttributes {
        key
        value
        valueType
      }

      education {
        school {
          _id
          key: _id
        }
        qualification
        grade
        schoolGraduationDate
      }
      permissions {
        displayName
        permission
        _id
        key: _id
      }
    }
  }
`

export const DELETE_PLAYER = `
  mutation DELETE_PLAYER($_id: ID!) {
    deletePlayer(_id: $_id) {
      _id
    }
  }
`

export const VERIFY_PLAYER = `
  mutation VERIFY_PLAYER($_id: ID!) {
    verifyAccount(_id: $_id)
  }
`

export const DELETE_PLAYER_ACCOUNT = `
  mutation DELETE_PLAYER($_id: ID!) {
    deletePlayerAccount(_id: $_id) {
      _id
    }
  }
`

export const ADD_PLAYER = `
mutation ADD_PLAYER(
  $name: String!
  $type: String!
  $nameObject: PlayerNameObjectInput
  $displayName: String
  $phone: String
  $landline: String
  $email: String
  $about: String
  $gender: String
  $profilePicture: String
  $dob: String
  $nationality: String
  $isFeatured: Boolean
  $isOnline: Boolean
  $isEntity: Boolean
  $entity: ID
  $addresses: [PlayerAddressInput]
  $location: PlayerLocationInput
  $parentalInfo: [PlayerParentalInfoInput]
  $taxonomies: [ID]
  $id: PlayerIdInput
  $business: PlayerBusinessInput
  $portfolio: PlayerPortfolioInput
  $isApproved: Boolean
) {
  addPlayer(
    name: $name
    type: $type
    nameObject: $nameObject
    displayName: $displayName
    phone: $phone
    landline: $landline
    email: $email
    about: $about
    gender: $gender
    profilePicture: $profilePicture
    dob: $dob
    nationality: $nationality
    isFeatured: $isFeatured
    isOnline: $isOnline
    isEntity: $isEntity
    entity: $entity
    addresses: $addresses
    location: $location
    parentalInfo: $parentalInfo
    taxonomies: $taxonomies
    id: $id
    business: $business
    portfolio: $portfolio
    isApproved: $isApproved
  ) {
    key: _id
    _id
  }
}
`
// language=graphql
export const EDIT_PLAYER = `
  mutation EDIT_PLAYER(
    $_id: ID!
    $type: String!
    $name: String
    $nameObject: PlayerNameObjectInput
    $personToContact: PersonToContactInput
    $displayName: String
    $phone: String
    $landline: String
    $email: String
    $about: String
    $gender: String
    $batchName: String
    $isConfirmedByAdmin: Boolean
    $profilePicture: String
    $dob: String
    $nationality: String
    $isFeatured: Boolean
    $isOnline: Boolean
    $isEntity: Boolean
    $entity: ID
    $addresses: [PlayerAddressInput]
    $location: PlayerLocationInput
    $parentalInfo: [PlayerParentalInfoInput]
    $taxonomies: [ID]
    $id: PlayerIdInput
    $business: PlayerBusinessInput
    $portfolio: PlayerPortfolioInput
    $customAttributes: [PlayerCustomAttributeInput]
    $city: ID
    $country: ID
    $district: ID
    $education: PlayerEducationInput
    $isApproved: Boolean
    $dateOfBirth: PlayerDateOfBirthInput
    $updateUsername:Boolean
    $permissions: [ID]
  ) {
    editPlayer(
      _id: $_id
      type: $type
      name: $name
      nameObject: $nameObject
      displayName: $displayName
      personToContact: $personToContact
      phone: $phone
      batchName: $batchName
      isConfirmedByAdmin: $isConfirmedByAdmin 
      landline: $landline
      email: $email
      about: $about
      gender: $gender
      profilePicture: $profilePicture
      dob: $dob
      nationality: $nationality
      isFeatured: $isFeatured
      isOnline: $isOnline
      isEntity: $isEntity
      entity: $entity
      addresses: $addresses
      location: $location
      parentalInfo: $parentalInfo
      taxonomies: $taxonomies
      id: $id
      business: $business
      portfolio: $portfolio
      customAttributes: $customAttributes
      city: $city
      country: $country
      district: $district
      education: $education
      isApproved: $isApproved
      dateOfBirth: $dateOfBirth
      updateUsername:$updateUsername
      permissions: $permissions
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const ADD_PLAYER_ACCOUNT = `
  mutation ADD_PLAYER_ACCOUNT(
    $name: String!
    $type: String!
    $username: String!
    $password: String!
    $nameObject: PlayerNameObjectInput
    $displayName: String
    $phone: String
    $landline: String
    $email: String
    $about: String
    $gender: String
    $profilePicture: String
    $dateOfBirth: String
    $nationality: String
    $isFeatured: Boolean
    $isOnline: Boolean
    $isEntity: Boolean
    $entity: ID
    $addresses: [PlayerAddressInput]
    $location: PlayerLocationInput
    $parentalInfo: [PlayerParentalInfoInput]
    $taxonomies: [ID]
    $country: ID
    $city: ID
    $district: ID
    $id: PlayerIdInput
    $business: PlayerBusinessInput
    $customAttributes: [PlayerCustomAttributeInput]
    $dateOfBirth: PlayerDateOfBirthInput
    $portfolio: PlayerPortfolioInput
    $isApproved: Boolean
  ) {
    addPlayerAccount(
      name: $name
      type: $type
      username: $username
      password: $password
      sendPin: false
      nameObject: $nameObject
      displayName: $displayName
      phone: $phone
      landline: $landline
      email: $email
      about: $about
      gender: $gender
      profilePicture: $profilePicture
      dateOfBirth: $dateOfBirth
      nationality: $nationality
      isFeatured: $isFeatured
      isOnline: $isOnline
      isEntity: $isEntity
      entity: $entity
      addresses: $addresses
      dateOfBirth: $dateOfBirth
      location: $location
      parentalInfo: $parentalInfo
      taxonomies: $taxonomies
      id: $id
      customAttributes: $customAttributes
      business: $business
      portfolio: $portfolio
      country: $country
      city: $city
      district: $district
      isApproved: $isApproved
    ) {
      player {
        key: _id
        _id
      }
    }
  }
`
export const ADD_STUDENT_ACCOUNT = `
  mutation ADD_PLAYER_ACCOUNT(
    $name: String!
    $type: String!
    $password: String!
    $nameObject: PlayerNameObjectInput
    $displayName: String
    $phone: String
    $landline: String
    $email: String!
    $about: String
    $gender: String
    $profilePicture: String
    $nationality: String
    $isFeatured: Boolean
    $isOnline: Boolean
    $isEntity: Boolean
    $entity: ID
    $addresses: [PlayerAddressInput]
    $location: PlayerLocationInput
    $parentalInfo: [PlayerParentalInfoInput]
    $taxonomies: [ID]
    $country: ID
    $city: ID
    $district: ID
    $id: PlayerIdInput
    $customAttributes: [PlayerCustomAttributeInput]
    $dateOfBirth: PlayerDateOfBirthInput
    $business: PlayerBusinessInput
    $portfolio: PlayerPortfolioInput
    $isApproved: Boolean
  ) {
    addPlayerAccount(
      name: $name
      type: $type
      username: $email
      password: $password
      sendPin: false
      nameObject: $nameObject
      displayName: $displayName
      phone: $phone
      landline: $landline
      email: $email
      about: $about
      gender: $gender
      profilePicture: $profilePicture
      nationality: $nationality
      isFeatured: $isFeatured
      isOnline: $isOnline
      isEntity: $isEntity
      entity: $entity
      addresses: $addresses
      location: $location
      parentalInfo: $parentalInfo
      taxonomies: $taxonomies
      id: $id
      business: $business
      portfolio: $portfolio
      country: $country
      customAttributes: $customAttributes
      dateOfBirth: $dateOfBirth
      city: $city
      district: $district
      isApproved: $isApproved
    ) {
      player {
        key: _id
        _id
      }
    }
  }
`
export const ADD_SCHOOL_ACCOUNT = `
  mutation addSchoolAccount(
    $name: String!
    $type: String!
    $phone: String
    $landline: String
    $personToContact: PersonToContactInput
    $nameObject: PlayerNameObjectInput
    $email: String
    $profilePicture: String
    $schoolId: ID
    $username: String!
    $password: String!
    $verificationStrategy: String
    $sendPin: Boolean
    $country: ID
    $city: ID
    $district: ID
    $id: PlayerIdInput
    $education: PlayerEducationInput
  ) {
    addSchoolAccount(
      name: $name
      type: $type
      phone: $phone
      landline: $landline
      personToContact: $personToContact
      nameObject: $nameObject
      email: $email
      profilePicture: $profilePicture
      schoolId: $schoolId
      username: $username
      password: $password
      verificationStrategy: $verificationStrategy
      sendPin: $sendPin
      country: $country
      city: $city
      district: $district
      id: $id
      education: $education
    ) {
      player {
        _id
        email
        type
      }
    }
  }
`

export const GET_ADD_ONS = `
  query getAddOns (
    $taxonomies: [ID]
    $searchTerm: String
    $name: String
    $sort: AddOnSort
    $page: Int
    $pageSize: Int
  ) {
    getAddOns (
    taxonomies: $taxonomies
    searchTerm: $searchTerm
    name: $name
    sort: $sort
    page: $page
    pageSize: $pageSize
    ) {
    totalCount
    items {
     key: _id
      _id
      name {
        en
        ar
      }
      refType
      price {
        mainPrice
        discountedPrice
      }
    }
    }
  }
`

export const GET_ADD_ON = `
  query getAddOn (
    $_id: ID!
  ) {
    getAddOn (
    _id: $_id
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
      price {
        mainPrice
        discountedPrice
      }
    description {
      en
      ar
    }
    hasStock
    stock {
      branch {
        key: _id
        _id
        name {
          en
          ar
        }
      }
      amount
    }
    price {
      mainPrice
      discountedPrice
    }
    images
    taxonomies {
      key: _id
      _id
      name {
        en
        ar
      }
    }
    refType
    ref {
      ... on Product {
        _id
        key: _id
        name {
          en
          ar
        }
      }
    }
    }
  }
`

export const EDIT_ADD_ON = `
  mutation editAddOn (
    $_id: ID!
    $name: NameInput
    $description: NameInput
    $hasStock: Boolean
    $stock: [AddOnStockInput]
    $price: AddOnPriceInput
    $images: [String]
    $taxonomies: [ID]
    $ref: ID
    $refType: String
  ) {
    editAddOn (
    _id: $_id
    name: $name
    description: $description
    hasStock: $hasStock
    stock: $stock
    price: $price
    images: $images
    taxonomies: $taxonomies
    ref: $ref
    refType: $refType
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
      price {
        mainPrice
        discountedPrice
      }
    description {
      en
      ar
    }
    hasStock
    stock {
      branch {
        key: _id
        _id
        name {
          en
          ar
        }
      }
      amount
    }
    price {
      mainPrice
      discountedPrice
    }
    images
    taxonomies {
      key: _id
      _id
      name {
        en
        ar
      }
    }
    refType
    }
  }
`

export const DELETE_ADD_ON = `
  mutation deleteAddOn(
    $_id: ID!
  ) {
    deleteAddOn(
      _id: $_id
    ) {
      _id
      key: _id
    }
  }
`

export const ADD_ADD_ON = `
  mutation addAddOn (
    $name: NameInput!
    $description: NameInput
    $hasStock: Boolean
    $stock: [AddOnStockInput]
    $price: AddOnPriceInput
    $images: [String]
    $taxonomies: [ID]
    $ref: ID
    $refType: String
  ) {
    addAddOn (
    name: $name
    description: $description
    hasStock: $hasStock
    stock: $stock
    price: $price
    images: $images
    taxonomies: $taxonomies
    ref: $ref
    refType: $refType
    ) {
      key: _id
      _id
      name {
        en
        ar
      }
      price {
        mainPrice
        discountedPrice
      }
    description {
      en
      ar
    }
    hasStock
    stock {
      branch {
        _id
        name {
          en
          ar
        }
      }
      amount
    }
    price {
      mainPrice
      discountedPrice
    }
    images
    taxonomies {
      _id
      name {
        en
        ar
      }
    }
    refType
    }
  }
`

export const GET_COMPANIES = `
  query GET_COMPANIES(
    $searchTerm: String
    $sort: CompaniesSort
    $page: Int
    $pageSize: Int
  ) {
    getCompanies(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        key: _id
        _id
        name {
          en
          ar
        }
        contactName {
          en
          ar
        }
        phone
      }
    }
  }
`

export const GET_COMPANY = `
  query GET_COMPANY($_id: ID!) {
    getCompany(_id: $_id) {
      key: _id
      _id
      name {
        en
        ar
      }
      contactName {
        en
        ar
      }
      phone
      businessUnits {
        _id
        key: _id
        name {
          en
          ar
        }
        taxonomies {
          _id
        }
      }
      taxonomies {
        _id
      }
    }
  }
`

export const DELETE_COMPANY = `
  mutation DELETE_COMPANY($_id: ID!) {
    deleteCompany(_id: $_id) {
      _id
    }
  }
`

export const ADD_COMPANY = `
  mutation ADD_COMPANY(
    $name: CompanyNameInput!
    $contactName: CompanyNameInput
    $phone: String
    $businessUnits: [ID]
    $taxonomies: [ID]
  ) {
    addCompany(
      name: $name
      contactName: $contactName
      phone: $phone
      businessUnits: $businessUnits
      taxonomies: $taxonomies
    ) {
      key: _id
      _id
    }
  }
`

export const ADD_COMPANY_ACCOUNT = `
  mutation ADD_COMPANY_ACCOUNT(
    $username: String!
    $password: String!
    $name: CompanyNameInput!
    $contactName: CompanyNameInput
    $phone: String
    $businessUnits: [ID]
    $taxonomies: [ID]
  ) {
    addCompanyAccount(
      username: $username
      password: $password
      sendPin: false
      name: $name
      contactName: $contactName
      phone: $phone
      businessUnits: $businessUnits
      taxonomies: $taxonomies
    ) {
      company {
        key: _id
        _id
      }
    }
  }
`

export const EDIT_COMPANY = `
  mutation EDIT_COMPANY(
    $_id: ID!
    $name: CompanyNameInput!
    $contactName: CompanyNameInput
    $phone: String
    $businessUnits: [ID]
    $taxonomies: [ID]
  ) {
    editCompany(
      _id: $_id
      name: $name
      contactName: $contactName
      phone: $phone
      businessUnits: $businessUnits
      taxonomies: $taxonomies
    ) {
      key: _id
      _id
    }
  }
`
export const GET_BUSINESS_UNITS = `
  query GET_BUSINESS_UNITS(
    $searchTerm: String
    $sort: BusinessUnitsSort
    $page: Int
    $pageSize: Int
  ) {
    getBusinessUnits(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        key: _id
        _id
        name {
          en
          ar
        }
      }
    }
  }
`

export const GET_BUSINESS_UNIT = `
  query GET_BUSINESS_UNIT($_id: ID!) {
    getBusinessUnit(_id: $_id) {
      key: _id
      _id
      name {
        en
        ar
      }
      taxonomies {
        _id
        name {
          en
          ar
        }
      }
    }
  }
`

export const DELETE_BUSINESS_UNIT = `
  mutation DELETE_BUSINESS_UNIT($_id: ID!) {
    deleteBusinessUnits(_id: $_id) {
      _id
    }
  }
`

export const ADD_BUSINESS_UNIT = `
  mutation ADD_BUSINESS_UNIT($name: BusinessUnitNameInput!, $taxonomies: [ID]) {
    addBusinessUnit(name: $name, taxonomies: $taxonomies) {
      key: _id
      _id
    }
  }
`

export const EDIT_BUSINESS_UNIT = `
  mutation EDIT_BUSINESS_UNIT(
    $_id: ID!
    $name: BusinessUnitNameInput!
    $taxonomies: [ID]
  ) {
    editBusinessUnit(
      _id: $_id
      name: $name
      taxonomies: $taxonomies
    ) {
      key: _id
      _id
    }
  }
`

export const GET_PAYMENTS = `
  query getPayments ($context: ID) {
    getPayments (context: $context) {
      totalCount
      items {
        _id
        key: _id
        amount
        method
      }
    }
  }
`

// language=graphQL
export const GET_CITIES = `
  query GET_CITIES(
    $searchTerm: String
    $sort: CitiesSort
    $page: Int
    $pageSize: Int
    $country: [String]
  ) {
    getCities(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
      country: $country
    ) {
      totalCount
      items {
        key: _id
        _id

        name {
          en
          ar
        }
        testCity
        country {
          name {
            en
            ar
          }
        }

        isCapital
      }
    }
  }
`

// language=graphQL
export const GET_TEST_CITIES = `
  query GET_CITIES(
    $searchTerm: String
    $sort: CitiesSort
    $page: Int
    $pageSize: Int
    $country: [String]
  ) {
    getCities(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
      country: $country
      testCity: true
    ) {
      totalCount
      items {
        key: _id
        _id

        name {
          en
          ar
        }
        testCity
        country {
          name {
            en
            ar
          }
        }

        isCapital
      }
    }
  }
`

// language=graphql
export const GET_CITY = `
  query GET_CITY($_id: ID!) {
    getCity(_id: $_id) {
      key: _id
      _id

      name {
        en
        ar
      }

      country {
        _id
        name {
          en
          ar
        }
      }

      testCity
      isCapital
    }
  }
`

export const DELETE_CITY = `
  mutation DELETE_CITY($_id: ID!) {
    deleteCity(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const ADD_CITY = `
  mutation ADD_CITY($name: CityNameInput!, $country: ID, $isCapital: Boolean, $testCity: Boolean) {
    addCity(name: $name, country: $country, isCapital: $isCapital, testCity: $testCity) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EDIT_CITY = `
  mutation EDIT_CITY(
    $_id: ID!
    $name: CityNameInput
    $country: ID
    $isCapital: Boolean
    $testCity: Boolean
  ) {
    editCity(
      _id: $_id
      name: $name
      country :$country
      isCapital: $isCapital
      testCity: $testCity
    ) {
      key: _id
      _id
    }
  }
`

export const GET_COUNTRIES = `
  query GET_COUNTRIES(
    $searchTerm: String
    $sort: CountriesSort
    $page: Int
    $pageSize: Int
  ) {
    getCountries(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        key: _id
        _id

        name {
          en
          ar
        }
        code

        region

        continent

        language

        testCountry
      }
    }
  }
`

// language=graphql
export const GET_COUNTRY_BY_NAME = `
  query GET_COUNTRY_BY_NAME($name: String!) {
    getCountryByName(name: $name) {
      key: _id
      _id
      
      name {
        en
        ar
      }
    }
  }
`
export const GET_COUNTRY = `
  query GET_COUNTRY($_id: ID!) {
    getCountry(_id: $_id) {
      key: _id
      _id

      name {
        en
        ar
      }

      timezone
      code

      region

      continent

      language

      testCountry
    }
  }
`

export const DELETE_COUNTRY = `
  mutation DELETE_COUNTRY($_id: ID!) {
    deleteCountry(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const ADD_COUNTRY = `
  mutation ADD_COUNTRY(
    $name: CountryNameInput!
    $code: String!
    $language: String
    $continent: String
    $timezone: String
    $testCountry: Boolean
  ) {
    addCountry(
      name: $name
      code: $code
      continent: $continent
      language: $language
      timezone: $timezone
      testCountry: $testCountry
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EDIT_COUNTRY = `
  mutation EDIT_COUNTRY(
    $_id: ID!
    $name: CountryNameInput
    $code: String
    $language: String
    $continent: String
    $timezone: String
    $testCountry: Boolean
  ) {
    editCountry(
      _id:$_id
      name: $name
      code: $code
      continent: $continent
      language: $language
      timezone: $timezone
      testCountry: $testCountry
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const GET_DISTRICTS = `
  query GET_DISTRICTS (
    $searchTerm: String
    $sort: DistrictsSort
    $page: Int
    $pageSize: Int
    $testDistrict: Boolean
    $city:[String]
  ) {
    getDistricts(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
      testDistrict: $testDistrict
      city:$city
    ) {
      totalCount
      items {
        key: _id
        _id

        name {
          en
          ar
        }
        country {
          _id
          name {
            en
            ar
          }
        }
        testDistrict

        city {
          _id
          name {
            en
            ar
          }
        }
      }
    }
  }
`

// language=graphql
export const GET_TEST_DISTRICTS = `
  query GET_DISTRICTS (
    $searchTerm: String
    $sort: DistrictsSort
    $page: Int
    $pageSize: Int
    $city:[String]
  ) {
    getDistricts(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
      city:$city
      testDistrict: true
    ) {
      totalCount
      items {
        key: _id
        _id

        name {
          en
          ar
        }
        country {
          _id
          name {
            en
            ar
          }
        }
        testDistrict
        city {
          _id
          name {
            en
            ar
          }
        }
      }
    }
  }
`

// language=graphql
export const GET_DISTRICT = `
  query GET_DISTRICT ($_id: ID!) {
    getDistrict(_id: $_id) {
      key: _id
      _id

      name {
        en
        ar
      }
      testDistrict
      country {
        _id
        name {
          en
          ar
        }
      }

      city {
        _id
        name {
          en
          ar
        }
      }
    }
  }
`

export const DELETE_DISTRICT = `
  mutation DELETE_DISTRICT($_id: ID!) {
    deleteDistrict(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const ADD_DISTRICT = `
  mutation ADD_DISTRICT(
    $name: DistrictNameInput!
    $country: ID
    $city: ID
    $testDistrict: Boolean
  ) {
    addDistrict(
      name: $name
      city: $city
      country: $country
      testDistrict: $testDistrict
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EDIT_DISTRICT = `
  mutation EDIT_DISTRICT (
    $_id: ID!
    $name: DistrictNameInput
    $country: ID
    $city: ID
    $testDistrict: Boolean
  ) {
    editDistrict(
      _id: $_id
      name: $name
      city: $city
      country: $country
      testDistrict: $testDistrict
    ) {
      key: _id
      _id
    }
  }
`

export const GET_SCHOOLS = `
  query GET_SCHOOLS (
    $searchTerm: String
    $sort: SchoolsSort
    $page: Int
    $pageSize: Int
  ) {
    getSchools(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
    totalCount
    items {
      key: _id
      _id

      name {
        en
        ar
      }
      country {
        _id
        name {
          en
          ar
        }
      }

      city {
        _id
        name {
          en
          ar
        }
      }

      district {
        _id
        name {
          en
          ar
        }
      }
    }
  }
}
`

export const bulkMarkAsPaidV1 = `
  mutation bulkMarkAsPaidV1  (
    $roundId: ID!,
    $schoolId: ID!
  ) {
    bulkMarkAsPaid(
      roundId: $roundId,
      educationId: $schoolId
    ) {
    totalCount
      items {
        _id
        totals {
          total
          paid
          unpaid
          total
        }
        player {
          name
          education {
            school {
              _id
            }
          }
        }
      }
  }
}
`

export const GET_SCHOOL = `
  query GET_SCHOOL ($_id: ID!) {
    getSchool(_id: $_id) {
        key: _id
        _id

      key: _id
      _id

      name {
        en
        ar
      }
      country {
        _id
        name {
          en
          ar
        }
      }

      city {
        _id
        name {
          en
          ar
        }
      }

      district {
        _id
        name {
          en
          ar
        }
      }
    }
  }
`

export const DELETE_SCHOOL = `
  mutation DELETE_SCHOOL($_id: ID!) {
    deleteSchool(_id: $_id) {
      _id
    }
  }
`

export const ADD_SCHOOL = `
mutation ADD_SCHOOL (
  $name: SchoolNameInput!
  $country: ID
  $city: ID
  $district: ID
) {
  addSchool (
    name: $name
    city: $city
    country: $country
    district: $district
  ) {
    key: _id
    _id
  }
}
`

export const EDIT_SCHOOL = `
  mutation EDIT_SCHOOL (
  $_id: ID!
  $name: SchoolNameInput!
  $country: ID
  $city: ID
  $district: ID
) {
  editSchool(
    _id: $_id
    name: $name
    city: $city
    country: $country
    district: $district
    ) {
      key: _id
      _id
    }
  }
`

export const GET_UNIVERSITIES = `
  query GET_UNIVERSITIES (
    $searchTerm: String
    $sort: UniversitiesSort
    $page: Int
    $pageSize: Int
  ) {
    getUniversities(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
    totalCount
    items {
      key: _id
      _id

      name {
        en
        ar
      }

      isPrivate
    }
  }
}
`

export const GET_UNIVERSITY = `
  query GET_UNIVERSITY ($_id: ID!) {
    getUniversity(_id: $_id) {
      key: _id
      _id

      name {
        en
        ar
      }

      isPrivate
    }
  }
`

export const DELETE_UNIVERSITY = `
  mutation DELETE_UNIVERSITY($_id: ID!) {
    deleteUniversity(_id: $_id) {
      _id
    }
  }
`

export const ADD_UNIVERSITY = `
mutation ADD_UNIVERSITY (
  $name: UniversityNameInput!
  $isPrivate: Boolean
) {
  addUniversity (
    name: $name
    isPrivate: $isPrivate
  ) {
    key: _id
    _id
  }
}
`

export const EDIT_UNIVERSITY = `
  mutation EDIT_UNIVERSITY (
  $_id: ID!
  $name: UniversityNameInput!
  $isPrivate: Boolean
) {
  editUniversity (
    _id: $_id
    name: $name
    isPrivate: $isPrivate
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const GET_ROUNDS = `
  query GET_ROUNDS (
    $searchTerm: String
    $sort: RoundSort
    $page: Int
    $pageSize: Int
  ) {
    getRounds(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        key: _id
        _id

        name {
          en
          ar
        }

        roundId

        country {
          name {
            en
          }
        }

        startDate
        endDate
        statistics {
          roundAverages{
            adminAverages {
              events {
                name
                eventId
                subjects {
                  averageScore
                  name
                  subjectId
                }
              }
            }
          }
      }
      }
    }
  }
`

// language=graphql
export const GET_ROUND = `
  query GET_ROUND ($_id: ID!) {
    getRound(_id: $_id) {
      key: _id
      _id
      name {
        en
        ar
      }

      roundId

      startDate
      endDate

      currency
      country {
        _id
      }

      showTickets
      showScores
      hasOnlinePayment
      hasExtraFees {
        hasFees
        price
      }
      statistics {
        roundAverages{
          adminAverages {
            events {
              name
              eventId
              subjects {
                averageScore
                name
                subjectId
              }
            }
          }
        }
      }
    }
  }
`

export const DELETE_ROUND = `
  mutation DELETE_ROUND($_id: ID!) {
    deleteRound(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const ADD_ROUND = `
  mutation ADD_ROUND (
    $name: RoundNameInput!
    $startDate: String!
    $endDate: String!
    $roundId: String
    $showScores: Boolean
    $showTickets: Boolean
    $currency: RoundCurrency!
    $country: ID
    $hasOnlinePayment: Boolean
    $hasExtraFees: RoundFeesInput
  ) {
    addRound (
      name: $name
      startDate: $startDate
      endDate: $endDate
      roundId: $roundId
      showScores: $showScores
      showTickets: $showTickets
      currency: $currency
      country: $country
      hasOnlinePayment: $hasOnlinePayment
      hasExtraFees: $hasExtraFees
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EDIT_ROUND = `mutation EDIT_ROUND (
  $_id: ID!
  $name: RoundNameInput!
  $startDate: String!
  $endDate: String!
  $showScores: Boolean
  $showTickets: Boolean
  $country: ID
  $hasOnlinePayment: Boolean
  $currency: RoundCurrency
  $hasExtraFees: RoundFeesInput
  $statistics: StatisticsInput
) {
  editRound (
    _id: $_id
    name: $name
    startDate: $startDate
    endDate: $endDate
    showScores: $showScores
    showTickets: $showTickets
    country: $country
    hasOnlinePayment: $hasOnlinePayment
    currency: $currency
    hasExtraFees: $hasExtraFees
    statistics: $statistics
  ) {
    key: _id
    _id
  }
}
`

export const GET_STUDENTS = getPlayersForType('student')

export const GET_PROCTORS = getPlayersForType('proctor')

export const GET_SCHOOL_ADMINS = getPlayersForType('school')

// language=graphql
export const GET_ADMISSIONS = `
  query GET_ADMISSIONS(
    $searchTerm: String
    $sort: AdmissionSort
    $page: Int
    $pageSize: Int
  ) {
    getAdmissions(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount

      items {
        key: _id
        _id
        allowSharing
        admissionId
        status
        events {
          event {
            _id
            name {
              en
            }
          }
          seat
          room
        }
        player {
          _id
          name
        }
        round {
          _id
          name {
            en
          }
        }
      }
    }
  }
`
export const DELETE_ADMISSION = `
  mutation DELETE_ADMISSION($_id: ID!) {
    deleteAdmission(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const EDIT_ADMISSION = `
  mutation EDIT_ADMISSION(
    $_id: ID!
    $allowSharing: Boolean
    $city: ID
    $district: ID
    $events: [EditAdmissionEventInput]
    $hasSpecialNeeds: Boolean
    $universities: [ID]
  ) {
    editAdmission(
      _id: $_id
      city: $city,
      district: $district
      allowSharing: $allowSharing
      events: $events
      hasSpecialNeeds: $hasSpecialNeeds
      universities: $universities
    )  {
      admission {
        _id
      }
    }
  }
`

// language=graphql
export const ADD_ADMISSION = `
  mutation ADD_ADMISSION(
    $player: ID!
    $city: ID
    $district: ID
    $universities: [ID]
    $round: ID!
    $events: [AdmissionEventInput]
    $allowSharing: Boolean
    $hasSpecialNeeds: Boolean
  ) {
    addAdmission(
      player: $player
      city: $city,
      district: $district
      universities: $universities
      round: $round
      events: $events
      hasSpecialNeeds: $hasSpecialNeeds
      allowSharing: $allowSharing
    )  {
      admission {
        _id
      }
    }
  }
`

// language=graphql
export const CANCEL_ADMISSION = `
  mutation CANCEL_ADMISSION($_id: ID!, $cancellationFee: Float) {
    cancelAdmission(_id: $_id, cancellationFee: $cancellationFee) {
      _id
    }
  }
`

export const EXPORT_ADMISSION_RECEIPTS = `
  mutation EXPORT_ADMISSION_RECEIPTS {
    exportAdmissionReceipts
  }
`
// language=graphql
export const EXPORT_PROCTORS = `
  mutation EXPORT_PROCTORS($roundId: String) {
    exportProctorsAsCsv(roundId: $roundId)
  }
`

// language=graphql
export const EXPORT_PROCTOR_ASSIGNMENTS = `
  mutation EXPORT_PROCTOR_ASSIGNMENTS($roundId: String) {
    exportProctorAssignmentsAsCsv(roundId: $roundId)
  }
`
// language=graphql
export const ADD_STATS = `
mutation ADD_STATS($roundId: ID!) {
  addRoundStats(roundId: $roundId) {
    _id
  } 
}
`

// language=graphql
export const EXPORT_CENTERS = `
  mutation EXPORT_CENTERS {
    exportCentersAsCsv
  }
`

// language=graphql
export const EXPORT_SCHOOLS = `
  mutation EXPORT_SCHOOLS {
    exportSchoolsAsCsv
  }
`
// language=graphql
export const EXPORT_EST_RESOURCES = `
  mutation EXPORT_EST_RESOURCES {
    exportESTResourcesAsCsv
  }
`

// language=graphql
export const EXPORT_ALL_ROUNDS_SCORES = `
  mutation EXPORT_ALL_ROUNDS_SCORES($email : String!) {
    exportAllRoundsScoresAsCsv(email :$email)
  }
`

// language=graphql
export const EXPORT_ROUND_SCORES = `
  mutation EXPORT_ADMISSION_SCORES($roundId: String!) {
    exportRoundScoresAsCsv(roundId: $roundId)
  }
`

// language=graphql
export const EXPORT_COMMENTS_FOR_ROUND = `
  mutation EXPORT_ROUND_COMMENTS($roundId: String!) {
    exportCommentsPerRoundCsv(roundId: $roundId)
  }
`

// language=graphql
export const EXPORT_ADMISSIONS_FOR_ROUND = `
  mutation EXPORT_ADMISSIONS_FOR_ROUND($roundId: String!) {
    exportRoundAdmissionsAsCsv(roundId: $roundId)
  }
`

// language=graphql
export const IMPORT_SCORES_FOR_ROUND = `
  mutation IMPORT_SCORES_FOR_ROUND($round: String!, $dryRun: Boolean, $url: String!) {
    importScoresForRound(round: $round, dryRun: $dryRun, url: $url)
  }
`

// language=graphql
export const IMPORT_PROCTOR_ASSIGNMENTS_FOR_ROUND = `
  mutation IMPORT_PROCTOR_ASSIGNMENTS_FOR_ROUND($round: String!, $dryRun: Boolean, $url: String!) {
    importProctorsAssignmentsForRound(round: $round, dryRun: $dryRun, url: $url)
  }
`

// language=graphql
export const IMPORT_PROCTORS = `
  mutation IMPORT_PROCTORS($dryRun: Boolean, $url: String!) {
    importProctors(dryRun: $dryRun, url: $url)
  }
`

// language=graphql
export const IMPORT_CENTERS_FOR_ROUND = `
  mutation IMPORT_CENTERS_FOR_ROUND($round: String!, $dryRun: Boolean, $url: String!) {
    importCenterAssignmentsForRound(round: $round, dryRun: $dryRun, url: $url)
  }
`

// language=graphql
export const GET_ADMISSION = `
  query GET_ADMISSION($_id: ID!) {
    getAdmission(_id: $_id) {
      _id
      admissionId
      allowSharing
      hasSpecialNeeds
      status
      ESTOneReportReference
      ESTTwoReportReference
      events {
        _id

        score {
          total
          scoreReportPdfPath
          subjects {
            name
            range {
              min
              max
            }
            score
            type
          }
        }
        tickets {
          _id
          ticket {
            _id
            name
            time
            endTime
            duration
            price
          }
          checkedIn {
            at
            by {
              _id
              name
              nameObject {
                en
              }
            }
            forced
            forceReason
            room
          }

          checkedOut {
            at
            by {
              _id
              nameObject {
                en
              }
            }
            forced
            forceReason
          }
          price
          isPaid
        }

        event {
          _id
          startDate
          name {
            en
          }
        }

        venue {
          _id
          name {
            en
          }
        }
        seat
        room
      }

      player {
        _id
        name
        nameObject {
          en
          ar
        }
        phone
        email
        gender
      }

      round {
        _id
        roundId
        name {
          en
          ar
        }
        currency
        showTickets
        startDate
        endDate
      }

      universities {
        _id
      }

      district {
        _id
        name {
          en
        }
      }

      city {
        _id
        name {
          en
        }
      }

      totals {
        total
        unpaid
        paid
        lateFees
        earlyDiscount
        fees {
          cancellationFee
        }
      }
      payments {
        totalCount
        items {
          _id
        }
      }
      canceled
    }
  }
`

// language=graphql
export const GET_EVENTS = `
  query GET_EVENTS (
    $searchTerm: String
    $sort: EventSort
    $page: Int
    $pageSize: Int
    $round: String
  ) {
    getEvents(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
      round: $round
    ) {
      totalCount
      items {
        key: _id
        _id
        checkoutDate
        name {
          en
        }

        tickets {
          name
          _id
          key: _id
          checkoutDate
        }

        description {
          en
        }

        round {
          name {
            en
          }
        }
      }
    }
  }
`

// language=graphql
export const GET_EVENT = `
  query GET_EVENT($_id: ID!) {
    getEvent(_id: $_id) {
      key: _id
      _id

      name {
        en
      }
      description {
        en
      }
      checkoutDate
      startDate
      bookingDates {
        early {
          endDate
          startDate
        }
        normal {
          endDate
          startDate
        }
        late {
          endDate
          startDate
        }
      }

      tickets {
        _id
        price
        name
        duration
        time
        endTime
        checkoutDate
      }

      round {
        _id
        name {
          en
        }
      }
    }
  }
`

export const DELETE_EVENT = `
  mutation DELETE_EVENT($_id: ID!) {
    deleteEvent(_id: $_id) {
      _id
    }
  }
`

export const ADD_EVENT = `
mutation ADD_EVENT(
  $name: EventNameInput
  $bookingDates: EventBookingDatesInput
  $round: ID
  $tickets: [EventTicketInput]
  $startDate: String
  $checkoutDate: String
) {
  addEvent(
    duration: 0 # this is required in add only and tickets contains the duration
    name: $name
    round: $round
    bookingDates: $bookingDates
    tickets: $tickets
    startDate: $startDate
    checkoutDate: $checkoutDate
  ) {
    event {
      key: _id
      _id
    }
  }
}
`

export const EDIT_EVENT = `
mutation EDIT_EVENT(
  $_id: ID!
  $name: EventNameInput
  $bookingDates: EventBookingDatesInput
  $round: ID
  $tickets: [EditEventTicketInput]
  $startDate: String
  $checkoutDate: String
) {
  editEvent(
    _id: $_id
    name: $name
    round: $round
    bookingDates: $bookingDates
    tickets: $tickets
    startDate: $startDate
    checkoutDate: $checkoutDate
    
  ) {
    key: _id
    _id
  }
}
`
// big ugly instead of doing multiple requests lets just do single request multiple queries
export const GET_PAYMENTS_TRANSACTIONS = (payments) => `
{
${payments.map((paymentId) => `
    payment_${paymentId.replace(/-/g, '_')}: getTransactions(payment: "${paymentId}") {
    items {
      _id
      externalReference
    }
  }
`)
  .join('\n')}
}
`

export const GET_PAYMOB_TRANSACTIONS = (externalReferences) => `
{
  ${
  externalReferences.map((ref) => `transaction_${ref.replace(/-/g, '_')}: getExternalTransaction(externalTransactionId:"${ref}", provider: "paymob")`)
    .join('\n')
}
}
`
export const MARK_ADMISSION_AS_PAID = `
mutation MARK_ADMISSION_AS_PAID($_id: ID!) {
  markAdmissionAsPaid(_id: $_id) {
    _id
  }
}
`

// language=graphql
export const EXPORT_ADMISSIONS = `
  mutation {
    exportAdmissionsAsCsv
  }
`

// language=graphql
export const EXPORT_STUDENTS = `
  mutation {
    exportStudentsAsCsv
  }
`

// language=graphql
export const GET_NEW_CMS = `
  query GET_CMS {
    cms {
      menus {
        name
        data
      }
      FAQs {
        question
        answer
        category
        order
        media {
          url
          type
          name
        }
      }
      news {
        title
        description
        content
        slug
        createdAt
        media {
          url
          type
        }
      }
      sliders {
        name
        data
        imageUrl
        action {
          title
          url
        }
      }
      faqCategories {
        name
        order
      }
      newsCategories {
        name
        order
      }
      pages {
        name
        bannerUrl
        rightImgUrl
        sections {
          content {
            ar
            en
          }
          subTitle {
            ar
            en
          }
          title {
            ar
            en
          }
        }
      }
      newsBanner
    }
  }
`

// language=graphql
export const EDIT_NEW_CMS = `
  mutation EDIT_CMS(
    $FAQs: [FaqInput]
    $news: [NewsInput]
    $sliders: [SliderInput]
    $pages: [PageInput]
    $menus: [CMSMenuInput]
    $faqCategories: [CMSCategoryInput]
    $newsCategories: [CMSCategoryInput]
    $newsBanner: String
  ) {
    editCMS(
      FAQs: $FAQs
      news: $news
      sliders: $sliders
      pages: $pages
      menus: $menus
      faqCategories: $faqCategories
      newsCategories: $newsCategories
      newsBanner: $newsBanner
    ) {
      _id
    }
  }
`

// language=graphql
export const GET_CMS_FAQ_CATEGORIES = `
  query GET_CMS {
    cms {
      items: faqCategories {
        value: name
        label: name
      }
    }
  }
`

// language=graphql
export const GET_CMS_NEWS_CATEGORIES = `
  query GET_CMS {
    cms {
      items: newsCategories {
        value: name
        label: name
      }
    }
  }
`

// language=graphql
export const GET_CMS_NEWS_ARTICLE = `
  query GET_CMS_NEWS_ARTICLE($_id: ID!) {
    getCMSNewsArticle(_id: $_id) {
      _id
      key: _id
      title
      content
      description
      category
      slug
      publishedAt
      sortDate
      featured
      published
      media {
        url
        name
        type
      }
    }
  }
`

// language=graphql
export const GET_CMS_NEWS = `
  query GET_CMS_NEWS($searchTerm: String) {
    getCMSNews(searchTerm: $searchTerm, showUnpublished: true) {
      totalCount
      items {
        _id
        key: _id
        title
        slug
        content
        category
        description
        publishedAt
        published
      }
    }
  }
`

export const DELETE_CMS_NEWS = `
mutation GET_CMS_NEWS($_id: ID!) {
  deleteCMSNewsArticle(_id: $_id) {
    _id
    key: _id
  }
}
`

export const ADD_CMS_NEWS = `
mutation($news: NewsInput) {
  addCMSNews(news: $news) {
    _id
    key: _id
  }
}
`

export const EDIT_CMS_NEWS = `
mutation($news: NewsInput) {
  editCMSNews(news: $news) {
    _id
    key: _id
  }
}
`

export const GET_ADMISSION_SERVICES = `
query GET_ADMISSION_SERVICES(
  $searchTerm: String
  $sort: AdmissionServiceSort
  $page: Int
  $pageSize: Int
) {
  getAdmissionServices(
    searchTerm: $searchTerm
    sort: $sort
    page: $page
    pageSize: $pageSize
    showAvailableOnly: false
  ) {
    totalCount
    items {
      _id
      key: _id
      name {
        en
      }
      code
      enabled
      endDate
      rounds {
        _id
        name {
          en
        }
        roundId
        sortOrder
      }
      description {
        en
      }
    }
  }
}
`

// language=graphql
export const GET_ADMISSION_SERVICE = `
  query GET_ADMISSION_SERVICE ($_id: ID!) {
    getAdmissionService(_id: $_id) {
      _id
      key: _id
      basePrice
      expressFee
      isSchoolService
      isRoundService
      startsFrom
      shippingPrice

      name {
        en
        ar
      }

      code
      enabled
      endDate

      rounds {
        _id
        key: _id
      }

      fields

      description {
        en
      }
      summary {
        en
      }
    }
  }
`

// language=graphql
export const DELETE_ADMISSION_SERVICE = `
  mutation DELETE_ADMISSION_SERVICE($_id: ID!) {
    deleteAdmissionService(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const ADD_ADMISSION_SERVICE = `
  mutation ADD_ADMISSION_SERVICE(
    $name: AdmissionServiceNameInput!
    $code: String!
    $description: AdmissionServiceNameInput!
    $summary: AdmissionServiceNameInput!
    $fields: [JSONObject!]!
    $endDate: DateTime
    $rounds: [ID]
    $enabled: Boolean
    $basePrice: Float
    $expressFee: Float
    $isSchoolService: Boolean
    $isRoundService: Boolean
    $startsFrom: Float
  ) {
    addAdmissionService(
      name: $name
      code: $code
      description: $description
      fields: $fields
      endDate: $endDate
      rounds: $rounds
      enabled: $enabled
      basePrice: $basePrice
      expressFee: $expressFee
      isSchoolService: $isSchoolService
      isRoundService: $isRoundService
      startsFrom: $startsFrom
      summary: $summary
    ) {
      key: _id
      _id
    }
  }
`
// language=graphql
export const EDIT_ADMISSION_SERVICE = `
  mutation EDIT_ADMISSION_SERVICE (
    $_id: ID!
    $name: AdmissionServiceNameInput
    $description: AdmissionServiceNameInput
    $summary: AdmissionServiceNameInput
    $fields: [JSONObject!]
    $endDate: DateTime
    $rounds: [ID]
    $enabled: Boolean
    $basePrice: Float
    $expressFee: Float
    $isSchoolService: Boolean
    $isRoundService: Boolean
    $startsFrom: Float
    $shippingPrice: Float
  ) {
    editAdmissionService(
      _id: $_id
      name: $name
      description: $description
      fields: $fields
      endDate: $endDate
      rounds: $rounds
      enabled: $enabled
      basePrice: $basePrice
      expressFee: $expressFee
      isSchoolService: $isSchoolService
      isRoundService: $isRoundService
      startsFrom: $startsFrom
      shippingPrice: $shippingPrice
      summary: $summary
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const GET_ADMISSION_SERVICE_REQUESTS = `
  query GET_ADMISSION_SERVICE_REQUESTS(
    $searchTerm: String
    $sort: AdmissionServiceRequestSort
    $page: Int
    $pageSize: Int
  ) {
    getAdmissionServiceRequests(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        _id
        key: _id
        player {
          name
          email
        }
        requestId
        serviceCopy {
          name {
            en
          }
        }
        createdAt
        status
      }
    }
  }
`

// language=graphql
export const GET_ADMISSION_SERVICE_REQUEST = `
  query GET_SERVICE_REQUEST($_id: ID!) {
    getAdmissionServiceRequest(_id: $_id) {
      _id
      requestId
      attributes {
        name
        valueText
        value
        valueId
      }
      player {
        _id
        name
        email
        phone
        nameObject {
          ar
        }
      }
      serviceCopy {
        _id
        name {
          en
        }
        fields
        basePrice
        expressFee
        fields
      }
      orderId

      receipt {
        createdAt
      }

      status
      price
    }
  }
`

// language=graphql
export const EDIT_ADMISSION_SERVICE_REQUEST = `
  mutation UPDATE_REQUEST_STATUS(
    $_id: ID!
    $status: AdmissionServiceRequestStatus!
  ) {
    editAdmissionServiceRequest(_id: $_id, status: $status ) {
      _id
      status
    }
  }
`

// language=graphql
export const EDIT_ADMISSION_SERVICE_REQUEST_ATTRIBUTES = `
  mutation UPDATE_REQUEST_ATTRIBUTES(
    $_id: ID!
    $attributes: [ServiceRequestAttributeInput!]!
  ) {
    editAdmissionServiceRequest(_id: $_id, attributes: $attributes ) {
      _id
      status
    }
  }
`

export const DELETE_ADMISSION_SERVICE_REQUEST = `
mutation DELETE_ADMISSION_SERVICE_REQUEST($_id: ID!) {
  deleteAdmissionServiceRequest(_id: $_id) {
    _id
  }
}
`

export const EXPORT_ADMISSION_REQUESTS = `
mutation EXPORT_ADMISSION_REQUESTS($_id: ID) {
  exportAdmissionServiceRequest(serviceId: $_id)
}
`

export const GET_STAFFS = `
    query getStaffs (
    $branches: [ID]
    $phone: String
    $email: String
    $searchTerm: String
    $sort: StaffSort
    $page: Int
    $pageSize: Int
    ){
    getStaffs(
    branches: $branches
    phone: $phone
    email: $email
    searchTerm: $searchTerm
    sort: $sort
    page: $page
    pageSize: $pageSize
    ){
    totalCount
    items {
    _id
    key: _id
    name
    phone
    email
    createdAt
    }
    }
    }
`

export const GET_STAFF = `
    query getStaff (
    $_id: ID!
    ){
    getStaff(
    _id: $_id 
    ){
    _id
    key: _id
    name
    phone
    email
    branches {
    _id
    key: _id
    name {
    en
    }
    }
    }
    }
`

export const EDIT_STAFF = `
    mutation editStaff (
    $_id: ID!
    $branches: [ID]
    $phone: String
    $email: String
    $name: String
    $customAttributes: [StaffCustomAttributeInput]
    ){
    editStaff(
    _id: $_id
    branches: $branches
    phone: $phone
    email: $email
    name: $name
    customAttributes: $customAttributes
    ){
    _id
    key: _id
    name
    phone
    email
    }
    }
`

export const ADD_STAFF = `
    mutation addStaff (
    $branches: [ID]
    $phone: String
    $email: String
    $name: String!
    $customAttributes: [StaffCustomAttributeInput]
    ){
    addStaff(
    branches: $branches
    phone: $phone
    email: $email
    name: $name
    customAttributes: $customAttributes
    ){
    _id
    key: _id
    name
    phone
    email
    }
    }
`

export const ADD_STAFF_ACCOUNT = `
    mutation addStaffAccount (
    $branches: [ID]
    $phone: String
    $password: String!
    $username: String!
    $email: String
    $name: String!
    $customAttributes: [StaffCustomAttributeInput]
    ){
    addStaffAccount(
    username: $username
    password: $password
    branches: $branches
    phone: $phone
    email: $email
    name: $name
    customAttributes: $customAttributes
    ){
    token
    staff {
    _id
    key: _id
    }
    }
    }
`

export const DELETE_STAFF = `
    mutation deleteStaff (
    $_id: ID!
    ){
    deleteStaff(
    _id: $_id
    ){
    _id
    key: _id
    }
    }
`

export const DELETE_STAFF_ACCOUNT = `
    mutation deleteStaffAccount (
    $_id: ID!
    ){
    deleteStaffAccount(
    _id: $_id
    ){
    _id
    key: _id
    }
    }
`

export const SUP_GET_TOP_PRODUCTS_REPORT = `
  query getTopProducts (
    $dateRange: SupDateRangeInput
    $vendor: ID
    $customer: ID
    $page: Int
    $pageSize: Int
  ) {
    getSupTopProducts (
      dateRange: $dateRange
      vendor: $vendor
      customer: $customer
      page: $page
      pageSize: $pageSize
    ) {
      items {
        product {
          name
          _id
        }
        countryOfOrigin {
          name
          _id
        }
        date
        price
        marketplacePrice
        quantityDisplay
        priceGreaterThanMarketplacePrice
        quantity
      }
      totalCount
    }
  }
`

export const SUP_GET_TOTALS_REPORT = `
  query getTotals (
    $dateRange: SupDateRangeInput
    $vendor: ID
    $customer: ID
  ) {
    getSupTotalsReport (
      dateRange: $dateRange
      vendor: $vendor
      customer: $customer
    ) {
      purchases
      expanses
      marketplaceValue
    }
  }
`

export const SUP_GET_TOP_TAXONOMIES_REPORT = `
  query getTaxonomies (
    $dateRange: SupDateRangeInput
    $vendor: ID
    $customer: ID
  ) {
    getSupTaxonomiesReport (
      dateRange: $dateRange
      vendor: $vendor
      customer: $customer
    ) {
      topPurchases {
        purchases
        taxonomy {
          name
          _id
        }
      }
      topExpanses {
        expanses
        taxonomy {
          name
          _id
        }
      }
    }
  }
`

export const SUP_EXPORT_TOP_PRODUCTS_REPORT_AS_CSV = `
  mutation exportSupTopProductsAsCsv (
    $dateRange: SupDateRangeInput
    $vendor: ID
    $customer: ID
  ) {
    exportSupTopProductsAsCsv (
      dateRange: $dateRange
      vendor: $vendor
      customer: $customer
    )
  }
`

// language=graphql
export const GET_VENUES = `
  query GET_VENUES(
    $searchTerm: String
    $sort: VenueSort
    $page: Int
    $pageSize: Int
  ) {
    getVenues(
      searchTerm: $searchTerm,
      sort: $sort,
      page: $page,
      pageSize: $pageSize
    ) {
      totalCount
      items {
        _id
        key: _id
        name {
          en
          ar
        }
      }
    }
  }
`

// language=graphql
export const GET_VENUE = `
  query GET_VENUE($_id: ID!) {
    getVenue(_id: $_id) {
      _id
      key: _id
      address {
        address1
        city
      }

      customAttributes {
        key
        value
        valueType
      }

      district {
        _id
      }

      city {
        _id
      }
      
      country {
        _id
      }

      name {
        en
        ar
      }
    }
  }
`

// language=graphql
export const DELETE_VENUE = `
  mutation DELETE_VENUE($_id: ID!) {
    deleteVenue(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const ADD_VENUE = `
  mutation ADD_VENUE(
    $name: NameInput
    $address: VenueAddressInput
    $customAttributes: [CustomAttributeInput]
    $city: ID
    $district: ID
    $country: ID
  ) {
    addVenue(
      name: $name
      address: $address
      customAttributes: $customAttributes
      city: $city
      district: $district
      country: $country
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EDIT_VENUE = `
  mutation EDIT_VENUE(
    $_id: ID!
    $name: NameInput
    $address: VenueAddressInput
    $customAttributes: [CustomAttributeInput]
    $city: ID
    $district: ID
    $country: ID
  ) {
    editVenue(
      _id: $_id
      name: $name
      address: $address
      customAttributes: $customAttributes
      city: $city
      district: $district
      country: $country
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EDIT_ADMISSION_EVENT_VENUE = `
  mutation EDIT_ADMISSION_EVENT_VENUE ($admissionId: ID!, $eventId: ID!, $venue: ID, $update:UpdateAdmissionVenueType!, $room: String ) {
    editAdmissionEventVenue(admissionId: $admissionId, eventId: $eventId, venue: $venue, update: $update, room: $room) {
      _id
    }
  }
`

export const GET_RECURRING_ORDERS = `
    query GET_RECURRING_ORDERS (
     $customer: ID
     $searchTerm: String
     $date: OrderDateRangeInput
     $createdAtRange: OrderDateRangeInput
     $verndors: [ID]
     $branches: [ID]
     $recurrencePattern: RecurringOrderRecurrence
     $sort: FulfillmentSort
     $page: Int
     $pageSize: Int
    ){
    getRecurringOrders(
    customer: $customer
    branches: $branches
    searchTerm: $searchTerm
    date: $date
    createdAtRange: $createdAtRange
    vendors: $verndors
    recurrencePattern: $recurrencePattern
    sort: $sort
    page: $page
    pageSize: $pageSize
     ) {
     totalCount
     items {
     _id
     key: _id
     customer {
     _id
          key: _id
     name
     }
     date
     hour
     recurrencePattern
     paymentMethod
     branch {
     _id
          key: _id
     name {
     en
     }
     }
     variations {
     quantity
     variation {
     _id
     key: _id
     product {
     _id
     name {
     en
     }
     }
     }
     }
     }
     }
    }
`

export const GET_RECURRING_ORDER = `
        query GET_RECURRING_ORDER (
     $_id: ID!
    ){
    getRecurringOrder(
    _id: $_id
     ) {
        recurringOrder {
             _id
     key: _id
     customer {
     _id
     key: _id
     name
     phone
     }
     date
     hour
     recurrencePattern
     lastRunAt
     nextRunAt
     paymentMethod
     branch {
     _id
          key: _id
     name {
     en
     }
     }
     variations {
     quantity
     variation {
     _id
     key: _id
     price {
     discountedPrice
     mainPrice
     }
     barcode
     product {
     _id
     name {
     en
     }
     }
     }
     }
        }
     orders {
     _id
     checkoutId
     createdAt
     totals {
     total
     }
     status
     vendor {
     _id
     name {
     en
     }
     }
     customer {
     _id
     name
     }
     }
     }
     
    }
`

export const CANCEL_RECURRING_ORDER = `
  mutation cancelRecurringOrder(
  $_id: ID!
) {
  cancelRecurringOrder(
   _id: $_id
  ) {
    _id
    key: _id
  }
}
`

export const GET_LOCATION_REQUESTS = `
 query getLocationRequests(
     $sort: LocationRequestsSort
     $page: Int
    $pageSize: Int
 ){
 getLocationRequests(
    sort: $sort
    page: $page
    pageSize: $pageSize
 ){
 totalCount
 items {
      _id
      existingCountry {
        _id
        name {
          en
        }
      }
      newCountry {
        en
      }
      existingCity {
        _id
        name {
          en
        }
      }
      newCity {
        en
      }
      newDistrict {
        en
      }
      player {
        _id
        name
      }
      createdAt
    }
 }
 }
`

export const GET_SERVICE_REQUESTS = `
 query getTaxonomyRequests(
     $sort: TaxonomySort
     $page: Int
     $pageSize: Int
 ){
 getTaxonomyRequests(
    sort: $sort
    page: $page
    pageSize: $pageSize
 ){
 totalCount
 items {
 _id
 newParent {
 name {
 en
 }
 }
  newTaxonomy {
 name {
 en
 }
 }
 existingParent {
 _id
 name {
 en
 }
 }
 player{
 _id
 name
 }
 }
 }
 }
`

export const GET_LOCATION_REQUEST = `
 query getLocationRequest(
     $_id: ID!
 ){
 getLocationRequest(
    _id: $_id
 ){
      _id
      existingCountry {
        _id
        name {
          en
        }
      }
      newCountry {
        en
      }
      existingCity {
        _id
        name {
          en
        }
      }
      newCity {
        en
      }
      newDistrict {
        en
      }
      player {
        _id
        name
      }
      createdAt
 }
 }
`

export const APPROVE_LOCATION_REQUEST = `
  mutation approveLocationRequest(
  $_id: ID!
  $code: String
) {
  approveLocationRequest(
   _id: $_id
   code: $code
  ) {
    _id
    key: _id
  }
}
`

export const APPROVE_TAXONOMY_REQUEST = `
  mutation approveTaxonomyRequest(
  $_id: ID!
  $newTaxonomy: NewTaxonomyApproveInput!
  $newParent: NewTaxonomyApproveInput!
) {
  approveTaxonomyRequest(
   _id: $_id
   newTaxonomy: $newTaxonomy
   newParent: $newParent
  ) {
    _id
    key: _id
  }
}
`

export const EDIT_LOCATION_REQUEST = `
  mutation editLocationRequest(
  $_id: ID!
  $newCountry: CountryNameInput
  $newCity: CityNameInput
  $newDistrict: DistrictNameInput
  $existingCountry: ID
  $existingCity: ID
) {
  editLocationRequest(
   _id: $_id
   newCountry: $newCountry
   newCity: $newCity
   newDistrict: $newDistrict
   existingCountry: $existingCountry
   existingCity: $existingCity
  ) {
    _id
    key: _id
  }
}
`

// language=graphql
export const GET_PROCTOR_ASSIGNMENTS = `
  query GET_PROCTOR_ASSIGNMENTS(
    $role: ProctorRole
    $proctor: ID
    $searchTerm: String
    $rounds: [ID]
    $centers: [ID]
    $districts: [ID]
    $cities: [ID]
    $sort: ProctorAssignmentSort
    $page: Int
    $pageSize: Int
  ) {
    getProctorAssignments(
      role: $role
      centers: $centers
      rounds: $rounds
      districts: $districts
      cities: $cities
      proctor: $proctor
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        _id
        key: _id
        role
        exam{
          name{
            en
          }
        }
        room

        round {
          name {
            en
          }
        }

        player {
          _id
          nameObject {
            en
          }
        }

        centers {
          name {
            en
          }
        }
      }
    }
  }
`

// language=graphql
export const ADD_PROCTOR_ASSIGNMENT = `
  mutation ADD_PROCTOR_ASSIGNMENTS(
    $role: ProctorRole!
    $player: ID!
    $round: ID!
    $centers: [ID]
    $exam: ID!
    $room: String
    $selectAllCenters: Boolean
  ) {
    addProctorAssignment(
      centers: $centers
      player: $player
      round: $round
      role: $role
      exam: $exam
      room: $room
      selectAllCenters: $selectAllCenters
    ) {
      _id
      key: _id
    }
  }
`

// language=graphql
export const GET_PROCTOR_ASSIGNMENT_FULL = `
  query GET_PROCTOR_ASSIGNMENT($_id: ID!) {
    getProctorAssignment(_id: $_id) {
      _id
      key: _id
      role

      round {
        name {
          en
        }
      }
      exam {
        name {
          en
        }
      }

      room
      player {
        _id
        nameObject {
          en
        }
      }

      seniorProctors {
        nameObject {
          en
        }
      }

      proctors {
        nameObject {
          en
        }
      }

      centerExamAssignments {
        center {
          _id
          name {
            en
          }
        }

        seniorProctors {
          nameObject {
            en
          }
        }

        proctors {
          nameObject {
            en
          }
        }

        exam {
          name {
            en
          }
        }
      }

      centers {
        name {
          en
        }

        district {
          name {
            en
          }
        }

        city {
          name {
            en
          }
        }
      }
    }
  }
`

// language=graphql
export const GET_PROCTOR_ASSIGNMENT = `
  query GET_PROCTOR_ASSIGNMENT($_id: ID!) {
    getProctorAssignment(_id: $_id) {
      _id

      round {
        name {
          en
        }
        _id
      }

      player {
        nameObject {
          en
        }
      }

      centers {
        name { en }
        _id
      }

      exam {
        name { en }
        _id
      }

      role
      room

      seniorProctors {
        nameObject { en }
        _id
      }

      proctors {
        nameObject { en }
        _id
      }
    }
  }
`

// language=graphql
export const EDIT_PROCTOR_ASSIGNMENT = `
  mutation EDIT_PROCTOR_ASSIGNMENTS(
    $_id: ID!
    $player: ID
    $round: ID
    $centers: [ID]
    $role: ProctorRole!
    $exam: ID!
    $room: String
    $selectAllCenters: Boolean
  ) {
    editProctorAssignment(
      _id: $_id
      centers: $centers
      player: $player
      round: $round
      role: $role
      exam: $exam
      room: $room
      selectAllCenters: $selectAllCenters
    ) {
      _id
      key: _id
    }
  }
`

// language=graphql
export const DELETE_PROCTOR_ASSIGNMENT = `
  mutation DELETE_PROCTOR_ASSIGNMENT($_id: ID!) {
    deleteProctorAssignment(_id: $_id) {
      _id
    }
  }
`

export const GET_ORDER_CONFIGURATION = `
  query getOrderConfiguration{
  getOrderConfiguration {
  _id
  orderStatusList {
  name
  type
  }
  }
  }
`

// language=graphql
export const EDIT_ADMISSION_SCORE = `mutation editAdmissionScore($admission: ID, $event: ID, $score: AdmissionEventScoreInput!, $ESTOneReportReference: String,$ESTTwoReportReference: String) {
  editAdmissionScore(admission: $admission, event: $event, score: $score, ESTOneReportReference: $ESTOneReportReference, ESTTwoReportReference: $ESTTwoReportReference) {
    _id
  }
}
`

// language=graphql
export const GET_ADMISSION_NOTES = `
  query GET_ADMISSION_NOTES($admission: ID,$subjects: [ID],$exams:[ID]) {
    getAdmissionNotes(   
      admission: $admission
      exams: $exams
      subjects: $subjects) {
      items{
        _id
        note
        subject{
          _id 
        }
        createdAt
        proctorAssignment{
          _id
          player{
            name
          }
        }
        admission{
          _id
        }
      }
      totalCount
  }
}
`

// language=graphql
export const GET_EXAM_COMMENTS = `
  query GET_EXAM_COMMENTS($exam:ID) {
    getEventComments(event: $exam) {
      items{
        _id
        subject{
          _id
          name
        }
        comment
        createdAt
        room
        proctorAssignment{
          _id
          player{
            name
          }
        }
      }
      totalCount
  }
}
`
// language=graphql
export const EXPORT_ADMISSIONS_NOTES = `
  mutation {
    exportAdmissionsNotesAsCsv
  }
`
// language=graphql
export const EXPORT_ADMISSIONS_NOTES_FOR_ROUND = `
  mutation EXPORT_ADMISSIONS_NOTES_FOR_ROUND($roundId: String!) {
    exportRoundAdmissionsNotesAsCsv(roundId: $roundId)
  }
`
// language=graphql
export const EXPORT_CHECK_IN_FOR_ROUND = `
  mutation exportCheckInPerRoundCsv($roundId: String!) {
    exportCheckInPerRoundCsv(roundId: $roundId)
  }
`

export const GET_TEST_COUNTRIES = `
  query GET_COUNTRIES(
    $searchTerm: String
    $sort: CountriesSort
    $page: Int
    $pageSize: Int
  ) {
    getCountries(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
      testCountry: true
    ) {
      totalCount
      items {
        key: _id
        _id

        name {
          en
          ar
        }
        code

        region

        continent

        language

        testCountry
      }
    }
  }
`

export const EDIT_TEST_COUNTRY = `
  mutation EDIT_COUNTRY($_id: ID!) {
    editCountry(_id: $_id, testCountry:false) {
      key: _id
      _id
    }
  }
`

export const EDIT_TEST_CITY = `
  mutation EDIT_CITY($_id: ID!) {
    editCity(_id: $_id, testCity:false) {
      key: _id
      _id
    }
  }
`
export const EDIT_TEST_DISTRICT = `
  mutation EDIT_DISTRICT($_id: ID!) {
    editDistrict(_id: $_id, testDistrict:false) {
      key: _id
      _id
    }
  }
`

// language=graphQL
export const GET_ADMISSION_NOTE_CATEGORIES = `
  query GET_ADMISSION_NOTE_CATEGORIES(
    $searchTerm: String
    $sort: AdmissionNoteCategoriesSort
    $page: Int
    $pageSize: Int
  ) {
    getAdmissionNoteCategories(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        key: _id
        _id
        name        
        }

      }
    }
`

// language=graphql
export const GET_ADMISSION_NOTE_CATEGORY = `
  query GET_ADMISSION_NOTE_CATEGORY($_id: ID!) {
    getAdmissionNoteCategory(_id: $_id) {
      key: _id
      _id
      name
    }
  }
`

export const DELETE_ADMISSION_NOTE_CATEGORY = `
  mutation DELETE_ADMISSION_NOTE_CATEGORY($_id: ID!) {
    deleteAdmissionNoteCategory(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const ADD_ADMISSION_NOTE_CATEGORY = `
  mutation ADD_ADMISSION_NOTE_CATEGORY($name: String!) {
    addAdmissionNoteCategory(name: $name) {
      key: _id
      _id
    }
  }
`

// language=graphQL
export const GET_ADMISSION_NOTE_SUB_CATEGORIES = `
  query GET_ADMISSION_NOTE_SUB_CATEGORIES(
    $searchTerm: String
    $sort: AdmissionNoteSubCategoriesSort
    $page: Int
    $pageSize: Int
  ) {
    getAdmissionNoteSubCategories(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        key: _id
        _id
        name   
        admissionNoteCategory{
          _id
        }     
        }

      }
    }
  
`

// language=graphql
export const GET_ADMISSION_NOTE_SUB_CATEGORY = `
  query GET_ADMISSION_NOTE_SUB_CATEGORY($_id: ID!) {
    getAdmissionNoteSubCategory(_id: $_id) {
      key: _id
      _id
      name
      admissionNoteCategory{
        _id
      }
    }
  }
`

export const DELETE_ADMISSION_NOTE_SUB_CATEGORY = `
  mutation DELETE_ADMISSION_NOTE_SUB_CATEGORY($_id: ID!) {
    deleteAdmissionNoteSubCategory(_id: $_id) {
      _id
    }
  }
`

// language=graphql
export const ADD_ADMISSION_NOTE_SUB_CATEGORY = `
  mutation ADD_ADMISSION_NOTE_SUB_CATEGORY(
    $name: String!
    $admissionNoteCategory: ID!) {
    addAdmissionNoteSubCategory(
      name: $name
      admissionNoteCategory: $admissionNoteCategory 
       ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EDIT_ADMISSION_NOTE_CATEGORY = `
  mutation EDIT_ADMISSION_NOTE_CATEGORY(
    $_id: ID!
    $name: String
  ) {
    editAdmissionNoteCategory(
      _id: $_id
      name: $name
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EDIT_ADMISSION_NOTE_SUB_CATEGORY = `
  mutation EDIT_ADMISSION_NOTE_SUB_CATEGORY(
    $_id: ID!
    $name: String
    $admissionNoteCategory: ID

  ) {
    editAdmissionNoteSubCategory(
      _id: $_id
      name: $name
      admissionNoteCategory:$admissionNoteCategory
    ) {
      key: _id
      _id
    }
  }
`

// language=graphql
export const EXPORT_PROCTORS_PER_ROUND = `
  mutation EXPORT_PROCTORS_PER_ROUND($roundId: String) {
    exportProctorsPerRoundAsCsv(roundId: $roundId)
  }
`

// language=graphql
export const EXPORT_PLAYERS = `
  mutation EXPORT_PLAYERS($startDate: DateTime, $endDate: DateTime) {
    exportPlayers(startDate: $startDate, endDate: $endDate)
  }
`

// language=graphql
export const EXPORT_ORDERS = `
  mutation EXPORT_ORDERS($startDate: DateTime, $endDate: DateTime) {
    exportOrders(startDate: $startDate, endDate: $endDate)
  }
`

// language=graphql
export const GET_REVIEWS = `
  query GET_REVIEWS(
    $reviewer: ID
    $reviewerType: String
    $reviewee: ID
    $revieweeType: String
    $context: ID
    $contextType: String
    $searchTerm: String
    $sort: ReviewSort
    $page: Int
    $pageSize: Int
  ) {
    getReviews(
      reviewer: $reviewer
      reviewerType: $reviewerType
      reviewee: $reviewee
      revieweeType: $revieweeType
      context: $context
      contextType: $contextType
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
      totalCount
      items {
        key: _id
        _id
        rating
        title
        content
      }
    }
  }
`

// language=graphql
export const GET_PROMO_CODES_CLEVERSELL = `
query getPromoCodes(
  $searchTerm: String
  $sort: PromoCodeSort
) {
  getPromoCodes(
    searchTerm: $searchTerm
    sort: $sort
  ) {
    totalCount
    items {
      key: _id
      name {
        en
      }
      code
      value
      type
      endDate
      currentUses
    }
  }
}
`

// language=graphql
export const DELETE_PROMO_CODE_CLEVERSELL = `
mutation deletePromoCode (
  $_id: ID!
) {
  deletePromoCode (
    _id: $_id
  ) {
    key: _id
  }
}
`

// language=graphql
export const GET_PROMO_CODE_CLEVERSELL = `
query getPromoCode(
  $_id: ID!
) {
  getPromoCode(
    _id: $_id
  ) {
    key: _id
    name {
      en
    }
    code
    value
    type
    startDate
    endDate
    hasDateRange
    active
    maxTotalUses
    maxUsesPerUser
    currentUses
  }
}
`

// language=graphql
export const ADD_PROMO_CODE_CLEVERSELL = `
mutation addPromoCode (
  $name: PromoCodeNameInput
  $code: String!
  $value: Float!
  $type: PromoCodeType
  $active: Boolean!
  $maxTotalUses: Int
  $maxUsesPerUser: Int
  $hasDateRange: Boolean
  $startDate: DateTime
  $endDate: DateTime
) {
  addPromoCode (
    name: $name
    code: $code
    value: $value
    type: $type
    active: $active
    maxTotalUses: $maxTotalUses
    maxUsesPerUser: $maxUsesPerUser
    hasDateRange: $hasDateRange
    startDate: $startDate
    endDate: $endDate
  ) {
    _id
    key: _id
  }
}
`

export const EDIT_PROMO_CODE_CLEVERSELL = `
mutation editPromoCode (
  $_id: ID!
  $name: PromoCodeNameInput
  $value: Float
  $type: PromoCodeType
  $active: Boolean
  $maxTotalUses: Int
  $maxUsesPerUser: Int
  $hasDateRange: Boolean
  $startDate: DateTime
  $endDate: DateTime
) {
  editPromoCode (
    _id: $_id
    name: $name
    value: $value
    type: $type
    active: $active
    maxTotalUses: $maxTotalUses
    maxUsesPerUser: $maxUsesPerUser
    hasDateRange: $hasDateRange
    startDate: $startDate
    endDate: $endDate
  ) {
    key: _id
    _id
  }
}
`
export const GET_CURRENT_MODERATOR = `
  query getCurrentPlayer {
    getCurrentPlayer {
      _id
        permissions {
          _id
          permission
      
      }
      name 
    }
  }
`
export const GET_ALL_PLAYER_PERMISSIONS = `
   query GET_ALL_PLAYER_PERMISSIONS  (
    $searchTerm: String
    $sort: PlayerPermissionsSort
    $page: Int
    $pageSize: Int
  ) {
    getAllPlayerPermissions(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
    ) {
    totalCount
      items {
        key: _id
      _id

      permission
      displayName
    }
  }
  }
`
export const DELETE_PLAYER_PERMISSION = `
   mutation DELETE_PLAYER_PERMISSION ($_id: ID!)  {
    deletePlayerPermission(_id:$_id){
    _id
  }
  }
`
export const ADD_PLAYER_PERMISSION = `
mutation ADD_PLAYER_PERMISSION($displayName: String!, $permission: String!) {
  addPlayerPermission(displayName: $displayName, permission: $permission) {
    _id
    key: _id
    permission
    displayName
  }
}
`

export const GET_PLAYER_PERMISSION = `
  query  GET_PLAYER_PERMISSION($_id:ID!){
      getPlayerPermission(_id:$_id){
         key: _id
        _id

        permission
        displayName
      }
  }
`
export const EDIT_PLAYER_PERMISSION = `
  mutation  EDIT_PLAYER_PERMISSION($displayName:String,$_id:ID!,$permission:String){
      editPlayerPermission(displayName:$displayName,_id:$_id,permission:$permission){
         key: _id
        _id
        permission
        displayName
      }
  }
`
export const GET_MODERATORS = getPlayersForType('moderator')

export const GET_GUIDE_BOOK = `
  query  GET_GUIDE_BOOK($_id:ID!){
      getGuideBook(_id:$_id){
         key: _id
        _id
        title
        description
        image
        enabled
        isDigital
        price
        shippingPrice
      }
  }
`
export const GET_GUIDE_BOOKS = `
  query  GET_GUIDE_BOOKS (  
    $searchTerm: String
    $sort:GuideBookSort
    $page: Int
    $pageSize: Int
    ){
    getGuideBooks(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
      ) {
            totalCount
            items {
            key: _id
            _id
            title
            description
            isDigital
            image
            price
            shippingPrice
            createdAt
            published:enabled
            }
        }  
  }
`

export const DELETE_GUIDE_BOOK = `
   mutation DELETE_GUIDE_BOOK ($_id: ID!)  {
    deleteGuideBook(_id:$_id){
    _id
    }
  }
`
export const ADD_GUIDE_BOOK = `
   mutation ADD_GUIDE_BOOK (
    $title:String!,
    $description:String!,
    $image:String,
    $enabled:Boolean,
    $isDigital:Boolean,
    $price:Float!,
    $shippingPrice:Float!,
    )  {
    addGuideBook(
        title:$title,
        description:$description,
        image:$image,
        enabled:$enabled,
        isDigital:$isDigital,
        price:$price,
        shippingPrice:$shippingPrice,
    ){
        key: _id
        _id
        title
        description
        image
        enabled
        isDigital
        price
        shippingPrice
    }
  }
`
export const EDIT_GUIDE_BOOK = `
   mutation EDIT_GUIDE_BOOK (
    $_id: ID!
    $title:String!,
    $description:String!,
    $image:String!,
    $enabled:Boolean,
    $isDigital:Boolean,
    $price:Float!,
    $shippingPrice:Float!,
    )  {
    editGuideBook(
        _id: $_id
        title:$title,
        description:$description,
        image:$image,
        enabled:$enabled,
        isDigital:$isDigital,
        price:$price,
        shippingPrice:$shippingPrice,
    ){
        key: _id
        _id
    }
  }
`
export const GET_GUIDE_BOOK_REQUEST = `
  query  GET_GUIDE_BOOK_REQUEST($_id:ID!){
      getGuideBookRequest(_id:$_id){
         key: _id
        _id
      requestId
      player {
        _id
        name
        email
        phone
        nameObject {
          ar
        }
      }
      guideBooksCopies {
        _id
        title
        description
        image
        enabled
        isDigital
        price
        shippingPrice
      }
      status
      orderId
      receipt {
        createdAt
      }
      price
      shippingAddress{
        recipient
        phone
        governorate
        city
        area
        street
        floor
        apartment
        landmark
        }
      }
  }
`
export const GET_GUIDE_BOOK_REQUESTS = `
  query  GET_GUIDE_BOOK_REQUESTS (  
    $searchTerm: String
    $sort:GuideBookRequestSort
    $page: Int
    $pageSize: Int
    $status:GuideBookRequestStatus
    ){
    getGuideBookRequests(
      searchTerm: $searchTerm
      sort: $sort
      page: $page
      pageSize: $pageSize
      status: $status
      ) {
            totalCount
            items {
            key: _id
            _id
            player {
                name
                email
                }
            requestId
            guideBooksCopies {
                title
            }
            createdAt
            status
        }  
    }  
  }
`
export const DELETE_GUIDE_BOOK_REQUEST = `
   mutation DELETE_GUIDE_BOOK_REQUEST ($_id: ID!)  {
    deleteGuideBookRequest(_id:$_id){
    _id
    }
  }
`
export const EDIT_GUIDE_BOOK_REQUEST = `
   mutation EDIT_GUIDE_BOOK_REQUEST (
    $_id: ID!
    $status:GuideBookRequestStatus,
    $shippingAddress:ShippingAddressRequestInput,
    )  {
    editGuideBookRequest(
        _id: $_id
        status:$status,
        shippingAddress:$shippingAddress,
    ){
        key: _id
        _id
        shippingAddress{
            recipient
            phone
            governorate
            city
            area
            street
            floor
            apartment
            landmark
        }
        requestId
        orderId
    }
  }
`
export const GET_ALL_MODERATOR_LOGS = `
   query GET_ALL_MODERATOR_LOGS (  
    $searchTerm: String
    $sort:PlayerLogsSort
    $page: Int
    $pageSize: Int
    ) { 
    getAllPlayerLogs(
        searchTerm: $searchTerm
        sort: $sort
        page: $page
        pageSize: $pageSize
        ) {
    items {
        key: _id
        _id
        message
        playerName
        playerEmail
        createdAt}
    }
  }
`
export const IMPORT_STUDENTS_MUTATION = `
  mutation ImportStudents($url: String!, $schoolId: String!, $dryRun: Boolean) {
    importStudents(url: $url, schoolId: $schoolId, dryRun: $dryRun)
  }
`
export const EXPORT_ROUND_SCORES_FOR_SCHOOL = `
  mutation exportRoundScoresForSchoolAsCsv($schoolId: String!, $roundId: String!) {
    exportRoundScoresForSchoolAsCsv(schoolId: $schoolId, roundId: $roundId)
  }
`
export const EXPORT_ROUND_ADMISSIONS_FOR_SCHOOL = `
  mutation exportRoundAdmissionsForSchoolAsCsv($schoolId: String!, $roundId: String!) {
    exportRoundAdmissionsForSchoolAsCsv(schoolId: $schoolId, roundId: $roundId)
  }
`
