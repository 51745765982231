import React from 'react'
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import { makeStyles } from '@material-ui/core/styles'

const ButtonInput = ({
  icon,
  uri,
  size,
  fullWidth,
  variant,
  loading,
  disabled,
  children,
  style,
  width,
  margin,
  onClick,
  type,
  disableRipple,
}) => {
  const buttonClasses = useButtonStyles({ width, margin })
  const Icon = icon

  return (
    <>
      <Button
        classes={buttonClasses}
        disabled={disabled || loading}
        startIcon={Icon ? <Icon style={{ fontSize: 'inherit' }} /> : null}
        disableRipple={disableRipple}
        variant={variant}
        color='primary'
        fullWidth={fullWidth}
        size={size}
        component={uri ? Link : 'button'}
        to={uri}
        onClick={onClick}
        style={style}
        type={type}
      >
        {loading && <CircularProgress size={14} color='inherit' style={{ marginRight: 8 }} />}
        {children}
      </Button>
    </>
  )
}

const useButtonStyles = makeStyles((theme) => ({
  root: {
    borderRadius: 8,
    position: 'relative',
    padding: 0,
    height: '2rem',
    width: (props) => props.width,
    margin: (props) => props.margin,
    fontSize: theme.typography.button.fontSize,
    textTransform: 'uppercase',
    fontFamily: 'Barlow Condensed',
    fontWeight: 400,
    boxShadow: theme.shadows[25],
    '&:hover': {
      color: theme.palette.text.secondary,
    },
  },
  text: {
    boxShadow: theme.shadows[0],
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  sizeSmall: {
    height: '2.3rem',
    fontSize: theme.typography.body1.fontSize,
    width: (props) => props.width,
    margin: (props) => props.margin,
    boxShadow: theme.shadows[3],
    '&$text': {
      boxShadow: theme.shadows[0],
    },
  },
  sizeLarge: {
    height: '4rem',
    fontSize: theme.typography.body2.fontSize,
    width: (props) => props.width,
    margin: (props) => props.margin,
    boxShadow: theme.shadows[26],
    '&$text': {
      boxShadow: theme.shadows[0],
    },
  },
}))

export default ButtonInput
