import { useEffect } from 'react'
import { useStateMachine } from 'little-state-machine'
import { useQuery } from 'react-query'

import useGetCurrentModerator from './useGetCurrentModerator'
import { setPermissions } from '../store/actions'
import parseStructure from './parseStructure'

function usePermissions () {
  const { actions, state } = useStateMachine({ setPermissions }, { shouldReRenderApp: false })
  const structure = parseStructure(state.structure)
  const token = state.token
  const getCurrentModerator = useGetCurrentModerator()

  const { data: permissions, isFetching } = useQuery('permissions', () => getCurrentModerator(structure, token), {
    refetchOnMount: false,
  })
  if (state.accountType !== 'moderator') {
    return { permissions: state.permissions, accountType: state.accountType }
  }

  useEffect(() => {
    actions.setPermissions({ permissions })
  }, [isFetching])

  return { permissions, accountType: state.accountType }
}

export default usePermissions
