import React from 'react'
import Badge from '@material-ui/core/Badge'

const AccountStatus = ({ record }) => {
  return (
    <Badge badgeContent={record?.active ? 'active' : 'inactive'} color={record?.active ? 'primary' : 'secondary'} />
  )
}

export default AccountStatus
