import React from 'react'
import * as R from 'ramda'
import { Controller } from 'react-hook-form'

import CoreComponents from '../components/core'
import CustomComponents from '../components/custom'

const filterField = ({ field, itemInArray, indexInArray, formMethods, moduleStructure }) => {
  if (typeof field.condition === 'function' && !field.condition(R, { value: R.path(field.key.split('.'), formMethods.getValues()), formValues: formMethods.getValues(), itemInArray, indexInArray })) return null

  let Comp
  if (field.type === 'custom') Comp = CustomComponents[field.name]
  else Comp = CoreComponents[field.type]

  if (field.type === 'array') {
    return (
      <Comp
        {...field}
        moduleStructure={moduleStructure}
        name={field.key}
        dataIndex={R.isNil(indexInArray) ? field.key : `${field.arrayKey}[${indexInArray}].${field.key}`}
      />
    )
  } else {
    return (
      <Controller
        sort={field.sortOrder}
        key={field.key}
        name={R.isNil(indexInArray) ? field.key : `${field.arrayKey}[${indexInArray}].${field.key}`}
        control={formMethods.control}
        defaultValue={R.path(field.key?.split('.'), itemInArray) || field.defaultValue}
        rules={{ validate: (value) => field.validate(R, { value, formValues: formMethods.getValues(), ...field.arrayKey && { itemInArray: R.path([field.arrayKey, indexInArray], formMethods.getValues()) }, indexInArray }) }}
        render={(
          { onChange, onBlur, value },
        ) => {
          return (
            <Comp
              {...field}
              onBlur={onBlur}
              onChange={onChange}
              value={value}
              moduleStructure={moduleStructure}
              name={R.isNil(indexInArray) ? field.key : `${field.arrayKey}[${indexInArray}].${field.key}`}
              error={field.arrayKey ? !!R.path([...field.arrayKey.split('.'), indexInArray, ...field.key.split('.')], formMethods.errors) : !!R.path(field.key.split('.'), formMethods.errors)}
              helperText={(
              field.arrayKey
                ? R.path([...field.arrayKey.split('.'), indexInArray, ...field.key.split('.')], formMethods.errors)
                  ? R.path([...field.arrayKey.split('.'), indexInArray, ...field.key.split('.'), 'message'], formMethods.errors)
                  : field.helperText || ' '
                : R.path(field.key.split('.'), formMethods.errors)
                  ? R.path([...field.key.split('.'), 'message'], formMethods.errors)
                  : field.helperText || ' '
            )}
            />
          )
        }}
      />
    )
  }
}

export default filterField
