import React, { forwardRef, useState, useEffect, Fragment, useCallback } from 'react'
import { equals, reject, isNil } from 'ramda'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import ImagesView from './ImagesView'
import * as Icons from '../../../Icons'
const AddIcon = Icons.Add

const MyUpload = forwardRef(({
  label = '',
  value,
  onChange = () => {},
  multiple = false,
  name = '',
  onMounted = () => {},
  disabled = false,
}, ref) => {
  const [state, setState] = useState(multiple ? [] : undefined)
  const [previews, setPreviews] = useState([])
  const [randomId, seRandomId] = useState()
  const classes = useStyles()
  const theme = useTheme()

  useEffect(() => {
    if (value && (typeof value === 'string' || Array.isArray(value)) && !equals(value, state)) handleChange(value)
  }, [value]) // eslint-disable-line

  useEffect(() => {
    seRandomId(Math.random())
    onMounted(name)
  }, []) // eslint-disable-line

  const handleChange = (val) => {
    let value
    if (Array.isArray(val)) {
      if (!multiple) value = val.pop()
      else value = val
    } else {
      if (!multiple) value = val
      else value = [val]
    }
    setState(value)
    if (onChange) onChange(value, name)
  }

  const onImageListChange = (newList, index) => {
    if (!index) { // change position
      onChange(newList)
    } else { // remove item
      const newState = state.filter((item, i) => i !== index)
      onChange(newState)
    }
  }

  const onFileAdded = async (fileList) => {
    if (multiple) {
      const files = []
      for (const file of fileList) { files.push(file) }
      onChange([...state, ...files])
    } else {
      onChange([fileList[0]])
    }
  }

  const getBase64 = useCallback((file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => resolve(reader.result)
      reader.onerror = (error) => reject(error)
    })
  }, [])

  const getPreviews = useCallback(async (list) => {
    const previewList = [...list]
    for (const i in previewList) {
      if (typeof previewList[i] === 'string') continue
      previewList[i] = await getBase64(previewList[i])
    }
    setPreviews([...previewList])
  }, [getBase64, setPreviews])

  useEffect(() => {
    if (multiple) getPreviews(state)
    else getPreviews(reject(isNil, [state]))
  }, [getPreviews, multiple, state])

  return (
    <>
      <input
        accept='image/*'
        className={classes.input}
        id={randomId}
        multiple={multiple}
        type='file'
        onChange={(e) => onFileAdded(e.target.files)}
      />
      <Grid container spacing={1}>
        {label &&
          <Grid item xs={12}>
            <Typography style={{ color: theme.palette.info.main, fontSize: theme.typography.h5.fontSize }}> {label} </Typography>
          </Grid>}
        <Grid item>
          <ImagesView list={previews} onChange={onImageListChange} readOnly={disabled} />
        </Grid>
        {!disabled &&
          <Grid item>
            <label htmlFor={randomId}>
              <Button variant='outlined' className={classes.button} color='primary' component='span' disabled={disabled}>
                <Box display='flex' justifyContent='center' flexDirection='column' alignItems='center'>
                  <AddIcon />
                  Upload
                </Box>
              </Button>
            </label>
          </Grid>}
      </Grid>
    </>
  )
})

const useStyles = makeStyles((theme) => ({
  input: {
    display: 'none',
  },
  button: {
    width: 100,
    height: 100,
    borderRadius: 8,
  },
}))

export default MyUpload
