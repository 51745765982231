import React from 'react'
import { ThemeProvider, useTheme, makeStyles } from '@material-ui/core/styles'
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core'
import { useStateMachine } from 'little-state-machine'

export const GlobalTheme = ({ children }) => {
  const theme = useTheme()
  const { state } = useStateMachine()

  const themeOverrides = {
    overrides: {
      MuiInput: {
        root: {
          fontSize: '1.3rem',
          fontWeight: 300,
          '&:hover:before': {
            borderWidth: '1px !important',
          },
        },
      },
      MuiInputLabel: {
        root: {
          color: '#bfbfbf',
        },
        shrink: {
          color: ['#4cc2c0', '!important'],
        },
      },
      MuiFormHelperText: {
        root: {
          color: '#4cc2c0',
        },
      },
    },

  }

  const lightTheme = createMuiTheme({
    palette: {
      type: 'light',
      primary: {
        main: '#ef414d',
      },
      secondary: {
        main: '#2b5264',
      },
      info: {
        main: '#4cc2c0',
      },
      background: {
        paper: '#fff',
        default: '#fafafa',
      },
      text: {
        primary: '#000',
        secondary: '#fff',
      },
    },
    typography: {
      fontFamily: 'Barlow Condensed',
      h2: {
        fontWeight: 400,
        fontSize: '2.5rem',
      },
    },
    shadows: [...theme.shadows, '4px 4px 3px 0 rgba(9, 11, 23, 0.79), -1px 3px 3px 0 rgba(9, 11, 23, 0.79)', '3px 3px 2px 0 rgba(9, 11, 23, 0.48)'],
    ...themeOverrides,
  })

  const darkTheme = createMuiTheme({
    palette: {
      type: 'dark',
      primary: {
        main: '#ef414d',
      },
      secondary: {
        main: '#292e3a',
      },
      info: {
        main: '#4cc2c0',
      },
      background: {
        paper: '#191b2a',
        default: '#292e3a',
      },
      text: {
        primary: '#fff',
        secondary: '#fff',
      },
    },
    typography: {
      fontFamily: 'Barlow Condensed',
    },
    shadows: [
      ...theme.shadows,
      '4px 4px 3px 0 rgba(9, 11, 23, 0.79), -1px 3px 3px 0 rgba(9, 11, 23, 0.79)',
      '5px 4px 5px 0 rgba(9, 11, 23, 0.79), -1px 3px 3px 0 rgba(9, 11, 23, 0.79)',
    ],
    ...themeOverrides,
  })

  return (
    <ThemeProvider theme={state.theme === 'light' ? responsiveFontSizes(lightTheme) : responsiveFontSizes(darkTheme)}>
      <ThemedComponent>
        {children}
      </ThemedComponent>
    </ThemeProvider>
  )
}

export default GlobalTheme

const ThemedComponent = ({ children }) => {
  useGlobalStyles()

  return (
    <>
      {children}
    </>
  )
}

const useGlobalStyles = makeStyles((theme) => ({
  '@global': {
    '*': {
      scrollbarColor: [theme.palette.primary.main, 'transparent'],
      scrollbarWidth: 'thin',
    },
    '*::-webkit-scrollbar': {
      width: '.4rem',
      heigh: '.2rem',
    },
    '*::-webkit-scrollbar-button': {
      width: 0,
      heigh: 0,
      background: 'transparent',
    },
    '*::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
    },
    body: {
      background: theme.palette.background.paper,
      margin: 0,
    },
  },
}))
