import { makeStyles } from '@material-ui/core/styles'
import React, { useState } from 'react'
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete'
import { Input } from '@material-ui/core'
import InputAdornment from '@material-ui/core/InputAdornment'
import * as Icons from '../../../Icons'

const { Search } = Icons

const useSearchStyles = makeStyles({
  container: {
    position: 'absolute',
    zIndex: '1',
    background: 'white',
    width: '80%',
    top: '10px',
    left: '15px',
  },
  suggestion: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    padding: '4px 6px',
  },
  suggestionActive: {
    backgroundColor: '#fafafa',
    cursor: 'pointer',
    padding: '4px 6px',
  },
  dropDownContainer: {
    boxShadow: '0px 0px 20px rgba(71, 71, 71, 0.1)',
  },
  input: { padding: '6px 15px 7px' },
  searchIcon: { marginRight: 4 },
})

export const LocationSearch = ({ onSelect, searchOptions }) => {
  const [address, setAddress] = useState('')
  const styles = useSearchStyles()

  const handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log('Success', onSelect(latLng)))
      .catch((error) => console.error('Error', error))
  }

  return (
    <div className={styles.container}>
      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <Input
              fullWidth
              classes={{ input: styles.input }}
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              endAdornment={<InputAdornment position='end'><Search className={styles.searchIcon} /></InputAdornment>}
            />
            <div className={styles.dropDownContainer}>
              {loading && <div className={styles.suggestion}>Loading...</div>}
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.placeId}
                  {...getSuggestionItemProps(suggestion, {
                    className: suggestion.active
                      ? styles.suggestionActive
                      : styles.suggestion,
                  })}
                >
                  <span>{suggestion.description}</span>
                </div>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  )
}
