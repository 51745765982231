import React from 'react'
import useRequest from '../../../helpers/useRequest'
import { useSnackbar } from 'notistack'
import { useMutation } from 'react-query'
import * as endpoints from '../../../config/endpoints'
import Button from '../../core/Button/Button'
import GetAppIcon from '@material-ui/icons/GetApp'
import * as queries from '../../../gql/queries'
import { saveAs } from 'file-saver'
import { last, path } from 'ramda'

const DownloadFileButton = ({ field, record }) => {
  const { request } = useRequest()

  const { enqueueSnackbar } = useSnackbar()

  const { endpoint } = field
  const { query, urlPath, title } = field.customProps
  const [mutate, { isLoading }] = useMutation((variables) => {
    return request({
      query: queries[query],
      variables,
      endpoint: endpoints[endpoint],
    })
  })

  const handleClick = () => {
    mutate({ _id: record._id })
      .then((resp) => {
        const url = path(urlPath, resp)
        saveAs(url, last(url.split('/')))
      })
      .catch((e) => {
        enqueueSnackbar('Failed to download file', { variant: 'error' })
      })
  }

  return (
    <>
      <Button loading={isLoading} onClick={handleClick}>
        {title ?? 'Download'} <GetAppIcon />
      </Button>
    </>
  )
}

export default DownloadFileButton
