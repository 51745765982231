import React, { useState } from 'react'
import Box from '@material-ui/core/Box'

import DateRange from './DateRange'
import ExportCsv from './ExportCsv'
import Totals from './Totals'
import Taxonomies from './Taxonomies'
import Products from './Products'

export default function OrderPage ({ moduleStructure }) {
  const [minDate, setMinDate] = useState()
  const [maxDate, setMaxDate] = useState()

  return (
    <div style={{ padding: '0 10px' }}>
      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <DateRange minValue={minDate} onMinValueChange={(val) => setMinDate(val)} maxValue={maxDate} onMaxValueChange={(val) => setMaxDate(val)} />
        <ExportCsv moduleStructure={moduleStructure} minDate={minDate} maxDate={maxDate} />
      </Box>
      <Totals moduleStructure={moduleStructure} minDate={minDate} maxDate={maxDate} />
      <Taxonomies moduleStructure={moduleStructure} minDate={minDate} maxDate={maxDate} />
      <Products moduleStructure={moduleStructure} minDate={minDate} maxDate={maxDate} />
    </div>
  )
}
