import { useCardStyles } from './InfoCardTab'
import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Divider, Link, Typography } from '@material-ui/core'
import { Link as LinkIcon } from '@material-ui/icons'

export const ScoreCard = ({ events, admission }) => {
  const cardClasses = useCardStyles()

  return (
    <div className={cardClasses.card}>
      <Grid container>
        {events.map(({ name, score: { scoreReportPdfPath, total, subjects } }) => (
          <React.Fragment key={name}>
            <Grid item xs={8} key={name} style={{ marginBottom: 16 }}>
              <Typography variant='h5'>
                {name}
                {scoreReportPdfPath && ' - '}
                {scoreReportPdfPath && (
                  <Link target='_blank' href={scoreReportPdfPath} rel='noreferrer' style={{ position: 'relative' }}>
                    PDF Report
                    <LinkIcon style={{ position: 'absolute', top: 4 }} />
                  </Link>
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant='h5' align='right'>
                {total ? `Total: ${total}` : ''}
              </Typography>
            </Grid>
            {subjects.map((sub) => (
              <React.Fragment key={sub.name}>
                <Grid item xs={8} style={{ marginBottom: 16 }}>
                  {sub.name} ({sub.range.min} - {sub.range.max}):
                </Grid>
                <Grid item xs={4} style={{ textAlign: 'right' }}>
                  {sub.type === 'NA' ? 'N/A' : sub.score}
                </Grid>
              </React.Fragment>
            ))}
            <Divider />
          </React.Fragment>
        ))}
        {admission?.ESTOneReportReference && (
          <>
            <Grid item xs={8} style={{ marginBottom: 16 }}>
              EST I report reference:
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              {admission?.ESTOneReportReference}
            </Grid>
          </>
        // eslint-disable-next-line
        )}

        {admission?.ESTTwoReportReference && (
          <>
            <Grid item xs={8} style={{ marginBottom: 16 }}>
              EST II report reference:
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              {admission?.ESTTwoReportReference}
            </Grid>
          </>
        // eslint-disable-next-line
        )}     
      </Grid>
    </div>
  )
}
