import React from 'react'
import Grid from '@material-ui/core/Grid'
import * as R from 'ramda'

import getFormField from './getFormField'
import VerticalTabs from '../components/core/Tabs/VerticalTabs'

const getFields = ({ fields, moduleStructure, itemInArray, indexInArray, formMethods, noTabs }) => {
  if (noTabs) return getFieldsWithNoTabs({ fields, moduleStructure, itemInArray, indexInArray, formMethods })

  const tabs = R.groupBy(R.prop('tab'), fields)
  if (Object.keys(tabs)[0] === 'undefined') return getFieldsWithNoTabs({ fields, moduleStructure, itemInArray, indexInArray, formMethods })

  const tabFields = Object.entries(tabs).map(([key, f]) => {
    const finalFields = getFieldsWithNoTabs({ fields: f, moduleStructure, itemInArray, indexInArray, formMethods })
    return { key, label: key, content: finalFields }
  })

  return (
    <VerticalTabs tabs={tabFields} />
  )
}

const getFieldsWithNoTabs = ({ fields, moduleStructure, itemInArray, indexInArray, formMethods }) => {
  const normalFields = fields.filter((field) => !field.grid && (itemInArray || !field.arrayKey)).map((field, index) => getFormField({ field: { ...field, sortOrder: field.sortOrder || index }, itemInArray, indexInArray, formMethods, moduleStructure }))
  const gridFields = fields.filter((field) => field.grid && (itemInArray || !field.arrayKey)).map((field, index) => ({ ...field, sortOrder: field.sortOrder || index })).sort((field1, field2) => field1.sortOrder - field2.sortOrder)
  const groupedGridFields = R.groupBy(R.path(['grid', 'name']), gridFields)
  const mapGroupedGridFields = (value, key) => {
    return (
      <Grid container key={key} spacing={2} sort={value[0].sortOrder} alignContent='center' alignItems='center' justify='center'>
        {
          value.map((field) => (
            <Grid item key={field.key} xl={field.grid.xl} lg={field.grid.lg} md={field.grid.md} sm={field.grid.sm} xs={field.grid.xs}>
              {getFormField({ field, itemInArray, indexInArray, formMethods, moduleStructure })}
            </Grid>
          ))
        }
      </Grid>
    )
  }
  const finalGridFields = R.mapObjIndexed(mapGroupedGridFields, groupedGridFields)
  const finalFields = [...normalFields, ...Object.values(finalGridFields)].filter((item) => item).sort((field1, field2) => field1.props.sort - field2.props.sort)
  return finalFields
}

export default getFields
