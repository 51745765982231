import React, { useEffect } from 'react'
import { useMutation } from 'react-query'
import { useSnackbar } from 'notistack'
import { last, path } from 'ramda'
import { saveAs } from 'file-saver'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'

import * as endpoints from '../../../config/endpoints'
import useRequest from '../../../helpers/useRequest'
import { SUP_EXPORT_TOP_PRODUCTS_REPORT_AS_CSV } from '../../../gql/queries'

const ExportCsv = ({ moduleStructure, minDate, maxDate }) => {
  const { request } = useRequest()
  const { enqueueSnackbar } = useSnackbar()

  const variables = { ...(minDate && maxDate) && { dateRange: { min: `${new Date(minDate).setHours(0, 0, 0, 0).valueOf()}`, max: `${new Date(maxDate).setHours(23, 59, 59, 0).valueOf()}` } } }
  const [mutate, { isLoading, isError, error }] = useMutation(() => request({
    query: SUP_EXPORT_TOP_PRODUCTS_REPORT_AS_CSV,
    endpoint: endpoints[moduleStructure.endpoint],
    variables,
  }))

  useEffect(() => {
    if (isError && error.message) {
      enqueueSnackbar(error.message, { variant: 'error' })
    }
  }, [isError, error])

  const handleClick = () => {
    mutate()
      .then((resp) => {
        const url = path(['exportSupTopProductsAsCsv'], resp)
        return saveAs(url, last(url.split('/')))
      })
      .catch((e) => enqueueSnackbar('Failed to download file', { variant: 'error' }))
  }

  return (
    <Button
      onClick={handleClick}
      disabled={isLoading}
      variant='contained'
      color='primary'
    >
      {isLoading ? <CircularProgress size={25} disableShrink /> : <span>EXPORT CSV</span>}
    </Button>
  )
}

export default ExportCsv
