import React, { useState } from 'react'
import Dialog from '../../core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import * as Icons from '../../../Icons'
import Table from '../../core/Table'
import { simpleAddOns } from '../SingleOrder/fields'

const ExitToAppIcon = Icons.ExitToApp

export default function SingleAddOn ({ addOns }) {
  const [dialogVisible, setDialogVisible] = useState(false)

  return (
    <>
      <IconButton size='small' onClick={() => setDialogVisible(true)}>
        <ExitToAppIcon style={{ fontSize: '1.7rem' }} />
      </IconButton>
      <Dialog label='Add-Ons, Per 1 Item' open={dialogVisible} onClose={() => setDialogVisible(false)}>
        <Table fields={simpleAddOns} records={addOns} />
      </Dialog>
    </>
  )
}
