import React from 'react'
import { Link } from 'react-router-dom'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { Button } from '@material-ui/core'

export const OrderFields = [
  {
    key: 'orderId',
    type: 'text',
    label: 'Order ID',
    permissions: ['view.module.order'],
    sortOrder: 1,
    formatField: (R, value) => {
      return value
    },
  },
  {
    key: 'vendorCopy',
    type: 'text',
    label: 'vendor',
    permissions: ['view.module.order.super'],
    sortOrder: 1,
    formatField: (R, value) => {
      return R.path(['name', 'en'], value)
    },
  },
  {
    key: 'createdAt',
    type: 'text',
    label: 'Date',
    permissions: ['view.module.order'],
    sortOrder: 1,
    formatField: (R, value) => {
      return new Date(Number(value)).toLocaleString('en-us', {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
      })
    },
  },
  {
    key: 'totals.total',
    type: 'text',
    label: 'Total',
    permissions: ['view.module.order'],
    sortOrder: 2,
    formatField: (R, value) => {
      return parseFloat(value)
        .toFixed(2)
    },
  },
  {
    key: 'status',
    type: 'custom',
    label: 'Status',
    permissions: ['view.module.order'],
    sortOrder: 2,
    name: 'SelectOrderStatusAction',
    endpoint: process.env.REACT_APP_ENDPOINT,
  },
  {
    key: 'details',
    type: 'goTo',
    label: 'Details',
    permissions: ['view.module.order'],
    sortOrder: 3,
    route: '/orders/:key/view',
  },
]

export const RequestFields = [
  {
    key: 'variation.product.name.en',
    type: 'text',
    label: 'Product',
    permissions: ['view.module.orderRequest'],
    sortOrder: 1,
    formatField: (R, value) => {
      return value
    },
  },
  {
    key: 'quantity',
    type: 'text',
    label: 'Quantity',
    permissions: ['view.module.orderRequest'],
    sortOrder: 2,
    formatField: (R, value) => {
      return value
    },
  },
  {
    key: 'price',
    type: 'text',
    label: 'Price',
    permissions: ['view.module.orderRequest'],
    sortOrder: 3,
    formatField: (R, value) => {
      return parseFloat(value)
        .toFixed(2)
    },
  },
  {
    key: 'vendor.name.en',
    type: 'text',
    label: 'Vendor',
    permissions: ['view.module.orderRequest.super'],
    sortOrder: 4,
    formatField: (R, value) => {
      return value
    },
  },
  {
    key: 'vendor',
    type: 'custom',
    label: 'Paid',
    name: 'CheckoutPaymentStatus',
    endpoint: 'SUP_MS',
    permissions: ['view.module.orderRequest'],
    sortOrder: 5,
  },
  {
    key: 'accept',
    type: 'custom',
    label: 'Accept',
    permissions: ['view.module.orderRequest'],
    sortOrder: 6,
    name: 'AcceptOrderRequest',
    endpoint: process.env.REACT_APP_ENDPOINT,
  },
  {
    key: 'reject',
    type: 'custom',
    label: 'Reject',
    permissions: ['view.module.orderRequest'],
    sortOrder: 7,
    name: 'RejectOrderRequest',
    endpoint: process.env.REACT_APP_ENDPOINT,
  },
]

export const sortOptions = [
  { key: '', label: 'Default' },
  { label: 'Newest', key: 'Newest' },
  { label: 'Oldest', key: 'Oldest' },
]

export const fulfilmentCells = [
  { key: 'Fulfillment ID', path: ['checkoutId'] },
  { key: 'status', path: ['status'] },
  {
    key: '',
    path: ['_id'],
    formatter: (key) => (
      <Link type='link' style={{ alignSelf: 'center', textDecoration: 'none' }} to={`/fulfillment/${key}/view`}>
        <Button variant='contained' color='primary'>
          View Fulfillment
          <ExitToAppIcon />
        </Button>
      </Link>
    ),
  },
]
